@import "../utils/colors.scss";

// Widget Title Controls
@mixin largeTitle {
  color: $Brand100;
  font-size: 30px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}

@mixin regularTitle {
  color: $Brand100;
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: left;
}

@mixin smallTitle {
  color: $Brand100;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@mixin headingBold {
  color: $Brand100;
  font-size: 30px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

@mixin headingRegular {
  color: $Brand100;
  font-size: 24px;
  font-weight: 600;
  line-height: 26.4px;
  text-align: left;
}
@mixin headingThin {
  color: $Brand100;
  font-size: 24px;
  font-weight: 400;
  line-height: 26.4px;
  text-align: left;
}

@mixin bodyBold {
  color: $Brand100;
  font-size: 16px;
  font-weight: 600;
  line-height: 17.6px;
  text-align: left;
}

@mixin bodyRegular {
  color: $Brand100;
  font-family: Graphik;
  font-size: 16px;
  font-weight: 500;
  line-height: 17.6px;
  text-align: left;
}

@mixin bodyThin {
  color: $Brand100;
  font-size: 16px;
  font-weight: 400;
  line-height: 17.6px;
  text-align: left;
}

@mixin secondaryBold {
  color: $Brand100;
  font-size: 14px;
  font-weight: 600;
  line-height: 15.4px;
  text-align: left;
}
@mixin secondaryRegular {
  color: $Brand100;
  font-size: 14px;
  font-weight: 500;
  line-height: 15.4px;
  text-align: left;
}
@mixin secondaryThin {
  color: $Brand100;
  font-size: 14px;
  font-weight: 400;
  line-height: 15.4px;
  text-align: left;
}
@mixin tertiaryBold {
  color: $Brand100;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  text-align: left;
}
@mixin tertiaryRegular {
  color: $Brand100;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
}

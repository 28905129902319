.extendSelectedModal {
  .modalTitle {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    & > *:first-child {
      margin-right: 0.5rem;
    }
  }
  .modal-body {
    display: flex;
    flex: 1;
    justify-content: center;
    & > * {
      display: flex;
      flex: 1;
      min-width: max-content;
    }
  }
}

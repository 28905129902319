.linearCpms {
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  .constraintTable {
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
    padding-bottom: 0.5rem;
    .tableHeader {
      flex-direction: column;
      display: flex;
      justify-content: space-between;
      .tableName {
        font-size: 24px;
      }

      button {
        margin: 5px;
      }
    }

    .networkLogo {
      height: 60px;
    }

    .dataPill {
      border-radius: 7px;
      padding: 10px 20px 10px 20px;

      & > span {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &.withBackground {
        box-shadow: inset 0 -1px 0 0 #f2f4f5;
      }
      &:not(.withBackground) {
        border: 1px solid #eceeef;
      }
      .subValue {
        font-size: 9px;
      }
    }

    .tableContainer {
      flex: 1;
      min-height: 0;
    }
  }
}

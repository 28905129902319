@import "../../utils/colors.scss";

// Styling for horizontal table view
.obttHorizontalTableContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  min-height: 175px;
  .obttHorizontalTableView {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    position: relative;
    border-bottom: 2px solid $GreyHeat100;
    .obttHorizontalHeaderContainer {
      display: flex;
      flex-direction: column;
      position: sticky;
      z-index: 2;
      background-color: $White;
      left: 0px;
      .obttHorizontalEmptyRow {
        display: flex;
        height: 35px;
        padding-bottom: 4px;
      }
      .obttHorizontalHeaderSection {
        display: flex;
        flex-direction: row;
        height: 100%;
        border-top: 2px solid $GreyHeat100;
        .obttHorizontalHeader {
          display: flex;
          flex-direction: column;
          font-size: 1rem;
          .obttHorizontalHeader1 {
            display: flex;
            align-items: center;
            padding: 0px 16px;
            font-weight: 500;
            height: 50%;
            border-bottom: 1px solid $GreyHeat100;
            border-right: 2px solid $GreyHeat100;
            white-space: nowrap;
            .simpleTooltipText {
              white-space: wrap;
            }
            &.bottom {
              border-top: 1px solid $GreyHeat100;
            }
            &.top {
              background-color: $Purple0;
            }
          }
          .obttHorizontalHeader2 {
            display: flex;
            align-items: center;
            padding: 0px 16px;
            font-weight: 400;
            height: 50%;
            border-right: 2px solid $GreyHeat100;
            white-space: nowrap;
            background-color: $Purple0;
            .simpleTooltipText {
              white-space: wrap;
            }
          }
        }
      }
    }
    .obttHorizontalDataSection {
      display: flex;
      .obttHorizontalDataList {
        display: flex;
        flex-direction: row;
        height: 100%;
        .obttHorizontalItem {
          display: flex;
          flex-direction: column;
          padding: 0px;
          min-width: 80px;
          border: 0px;
          border-radius: 0;
          .obttHorizontalDateCell {
            display: flex;
            height: 35px;
            padding: 0px 12px 4px;
            align-items: flex-end;
            justify-content: flex-end;
            font-size: 0.875rem;
            font-weight: 500;
          }
          .obttHorizontalDataCells {
            display: flex;
            flex-direction: column;
            height: 100%;
            font-size: 1rem;
            border-top: 2px solid $GreyHeat100;
            background-color: $Purple0;
            .obttHorizontalCell1 {
              display: flex;
              height: 50%;
              align-items: center;
              justify-content: flex-end;
              width: 100%;
              padding-left: 12px;
              padding-right: 12px;
              border-bottom: 1px solid $GreyHeat100;
              font-weight: 500;
              border-right: 1px solid $GreyHeat100;
              &.last {
                border-right: 0px;
              }
            }
            .obttHorizontalCell2 {
              display: flex;
              height: 50%;
              align-items: center;
              justify-content: flex-end;
              width: 100%;
              padding-left: 12px;
              padding-right: 12px;
              font-weight: 400;
              border-right: 1px solid $GreyHeat100;
              &.last {
                border-right: 0px;
              }
            }
          }
        }
      }
    }
  }
  .obttHorizontalTableBorder {
    display: flex;
    flex-direction: column;
    width: 2px;
    .obttHorizontalEmptySpace {
      display: flex;
      height: 35px;
      padding-bottom: 8px;
      background-color: $White;
    }
    .obttHorizontalEmptyRectangle {
      height: 100%;
      display: flex;
      &.left {
        border-right: 2px solid $GreyHeat100;
      }
      &.right {
        border-left: 2px solid $GreyHeat100;
      }
    }
  }
}
// Styling for vertical table view
.obttVerticalTableContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .obttVerticalTableView {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    position: relative;
    min-width: 799px;
    border-right: 2px solid $GreyHeat100;
    .obttVerticalHeaderContainer {
      display: flex;
      flex-direction: row;
      position: sticky;
      z-index: 2;
      background-color: $White;
      top: 0px;
      .obttVerticalEmptyRow {
        display: flex;
        width: 88px;
        padding-right: 12px;
      }
      .obttVerticalHeaderSection {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-left: 2px solid $GreyHeat100;
        .obttVerticalHeader {
          display: flex;
          flex-direction: row;
          font-size: 1rem;
          height: 36.22px;
          border-bottom: 2px solid $GreyHeat100;
          background-color: $Purple0;
          .obttVerticalHeader1 {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 0px 16px;
            font-weight: 500;
            width: 50%;
            border-right: 1px solid $GreyHeat100;
          }
          .obttVerticalHeader2 {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 0px 16px;
            font-weight: 400;
            width: 50%;
          }
        }
      }
    }
    .obttVerticalDataSection {
      display: flex;
      .obttVerticalDataList {
        display: flex;
        flex-direction: column;
        width: 100%;
        .obttVerticalItem {
          display: flex;
          flex-direction: row;
          padding: 0px;
          height: 36.22px;
          border: 0px;
          border-radius: 0;
          .obttVerticalDateCell {
            display: flex;
            width: 88px;
            padding-right: 12px;
            justify-content: flex-end;
            align-items: center;
            font-size: 0.875rem;
            font-weight: 500;
          }
          .obttVerticalDataCells {
            display: flex;
            flex-direction: row;
            width: 100%;
            font-size: 1rem;
            border-left: 2px solid $GreyHeat100;
            border-bottom: 1px solid $GreyHeat100;
            &.last {
              border-bottom: 0px;
            }
            .obttVerticalCell1 {
              display: flex;
              width: 50%;
              align-items: center;
              justify-content: flex-end;
              padding-left: 16px;
              padding-right: 16px;
              font-weight: 500;
              background-color: $Purple0;
              border-right: 1px solid $GreyHeat100;
            }
            .obttVerticalCell2 {
              display: flex;
              width: 50%;
              align-items: center;
              justify-content: flex-end;
              padding-left: 16px;
              padding-right: 16px;
              font-weight: 400;
              background-color: $Purple0;
            }
          }
        }
      }
    }
  }
  .obttVerticalTableBorder {
    display: flex;
    flex-direction: row;
    height: 2px;
    .obttVerticalEmptySpace {
      display: flex;
      width: 89px;
      background-color: $White;
    }
    .obttVerticalEmptyRectangle {
      width: 100%;
      display: flex;
      &.top {
        border-bottom: 2px solid $GreyHeat100;
      }
      &.bottom {
        border-top: 2px solid $GreyHeat100;
      }
    }
  }
}

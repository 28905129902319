@import "../utils/colors.scss";

.channelChip {
  display: flex;
  align-items: center;
  border-radius: 20px;
  min-width: 110px;
  justify-content: center;
  cursor: pointer;

  &.notLive {
    opacity: 0.5;
  }

  &.streaming {
    background: $Streaming20;
  }
  &.audio {
    background: $Audio20;
  }
  &.tv {
    background: $Linear20;
  }
  &.display {
    background: $Display20;
  }

  &.unselected {
    background: white;
    border: 1.5px solid $Grey60;
    & > * {
      & > * {
        color: $Grey60 !important;
      }
    }
    &:hover {
      filter: drop-shadow(0px 4px 4px rgba(78, 78, 80, 0.2));
      border: 1.5px solid $GreyHeat80;
    }
  }

  .channelChipTitle {
    font-weight: 500;
    font-size: 14px;
    height: 100%;
    display: flex;
    align-items: center;
    .channelChipTitleIcon {
      margin-left: 0.5rem;
      width: 18px;
      height: 18px;
    }
    &.streaming {
      color: $Streaming80;
    }
    &.audio {
      color: $Audio80;
    }
    &.tv {
      color: $Linear80;
    }
    &.display {
      color: $Display80;
    }
    & > * {
      &:first-child {
        margin-right: 6px;
      }
    }
  }

  .channelChipToggle {
    border: none;
    border-radius: 20px;
    &.streaming {
      background: $Streaming20;
      color: $Streaming80;
    }
    &.audio {
      background: $Audio20;
      color: $Audio80;
    }
    &.display {
      background: $Display20;
      color: $Display80;
    }

    &::after {
      display: none !important;
    }
    &:focus {
      &.streaming {
        background: $Streaming20;
      }
      &.audio {
        background: $Audio20;
      }
      box-shadow: none !important;
    }
    &:active {
      outline: none;
      box-shadow: none;
      cursor: pointer;
    }
  }
  .dropdown-menu {
    border: 2px solid $Streaming80;
    border-radius: 4px;
    overflow: hidden;
  }
  .channelChipDropdown {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    font-size: 14px;

    .typeSelector {
      display: flex;
      flex-direction: row;
      margin-bottom: 0.5rem;
      & > * {
        margin-right: 1rem;
        color: $GreyHeat80;
        cursor: pointer;
        transition: color 0.12s ease;
        &.selected {
          color: $Streaming80;
          &::after {
            width: 100%;
            left: 0;
          }
        }
        &:hover {
          color: $Streaming80;
        }
        position: relative;
        &::after {
          content: " ";
          position: absolute;
          background-color: $Streaming80;
          width: 0;
          bottom: -2px;
          left: 50%;
          height: 2px;
          transition: all 0.12s ease;
        }
      }
    }

    .lagSelector {
      display: flex;
      flex-direction: row;
      align-items: center;

      .lagSelectorTitle {
        margin-right: 8px;
      }
      .option {
        color: $GreyHeat80;
        margin-right: 8px;
        margin-right: 1rem;
        color: $GreyHeat80;
        cursor: pointer;
        transition: color 0.12s ease;
        &.selected {
          color: $Streaming80;
          &::after {
            width: 100%;
            left: 0;
          }
        }
        &:hover {
          color: $Streaming80;
        }
        position: relative;
        &::after {
          content: " ";
          position: absolute;
          background-color: $Streaming80;
          width: 0;
          bottom: -2px;
          left: 50%;
          height: 2px;
          transition: all 0.12s ease;
        }
      }
    }
  }
}

.channelChipRedesign {
  display: flex;
  align-items: center;
  border-radius: 36px;
  height: 24px;
  background-color: #977aff;
  color: white;
  padding: 6px 12px 6px 12px;
  font-size: 12px;
  margin: 2px 0px 2px 12px;
  cursor: pointer;
  &.unselected {
    background-color: #f8f7ff;
    color: #6b2def;
  }
}

.orderList {
  display: flex;
  flex-direction: column;
  .pdfLink {
    background: none !important;
    border: none;
    color: #337ab7;
    text-decoration: underline;
    cursor: pointer;
  }
}

@import "../../utils/colors.scss";
@import "../../utils/sassHelpers.scss";

.spendAndEffectModal {
  .modal-xl {
    max-width: 85vw;
    max-height: 80vh;
  }
  ::-webkit-scrollbar {
    height: $scrollbar-width-redesign;
    width: $scrollbar-width-redesign;
  }
  .modal-content {
    border-radius: 16px;
    height: 100% !important;
    min-height: 836px;
    .widgetContainer {
      margin-bottom: 0;
      height: 100%;
      min-height: 836px;
      .spendEffectModalHeader {
        width: 100%;
        padding: 0;
      }
      .widgetBody {
        margin-top: 15px;

        .spendEffectModalBody {
          padding: 0;

          .chartContainer {
            margin: 0;
            height: 744px;
            .header {
              padding: 0 16px;
              .groupRightActions {
                display: flex;
                justify-content: space-between;
                .BPMToggleSwitch {
                  margin-right: 8px;
                  .custom-control-input:checked ~ .custom-control-label::before {
                    background-color: $Brand100;
                    border-color: black;
                  }
                }
              }
            }
            .contents {
              overflow: hidden;
            }
            .SpendAndEffectBarChart {
              .chartLegend {
                padding-top: 8px;
                .legend {
                  margin-right: 8px;
                  .itemNameAndPercent {
                    width: 110px;
                  }
                  .itemLabel {
                    width: 160px;
                  }
                }
              }
            }
            .secondaryChart {
              height: 100%;
              max-width: 475px;
              min-width: 442px;
              .titleWithDropdown {
                display: flex;
                align-items: flex-end;
                span {
                  font-size: 20px;
                }
                .dropdown-toggle {
                  padding-left: 8px;
                }
              }
              .contents {
                overflow: scroll;
              }
              .sankeyContainer {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: flex-start;
                .totalSankeyLabel {
                  font-weight: 600;
                  font-size: 12px;
                  display: flex;
                  justify-content: space-between;

                  .totalSankeyLabelInner {
                    width: 80px;
                  }
                  .zeroPrimaryValue {
                    align-self: flex-end;
                  }
                  &.added {
                    flex-direction: row-reverse;
                    .totalSankeyLabelInner {
                      width: 100px;
                      text-align: end;
                    }
                  }
                }
                .singleSankeyContainer {
                  width: 100%;
                  .zeroDataMessage {
                    text-align: center;
                    background-color: $Grey40;
                    border-radius: 6px;
                  }
                }
                .singleSankeyContainer:not(:first-child) {
                  padding-top: 16px;
                }
                .sankeyChart {
                  width: 100%;
                  display: flex;
                  &.effect {
                    padding-bottom: 5px;
                  }
                  &.added {
                    justify-content: flex-end;
                  }
                  .recharts-wrapper {
                    left: 0;
                  }
                  .recharts-surface {
                    overflow: visible;
                  }
                  .customLinkForeignObject {
                    overflow: visible;
                    width: 250px;
                    display: flex;
                    .sankeyLabelContainer {
                      display: flex;
                      align-items: center;
                      justify-content: flex-start;
                      width: 100%;
                      height: 100%;
                      overflow: visible;
                      .sankeyLabel {
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 1em;
                        &.primary {
                          font-weight: 600;
                        }
                      }
                    }
                    &.valueAdded {
                      .sankeyLabelContainer {
                        justify-content: flex-end;
                        .sankeyLabel {
                          text-align: end;
                        }
                      }
                    }
                  }
                }
              }
            }
            .chartContainer {
              height: auto;
            }
          }
        }
      }
    }
  }
  // TODO fix arrow to be dynamic
  // .barChartTooltip::before {
  //   content: "";
  //   width: 0;
  //   height: 0;
  //   border-style: solid;
  //   border-width: 0 8px 8px 8px;
  //   border-color: transparent transparent $Neutral700 transparent;
  //   position: absolute;
  //   top: -7px;
  //   left: calc(50% - 8px);
  // }
  .barChartTooltip {
    min-height: 28px;
    background-color: $Neutral700;
    color: white;
    border-radius: 4px;
    padding: 3px 8px;
    transform: translate(-50%, 5px);
    font-size: 14px;
  }

  .multiBarChartContainer {
    flex-direction: column;
    flex-grow: 2;
    justify-content: flex-start;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .divider {
    height: 0px;
    width: 100%;
    border-top: solid gray 2px;
  }
  .patternDefinitions {
    height: 0;
    width: 0;
    overflow: hidden;
  }
}

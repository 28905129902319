@import "../../utils/colors.scss";

// Filled button styling
.filled-button {
  animation-duration: 0.3s;
  animation-direction: forwards;
  &.primary {
    &.light {
      background-color: $Brand50;
      color: $White;
      &.hover:not(.disabled),
      &:hover:not(.disabled) {
        background-color: $Brand70;
      }
      &.animated {
        animation-name: brand70ToBrand50;
      }
    }
    &.dark {
      background-color: $White;
      color: $Brand60;
      &.hover:not(.disabled),
      &:hover:not(.disabled) {
        background-color: $Brand20;
        color: $Brand70;
      }
      &.animated {
        animation-name: brand20ToWhite;
      }
    }
  }
  &.secondary {
    &.light {
      background-color: $Neutral200;
      color: $Brand100;
      &.hover:not(.disabled),
      &:hover:not(.disabled) {
        background-color: $Neutral400;
      }
      &.animated {
        animation-name: neutral400ToNeutral200;
      }
    }
    &.dark {
      background-color: $Brand60;
      color: $White;
      &.hover:not(.disabled),
      &:hover:not(.disabled) {
        background-color: $Brand50;
      }
      &.animated {
        animation-name: brand50ToBrand60;
      }
    }
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

// Outlined button styling
.outlined-button {
  animation-duration: 0.3s;
  animation-direction: forwards;
  &.primary {
    &.light {
      background-color: transparent;
      color: $Brand100;
      border: 1px solid $Brand50;
      &.hover:not(.disabled, .animated),
      &:hover:not(.disabled, .animated) {
        border-color: $Brand70;
        background-color: $Brand20;
      }
      &.animated {
        animation-name: brand20ToTransparent;
      }
    }
    &.dark {
      background-color: transparent;
      border: 1px solid $White;
      color: $White;
      &.hover:not(.disabled, .animated),
      &:hover:not(.disabled, .animated) {
        background-color: $Brand70;
      }
      &.animated {
        animation-name: brand70ToTransparent;
      }
    }
  }
  &.secondary {
    &.light {
      background-color: transparent;
      color: $Brand100;
      border: 1px solid $Neutral400;
      &.hover:not(.disabled, .animated),
      &:hover:not(.disabled, .animated) {
        background-color: $Neutral200;
        border-color: $Neutral600;
      }
      &.animated {
        animation-name: neutral200ToTransparent;
      }
    }
    &.dark {
      background-color: transparent;
      border: 1px solid $Brand50;
      color: $White;
      &.hover:not(.disabled, .animated),
      &:hover:not(.disabled, .animated) {
        background-color: $Brand70;
      }
      &.animated {
        animation-name: brand70ToTransparent;
      }
    }
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

// Empty button styling
.empty-button {
  animation-duration: 0.3s;
  animation-direction: forwards;
  &.primary {
    &.light {
      background-color: transparent;
      color: $Brand60;
      &.hover:not(.disabled, .animated),
      &:hover:not(.disabled, .animated) {
        background-color: $Brand20;
        color: $Brand70;
      }
      &.animated {
        animation-name: brand20ToTransparent;
      }
    }
    &.dark {
      background-color: transparent;
      color: $White;
      &.hover:not(.disabled, .animated),
      &:hover:not(.disabled, .animated) {
        background-color: $Brand70;
      }
      &.animated {
        animation-name: brand70ToTransparent;
      }
    }
  }
  &.secondary {
    &.light {
      background-color: transparent;
      color: $Brand100;
      &.hover:not(.disabled, .animated),
      &:hover:not(.disabled, .animated) {
        background-color: $Neutral200;
      }
      &.animated {
        animation-name: neutral200ToTransparent;
      }
    }
    &.dark {
      background-color: transparent;
      color: $Brand30;
      &.hover:not(.disabled, .animated),
      &:hover:not(.disabled, .animated) {
        background-color: $Brand70;
        color: $White;
      }
      &.animated {
        animation-name: brand70ToTransparent;
      }
    }
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

// onClick Animations
@keyframes brand70ToBrand50 {
  0% {
    background-image: radial-gradient(circle at center, $Brand50 0%, $Brand70 0%);
  }
  10% {
    background-image: radial-gradient(circle at center, $Brand50 15%, $Brand70 15%);
  }
  20% {
    background-image: radial-gradient(circle at center, $Brand50 30%, $Brand70 30%);
  }
  30% {
    background-image: radial-gradient(circle at center, $Brand50 45%, $Brand70 45%);
  }
  40% {
    background-image: radial-gradient(circle at center, $Brand50 60%, $Brand70 60%);
  }
  50% {
    background-image: radial-gradient(circle at center, $Brand50 75%, $Brand70 75%);
  }
  60% {
    background-image: radial-gradient(circle at center, $Brand50 90%, $Brand70 90%);
  }
  70% {
    background-image: radial-gradient(circle at center, $Brand50 105%, $Brand70 105%);
  }
  80% {
    background-image: radial-gradient(circle at center, $Brand50 120%, $Brand70 120%);
  }
  90% {
    background-image: radial-gradient(circle at center, $Brand50 135%, $Brand70 135%);
  }
  100% {
    background-image: radial-gradient(circle at center, $Brand50 150%, $Brand70 150%);
  }
}

@keyframes brand20ToWhite {
  0% {
    background-image: radial-gradient(circle at center, $White 0%, $Brand20 0%);
  }
  10% {
    background-image: radial-gradient(circle at center, $White 15%, $Brand20 15%);
  }
  20% {
    background-image: radial-gradient(circle at center, $White 30%, $Brand20 30%);
  }
  30% {
    background-image: radial-gradient(circle at center, $White 45%, $Brand20 45%);
  }
  40% {
    background-image: radial-gradient(circle at center, $White 60%, $Brand20 60%);
  }
  50% {
    background-image: radial-gradient(circle at center, $White 75%, $Brand20 75%);
  }
  60% {
    background-image: radial-gradient(circle at center, $White 90%, $Brand20 90%);
  }
  70% {
    background-image: radial-gradient(circle at center, $White 105%, $Brand20 105%);
  }
  80% {
    background-image: radial-gradient(circle at center, $White 120%, $Brand20 120%);
  }
  90% {
    background-image: radial-gradient(circle at center, $White 135%, $Brand20 135%);
  }
  100% {
    background-image: radial-gradient(circle at center, $White 150%, $Brand20 150%);
  }
}

@keyframes neutral400ToNeutral200 {
  0% {
    background-image: radial-gradient(circle at center, $Neutral200 0%, $Neutral400 0%);
  }
  10% {
    background-image: radial-gradient(circle at center, $Neutral200 15%, $Neutral400 15%);
  }
  20% {
    background-image: radial-gradient(circle at center, $Neutral200 30%, $Neutral400 30%);
  }
  30% {
    background-image: radial-gradient(circle at center, $Neutral200 45%, $Neutral400 45%);
  }
  40% {
    background-image: radial-gradient(circle at center, $Neutral200 60%, $Neutral400 60%);
  }
  50% {
    background-image: radial-gradient(circle at center, $Neutral200 75%, $Neutral400 75%);
  }
  60% {
    background-image: radial-gradient(circle at center, $Neutral200 90%, $Neutral400 90%);
  }
  70% {
    background-image: radial-gradient(circle at center, $Neutral200 105%, $Neutral400 105%);
  }
  80% {
    background-image: radial-gradient(circle at center, $Neutral200 120%, $Neutral400 120%);
  }
  90% {
    background-image: radial-gradient(circle at center, $Neutral200 135%, $Neutral400 135%);
  }
  100% {
    background-image: radial-gradient(circle at center, $Neutral200 150%, $Neutral400 150%);
  }
}

@keyframes brand50ToBrand60 {
  0% {
    background-image: radial-gradient(circle at center, $Brand60 0%, $Brand50 0%);
  }
  10% {
    background-image: radial-gradient(circle at center, $Brand60 15%, $Brand50 15%);
  }
  20% {
    background-image: radial-gradient(circle at center, $Brand60 30%, $Brand50 30%);
  }
  30% {
    background-image: radial-gradient(circle at center, $Brand60 45%, $Brand50 45%);
  }
  40% {
    background-image: radial-gradient(circle at center, $Brand60 60%, $Brand50 60%);
  }
  50% {
    background-image: radial-gradient(circle at center, $Brand60 75%, $Brand50 75%);
  }
  60% {
    background-image: radial-gradient(circle at center, $Brand60 90%, $Brand50 90%);
  }
  70% {
    background-image: radial-gradient(circle at center, $Brand60 105%, $Brand50 105%);
  }
  80% {
    background-image: radial-gradient(circle at center, $Brand60 120%, $Brand50 120%);
  }
  90% {
    background-image: radial-gradient(circle at center, $Brand60 135%, $Brand50 135%);
  }
  100% {
    background-image: radial-gradient(circle at center, $Brand60 150%, $Brand50 150%);
  }
}

@keyframes brand20ToTransparent {
  0% {
    background-image: radial-gradient(circle at center, transparent 0%, $Brand20 0%);
  }
  10% {
    background-image: radial-gradient(circle at center, transparent 15%, $Brand20 15%);
  }
  20% {
    background-image: radial-gradient(circle at center, transparent 30%, $Brand20 30%);
  }
  30% {
    background-image: radial-gradient(circle at center, transparent 45%, $Brand20 45%);
  }
  40% {
    background-image: radial-gradient(circle at center, transparent 60%, $Brand20 60%);
  }
  50% {
    background-image: radial-gradient(circle at center, transparent 75%, $Brand20 75%);
  }
  60% {
    background-image: radial-gradient(circle at center, transparent 90%, $Brand20 90%);
  }
  70% {
    background-image: radial-gradient(circle at center, transparent 105%, $Brand20 105%);
  }
  80% {
    background-image: radial-gradient(circle at center, transparent 120%, $Brand20 120%);
  }
  90% {
    background-image: radial-gradient(circle at center, transparent 135%, $Brand20 135%);
  }
  100% {
    background-image: radial-gradient(circle at center, transparent 150%, $Brand20 150%);
  }
}

@keyframes brand70ToTransparent {
  0% {
    background-image: radial-gradient(circle at center, transparent 0%, $Brand70 0%);
  }
  10% {
    background-image: radial-gradient(circle at center, transparent 15%, $Brand70 15%);
  }
  20% {
    background-image: radial-gradient(circle at center, transparent 30%, $Brand70 30%);
  }
  30% {
    background-image: radial-gradient(circle at center, transparent 45%, $Brand70 45%);
  }
  40% {
    background-image: radial-gradient(circle at center, transparent 60%, $Brand70 60%);
  }
  50% {
    background-image: radial-gradient(circle at center, transparent 75%, $Brand70 75%);
  }
  60% {
    background-image: radial-gradient(circle at center, transparent 90%, $Brand70 90%);
  }
  70% {
    background-image: radial-gradient(circle at center, transparent 105%, $Brand70 105%);
  }
  80% {
    background-image: radial-gradient(circle at center, transparent 120%, $Brand70 120%);
  }
  90% {
    background-image: radial-gradient(circle at center, transparent 135%, $Brand70 135%);
  }
  100% {
    background-image: radial-gradient(circle at center, transparent 150%, $Brand70 150%);
  }
}

@keyframes neutral200ToTransparent {
  0% {
    background-image: radial-gradient(circle at center, transparent 0%, $Neutral200 0%);
  }
  10% {
    background-image: radial-gradient(circle at center, transparent 15%, $Neutral200 15%);
  }
  20% {
    background-image: radial-gradient(circle at center, transparent 30%, $Neutral200 30%);
  }
  30% {
    background-image: radial-gradient(circle at center, transparent 45%, $Neutral200 45%);
  }
  40% {
    background-image: radial-gradient(circle at center, transparent 60%, $Neutral200 60%);
  }
  50% {
    background-image: radial-gradient(circle at center, transparent 75%, $Neutral200 75%);
  }
  60% {
    background-image: radial-gradient(circle at center, transparent 90%, $Neutral200 90%);
  }
  70% {
    background-image: radial-gradient(circle at center, transparent 105%, $Neutral200 105%);
  }
  80% {
    background-image: radial-gradient(circle at center, transparent 120%, $Neutral200 120%);
  }
  90% {
    background-image: radial-gradient(circle at center, transparent 135%, $Neutral200 135%);
  }
  100% {
    background-image: radial-gradient(circle at center, transparent 150%, $Neutral200 150%);
  }
}

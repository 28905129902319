@import "../utils/sassHelpers";

$gap: 10px;

.propertiesButtons {
  margin-right: $page-padding;
  display: flex;
  align-items: center;
  .buttonBox {
    display: flex;
    flex-direction: row;
  }

  .discardButton {
    min-width: 135px;
    margin-right: 20px;
  }
}

.bpmProperties {
  padding: $page-padding;

  .tableSectionHeader {
    font-size: 24px;
  }

  .tableSection {
    border: 1px solid black;
    padding: 10px;
    margin: $gap 0;

    td {
      vertical-align: middle;

      &.textInput {
        width: 250px;
      }

      &.checkbox {
        text-align: center;
      }

      &.dropdown {
        text-align: center;
      }
    }
  }
}

@use "sass:math";
@import "../../../utils/colors.scss";

$dateWidth: 24px;
$dateMargin: 6px;
$selectedBorderRadius: 12px;

.DPMonth {
  display: flex;
  flex-direction: column;
  user-select: none;

  .monthHeader {
    text-align: center;
    margin-bottom: 16px;
    select {
      border: none;
      background-color: transparent;
      font-size: 14px;
      font-weight: 500;

      color: #484848;
      cursor: pointer;
      appearance: none;
      width: 80px;
      outline: none;
      &:first-child {
        text-align-last: right;
        margin-right: 2px;
      }
      &:last-child {
        margin-left: 2px;
        text-align-last: left;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .daysOfWeek {
    color: #4b506d;
    font-size: 11px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    & > * {
      width: $dateWidth;
      text-align: center;
      margin: 0 $dateMargin;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .dayGrid {
    color: #4b506d;
    display: flex;
    flex-direction: column;
    & > * {
      display: flex;
      flex-direction: row;
      & > * {
        width: $dateWidth;
        height: $dateWidth;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: $dateMargin;
        cursor: pointer;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        &.notCurrentMonth {
          color: #d2d4d5;
        }
        &.selected {
          color: white;
          position: relative;
          z-index: 10;
          &.selectedLeft {
            position: relative;
            border-radius: $selectedBorderRadius 0 0 $selectedBorderRadius;
            &.nextIsLast:not(:first-child) {
              width: $dateWidth + $dateMargin;
              padding-right: $dateMargin;
            }
          }
          &.selectedLeft.nextIsLast:first-child::before {
            width: $dateWidth + $dateMargin;
          }
          &.selectedRight {
            border-radius: 0 $selectedBorderRadius $selectedBorderRadius 0;
            &.lastWasFirst:not(:first-child) {
              width: $dateWidth + $dateMargin;
              padding-left: $dateMargin;
              margin-left: -$dateMargin;
            }
            &.lastWasFirst:nth-child(2) {
              width: $dateWidth + $dateMargin;
              padding-left: $dateMargin + 2px;
              margin-left: 0;
            }
          }
        }
        &.selected::after {
          content: "";
          background-color: $Brand50;
          color: black;
          position: absolute;
          bottom: 0px;
          height: 24px;
          left: 2px;
          margin: -2px -4px 0 -2px;
          right: 0px;
          top: 2px;
          width: 24px;
          border-radius: 12px;
          z-index: -1;
        }
        &.selected:hover::after {
          background-color: $Brand60;
        }
        &.selectedRight {
          &.lastWasFirst:not(:first-child)::after {
            left: $dateMargin + 2;
          }
        }
        &.selectedRight::before,
        &.selectedLeft::before {
          content: "";
          position: absolute;
          box-sizing: border-box;
          display: block;
          height: 24px;
          width: 12px;
          margin: -2px -4px 0 -2px;
          z-index: -2;
          background-color: $Brand20;
          top: 2px;
          right: 0px;
          bottom: 0px;
          left: 0px;
        }
        &.selectedLeft::before {
          height: 24px;
          width: 14px;
          right: 12px;
          left: 12px;
        }
        &.lastWasFirst:not(:first-child)::before {
          width: $dateWidth;
        }
        &.nextIsLast:not(:first-child)::before {
          width: $dateWidth;
        }
        &.invalid {
          color: #d2d4d5;
          cursor: not-allowed;
        }
        &.notCurrentMonth {
          cursor: default;
        }
        &:not(.invalid) {
          &:not(.notCurrentMonth, .span, .selected):hover {
            position: relative;
            border: solid 1px $Brand50;
            color: $Brand50;
            border-radius: $selectedBorderRadius;
          }
          &.span {
            background-color: $Brand20;
            width: $dateWidth + 2 * $dateMargin;
            margin: $dateMargin 0;
            &.lastWasFirst,
            &.nextIsLast {
              width: $dateWidth + 3 * $dateMargin;
            }
            &:first-child,
            &:last-child {
              width: $dateWidth + $dateMargin;
            }
            &:first-child,
            &.nextIsLast {
              padding-right: $dateMargin;
            }
            &.nextIsLast:first-child {
              width: $dateWidth + $dateMargin * 2;
              padding-right: $dateMargin * 2;
            }
            &:last-child,
            &.lastWasFirst {
              padding-left: $dateMargin;
            }
            &.lastWasFirst:last-child {
              width: $dateWidth + $dateMargin * 2;
              padding-left: $dateMargin * 2;
            }
            &.lastWasFirst {
              margin-left: -$dateMargin;
            }
            &.nextIsLast {
              margin-right: -$dateMargin;
            }
            &.lastWasFirst.nextIsLast {
              width: $dateWidth + 4 * $dateMargin;
              padding: 0;
            }
          }
          &.span:hover {
            color: $Brand100;
            position: relative;
            z-index: 10;
          }
          &.span:hover::after {
            content: "";
            background-color: $Brand30;
            width: 24px;
            border-radius: 12px;
            position: absolute;
            top: 2px;
            left: 8px;
            bottom: 0px;
            right: 0px;
            height: 24px;
            margin: -2px -4px 0 -2px;
            z-index: -1;
          }
          &.span:first-child:hover::after {
            left: 2px;
          }
          &.span.lastWasFirst:hover::after {
            left: $dateMargin * 2 + 2;
          }
        }
      }
    }
  }
  &.comparison {
    .dayGrid {
      .selected {
        color: $Brand100;
      }
      .span,
      .selectedRight::before,
      .selectedLeft::before {
        border-top: dashed 1px $Brand100;
        border-bottom: dashed 1px $Brand100;
        background-color: $Brand10;
        border-radius: 1px;
      }
      .selected::after {
        border: dashed 1px $Brand100;
        color: $Brand100;
        background-color: $Brand25;
      }
      .span:hover::after {
        border: dashed 1px $Brand100;
        background-color: $Brand10;
        top: 1px;
      }
      & > * {
        & > * {
          &:not(.invalid) {
            &:not(.notCurrentMonth, .span, .selected):hover {
              border: dashed 1px $Brand80;
              color: $Brand80;
            }
          }
        }
      }
    }
  }
}

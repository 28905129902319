@import "../utils/sassHelpers";

$invalid-color: #ffcccc;
$warning-color: #fffacd;

.bpmLinearRatesActions {
  display: flex;
  align-items: center;

  & > * {
    margin-right: 0.5rem;
  }
}

.bpmLinearRates {
  height: 100%;
  width: 100%;
  padding: $page-padding;
  display: flex;

  .linearRatesTable {
    height: 100%;
    width: 100%;
  }

  .invalidCell {
    background-color: $invalid-color;
  }

  .warningCell {
    background-color: $warning-color;
  }
}

@use "sass:math";

@import "../../utils/colors.scss";

$dimension-padding: 0.35rem;
$side-margin: 1.7rem;
$gridBorderColor: #e6eaee;

// NOTE: adjust the constants in the TSX file if these change
$colWidth: 220px;
$dividerWidth: 10px;
$halfGutter: 8px;

.configBox {
  flex: 1;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 0;

  .sortingBox {
    margin: 0.5rem $side-margin 0;
    padding: 0.5rem;
    border: 2px solid $gridBorderColor;
    border-radius: 5px;
    align-self: flex-start;
    min-width: 200px;
    min-height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: background-color 0.12s ease;
    &.isOver {
      background-color: #eeeeee;
    }
    .dragHere {
      font-size: 0.7rem;
      margin-left: 0.75rem;
      font-style: italic;
      margin-top: 1px;
    }
    .sortItemBox {
      display: flex;
      flex-direction: row;
      margin-left: 1rem;
      .sortItem {
        margin: 0 0.25rem;
        $iconSize: 18px;
        $iconPadding: 2px;
        $borderWidth: 8px;
        border: $borderWidth solid black;
        border-radius: $borderWidth;

        font-size: $iconSize;
        line-height: $iconSize;
        padding: $iconPadding;
        width: $iconSize + ($iconPadding + $borderWidth) * 2;
        height: $iconSize + ($iconPadding + $borderWidth) * 2;

        cursor: grab;
        transition: background-color 0.12s ease;
        background-color: white;
        &:hover {
          background-color: #dddddd;
        }
        & > * {
          cursor: pointer;
        }
        &.isOver {
          background-color: #dddddd;
        }
      }
    }
  }

  .configBoxInner {
    overflow: auto;

    flex: 1;

    $topMargin: 30px;
    $superHeaderHeight: 40px;
    $buttonWidth: 28px;

    display: flex;
    flex-direction: row;
    padding: 2 * $halfGutter $halfGutter;
    padding-left: $halfGutter * 3;

    max-width: 100%;
    position: relative;

    &.hasHeaders {
      .columnConfigOuter {
        margin-top: $superHeaderHeight + $halfGutter * 2;
      }
      .addButton {
        top: $superHeaderHeight + $halfGutter;
      }
      .addHeaderButton {
        top: $superHeaderHeight + math.div($halfGutter, 2);
      }
    }
    .addButton {
      position: relative;
      overflow: visible;
      top: -$halfGutter;
      height: 0;
      color: $primary;
      cursor: pointer;
      &::before {
        content: "";
        position: absolute;
        width: $buttonWidth;
        left: math.div(-$buttonWidth, 2);
        height: $buttonWidth;
        border-radius: 50% 50% 50% 0;
        border: 2px solid $primary;
        transform: rotate(-45deg);
      }
      &::after {
        content: "+";
        position: absolute;
        font-weight: 500;
        $fontSize: 30px;
        font-size: $fontSize;
        height: $fontSize;
        transform: translateX(-50%) translateY(-25%);
      }
      &:hover {
        .innerPicker {
          display: block;
        }
        &::after {
          color: white;
        }
        &::before {
          background-color: $primary;
        }
      }
    }

    .addHeaderButton {
      left: math.div($colWidth, 2) + math.div($buttonWidth, 4);
      top: math.div(-$halfGutter, 2);
      &::before {
        transform: rotate(135deg) !important;
      }
    }

    .superHeaderContainer {
      position: relative;
      top: -$halfGutter;
      left: $halfGutter;
      height: $superHeaderHeight;
      width: 0;

      & > * {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border: 2px solid $gridBorderColor;
        padding: 0 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        & > *:not(:first-child) {
          margin-left: 0.5rem;
        }
        .sizerBox {
          display: flex;
          flex-direction: row;
        }
      }
    }

    .divider {
      min-width: 10px;
      background-color: #666666;
      min-height: 100%;
      margin: -$halfGutter 0;
      margin-right: $halfGutter * 3;
    }
  }
}

@use "sass:math";

$padding: 15px;
$circle-diameter: 14px;
$circle-padding: 10px;

.streamingDeliveryChart {
  flex: 1;
  width: 100%;
  height: 100%;
}
.noDataLabel {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.streamingDeliveryChartTooltip {
  padding: 30px 25px 25px;
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: 100000;

  box-shadow: 0px 0px 20px 0px rgba(78, 78, 80, 0.2);

  border-radius: 5px;
  .cardTitle {
    font-size: 18px;
    color: #130d2d;
  }
  .wo {
    margin-right: 5px;
  }
  .totalsSubtitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 25px;
    font-size: 16px;
    color: #8c8a97;

    & > * > *:first-child {
      font-weight: 700;
    }
    & > *:not(:last-child) {
      margin-right: $padding;
    }
  }
  .barItemListContainer {
    // https://stackoverflow.com/a/63000747/2108612
    .barItemList {
      max-height: 100%;
      min-width: calc(100% + 15px);
      margin-left: -$padding;
      display: flex;
      flex-flow: row wrap;
      align-content: stretch;
      writing-mode: vertical-lr;
      & > * {
        color: #130d2d;
        font-size: 14px;
        writing-mode: horizontal-tb;
        margin-left: $padding + $circle-diameter + $circle-padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: relative;
        &.creative {
          font-size: 12px;
        }
        .circle {
          position: absolute;
          top: 2px;
          width: $circle-diameter;
          height: $circle-diameter;
          left: -$circle-diameter - $circle-padding;
          border-radius: math.div($circle-diameter, 2);
        }
        & > *:not(:last-child) {
          margin-right: 5px;
          max-width: 200px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

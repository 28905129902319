@import "./../utils/colors.scss";

.kpiSetupPage {
  .list-item {
    color: $primary;
    border-color: $primary;
    border: solid $primary;
    border-width: 2px;
    font-size: large;
    margin: 5px;
  }

  .list-item:hover {
    background-color: lavender;
  }

  .list-item:active {
    background-color: $primary;
    color: white;
  }

  .kpiButton {
    margin: 5px;
  }

  .companyPicker {
    margin-left: 5px;
    min-width: 500px;
  }
}

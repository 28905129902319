.tvadCrossChannelContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  .unevenRowContainer {
    display: flex;
    flex-direction: row;
    & > :first-child {
      flex: 1;
      width: 30%;
    }
    & > *:not(:first-child) {
      flex: 2;
      width: 70%;
    }
  }
}

.tvadCrossChannelTitle {
  display: flex;
  flex-direction: row;
  & > *:not(:first-child) {
    margin-left: 1rem;
  }
}

.logsTrackerPage {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;

  .BPMFilterBar {
    margin: 0.5rem;
    flex: 0;
    z-index: 0;
  }

  .columnsContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    min-height: 0;

    .column {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-height: 0;
      &:not(:last-child) {
        border-right: 2px black solid;
      }

      .title {
        font-size: 24px;
        font-weight: 500;
        border-bottom: 2px black solid;
        display: flex;
        justify-content: center;
      }
      .boxesList {
        overflow: auto;
        min-height: 0;
        .box {
          flex-direction: column;
          &:hover {
            cursor: pointer;
          }
          &.allDNR {
            background: repeating-linear-gradient(
              45deg,
              #ffffff,
              #ffffff 10px,
              #d3d3d3 10px,
              #d3d3d3 20px
            );
          }
          display: flex;
          flex: 1;
          align-items: center;
          border: 1px solid black;
          padding: 0.5rem;
          margin: 0.5rem;
          background-color: white;
          border-radius: 5px;

          .missingCompanies {
            font-size: 0.9rem;
            .label {
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

.modal-body {
  & > * {
    margin-bottom: 1rem;
  }
  .checkAllButton {
    display: flex;
    align-items: center;
    & > * {
      &:first-child {
        margin-right: 0.25rem;
      }
    }
  }
}

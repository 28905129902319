@import "../../styles/Fonts.scss";

.mmmFAQ {
  .faqTitle {
    @include largeTitle;
    margin: 16px 0 32px 8px;
  }
  .questionsWrapper {
    margin: 0 auto;
    width: fit-content;
    .widgetContainer {
      &.primary {
        max-width: 1437px;
      }
      &.secondary {
        .widgetBody {
          max-width: 1148px;
        }
      }
      .headerLeft {
        flex-direction: row;
        gap: 0;
      }
      .widgetBody {
        ul {
          @include bodyRegular;
        }
        li {
          margin-bottom: 8px;
        }
      }
    }
    .italics {
      font-style: italic;
    }
    p {
      @include bodyRegular;
      line-height: 1.3rem;
    }
  }
}

$border-size: 1px;
$border-color: #8c8a97;

.stickyTableOuter {
  * {
    box-sizing: border-box;
  }
  position: relative;
  overflow: hidden;
  background-color: white;
  .stickyTable {
    position: relative;
    overflow: auto;
    .STInnerGrid {
      position: relative;
      overflow: hidden;
      & > * {
        overflow: hidden;
      }
    }
  }
  .stickyTableCell {
    position: absolute;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    &.STOdd {
      background-color: #f7f8fa;
    }
    &:not(.STOdd) {
      background-color: white;
    }
    &.stickyTableTextCell {
      padding: 5px 10px;
      overflow: hidden;
    }
  }
  .STCorner {
    z-index: 2;
    position: sticky;
    overflow: visible;
    .STCornerInner {
      background-color: white;
      position: absolute;
      &.STnw {
        border-right: $border-size solid $border-color;
        border-bottom: $border-size solid $border-color;
      }
      &.STne {
        border-left: $border-size solid $border-color;
        border-bottom: $border-size solid $border-color;
      }
      &.STsw {
        border-right: $border-size solid $border-color;
        border-top: $border-size solid $border-color;
      }
      &.STse {
        border-left: $border-size solid $border-color;
        border-top: $border-size solid $border-color;
      }
    }
  }
  .stickyHeader {
    position: sticky;
    z-index: 1;

    &.STTopHeader {
      top: 0;
      & > * {
        border-bottom: $border-size solid $border-color;
      }
    }
    &.STLeftHeader {
      left: 0;
      & > * {
        border-right: $border-size solid $border-color;
      }
    }
    &.STBottomHeader {
      bottom: 0;
      & > * {
        border-top: $border-size solid $border-color;
      }
    }
    &.STRightHeader {
      right: 0;
      & > * {
        border-left: $border-size solid $border-color;
      }
    }
  }
}

@import "../utils/colors.scss";

.targeting-check-box {
  cursor: pointer;
  color: white;
  background-color: white;
  transition: all 0.12s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #c5d0de;

  $size: 20px;
  height: $size;
  width: $size;
  min-height: $size;
  min-width: $size;

  border-radius: 3px;
  font-size: 18px;

  &.green-check {
    border-color: green;
    background-color: green;
  }
  &.yellow-check {
    border-color: #fcb603;
    background-color: #fcb603;
  }
  &.red-x {
    color: red;
    border-color: red;
    background-color: $White;
  }
  &.disabled {
    cursor: not-allowed;
    &.checked {
      background-color: #dddddd;
      color: #777777;
      border-color: #bbbbbb;
    }
  }
}

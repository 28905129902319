.linearBuyingAssignmentsPageContainer {
  padding: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0px;

  .tableHeader {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    .tableName {
      font-size: 24px;
    }
  }

  .linearBuyingAssignmentsTable {
    flex: 1;
    min-height: 0px;
    min-width: 0px;
    height: 100%;
  }
}

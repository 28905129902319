@import "../utils/colors.scss";

.keyContainer {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 159px;
  height: 64px;
  .focalAdvertiser {
    display: flex;
    align-items: center;
    height: 28px;
    border-radius: 6px;
    border: 1px solid $Grey60;
    padding: 8px 0px 8px 8px;
    color: $Purple80;
    font-size: 0.75rem;
    font-weight: 500;
    .line {
      width: 18px;
      height: 3px;
      background-color: $Purple80;
      border-radius: 6px;
      margin-left: 7px;
    }
  }
  .competitorSection {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    border: 1px solid $Grey60;
    padding: 8px;
    row-gap: 7px;
    .competitorLabel {
      display: flex;
      align-items: center;
      height: 0.75rem;
      color: $GreyHeat100;
      font-weight: 500;
      font-size: 0.75rem;
      .line {
        width: 18px;
        height: 3px;
        background-color: $GreyHeat100;
        border-radius: 6px;
        margin-left: 7px;
      }
    }
    .competitors {
      display: flex;
      font-weight: 400;
      font-size: 0.6875rem;
    }
  }
}

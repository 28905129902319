.wtoDuplicateModal {
  .livePreview {
    display: flex;

    input {
      width: 18%;

      &:not(:last-child) {
        margin-right: 10px;
        width: 41%;
      }
    }
  }

  .warning {
    margin-bottom: 15px;
  }

  .form {
    .helperText {
      font-style: italic;
      font-size: 0.8rem;
      color: #9e9e9e;
      line-height: 0.8rem;
    }
  }
}

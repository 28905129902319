@import "../../../utils/colors.scss";

.dateInputFieldContainer {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 16px;
  margin-bottom: 18px;
  .dateInputFieldLabel {
    position: absolute;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    color: #7a7a7a;
    padding: 0px 4px;
    top: -14px;
    left: 10px;
    background-color: white;
  }
  .dateInputFieldValue {
    height: 40px;
    width: 140px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 6px;
    border: 2px solid $GreyHeat80;
    &.dashed {
      border-style: dashed;
    }
    padding: 8px 5px 8px 16px;
    color: $Grey100;
    caret-color: $Brand60;
    &,
    & > input {
      font-size: 0.9rem;
      color: #7a7a7a;
      font-weight: 500;
    }
    &:focus {
      outline: none;
      border-color: $Brand60;
    }
    &:hover:not(:focus) {
      border-color: $Brand60;
    }
    &.invalid {
      border-color: $Error1;
    }
    input {
      outline: none;
      border: none;
      height: 100%;
      &.month,
      &.day {
        width: 1.5em;
      }
      &.year {
        width: 4em;
      }
    }
  }
  .dateInputFieldError,
  .dateInputFieldInfo {
    position: absolute;
    bottom: -18px;
    left: 16px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-wrap: nowrap;
  }

  .dateInputFieldError {
    color: $Error1;
  }

  .dateInputFieldInfo {
    color: var(--brand-60, #6b2def);
  }

  .dateInputFieldInfo + .dateInputFieldError {
    bottom: -40px;
  }
}

@use "sass:math";

$gutter: 1rem;

.BPMGridPickerModal {
  .bodyText {
    margin-bottom: 0.5rem;
  }
  .gridPicker {
    margin: 1rem 0;
    max-height: 300px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    padding: math.div($gutter, 2);
    display: flex;
    flex-flow: row wrap;
    overflow-y: auto;
    .option {
      margin: $gutter;
      width: 151px;
      display: flex;
      flex-direction: column;
      transition: background-color 0.1s ease;
      cursor: pointer;
      .thumbnail {
        height: 110px;
        background-color: rgba(0, 0, 0, 0.1);
        color: #666666;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.1rem;
        padding: 1rem;
        text-align: center;
      }
      .caption {
        margin: 0.25rem;
        font-size: 0.8rem;
      }
      &:hover,
      &.selected {
        background-color: #eeeeee;
      }
    }
  }
  .saveButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    .BPMSpinner {
      margin-left: 0.5rem;
    }
  }
  .gridPickerGroups {
    margin-top: 0.5rem;
    display: flex;

    .gridPickerSection {
      margin-right: 30px;
      display: flex;
      flex-flow: column;

      .gridPickerSectionElements {
        margin-bottom: 0.5rem;
      }
    }
  }
}

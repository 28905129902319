.budgetSnapshot {
  display: flex;
  flex: 1;
  flex-direction: column;
  .aboveChart {
    display: flex;
    .budgetSnapshotControls {
      display: flex;
      flex: 1;
      flex-direction: row;
      font-size: 12px;
      justify-content: space-between;
      .budgetSpendSelector {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: 500;
        .budgetSnapshotDropdown {
          margin-left: 4px;
          display: flex !important;
          border-radius: 0 20px 20px 0;
        }
      }
      .totalBudgetPacing {
        display: flex;
        flex: 0 1 auto;
        align-items: center;
        border-radius: 4px;
        border: 1px solid #cdcdcd;
        background: #f7f8f9;
        padding: 4px;
      }
    }
  }
  .chartContents {
    display: flex;
    flex: 1;
    flex-direction: row;
    .budgetSnapshotChart {
      display: flex;
      flex: 1;
      flex-direction: row;
      .recharts-label-list {
        & > * {
          stroke: none !important;
        }
      }
    }
    .rightOfChart {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

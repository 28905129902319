@import "../utils/sassHelpers.scss";
@import "../utils/colors.scss";

.accountPlanningPageContainer {
  padding: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0px;

  .tableHeader {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    .tableName {
      font-size: 24px;
    }
  }

  .accountPlanningTable {
    flex: 1;
    min-height: 0px;
    min-width: 0px;
    height: 100%;
  }
}

.accountPlanningActions {
  display: flex;
  gap: 0.5rem;
}

.constraintViewEditModal {
  .form-group {
    margin-bottom: 0.25rem !important;
  }

  .modalRow {
    height: 100% !important;
  }
}

.modalHeaderLabel {
  font-size: 0.95rem !important;
}

.labeledBox {
  padding: 4px;
  border-radius: 5px;
  text-align: center;
  font-weight: 500;
  color: white;
  align-items: center;
  .agencybpm {
    background-color: #7859bc;
    border-radius: inherit;
    width: inherit;
    padding: inherit;
    width: 55px;
  }
  .agencyppm {
    background-color: #ff3d67;
    border-radius: inherit;
    width: inherit;
    padding: inherit;
    width: 55px;
  }
  .agencytinuiti {
    background-color: #1778ff;
    border-radius: inherit;
    width: inherit;
    padding: inherit;
    width: 55px;
  }
  .tier0 {
    background-color: #a6a6a6;
    border-radius: inherit;
    width: inherit;
    padding: inherit;
    width: 35px;
  }
  .tier1 {
    background-color: #70c430;
    border-radius: inherit;
    width: inherit;
    padding: inherit;
    width: 35px;
  }
  .tier2 {
    background-color: #1f9de6;
    border-radius: inherit;
    width: inherit;
    padding: inherit;
    width: 35px;
  }
  .tier3 {
    background-color: #f0aa28;
    border-radius: inherit;
    width: inherit;
    padding: inherit;
    width: 35px;
  }
  .tier4 {
    background-color: #e75146;
    border-radius: inherit;
    width: inherit;
    padding: inherit;
    width: 35px;
  }
  .tier5 {
    background-color: #7859bc;
    border-radius: inherit;
    width: inherit;
    padding: inherit;
    width: 35px;
  }
}

.secondariesCell {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.disabledCell {
  background-color: $background-grey;
  width: 100%;
  height: 45px;
  text-align: center;
  padding-top: 12px;
}

@use "sass:math";
@import "../utils/colors.scss";
@import "../utils/sassHelpers.scss";

.bpmPage:has(.performance, .metricsTableControlsContainer) {
  background-color: $Neutral300;
  .body {
    padding-top: 16px;
  }
}

.performance {
  background-color: #ffffff;
}
.performance.metricsTable {
  background-color: $Neutral200;
  height: 100%;
  .stickyTableOuter {
    border-bottom: 2px solid $Neutral400;
  }
}

.body:has(.overviewCompareMetricsChart, .overviewCompareMetricsChart) {
  display: flex;
  gap: 16px;
  padding: 16px;
  .overviewSparkCharts {
    display: flex;
    flex: 1;
    gap: 16px;
    justify-content: space-between;
    .sparkChartContainer {
      height: 100%;
      margin: 0px;
    }
  }
  .overviewCompareMetricsChart {
    display: flex;
    flex: 2.4;
    .chartContainer {
      margin: 0px;
    }
  }
}

.performanceActions {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .dropdownMenuContainer {
    max-height: 350px;
    .dropdownMenuItemsContainer {
      overflow-y: scroll;
    }
  }
  .dateLabel {
    color: #ffffff;
    font-family: Graphik !important;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: -10px;
  }
  .optionsDropdown {
    margin-right: unset;
    .optionsButton {
      border: 1px solid #ffffff;
      border-radius: 6px;
      * {
        color: #ffffff;
      }
    }
    .dropdown-menu {
      border: 2px solid $Brand50;
      border-radius: 6px;
      padding: 8px 0px !important;
      .dropdown-item {
        align-items: center;
        display: flex;
        height: 28px !important;
        padding-left: 8px !important;
        padding-right: 12px !important;
        width: 100%;
        svg {
          color: $Brand60;
          height: 18px;
          margin-right: 6px;
          stroke: $Brand60;
          width: 18px;
        }
        .label {
          color: $Brand100;
          font-family: Graphik !important;
          font-feature-settings: "clig" off, "liga" off;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }
  .editControls {
    display: flex;
  }
}

// Controls Styling
.metricsTableControlsContainer {
  align-items: center;
  background: #ffffff;
  border-bottom: 1px solid $Neutral400;
  border-top: 1px solid $Neutral400;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 16px;
  .filterBarContainer {
    display: flex;
    align-items: center;
    flex: 3;
    max-width: 776px !important;
    .BPMFilterBarComponentContainer {
      width: 100%;
      .filterBarComponent {
        width: 100% !important;
      }
    }
  }
  .metricsTableControls {
    align-items: center;
    display: flex;
    gap: 16px;
    justify-content: flex-end;
  }
}
.colorScaleButton {
  background-color: #ffffff !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  .bf-label {
    align-items: center;
    display: flex;
    .colorScale {
      display: flex;
      flex-direction: row;
      gap: 4px;
      * {
        align-items: center;
        border-radius: 1px;
        color: #130d2d;
        display: flex;
        font-family: Graphik;
        font-feature-settings: "clig" off, "liga" off;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        height: 22px;
        justify-content: center;
        line-height: 100%; /* 10px */
        width: 28px;
      }
    }
  }
}

// Glossary Styling
.metricsGlossaryContainer {
  .modal-content {
    border-radius: 6px !important;
    border: 2px solid $Brand50 !important;
    box-shadow: 0px 4px 18px 0px rgba(148, 160, 184, 0.5) !important;
    height: 604px;
    overflow: hidden;
    width: 804px;
    .modal-header {
      align-items: center;
      display: flex;
      margin: 0px;
      padding: 24px 24px 16px 24px;
      .modal-title {
        color: $Brand100;
        font-family: Graphik !important;
        font-feature-settings: "clig" off, "liga" off;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        height: 28px;
        line-height: normal;
      }
      .close {
        color: $Brand100;
        height: 34px;
        opacity: 1;
        padding: 3px 6px;
        width: 28px;
      }
    }
    .modal-body {
      height: 732px;
      padding: 0 !important;
      .glossaryFilterContainer {
        margin: 0px;
        padding: 16px 12px;
      }
      .glossaryContent {
        height: 467px;
        margin: 0px;
        overflow-y: auto;
        .section {
          padding: 0px 24px 24px 12px;
          .term {
            color: $Brand100;
            font-family: Graphik !important;
            font-feature-settings: "clig" off, "liga" off;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding: 7px 0px;
          }
          .definition {
            color: $Brand100;
            font-family: Graphik !important;
            font-feature-settings: "clig" off, "liga" off;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            padding: 0px;
          }
        }
      }
    }
  }
}

@import "../../utils/colors.scss";

.deliveryTab {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  padding: 16px;
  width: 100%;
  .deliveryOverview {
    display: flex;
    gap: 16px;
    height: 74px;
    .deliveryOverviewItem {
      align-items: center;
      background: var(--White, #fff);
      border-radius: 16px;
      border: 1.5px solid var(--neutral-400, #cbd2e1);
      display: flex;
      gap: 4px;
      height: 74px;
      min-width: 0px;
      padding-left: 16px;
      padding-right: 24px;
      width: 100%;
      .overviewValueKeyContainer {
        align-items: center;
        display: flex;
        gap: 6px;
        padding-left: 16px;
        padding-right: 13px;
        .overviewValue {
          color: var(--brand-brand-100, #1f003f);
          font-family: Graphik;
          font-feature-settings: "clig" off, "liga" off;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          padding: 6px 0px;
        }
        .overviewKey {
          color: var(--brand-brand-100, #1f003f);
          font-family: Graphik;
          font-feature-settings: "clig" off, "liga" off;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      .overviewType {
        align-items: center;
        color: var(--brand-brand-100, #1f003f);
        display: flex;
        font-family: Graphik;
        font-feature-settings: "clig" off, "liga" off;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        height: 100%;
        line-height: normal;
        opacity: 0.5;
        padding: 6px 4px 0px 4px;
      }
      .percentChangeContainer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
      }
    }
  }
  .singleChannelDeliveryChartContainer {
    display: flex;
    height: 100%;
    min-height: 200px;
    width: 100%;
    flex: 100;
    .chartContainer {
      height: 100%;
      width: 100%;
    }
  }
}

.competitiveMetricsActions {
  display: flex;
  flex-direction: row;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 10px;
  }
  .companyLogo {
    height: 20px;
  }
  .competitorSelect {
    width: 350px;
  }
}

.competitiveMetricsBody {
  flex: 1;
  display: flex;
  flex-direction: column;

  .charts-container {
    height: 200px;
    width: auto;
    flex-direction: row;
    justify-content: space-evenly;
    display: flex;
    min-width: 1200px;
    overflow: auto;

    .chart-box {
      width: auto;
      flex: 1;
      display: flex;
      padding: 15px;
      flex-direction: row;

      .chart-inner-label-box {
        padding-top: 10px;
        display: flex;
        flex-direction: column;

        .chart-header-box {
          display: flex;
          flex-direction: column;
          align-items: center;

          .chart-title-box {
            display: flex;
            font-size: 1.1rem;
            justify-content: center;
          }

          .last-week-value-box {
            padding: 5px 1px 0px;
            display: flex;
            justify-content: center;
            font-size: 0.8rem;
          }

          .trend-box {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .trend-box-prev-wtd {
              display: flex;
              flex-direction: column;
              justify-content: center;
              text-align: center;

              .trend-header-wtd {
                flex: 2;
                padding: 5px 1px 0px;
                font-size: 0.75rem;
                font-weight: 500;
              }
              .trend-value-wtd {
                flex: 1;
                padding: 5px 1px;
                font-size: 0.75rem;
                font-weight: 500;
              }
            }
            .trend-box-values {
              display: flex;
              flex-direction: row;
            }
          }
        }
        .chart-inner-box {
          flex: 1;
          display: flex;
        }
      }

      .chart-inner-full-box {
        flex: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .chart-header-box {
          display: flex;
          flex-direction: column;
          align-items: center;

          font-size: 0.9rem;

          .chart-title-box {
            display: flex;
            font-size: 1.5rem;
            justify-content: center;
          }

          .last-week-value-box {
            display: flex;
            justify-content: center;
            font-size: 1.25rem;
          }

          .trend-box {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .trend-box-prev-wtd {
              display: flex;
              flex-direction: column;
              justify-content: center;
              text-align: center;
              padding: 0 10px;
              .trend-header-wtd {
                font-size: 1rem;
                font-weight: 500;
              }
              .trend-value-wtd {
                font-size: 0.9rem;
              }
            }
            .trend-box-values {
              display: flex;
              flex-direction: row;
            }
          }
        }
        .chart-inner-box {
          flex: 1;
          display: flex;
        }
      }
    }
  }

  .metrics-table-container {
    flex: 1;
    overflow: auto;
    display: flex;
    padding: 15px;
    font-size: 0.8rem;
    .header-logos {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      font-weight: 500;
      font-size: 1rem;
      padding-bottom: 3px;
      text-align: center;

      .networkLogo {
        height: 40px;
        width: 40px;
      }

      .length-label {
        font-size: 1rem;
        margin-top: auto;
      }
    }

    .row-labels {
      display: flex;
      align-items: center;
      flex-direction: row;
      font-weight: 500;
      justify-content: space-between;
      padding-right: 10px;

      .row-label {
        font-size: 1.1rem;
        margin-right: auto;
      }

      .row-label-daypart {
        font-size: 0.9rem;
      }
    }

    .metrics-table-box {
      flex: 1;
      display: flex;
    }
  }

  .expand-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;

    .show-hide-element {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 0 15px;
      font-size: 12px;
      .input-group-text {
        font-size: 12px;
      }
    }
  }
}

@import "../utils/sassHelpers.scss";
@import "../utils/colors.scss";

.breadcrumbTitle {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  & > * {
    transition: all 0.12s ease;
    &:not(:last-child) {
      cursor: pointer;
      text-decoration: underline;
      &:hover {
        color: $primary;
      }
    }
    &:last-child {
      pointer-events: none;
    }
  }
  .crumb {
    font-size: 0.7em;
    margin-left: 0.75rem;
    padding-left: 0.75rem;
    margin-bottom: 3px;
    position: relative;

    &::before {
      content: "/";
      position: absolute;
      left: 0;
      color: black;
    }
  }
}

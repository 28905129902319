.bpmToggleButtons {
  &.block {
    display: block;
  }
  &.noWrap {
    white-space: nowrap;
  }
  &.btn-group-sm > .btn {
    font-size: 0.875rem;
  }
}
.bpmToggleCheckboxes {
  .btn:not(:first-child) {
    margin-left: 1px !important;
  }
}

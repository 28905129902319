@import "../utils/sassHelpers.scss";

.bpm-companies {
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: $page-padding;

  .reconcile-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    &.companies {
      max-width: 600px;
    }
    &.builds .tableContainer {
      font-size: 12px;
    }

    .companies-name-column {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      font-size: 0.9rem;
      margin-left: 10px;

      .companies-logo {
        min-width: 80px;
        img {
          width: 80px;
          max-width: 80px;
          max-height: 40px;
        }
      }

      .companies-link {
        margin-left: 20px;
      }
    }

    .companies-ingest-buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      & > *:not(:first-child) {
        margin-left: 10px;
      }
      button {
        display: flex;
        font-size: 0.75rem;
      }
    }
  }
}

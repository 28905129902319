@import "../../utils/colors.scss";

// Styling for horizontal table view
.tbttHorizontalTableContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  min-height: 175px;
  .tbttHorizontalTableView {
    display: flex;
    flex-direction: row;
    border-bottom: 2px solid $GreyHeat100;
    overflow-x: auto;
    position: relative;
    .tbttHorizontalHeaderContainer {
      display: flex;
      flex-direction: column;
      position: sticky;
      z-index: 2;
      background-color: $White;
      left: 0px;
      .tbttHorizontalEmptyRow {
        display: flex;
        height: 35px;
        padding-bottom: 4px;
      }
      .tbttHorizontalHeaderSection {
        display: flex;
        flex-direction: row;
        height: 100%;
        border-top: 2px solid $GreyHeat100;
        .tbttHorizontalMainHeader {
          display: flex;
          flex-direction: column;
          .tbttHorizontalMainHeader1 {
            display: flex;
            height: 50%;
            border-bottom: 1px solid $GreyHeat100;
            padding: 22px 18px;
            font-size: 1.125rem;
            font-weight: 700;
            color: $Purple60;
            background-color: $Purple0;
            white-space: nowrap;
            .simpleTooltipText {
              white-space: wrap;
            }
          }
          .tbttHorizontalMainHeader2 {
            display: flex;
            height: 50%;
            border-top: 1px solid $GreyHeat100;
            padding: 22px 18px 0px 18px;
            font-size: 1.0625rem;
            font-weight: 700;
            color: $Grey100;
            background-color: $White;
            white-space: nowrap;
            .simpleTooltipText {
              white-space: wrap;
            }
          }
        }
        .tbttHorizontalSecondaryHeader {
          display: flex;
          flex-direction: column;
          font-size: 1rem;
          .tbttHorizontalSecondaryHeader1 {
            display: flex;
            align-items: center;
            padding: 0px 16px;
            font-weight: 500;
            height: 25%;
            border-bottom: 1px solid $GreyHeat100;
            border-left: 1px solid $GreyHeat100;
            border-right: 2px solid $GreyHeat100;
            white-space: nowrap;
            .simpleTooltipText {
              white-space: wrap;
            }
            &.bottom {
              border-top: 1px solid $GreyHeat100;
            }
            &.top {
              background-color: $Purple0;
            }
          }
          .tbttHorizontalSecondaryHeader2 {
            display: flex;
            align-items: center;
            padding: 0px 16px;
            font-weight: 400;
            height: 25%;
            border-left: 1px solid $GreyHeat100;
            border-right: 2px solid $GreyHeat100;
            white-space: nowrap;
            .simpleTooltipText {
              white-space: wrap;
            }
            &.top {
              border-bottom: 1px solid $GreyHeat100;
              background-color: $Purple0;
            }
          }
        }
      }
    }
    .tbttHorizontalDataSection {
      display: flex;
      .tbttHorizontalDataList {
        display: flex;
        flex-direction: row;
        height: 100%;
        .tbttHorizontalItem {
          display: flex;
          flex-direction: column;
          padding: 0px;
          min-width: 80px;
          border: 0px;
          border-radius: 0;
          .tbttHorizontalDateCell {
            display: flex;
            height: 35px;
            padding: 0px 12px 4px;
            align-items: flex-end;
            justify-content: flex-end;
            font-size: 0.875rem;
            font-weight: 500;
          }
          .tbttHorizontalDataCells {
            display: flex;
            flex-direction: column;
            height: 100%;
            font-size: 1rem;
            border-top: 2px solid $GreyHeat100;
            .tbttHorizontalCell1 {
              display: flex;
              height: 25%;
              align-items: center;
              justify-content: flex-end;
              width: 100%;
              padding-left: 12px;
              padding-right: 12px;
              border-bottom: 1px solid $GreyHeat100;
              font-weight: 500;
              border-right: 1px solid $GreyHeat100;
              &.bottom {
                border-top: 1px solid $GreyHeat100;
              }
              &.top {
                background-color: $Purple0;
              }
              &.last {
                border-right: 0px;
              }
            }
            .tbttHorizontalCell2 {
              display: flex;
              height: 25%;
              align-items: center;
              justify-content: flex-end;
              width: 100%;
              padding-left: 12px;
              padding-right: 12px;
              font-weight: 400;
              border-right: 1px solid $GreyHeat100;
              &.top {
                border-bottom: 1px solid $GreyHeat100;
                background-color: $Purple0;
              }
              &.last {
                border-right: 0px;
              }
            }
          }
        }
      }
    }
  }
  .tbttHorizontalTableBorder {
    display: flex;
    flex-direction: column;
    width: 2px;
    .tbttHorizontalEmptySpace {
      display: flex;
      height: 35px;
      padding-bottom: 8px;
      background-color: $White;
    }
    .tbttHorizontalEmptyRectangle {
      height: 100%;
      display: flex;
      &.left {
        border-right: 2px solid $GreyHeat100;
      }
      &.right {
        border-left: 2px solid $GreyHeat100;
      }
    }
  }
}
// Styling for vertical table view
.tbttVerticalTableContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .tbttVerticalTableView {
    display: flex;
    flex-direction: column;
    border-right: 2px solid $GreyHeat100;
    overflow-y: auto;
    position: relative;
    min-width: 799px;
    .tbttVerticalHeaderContainer {
      display: flex;
      flex-direction: row;
      position: sticky;
      z-index: 2;
      background-color: $White;
      top: 0px;
      .tbttVerticalEmptyRow {
        display: flex;
        width: 88px;
        padding-right: 12px;
      }
      .tbttVerticalHeaderSection {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-left: 2px solid $GreyHeat100;
        .tbttVerticalMainHeader {
          display: flex;
          flex-direction: row;
          height: 36.22px;
          border-bottom: 1px solid $GreyHeat100;
          .tbttVerticalMainHeader1 {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            padding: 0px 18px;
            font-size: 1.125rem;
            font-weight: 700;
            color: $Purple60;
            background-color: $Purple0;
            border-right: 2px solid $GreyHeat100;
          }
          .tbttVerticalMainHeader2 {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            padding: 0px 18px;
            font-size: 1.0625rem;
            font-weight: 700;
            color: $Grey100;
          }
        }
        .tbttVerticalSecondaryHeader {
          display: flex;
          flex-direction: row;
          font-size: 1rem;
          height: 36.22px;
          border-bottom: 2px solid $GreyHeat100;
          .tbttVerticalSecondaryHeader1 {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 0px 16px;
            font-weight: 500;
            width: 25%;
            &.left {
              background-color: $Purple0;
              border-right: 1px solid $GreyHeat100;
            }
            &.right {
              border-right: 1px solid $GreyHeat100;
            }
          }
          .tbttVerticalSecondaryHeader2 {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 0px 16px;
            font-weight: 400;
            width: 25%;
            &.left {
              background-color: $Purple0;
              border-right: 2px solid $GreyHeat100;
            }
          }
        }
      }
    }
    .tbttVerticalDataSection {
      display: flex;
      .tbttVerticalDataList {
        display: flex;
        flex-direction: column;
        width: 100%;
        .tbttVerticalItem {
          display: flex;
          flex-direction: row;
          padding: 0px;
          height: 36.22px;
          border: 0px;
          border-radius: 0;
          .tbttVerticalDateCell {
            display: flex;
            width: 88px;
            padding-right: 12px;
            justify-content: flex-end;
            align-items: center;
            font-size: 0.875rem;
            font-weight: 500;
          }
          .tbttVerticalDataCells {
            display: flex;
            flex-direction: row;
            width: 100%;
            font-size: 1rem;
            border-bottom: 1px solid $GreyHeat100;
            border-left: 2px solid $GreyHeat100;
            &.last {
              border-bottom: 0px;
            }
            .tbttVerticalCell1 {
              display: flex;
              width: 25%;
              align-items: center;
              justify-content: flex-end;
              padding-left: 16px;
              padding-right: 16px;
              font-weight: 500;
              &.left {
                background-color: $Purple0;
                border-right: 1px solid $GreyHeat100;
              }
              &.right {
                border-right: 1px solid $GreyHeat100;
              }
            }
            .tbttVerticalCell2 {
              display: flex;
              width: 25%;
              align-items: center;
              justify-content: flex-end;
              padding-left: 16px;
              padding-right: 16px;
              font-weight: 400;
              &.left {
                background-color: $Purple0;
                border-right: 2px solid $GreyHeat100;
              }
              &.last {
                border-bottom: 0px;
              }
            }
          }
        }
      }
    }
  }
  .tbttVerticalTableBorder {
    display: flex;
    flex-direction: row;
    height: 2px;
    .tbttVerticalEmptySpace {
      display: flex;
      width: 89px;
      background-color: $White;
    }
    .tbttVerticalEmptyRectangle {
      width: 100%;
      display: flex;
      &.top {
        border-bottom: 2px solid $GreyHeat100;
      }
      &.bottom {
        border-top: 2px solid $GreyHeat100;
      }
    }
  }
}

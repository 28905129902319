.streamingReportsPage {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;

  .fullPageRouter {
    flex: 1;
    display: flex;
    min-height: 0;
  }
}

@import "../utils/colors.scss";

.customSegmentsPage {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1.5px solid var(--neutral-400, #cbd2e1);
  border-radius: 1rem;
  padding: 1.5rem;
  margin: 0.5rem;
  width: 100%;
  max-height: calc(100vh - 4rem);

  & > * + * {
    margin-top: 1rem;
  }

  .customSegmentsHeader {
    display: flex;
    justify-content: space-between;

    .customSegmentsTitle {
      color: var(--brand-brand-100, #1f003f);
      font-size: 30px;
      font-weight: 500;
      line-height: 24px;
    }

    .dropdown-menu {
      background-color: white;

      .granularityExplanation {
        width: 306px; // Minimum width to get the text to fit on 3 lines at this font size
        font-size: 12px;
        padding: 0 0.5rem 0.5rem;
      }
    }
  }

  .segmentList {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    overflow-y: scroll;
    flex: 1 1 auto;

    .segmentContainer {
      display: flex;
      flex-direction: column;
      border: 2px solid var(--brand-60, #6b2def);
      width: calc((100% - 3rem) / 4);
      height: 400px;
      padding: 1.5rem;
      background-color: white;
      border-radius: 16px;

      &.default {
        border-width: 1px;
        border-color: var(--neutral-500, #94a0b8);

        .segmentHeader .segmentTypeLabel {
          color: var(--neutral-500, #94a0b8);
        }
      }

      .segmentHeader {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 1rem;

        .segmentTitle {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;

          .segmentTypeLabel {
            display: flex;
            align-items: center;
            text-transform: uppercase;
            font-size: 20px;
            color: var(--brand-60, #6b2def);

            .tooltipContainer {
              margin-left: 0.5rem;

              .customSegmentsTooltip {
                margin: 0;
                text-transform: none;
              }
            }
          }

          .segmentControls {
            display: flex;
          }
        }

        .segmentNameContainer {
          display: flex;
          flex-direction: row;
          align-items: center;

          .tooltipContainer {
            margin-left: 0.5rem;

            .customSegmentsTooltip {
              margin: 0;
            }

            svg {
              fill: var(--neutral-500, #94a0b8);
            }
          }
        }

        .segmentName {
          color: var(--brand-brand-100, #1f003f);
          font-size: 24px;
          font-weight: 600;
        }

        .segmentNameInput {
          border: 1px solid var(--brand-60, #6b2def);
          margin-top: 0.5rem;
          color: var(--brand-brand-100, #1f003f);
          font-size: 24px;
          font-weight: 600;
        }
      }

      .segmentBody {
        flex: 1 1 auto;
        overflow-y: scroll;
        // scrollbar space
        margin-right: -6px;

        .segmentValues {
          display: flex;
          flex-direction: column;

          & > * + * {
            margin-top: 0.5rem;
          }

          .editableValue {
            display: flex;

            .segmentValueInput {
              border: 1px solid var(--brand-60, #6b2def);
              color: var(--brand-brand-100, #1f003f);
              font-weight: 500;
            }
          }

          .option {
            display: flex;
            height: 2rem;
            padding-left: 1.5rem;
            color: var(--brand-brand-100, #1f003f);
            font-weight: 500;
          }

          .btn {
            margin-top: 0.5rem;
          }

          .segmentOptionDivider {
            width: 100%;
            margin-bottom: 0.5rem;
            border-top: 1px solid var(--neutral-500, #94a0b8);
          }
        }
      }

      .segmentFooter {
        margin-top: 1rem;

        button {
          width: 100%;
        }
      }

      .unsavedChanges {
        color: $Danger800;
        font-weight: 500;
        text-align: right;
        margin-bottom: -0.5rem;
        margin-top: 0.5rem;
      }
    }
  }

  .customSegmentsFooter {
    display: flex;
    justify-content: flex-end;
    margin: 1rem -1.5rem 0;
    padding: 1rem 1.5rem 0;
    border-top: 1.5px solid var(--neutral-400, #cbd2e1);

    .customSegmentsControls {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 1rem;

      .unsavedChanges {
        color: $Danger800;
        font-weight: 500;
        margin-right: 1rem;
      }
    }
  }
}

.geoSelectorMenu {
  .dropdownMenuContainer,
  .dropdownMenuBodyContainer {
    max-width: 264px;

    .dropdownMenuItems {
      max-height: 312px;
    }
  }
}

.notAccessible {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@import "../utils/sassHelpers";

.tableName {
  display: inline-block;
}

.tableActions {
  margin-bottom: 10px;
  float: right;
}

.dataConnectorsPageContainer {
  height: 100%;
  width: 100%;
  padding: $page-padding;
  display: flex;
  flex: 1;
  min-height: 0px;
  min-width: 0px;

  .dataConnectorsTable {
    flex: 1;
    min-height: 0px;
    min-width: 0px;
    height: 100%;
  }
}

@import "../utils/sassHelpers.scss";

@mixin creativeCard() {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin: 10px;
  .stripe {
    height: 100%;
    &.allocZero {
      background-color: #999999 !important;
    }
  }
  .creativeCard {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    .creativeContent {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      .creativeImgBox {
        display: flex;
        align-items: stretch;
        .thumbFetch {
          display: flex;
          align-items: center;
        }
        .creativeImg {
          width: 100%;
        }
        .noImg {
          border: 1px solid black;
          padding: 25px 30px;
        }
      }
      .isci {
        font-size: 16px;
        margin-top: 10px;
      }
      .subtitle {
        font-size: 12px;
        text-align: center;
        padding: 0 5px;
        max-height: 34px;
        overflow: hidden;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        .length {
          white-space: nowrap;
        }
      }
    }
    .allocInput,
    .clickthroughURLInput,
    .companionBannerInput {
      padding: 0 5px 6px;
      max-width: 183px;
      .companionDropdown {
        button {
          max-width: 173px;
          overflow-x: hidden;
        }
      }
      .label {
        font-size: 12px;
        text-align: center;
        padding: 0 5px;
        max-height: 34px;
        overflow: hidden;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}

.importModalBody {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1rem;
  * {
    margin-left: 1rem;
  }
}

.streamingCreativeActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  .datePickerLabel {
    font-size: 18px;
    font-weight: 500;
  }
  .importButtons {
    display: flex;
    flex-direction: row;
    margin-right: 1rem;
    * {
      margin-left: 2px;
    }
  }
  .viewButtons {
    margin-left: 1rem;
    .cartToggle {
      display: flex;
      flex-direction: row;
      align-items: center;
      .count {
        font-size: 1.2rem;
        margin-left: 2px;
      }
    }
  }
}
.streamingCreatives {
  flex: 1;
  padding: $page-padding;
  display: flex;
  flex-direction: row;
  max-height: 100%;
  .fullPageRouter {
    flex: 1;
    display: flex;
    min-width: 0;
    min-height: 0;
  }
  .staticCardContainer {
    @include creativeCard();
    .alloc {
      text-align: center;
      font-size: 24px;
      height: 38px;
    }
  }
  .streamingCreativesCardContainer {
    flex: 1;
    display: flex;
    min-width: 0;
    &.sideCart {
      flex: 0;
      min-width: 400px;
      padding-left: 20px;
    }
    height: 100%;
    .deleteButton:hover {
      color: white;
      background-color: #dc3545;
    }
    .streamingCreativesCard {
      flex: 1;
      display: flex;
      flex-direction: column;
      max-width: 100%;

      .noTags {
        font-size: 20px;
        padding: 20px;
        line-height: 1.5;
      }
      .cartList {
        flex: 1;
        font-size: 16px;
        display: flex;
        flex-direction: row;
        justify-content: stretch;
        min-height: 0;
        .cartEmpty {
          flex: 1;
          text-align: center;
          align-self: center;
        }
        .itemList {
          flex: 1;
          overflow: auto;
          .mainItem {
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
            .littlePie {
              width: 40px;
              height: 40px;
              background-color: cyan;
            }
            .itemLabel {
              flex: 1;
              padding-left: 0.75rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              min-width: 0;
            }
          }
        }
        .preview {
          flex: 3;
          position: relative;
          border-left: 1px solid rgba(0, 0, 0, 0.3);
          border-bottom: 1px solid rgba(0, 0, 0, 0.3);
          display: flex;
          flex-direction: column;
          max-height: 100%;
          .actions {
            display: flex;
            flex-direction: row;
            padding: 10px;
            justify-content: flex-end;
            & > * {
              margin-left: 10px;
            }
          }
          .pieBox {
            flex: 3;
            position: relative;
          }
          .placementList {
            border-top: 1px solid rgba(0, 0, 0, 0.3);
            flex: 1;
            display: flex;
            flex-direction: row;
            min-height: 0;
            & > * {
              overflow: auto;
              max-height: 100%;
              padding: 20px;
            }
            .networkGroups {
              flex: 1;
              .placementItem {
                cursor: pointer;
              }
            }
            .subPlacements {
              flex: 1;
            }
          }
        }
      }
      .selectList {
        flex: 1;
        font-size: 16px;
        overflow: auto;
        .cartListItem {
          cursor: pointer;
          position: relative;
          .removeItem {
            position: absolute;
            right: 5px;
            top: 5px;
          }
          .viewPlacementButton {
            position: absolute;
            right: 35px;
            top: 5px;
          }
          .putBack {
            position: absolute;
            right: 35px;
            top: 5px;
          }
          &.cartItem {
            .mainItem {
              display: flex;
              flex-direction: row;
              align-items: center;
              .littlePie {
                width: 40px;
                height: 40px;
                background-color: cyan;
              }
              .itemLabel {
                flex: 1;
                padding-left: 0.75rem;
                padding-right: 40px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
          .placementList {
            font-size: 12px;
            margin: 1rem -1.25rem 0 0.5rem;
            .placementItem {
              padding: 10px;
              position: relative;
            }
          }
        }
      }
      .buttonsContainer {
        padding: 10px;
        display: flex;
        flex-direction: row;

        & > * {
          flex: 1;
          &.clearAll {
            flex: 0;
          }
          &:first-child {
            margin-right: 0.5rem;
          }
          &:last-child {
            margin-left: 0.5rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          &.save {
            flex: 0;
            min-width: 55px;
          }
        }
      }

      .approvals {
        flex: 1;
        display: flex;
        flex-direction: row;
      }

      .generateAction {
        padding: 10px 10px 0;
        display: flex;
        flex-direction: row;
        & > * {
          flex: 1;
        }
      }
    }
    .missingNames {
      padding: 0 20px;
    }
    .generate {
      flex: 1;
      display: flex;
      flex-direction: column;
      min-height: 0;
      .generateContent {
        flex: 1;
        display: flex;

        min-height: 0;
        flex-direction: column;
        overflow: auto;
        margin: 10px;
        .networkHeader {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          & > *:first-child {
            flex: 0;
            min-width: 50px;
            height: 50px;
            margin: 0.25rem 0.75rem 0.25rem 0;
          }
        }
        .creativeHeader {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          & > *:first-child {
            flex: 0;
            width: 100px;
            min-width: 100px;
            min-height: 56.25px; // 16:9 ratio
            margin: 0.25rem 0.75rem 0.25rem 0;
          }
        }
        .rotationHeader {
          display: flex;
          flex-direction: row;
          padding: 0.25rem 0;
          font-size: 1rem;

          .legendItems {
            margin-left: 0.5rem;
            &.collapsed {
              display: flex;
              flex-flow: row wrap;
            }

            & > * {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin: 0 0.5rem;
              & > *:not(:first-child) {
                margin-left: 0.5rem;
              }
            }
          }
          .expandButton {
            font-weight: 500;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        .swatch {
          display: inline-block;
          height: 20px;
          width: 20px;
          border: 1px solid black;
        }
        .collapsingGroup {
          display: flex;
          flex-direction: column;
          .collapsingHeader {
            font-size: 1.5rem;
            padding: 2px 10px;
            cursor: pointer;
            display: flex;
            align-items: center;
            &:hover {
              background-color: rgba(0, 0, 0, 0.15);
            }
          }
          .collapsingList {
            overflow: hidden;
            transition: height 0.12s ease;
            margin-left: 2rem;
            display: flex;
            flex-direction: column;
          }
          .finalRows {
            overflow: hidden;
            padding: 0 10px;
          }
        }
      }
      .generateViewToggles {
        margin: 0 !important;
      }
    }
  }
  .nonCart {
    flex: 1;
    display: flex;
    .workspace {
      flex: 1;
      display: flex;
      .workspaceCard {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 20px;
        .workspaceCardInner {
          flex: 1;
          position: relative;
          .creativeCardContainer {
            @include creativeCard();
          }
        }
      }
    }
    .placementTableCell {
      justify-content: flex-start;
      padding: 0 1rem;
      font-size: 0.8rem;
    }
    .placementTableHeader {
      font-size: 1rem;
    }
  }
}

.viewPlacementModal {
  $margin: 100px;

  max-width: unset;
  width: calc(100% - #{$margin * 2});
  height: calc(100% - #{$margin * 2});
  margin: $margin;
  .tagName {
    font-size: 16px;
    font-weight: 100;
  }
  .modalFooter {
    max-height: 70px;
    .viewPlacementFooter {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .loadRotation {
        display: flex;
        flex-direction: row;
        align-items: center;
        .checkAll {
          margin-left: 10px;
          .custom-control-label {
            line-height: 1.7;
          }
        }
      }
    }
  }
  .modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    & > * {
      min-width: 700px;
    }
    .modal-body {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 0;

      .rotationBody {
        flex: 3;
        display: flex;
        position: relative;
        margin-bottom: 0px;
        max-height: 600px;

        .noRotation {
          font-size: 20px;
          flex: 1;
          align-self: center;
          justify-self: center;
          text-align: center;
        }
        .staticCardContainer {
          @include creativeCard();
          .alloc {
            text-align: center;
            font-size: 24px;
            height: 40px;
          }
        }
      }
      .metadata {
        flex: 1;
        border-top: 1px solid rgba(0, 0, 0, 0.3);
        padding: 20px;
        margin: 0;
        overflow: auto;
        max-height: 250px;
      }
    }
  }
}

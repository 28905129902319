@import "../utils/colors.scss";
.onboardingPageContainer {
  .onboardingProgress {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4px 8px;
    margin: 0 16px;
  }

  .onboardingProgressBar {
    margin-bottom: 8px;
  }

  .infoTab {
    visibility: hidden;
  }

  .onboardingPage {
    padding: 0.5rem 1rem;
    margin: 1rem;
    border-radius: 16px;
    border: 1.5px solid $White;
  }

  .formPage {
    font-weight: 600;
    font-size: 16px;
  }

  .formPageHeader {
    color: $Brand60;
    font-size: 24px;
    font-weight: 600;
  }

  .onboardingLinkCard {
    padding: 1rem 1.5rem;
    margin: 0.5rem 0;
  }

  .uploadedFiles {
    border: none;
    background-color: $Neutral200;
    padding: 1rem 1.5rem;
  }

  .formPageNavBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;

    .formPageSaveButton {
      margin-right: 0.5rem;
    }
  }

  .questionRows {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .onboardingFreeForm {
    margin-top: 1.5rem;
  }

  .onboardingLinkInput {
    border-radius: var(--4, 4px);
    border: 1.5px solid $Brand60;
  }

  *:disabled {
    opacity: 50%;
    background-color: $White;
  }
}

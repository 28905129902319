.retail-ops-channel-page {
  margin: 1.2rem;
  margin-top: 15px;

  .retailOpsControls {
    display: flex;
    flex-direction: column;

    .amazonOAuthContainer {
      display: flex;
      flex-direction: column;
      width: 700px;
      border: 1px solid grey;
      border-radius: 8px;
      padding: 8px;
      margin-top: 8px;
      background-color: white;
      .label {
        font-weight: 500;
        margin-bottom: 8px;
      }
      .controls {
        display: flex;
        flex-direction: row;
        & > * {
          margin-right: 8px;
        }
      }
    }
  }

  .basic-card {
    display: flex;
    margin-top: 15px;
    width: 100%;
  }

  .table-wrapper {
    width: 100%;
  }
}

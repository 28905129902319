@import "../utils/colors.scss";

.batchFailuresContainer {
  display: flex;
  flex: 1;
  padding: 1rem;

  .previousBuild {
    padding-right: 5px;
    font-size: 0.7rem;
  }

  .BPMButton {
    &:hover {
      box-shadow: 0 0 0 3px $primary-light;
    }
  }

  .jobName {
    text-align: left;
    width: 100%;
    padding: 20px;
  }
}

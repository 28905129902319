@use "sass:math";
@import "../../../utils/colors.scss";
$circle-diameter: 14px;

.chartTooltipV2 {
  background-color: white;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 2px 10px 0px rgba(230, 234, 238, 1);
  border: 2px solid $Neutral600;

  .tooltipContainer {
    display: flex;
    flex-direction: column;

    &.hasComparisonPeriod {
      border-right: 1px solid $Neutral600;
    }

    .tooltipHeader {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: $Brand100;
      font-size: 16px;
      font-weight: 400;
      background-color: $Neutral200;
      padding: 8px;
      .headerSuperLabel {
        font-size: 12px;
      }
    }

    .itemList {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-top: 8px;
      padding: 8px;
      .itemRow {
        display: flex;
        flex-direction: row;

        &.hasComparisonPeriod {
          flex-direction: column;
        }

        .labelContainer {
          display: flex;
          flex: 1;
          flex-direction: row;
          align-items: center;
          .label {
            font-size: 14px;
            font-weight: 500;
            margin-right: 12px;
            color: $Brand100;
          }
        }
        .value {
          margin-left: auto;
          font-size: 14px;
          color: $Brand100;
          &.hasComparisonPeriod {
            margin-left: $circle-diameter + 12px;
          }
        }

        // Styling for different key shape options
        .circle {
          width: $circle-diameter;
          height: $circle-diameter;
          border-radius: math.div($circle-diameter, 2);
          margin-right: 12px;
        }
        .square {
          width: $circle-diameter;
          height: $circle-diameter;
          margin-right: 12px;
        }
        .line {
          width: $circle-diameter;
          height: 3px;
          margin-right: 12px;
        }
        .dottedLine {
          width: $circle-diameter;
          margin: 0px 12px 0px 0px;
        }
      }
    }

    .footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 8px;
      border-top: 1px solid $Neutral600;
      .footerLabel {
        color: $Brand100;
        font-size: 16px;
        font-weight: 500;
      }
      .footerValue {
        color: $Brand100;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

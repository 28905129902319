@import "../../utils/colors.scss";

.addInputModal {
  .modal-header {
    flex-direction: column;
    padding: 1.5rem 2rem;

    .modalTitle {
      font-weight: 600;
    }

    .subTitle {
      color: #6b2def;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .modal-body {
    display: flex;
    flex-direction: row;
    padding: 0;

    & > * {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin: 0;
      padding: 1rem 2rem;
    }

    & > * + * {
      border-left: 1px solid #dee2e6;
    }

    .sectionTitle {
      font-weight: 600;
      margin-bottom: 0.5rem;

      .sectionHint {
        color: var(--brand-60, #6b2def);
        font-size: 12px;
        line-height: 100%;
        font-weight: 400;
      }

      .sectionTitleExtra {
        font-size: 14px;
        font-weight: 400;
      }
    }

    .asterisk {
      font-weight: 700;
      color: var(--brand-60, #6b2def);
    }

    .dateInputFieldContainer {
      margin-top: 0.5rem;
      margin-bottom: 0;

      .dateInputFieldLabel {
        color: var(--brand-100, #1f003f);
        left: 0;
      }

      .dateInputFieldValue:focus,
      .dateInputFieldValue:hover {
        border-color: #6b2def;
      }

      .dateInputFieldError,
      .dateInputFieldInfo {
        left: 0;
      }
    }

    .dateInputFieldContainer.optional {
      .dateInputFieldValue {
        border: 2px solid $Neutral500;
      }

      .dateInputFieldInfo {
        color: $Neutral500;
      }
    }

    .form-control,
    .dateInputFieldValue {
      border: 2px solid var(--brand-60, #6b2def);
      margin: 0 -1rem;
      width: calc(100% + 2rem);
    }

    .inputExplanation {
      margin-top: 0.5rem;
      color: #3f444d;
      font-size: 14px;
      font-weight: 400;
      line-height: 15.4px;
    }

    .dateInputExplanation {
      margin-top: 3rem;
    }

    .inputExplanation.warningExplanation {
      display: flex;
      color: $Warning600;

      & > svg {
        flex: 1 0 auto;
        margin-right: 0.5rem;
      }
    }

    .channelInput {
      & > .channelGroup {
        margin: 0 -2rem;
        padding: 0 2rem 1rem;

        &:not(:last-child) {
          border-bottom: 1px solid #dee2e6;
        }

        & ~ .channelGroup {
          padding-top: 1rem;
        }

        &:last-child {
          padding-bottom: 0;
        }
      }
    }

    .unitsInput {
      & > input.form-control {
        margin-bottom: 16px;
      }
    }

    .kpiDescriptionInput {
      textarea {
        resize: none;
        overflow: hidden;

        &.is-invalid {
          border: 2px solid #ff2630;
        }
      }

      .invalid-feedback {
        color: #ff2630;
      }
    }
  }

  .modal-footer {
    & > * {
      margin: 0;

      & + * {
        margin-left: 16px;
      }
    }
  }
}

.addOutcomeDataModal {
  .timeFrameInput {
    .dateInputFieldContainer ~ .dateInputFieldContainer {
      margin-top: 3.5rem;
    }
  }
}
@import "../utils/colors.scss";
.KPIPickers {
  min-width: 850px;
  .kpiFunnelInput {
    display: flex;
    flex-direction: row;
    flex: 1;
    .kpiFunnelPicker {
      flex: 1;
      margin-right: 5px;
    }
    .kpiFunnelClearButton {
      flex: 1;
      margin-left: 5px;
      max-width: 225px;
    }
  }
}

.bpmTooltip {
  pointer-events: none;
  white-space: pre;
  transition: all 0.15s ease;

  font-size: 0.8rem;
  background-color: #ffffff;
  border: 1px #e6eaee solid;
  border-radius: 2px;
  padding: 12px 10px;
  -moz-box-shadow: 0 2px 5px 0 #e6eaee;
  -webkit-box-shadow: 0 2px 5px 0 #e6eaee;
  box-shadow: 0 2px 5px 0 #e6eaee;
}

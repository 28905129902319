@use "sass:math";
$circle-diameter: 14px;

.liftFromTvTooltip {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 18px;
  box-shadow: 0px 2px 10px 0px rgba(230, 234, 238, 1);
  border-radius: 2px;

  .label {
    font-weight: 500;
  }

  .itemList {
    display: flex;
    flex-direction: column;
    .itemRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      .circle {
        width: $circle-diameter;
        height: $circle-diameter;
        border-radius: math.div($circle-diameter, 2);
        margin-right: 4px;
      }
      .name {
        font-size: 14px;
        font-weight: 500;
        margin-right: 8px;
      }
      .value {
        margin-left: auto;
        font-size: 14px;
      }
    }
  }
}

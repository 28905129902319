.linearOrdersActions {
  display: flex;
  flex-direction: row;
  & > :not(:last-child) {
    margin-right: 1rem;
  }
}

.optionsDropdown {
  .dropdownItem {
    display: flex;
    flex-direction: row;
    align-items: center;

    .label {
      margin-left: 8px;
    }
  }
}

.linearOrders {
  display: flex;
  flex: 1;
  padding: 1rem;
  background-color: white;

  .noOrders {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 2rem;
  }

  .pdfLink {
    background: none !important;
    border: none;
    color: #337ab7;
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    flex: 1;
    justify-content: flex-start;
  }

  .notes {
    height: 90%;
    resize: none;
  }

  .orderstatus {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem;

    &.Pending {
      color: orange;
    }
    &.Confirmed {
      color: green;
    }
    &.Sent {
      color: rgb(115, 126, 255);
    }
    &.Cancelled {
      color: red;
    }

    .fileInput {
      width: 90%;
      font-size: 12px;
      margin-left: 0.5rem;
    }
  }

  .pdfstatus {
    &.success {
      color: green;
    }
    &.pending {
      color: orange;
    }
    &.failed {
      color: red;
    }
  }
}

.path-analysis-page {
  margin: 1.2rem;
  margin-top: 15px;

  .BPMFilterBarContainer {
    margin: 0;
  }

  .basic-card {
    display: flex;
    width: 100%;
    padding: 0 0 5px;
    overflow: hidden;
    border-radius: 0 0 12px 12px;

    &.ad-path {
      flex-direction: column;
      border-radius: 12px;
      padding-top: 20px;

      .table-wrapper {
        flex: unset;
        width: auto;
      }
    }
  }

  .path-analysis-date-selection {
    .dt-value {
      display: none;
    }
  }

  .touchpoint-legend-wrapper {
    margin: 0 0 20px auto;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .touchpoint-legend {
    background: linear-gradient(0deg, #ffffff, #ffffff), #fffbfe;
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    display: flex;
    border-radius: 4px;
    height: 40px;
    padding: 0 40px;
    margin-left: 20px;
    gap: 24px;
  }

  .touchpoint-legend-key {
    display: flex;
    align-items: center;
    font-weight: normal;
  }

  .touchpoint-legend-color {
    height: 16px;
    width: 16px;
    border-radius: 8px;
    margin-right: 8px;
  }

  .recharts-pie * {
    cursor: pointer;
  }

  .pie-chart-tooltip {
    background: #fff;
    padding: 5px;
    border: 1px solid #bdbdbd;
    border-radius: 2px;
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);

    b {
      font-weight: 500;
    }
  }

  .path-analysis-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .back-arrow {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }

  .path-analysis-disclaimer {
    background: linear-gradient(0deg, #ffffff, #ffffff), #fffbfe;
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    height: 32px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    font-weight: 500;
  }

  .path-analysis-row {
    display: flex;
    gap: 20px;
    margin-bottom: 60px;
    width: 100%;
  }

  .row-header {
    display: flex;
    font-size: 20px;
    font-weight: 500;
    gap: 20px;
    margin-bottom: 20px;
    width: 100%;
    border: 1px solid #000;
    border-radius: 6px;
    padding: 8px 12px 8px 48px;
    background-color: #fff;
    position: relative;

    &::before {
      content: "»";
      position: absolute;
      left: 16px;
      top: -16px;
      font-size: 48px;
      font-weight: 900;
      color: #4423ac;
    }

    .row-header-text {
      width: 100%;
    }
  }

  .table-wrapper {
    display: flex;
    height: 600px;
    flex: 1 1;
    width: 100%;

    .stickyHeader .stickyTableCell {
      color: #fff;
      background-color: #8530ce;

      span {
        font-weight: 600;
      }

      &.useSortHeaderCaret {
        &:before {
          border-bottom-color: #fff;
        }

        &:after {
          border-top-color: #fff;
        }

        &.up:before {
          border-bottom-color: #8530ce;
        }

        &.down:after {
          border-top-color: #8530ce;
        }
      }
    }
  }

  .chart-wrapper {
    width: 100%;
  }

  .path-analysis-touchpoint {
    width: calc(25% - 15px);
  }

  .touchpoint-header {
    padding: 0 0 20px;
  }

  .percent-label {
    font-size: 14px;
    pointer-events: none;
  }

  .attribution-table {
    span {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  .table-cell {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    padding: 0 8px;

    &.align-center {
      justify-content: center;
    }

    &.align-left {
      justify-content: start;
    }

    &.align-right {
      justify-content: end;
    }
  }
}

.wtoRulesContainer {
  display: flex;
  justify-content: center;

  .wtoRules {
    width: 900px;

    .description {
      margin-bottom: 15px;
      line-height: 1.5625rem;
      color: #8d8d8d;
    }

    .ploverUrlWrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;

      p {
        margin: 0;
      }

      .ploverUrl {
        margin-left: 10px;
        color: #2a79ff;
        background: transparent;
        font-style: italic;
      }

      .copyUrlIcon {
        margin: auto 10px;
        cursor: pointer;
        color: #8254ff;

        &:hover {
          color: #5200ce;
        }
      }
    }

    .rulesHeader {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      h3 {
        margin: 0;
      }
    }

    .rulesToolbar {
      display: flex;
      align-items: center;

      button {
        margin-left: 16px;
      }
    }

    .reorderToggle {
      margin-left: auto;
    }

    .BPMToggleSwitch:not(:disabled) * {
      cursor: pointer;
    }

    .rulesEditor {
      font-weight: 500;
      border: 1px solid #e5e7eb;
      background: #fff;
      border-radius: 5px;
      padding: 20px 30px 80px;
      margin-bottom: 30px;
    }

    .rulePrompt {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 50px 0 40px;
      font-size: 1rem;
      line-height: 1.875rem;
      color: #666;

      button {
        margin-top: 20px;
        padding: 5px 30px;
      }
    }

    .ruleCard {
      width: 100%;
      border: 1px solid #e5e7eb;
      border-radius: 5px;
      padding: 25px 30px 30px;
      background: #fff;
      max-width: 694px;
      margin: 0 auto 18px;

      &.reorderable {
        max-width: 20rem;
        cursor: grab;

        &:hover {
          background: #f4f5f7;
        }

        &.isDragging {
          background: #deebff;
        }

        &.isUnder {
          outline: 2px solid #8254ff;
        }
      }

      .ruleHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .ruleIndex {
        display: flex;
        justify-content: center;
        font-size: 900;
        align-items: center;
        border-radius: 50%;
        height: 1.125rem;
        width: 1.125rem;
        line-height: 1.125rem;
        font-size: 0.75rem;
        color: #fff;
        background-color: #4e90ef;
        margin-right: 18px;
      }

      .ruleTitle {
        display: flex;
        align-items: center;
      }

      .ruleToolbar {
        display: flex;

        .actionsMenu {
          svg {
            margin-right: 10px;
          }
        }

        button:first-child {
          margin-right: 10px;
        }
      }

      .operator {
        text-transform: uppercase;
        background-color: #e4e4e4;
        padding: 8px 12px;
        font-size: 0.6875rem;
        border-radius: 3px;
        color: #4c4c4c;
        width: 3.625rem;
        text-align: center;
        margin: 0 2rem auto 0;

        &.rule {
          width: 4.875rem;
        }
      }

      .trigger {
        width: 7.5rem;
        margin-right: 10px;
      }

      &.default {
        background-color: #deebff;
        border: 1px solid #b9b9b9;

        >div {
          padding-left: 16px;
        }
      }

      .ruleName {
        font-style: italic;
        font-size: 1rem;
      }

      .lightText {
        color: #a2a2a2;
      }

      .ruleSpecifications {
        width: 100%;
        margin-top: 20px;

        .ruleSpecTitle {
          color: #c9cacd;
          font-size: 0.5625rem;
          margin: 26px auto 0 0;
        }

        .ruleSpecification {
          width: 100%;

          hr {
            width: 100%;
            height: 1px;
            border-top: 1px dotted #e5e7eb;
            margin: 0 auto 20px;
          }
        }

        .value {
          margin-right: 0;
          font-size: 0.75rem;
          border: 1px solid #e5e7eb;
          padding: 6px 16px;
          border-radius: 15px;
        }
      }

      .ruleSpecBody {
        width: 100%;
        padding-left: 30px;
        margin-bottom: 20px;
        font-size: 0.875rem;
        color: #555;
      }

      .ruleSpecRow {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;

        .operationName {
          width: 4rem;
          margin-right: 10px;
        }

        .iconWrapper {
          margin: 0 10px;

          svg {
            width: 24px;
            height: 24px;
          }
        }

        &.retention {
          >* {
            width: 33.33%;
          }
        }

        &.utmVariants {
          gap: 30px;

          .utmVariantId {
            margin: 0 35px auto 0;
            display: flex;
            white-space: nowrap;
            width: 4rem;
          }
        }

        .time-frame-card {
          display: flex;
          gap: 34px;
          align-items: center;

          .time-frame-header {
            display: inline-block;
            width: 50px;
          }

          .time-frame-value {
            display: inline-block;
            color: #a2a2a2;
          }
        }
      }

      .ruleSpecCol {
        width: 100%;
        display: flex;
        flex-direction: column;

        &.plover {
          width: 60%;
        }
      }

      .ploverPercentage {
        margin-bottom: auto;
      }

      .days {
        display: flex;
        margin-right: 30px;

        span {
          border: 1px solid;
          border-color: #3b7fe2;
          border-radius: 50%;
          font-size: 0.625rem;
          width: 1.25rem;
          height: 1.25rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 1px;
          color: #93b8ed;

          &.selected {
            color: white;
            background: #93b8ed;
          }
        }
      }

      .timeRange {
        width: 7rem;
        margin-right: 30px;
        font-size: 0.75rem;

        div {
          display: flex;
          justify-content: space-between;

          &:first-child {
            border-bottom: 1px #e5e7eb solid;
            padding-bottom: 5px;
            margin-bottom: 5px;
          }
        }
      }

      .dowDetails {
        margin-bottom: 0;
      }

      .dowVariant {
        &:last-child {
          margin: 0;
        }

        .dowName {
          width: 70%;
          margin-right: 10px;

          div {
            margin-bottom: 0;
          }
        }
      }

      .dowPercentage {
        width: 30%;
      }
    }
  }

  .rulesButtons {
    display: flex;
    width: 100%;
    margin-bottom: 1.2rem;

    button {
      margin: 0 0 1rem 20px;

      &:first-child {
        margin-left: auto;
      }
    }
  }
}

.form-check {
  display: flex;
  flex-direction: row;
  align-items: center;

  .form-check-input {
    width: 20px;
    height: 20px;
    accent-color: #6b2def;
  }

  .form-check-input[type="radio"]:checked {
    appearance: none;
    border-radius: 100%;
    background-color: #6b2def;
  }

  .form-check-input[type="radio"]:checked::after {
    content: "";
    display: block;
    position: relative;
    left: 2px;
    top: 2px;
    border: 2px solid #fff;
    border-radius: 100%;
    width: 16px;
    height: 16px;
  }

  .form-check-input:not(:checked) {
    appearance: none;
    border: 2px solid #6b2def;
  }

  .form-check-input[type="radio"]:not(:checked) {
    border-radius: 100%;
  }

  .form-check-input[type="checkbox"]:not(:checked) {
    border-radius: 2px;
  }

  .form-check-label {
    margin-left: 0.5rem;
    width: 100%;
  }

  * {
    cursor: pointer;
  }

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

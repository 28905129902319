.investMain {
  display: flex;
  justify-content: space-between;
  .investLeft {
    width: 50%;
    .snapshotChartContainer {
      .chartContainer .enableHoverDesign {
        width: 100%;
        margin-left: 0;
      }

      .chartContainer.enableHoverDesign > .header .leftSide .headerTitle {
        max-width: 300px;
      }
    }

    .snapshotChartContainer .chartContainer.enableHoverDesign {
      width: 100%;
      margin-left: 0;
    }
  }
  .investRight {
    width: 50%;
    .investToIncreaseRightActions {
      display: flex;
      gap: 16px;
    }
    .chartContainer {
      width: 100%;
      margin-right: 0;
      .snapshotGraphHeader {
        color: #1f003f;
        font-weight: 500;
      }
    }
    .stickyTableOuter {
      min-width: 100%;
      .stickyTable {
        min-width: 100%;
      }
    }
    .chartContainer .header .rightSide > * {
      z-index: 11;
    }
  }
}

@import "../utils/colors.scss";
@import "../styles/Fonts.scss";

.gradientLegend {
  display: inline-flex;
  padding: 0px 32px;
  align-items: center;
  gap: 32px;
  border-radius: 4px;
  border: 1px solid #cbd2e1;
  margin-bottom: 32px;
  .legendTitle {
    display: inline-flex;
    padding: 0px 0px;
    align-items: center;
    font-family: Graphik;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: $Brand100;
  }
  .legendContentsBox {
    display: flex;
    width: 583.5px;
    padding: 20px 0px 16px 0px;
    flex-direction: column;
    align-items: flex-start;
    .legendContents {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      align-self: stretch;
      .rectangle {
        height: 14px;
        align-self: stretch;
      }

      .legendTickContainer {
        position: relative;
        height: 30px;
        width: 100%;
        .legendTickBox {
          position: absolute;
          top: 0;
          transform: translateX(-50%);
          text-align: center;

          .legendTickLine {
            width: 1px;
            height: 10px;
            background: #1f003f;
            margin: 0 auto;
          }

          .legendTickText {
            margin-top: 5px;
            font-family: Graphik;
            font-size: 14px;
            font-weight: 500;
            color: $Brand100;
          }
        }
      }
    }
  }
}

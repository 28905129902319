@import "../../../utils/colors.scss";
@import "../../../styles/Fonts.scss";

.changeLogModal {
  .modal-dialog.modal-dialog-centered {
    justify-content: center;
    .modal-content {
      border-radius: 16px;
      border: 2px solid $Brand60;
      min-width: fit-content;
      display: flex;

      .changeLogModalHeader {
        display: flex;
        align-items: flex-start;
        padding: 24px 24px 16px;
        @include headingRegular;
        .close {
          color: $Brand60;
        }
      }

      .changeLogModalBody {
        display: flex;
        flex-direction: column;
        padding: 24px;
        justify-content: center;
        align-items: center;
        &.modal-body > * {
          margin: 0px;
        }
        .changeLogTable {
          thead {
            tr {
              border-bottom: none;
              th {
                background-color: $White;
                @include bodyBold;
                border: none;
                white-space: nowrap;
                text-align: left;
                padding: 12px 48px 12px 0px;
                &:nth-child(1) {
                  width: 50%;
                }
                &:nth-child(2) {
                  width: 25%;
                }
                &:nth-child(3) {
                  width: 25%;
                  padding-right: 0px;
                }
              }
            }
          }
          tbody {
            tr {
              border-bottom: 1px solid $Neutral400;
              &:last-child {
                border-bottom: none;
              }
              td {
                border: none;
                vertical-align: top;
                padding: 12px 48px 12px 0px;
                @include secondaryRegular;
                text-align: left;
                white-space: nowrap;
                &:nth-child(1) {
                  width: 50%;
                }
                &:nth-child(2) {
                  width: 25%;
                }
                &:nth-child(3) {
                  width: 25%;
                  padding-right: 0px;
                }
              }
            }
          }
        }
        .altText {
          @include secondaryRegular;
        }
      }

      .changeLogModalFooter {
        display: flex;
        flex-direction: row;
        column-gap: 16px;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        padding: 16px 12px;
        background-color: $Danger100;
        @include tertiaryRegular;
        color: $Danger800;
      }
    }
  }
}

.placementRow {
  display: flex;
  flex-direction: column;
  & > * {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .metadata {
    flex: 1;
  }
  .suppressed {
    margin-left: 0.5rem;
    font-size: 0.8rem;
    font-style: italic;
  }
  .existingTag {
    font-size: 0.8rem;
    font-style: italic;
  }
}

@import "../../utils/colors.scss";
@import "../../styles/Fonts.scss";

.modelOverview {
  display: flex;
  gap: 16px;
  .left {
    display: flex;
    flex-direction: column;
    flex: 3;
    gap: 16px;

    .chartContainer:first-child {
      padding-bottom: 16px;
      .header {
        padding-bottom: 0px;
      }
      .contents {
        padding-top: 6px;
      }
    }
    .chartContainer:last-child {
      .contents {
        padding-right: 0;
      }
    }
    .chartContainer {
      margin: 0;
      .headerText {
        @include headingThin;
      }
      .contents {
        padding-bottom: 0;
      }
      .rightOfChart {
        color: $Brand100;
        padding-bottom: 16px;
        padding-right: 2px;
        width: 168px;
      }
    }
    .modelOverviewSpend {
      z-index: 10;
      .areaChart {
        gap: 16px;
      }
    }
  }
  .right {
    .chartContainer {
      margin: 0;
      &.enableHoverDesign {
        height: 100%;
      }
    }
    .contents {
      padding: 11px 24px 14px 24px;
    }
    .dataSection {
      border-top: 1px solid $Neutral400;
      padding: 24px 8px 0px 8px;

      .dataSectionTitle {
        color: $Brand100;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .dataSectionRow {
        display: flex;
        font-size: 16px;
        font-style: normal;
        color: $Brand100;
        justify-content: space-between;
        line-height: 107.143%;
        margin-top: 12px;
        width: 100%;

        .label {
          align-items: center;
          color: inherit;
          display: flex;
          font-weight: 500;
          .tooltip-container {
            margin-left: 6px;
            top: -3px;
          }
        }
        .value {
          font-weight: 400;
          color: inherit;
          &.outcomeVariable {
            text-transform: capitalize;
          }
        }
      }
    }
  }
}

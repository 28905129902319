// https://codepen.io/imarkrige/pen/vgmij
.useSortHeaderCaret {
  position: relative;
  padding-right: 14px !important;
  cursor: pointer;
  user-select: none;
  & > * {
    max-width: 100%;
    overflow: hidden;
  }
  &:before,
  &:after {
    border: 4px solid transparent;
    content: "";
    display: block;
    height: 0;
    right: 4px;
    top: 50%;
    position: absolute;
    width: 0;
  }
  &:before {
    border-bottom-color: #aaaaaa;
    margin-top: -9px;
  }
  &:after {
    border-top-color: #aaaaaa;
    margin-top: 1px;
  }
  &.up:before {
    border-bottom-color: black;
  }
  &.down:after {
    border-top-color: black;
  }
}

.SpendAndEffectBarChart {
  display: flex;
  &.activeChannel {
    border-bottom: solid lightgrey 2px;
  }
  .chartLegend {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    padding-top: 10px;
    .legend {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      margin-right: 16px;
      width: auto;
      height: 54px !important;
      .itemLabel {
        padding-top: 4px;
        padding-right: 8px;
        font-weight: 500;
        height: 54px;
      }
      .itemValueWrapper {
        height: 60px;
      }
      .itemValue {
        width: auto;
        display: flex;
        justify-content: space-between;
        padding: 4px;
        .actualValuesSpan {
          width: 80px;
          display: flex;
          justify-content: flex-end;
        }
      }
      .itemNameAndPercent {
        width: 80px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .rechartContainer {
    min-width: 200px;
    padding-top: 5px;
  }
}

@import "../utils/colors.scss";
$gridBorderColor: #e6eaee;
$dimension-padding: 0.35rem;

.segmentationLabelingTable {
  display: flex;
  flex: 1;
  overflow-x: scroll;
  margin-bottom: -1rem;

  .frozenColumnsTable {
    height: 100%;
  }

  .stickyTableContainer {
    display: flex;
    flex: 1;
    .stickyTableCell {
      & > * {
        width: 90%;
        height: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .checkbox {
        width: auto;
        height: auto;
      }
      .leftCellDiv {
        padding: 0px 4px;
      }

      .leftCellDiv,
      .includeSortHeaderCaret {
        &.account {
          flex: 1;
        }
        &.campaign {
          flex: 3;
        }
        &.ad_group {
          flex: 3;
        }
        &.ad {
          flex: 3;
        }

        .cellText {
          cursor: default;
        }
        .digitsIcon {
          cursor: pointer;
        }
      }

      .selectContainer {
        & > * {
          width: 100%;
        }
      }
      .editableCell {
        border-radius: 6px;
        border: 1px solid black;
        padding: 0px 4px;
        cursor: pointer;
      }
      .oldValue {
        text-decoration: line-through;
      }
      .cellText {
        display: flex;
        word-break: break-all;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
      }
      &.leftCell {
        display: flex;
        flex-direction: row;
        align-items: center;
        &:not(.lastRow) {
          border-bottom: 1px solid $gridBorderColor;
        }
        .networkLogo {
          position: relative;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            max-height: 80%;
          }
        }

        .description {
          font-size: 11px;
        }
        .content {
          padding: 0 $dimension-padding;
          color: #666666;
          max-height: 100%;
        }
      }
    }
  }

  .checkBoxContainer {
    width: 28px !important;
    padding: 0px 4px;
  }

  .includeSortHeaderCaret {
    position: relative;
    padding-right: 14px !important;
    cursor: pointer;
    user-select: none;
    & > * {
      max-width: 100%;
      overflow: hidden;
    }
    &:before,
    &:after {
      border: 4px solid transparent;
      content: "";
      display: block;
      height: 0;
      right: 4px;
      top: 50%;
      position: absolute;
      width: 0;
    }
    &:before {
      border-bottom-color: #aaaaaa;
      margin-top: -9px;
    }
    &:after {
      border-top-color: #aaaaaa;
      margin-top: 1px;
    }
    &.up:before {
      border-bottom-color: black;
    }
    &.down:after {
      border-top-color: black;
    }
  }

  .noRows {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 28px;
    width: 100%;
    height: 100%;
  }

  .STTopHeader {
    height: 60px !important;
    .stickyTableCell {
      height: 60px !important;
    }
  }

  .STCornerInner.STnw {
    height: 60px !important;
    .includeSortHeaderCaret {
      display: flex;
      flex-direction: column;
    }
  }

  .resizeContainer {
    .resizeArea {
      position: absolute;
      width: fit-content;
      padding: 0 10px;
      cursor: ew-resize;
      z-index: 100;
      top: 141px;
      bottom: 83px;
    }
  }
}

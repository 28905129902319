.metaBuyingPage {
  display: flex;
  flex: 1;
  width: 100%;

  .metaBuyingCreate,
  .fullPageRouter {
    height: 100%;
    width: 100%;

    .metaBuyingTabs {
      padding: 10.5px 32px;
      gap: 16px;
    }
  }

  .metaBuyingContainer {
    max-height: 100%;
    display: flex;
  }

  .metaBuyingCard {
    position: relative;
    margin: 16px;
    border-radius: 5px;
    border: 2px solid #6b2def;
    background: #fff;
    width: 740px;
    border-radius: 16px;
    max-height: 100%;
    overflow: hidden;

    &.note {
      width: auto;
      padding: 24px;
      border: 1.5px solid #cbd2e1;
      width: 460px;
      font-size: 16px;
      font-weight: 500;
      line-height: 17.6px;

      h2 {
        font-size: 20px;
        font-weight: 600;
        line-height: 22px;
        margin-bottom: 18px;
      }

      ul {
        margin-top: 16px;
      }
    }

    .disabled {
      opacity: 0.65;
    }

    .selectedAsset {
      display: flex;
      align-items: center;

      button {
        margin: 0 8px;
      }
    }

    .metaBuyingHeader {
      height: 80px;
      display: flex;
      padding: 0 32px;
      align-items: center;
      font-family: "Graphik";
      font-size: 24px;
      font-weight: 400;
      line-height: 26.4px;
      border-bottom: 1px solid #cbd2e0;

      h2 {
        font-weight: 600;
        margin: 0 8px 0 0;
      }
    }

    .metaBuyingNameWrapper {
      display: flex;
      align-items: center;
      padding: 8px 8px 0;
      border-radius: 6px;
      background: #f1f3f9;
      width: 100%;
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
      color: #1f003f;
      font-family: "Graphik";
    }

    .metaBuyingNamePreview {
      font-size: 20px;
      line-height: 22px;
      margin-left: 6px;
      word-break: break-word;

      .placeholder {
        color: #94a0b8;
      }

      .constructed {
        color: #6b2def;
      }
    }

    .metaBuyingFormBody {
      padding: 32px;
      max-height: 100%;
      overflow: auto;
      padding-bottom: 160px;

      .form-control,
      .dropdown-toggle {
        font-size: 16px;
        line-height: 17.6px;
        height: auto;
        min-height: 40px;
        width: 100%;
        background: #fff;
        border: 1.5px solid #8254ff;
        color: #1f003f;
        display: flex;
        align-items: center;
        padding: 10px 12px;

        &::after {
          color: #8254ff;
          margin-left: auto;
        }

        .title {
          display: flex;
          flex-direction: column;
          gap: 10px;

          div {
            text-align: start;
          }
        }
      }

      .dropdown-item div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.selected {
          font-weight: 600;
          color: #8254ff;

          &:after {
            font-weight: 400;
            color: #1f003f;
            font-size: 24px;
            line-height: 10px;
            margin-left: 20px;
            content: "×";
          }
        }
      }
    }

    h3 {
      width: 420px;
      padding: 10px;
      margin: 0;
      border-radius: 6px;
      background: #f8f7ff;
      font-size: 24px;
      font-weight: 600;
      line-height: 26.4px;
      color: #6b2def;
    }

    .metaBuyingFormSection {
      width: 430px;
      margin: 18px 0 32px;
      font-size: 16px;
    }

    .metaBuyingFormSubsection {
      margin: 32px 0;

      h4 {
        font-size: 20px;
        font-weight: 600;
      }
    }

    .metaBuyingButtons {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      width: 100%;
      padding: 24px;
      border-top: 1px solid #94a0b8;
      background: #fff;

      button {
        margin-left: 16px;

        &:first-child {
          margin-left: auto;
        }
      }
    }

    .form-label {
      font-family: "Graphik";
      font-size: 16px;
      font-weight: 600;
      line-height: 17.6px;
      text-align: left;
    }

    .form-group {
      padding: 0 16px;
      margin: 22px 0;
    }

    .form-check {
      height: 32px;
      margin: 0 0 0.5rem 20px;
    }

    .form-check-label {
      font-family: "Graphik";
      font-size: 16px;
      font-weight: 500;
      line-height: 17.6px;
      text-align: left;
    }
  }

  .metaBuyingMenuWrapper {
    padding: 16px 24px 24px;
    margin-bottom: 16px;

    .dropdownMenuContainer {
      border: none !important;
      box-shadow: none;
      padding: 0;
      border-radius: 0;

      .dropdownMenuItem {
        margin-bottom: 10px;
      }
    }
  }

  .metaBuyingInputNote {
    font-size: 12px;
    margin-bottom: 8px;

    button {
      padding: 0;
    }
  }

  .metaBuyingInputSwitch {
    color: #0194ff;
    background: none;
    text-decoration: underline;
    border: none;
  }

  .metaBuyingSubmitInputWrapper {
    display: flex;
    align-items: center;
    position: relative;
    margin: 8px 0;

    .metaBuyingSubmitButton {
      position: absolute;
      right: 6px;
    }

    .metaBuyingSubmissions {
      position: absolute;
      left: 12px;
      top: 10px;
    }

    .metaBuyingSubmission {
      position: absolute;
      left: 12px;
    }
  }

  .metaBuyingSubLabel {
    font-weight: 400;
  }

  .dropdown-menu {
    max-height: calc(15rem + 18px);
    overflow: auto;
    background: #fff;
  }

  .metaBuyingPositions {
    padding-left: 100px;
  }

  .metaBuyingCollapsible {
    display: flex;
    align-items: center;
    height: 2rem;

    button {
      border-radius: 6px;
      height: 100%;
      color: #8254ff;
      padding: 0;
      background: transparent;
      border: none;

      &:hover {
        background: #e8e5ff;

        .arrowWrapper {
          border-right: 1px solid transparent;
        }
      }

      .arrowWrapper {
        display: flex;
        align-items: center;
        border-left: 1px solid transparent;
        border-right: 1px solid #cbd2e1;
        height: 16px;
        padding: 0 6px;
        padding: 0 18px;

        .arrow {
          width: 22px;
          height: 22px;

          &.up {
            transform: rotate(270deg);
          }

          &.down {
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  .retentionWrapper {
    display: flex;
    align-items: center;

    .SingleDatePicker {
      margin: 1rem;
    }
  }

  .metaBuyingScheduleWrapper {
    .metaBuyingDatePicker {
      display: flex;
      align-items: center;
      background: #fff;
      color: #212529;
      margin-bottom: 32px;
      height: 2.375rem;

      .SingleDatePicker {
        border: 1.5px solid #8254ff;
        border-radius: 6px;
        margin-right: 8px;
        font-weight: 500;
        color: #1f003f;
        height: 100%;

        > div,
        .SingleDatePickerInput,
        .DateInput {
          height: 100%;
          border-radius: 6px;
          overflow: hidden;
          display: flex;
          align-items: center;
        }

        input {
          font-weight: 500;
          color: #1f003f;
        }

        .DateInput_fang {
          display: none;
        }
      }

      .timeSelection {
        display: flex;
        align-items: center;
        border: 1.5px solid #8254ff;
        border-radius: 6px;
        font-weight: 500;
        height: 100%;
        width: 120px;
        justify-content: flex-end;

        .timeDropdown {
          height: 100%;
          display: flex;
          width: 100%;

          button {
            transition: none;
            border: none;
            color: #1f003f;
            background: transparent;
            padding: 0;
            min-height: auto;
            height: 100%;
            font-weight: 500;

            &::after {
              color: #1f003f;
            }
          }

          &.null button {
            opacity: 0.5;
          }

          &.hour button {
            width: 100%;
            justify-content: flex-end;
          }

          &.minute button {
            padding-right: 4px;
          }

          &.ampm button {
            width: 3.5rem;
            padding-right: 10px;
          }

          &:not(.ampm) {
            button:after {
              content: none;
            }
          }
        }
      }

      .timezone {
        padding: 0 12px;
        color: #1f003f;
        font-weight: 500;
      }
    }

    .form-check {
      margin: 0 0 0.5rem;
    }
  }

  .metaBuyingSearchMenu {
    position: relative;

    .dropdown-toggle {
      height: auto;

      .bf-label {
        height: auto;
        gap: 8px;
        max-width: calc(100% - 0.75rem);
        overflow: hidden;
      }

      .placeholder {
        opacity: 0.4;
      }
    }

    .searchMenu {
      top: calc(100% + 6px);
      right: 0;
      background: 0;
      max-width: 100%;
      position: absolute;
      padding: 12px;
      border: 2px solid var(--brand-brand-50, #8254ff);
      border-radius: 6px;
      background: #fff;
      z-index: 1;

      .searchBar {
        color: #94a0b8;
        height: 2rem;
        background: #f1f3f9;
        margin-bottom: 8px;
      }

      .searchMenuRows {
        max-height: 450px;
        overflow: auto;
      }

      .searchMenuRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 100%;
        margin-bottom: 5px;

        .searchMenuOption {
          word-break: break-word;
        }

        span {
          color: #94a0b8;
          padding-left: 1rem;
          text-align: right;
        }
      }

      .searchMenuControls {
        margin-top: 18px;
        display: flex;
        justify-content: flex-end;
        gap: 16px;
      }
    }
  }

  .appliedSelection {
    background: #f1f3f9;
    padding: 2px 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    line-height: 1.125rem;
    cursor: pointer;

    span {
      max-width: calc(100% - 1.5rem);
      overflow: hidden;
      color: #1f003f;
    }

    &:hover {
      background: #8254ff;

      span {
        color: #fff;
      }

      &::after {
        color: #fff;
      }
    }

    &:not(:last-child) {
      margin-bottom: 6px;
    }

    &::after {
      content: "×";
      color: #94a0b8;
      font-size: 2rem;
      line-height: 0.8rem;
      font-weight: 100;
      padding: 0.1rem 0 0 0.5rem;
    }
  }
}

.reviewModalAlert {
  .modal-body {
    line-height: 1.1rem;

    > div:first-child {
      height: 72px;
    }

    > div:nth-child(2) {
      width: calc(100% - 72px - 1.5rem);

      .body {
        b {
          font-weight: 500;
        }
        .levelNames {
          font-weight: 400;

          .levelRow {
            display: flex;
            margin-bottom: 8px;

            .level {
              width: 7rem;
            }

            .name {
              width: calc(100% - 7rem);
              word-wrap: break-word;
            }
          }
        }
      }
    }
  }
}

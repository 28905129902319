@import "../utils/colors.scss";

.BPMCheckBox {
  cursor: pointer;
  color: white;
  background-color: white;
  transition: all 0.12s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #c5d0de;

  $size: 32px;
  height: $size;
  width: $size;
  min-height: $size;
  min-width: $size;

  border-radius: 6px;
  font-size: 18px;

  &:hover:not(.disabled) {
    border-color: $primary;
  }
  &.sm {
    $size: 20px;
    height: $size;
    width: $size;
    min-height: $size;
    min-width: $size;
    border-radius: 3px;
  }
  &.checked {
    border-color: $primary;
    background-color: $primary;
    &:hover {
      box-shadow: 0 0 0 3px $primary-light;
    }
  }
  &.disabled {
    cursor: not-allowed;
    &.checked {
      background-color: #dddddd;
      color: #777777;
      border-color: #bbbbbb;
    }
  }
}
#secondaryDesign {
  align-self: center;
  $size: 16px;
  height: $size;
  width: $size;
  min-height: $size;
  min-width: $size;
  background-color: transparent;
  border-color: $White;
  &.checked {
    background-color: $White;
    color: $Brand80;
    border: none;
  }
}

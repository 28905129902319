@import "../../../utils/colors.scss";
@import "../../../utils/sassHelpers.scss";

.MetaBuyingCreativeAssetModal {
  ::-webkit-scrollbar {
    height: $scrollbar-width-redesign;
    width: $scrollbar-width-redesign;
  }
  .creativeAssetModalBody {
    .filterOptions {
      display: flex;
      justify-content: space-between;
      padding-bottom: 8px;
    }
    .thumbnailContainer {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      height: 450px;
      overflow-y: scroll;
      .spacer {
        flex-grow: 1;
        width: 270px;
        height: 2px;
      }
      .placeholder,
      .metaCreativeThumbnail {
        height: 358px;
        width: 270px;
        border-radius: 8px;
        border: solid transparent 2px;
        box-sizing: border-box;
        margin: 6px 0;
        font-size: 12px;
        display: flex;
        flex-direction: column;
        &.selected {
          border: solid $Brand50 2px;
        }
        .metaAssetContainer {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          padding: 10px 16px;
          .createiveAssetContainer {
            height: 240px;
            width: 240px;
            display: flex;
            justify-content: center;
            align-items: center;
            .assetLoadingPlaceholder {
              background-color: $Grey5;
              color: $GreyHeat20;
              padding: 10%;
              height: 80%;
              width: 100%;
            }
            .creativeImageItem {
              max-height: 100%;
              max-width: 100%;
            }
          }
        }
        .assetInfo {
          width: 100%;
          height: 92px;
          max-height: 100%;
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          .assetName {
            max-width: 100%;
            height: 100%;
            max-height: 73px;
            font-size: 16px;
            font-weight: 500;
            color: $Brand100;
            overflow-wrap: break-word;
            overflow: hidden;
            line-break: anywhere;
          }
          .createdAt {
            font-size: 12px;
            color: $Brand100;
          }
        }
        &.isVideo {
          .assetName {
            max-height: 50px;
          }
        }
      }
      .metaCreativeThumbnail:not(.selected):hover {
        border: solid $Grey60 2px;
      }
      .loadMoreButton {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 15px;
      }
    }
  }
}

.cro-onboard-container {
  width: 60%;
  margin: 20px;

  .cro-onboard-text-wrapper {
    .blue-text {
      color: rgb(42, 121, 255);
    }
  }

  .all-btn-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .toggle-wrapper {
      .BPMToggleSwitch {
        border: none;
        background-color: transparent;

        .custom-control-label {
          cursor: pointer; 
        }
      }
    }

    .button-wrapper {
      display: flex;
      gap: 12px;
    }
  }
}

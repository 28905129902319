@import "../utils/colors.scss";

.filtersPanelGoogleBrandHealth {
  display: flex;
  flex-direction: column;
  .filters {
    display: flex;
    flex-direction: column;
    height: 100%;
    row-gap: 24px;
    &.reduce-spacing-1 {
      row-gap: 20px;
    }
    &.reduce-spacing-2 {
      row-gap: 16px;
    }
    &.reduce-spacing-3 {
      row-gap: 12px;
    }
    .filterSection {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      &.reduce-spacing-1 {
        row-gap: 8px;
      }
      &.reduce-spacing-2 {
        row-gap: 4px;
      }
      &.reduce-spacing-3 {
        row-gap: 1px;
      }
      .filterName {
        height: 24px;
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 8px;
        color: $Brand100;
      }
      .filterOptions {
        display: flex;
        flex-direction: column;
        padding-left: 14px;
        row-gap: 14px;
        font-weight: 500;
        font-size: 16px;
        color: $Brand100;
        &.reduce-spacing-1 {
          row-gap: 10px;
        }
        &.reduce-spacing-2 {
          row-gap: 6px;
        }
        &.reduce-spacing-3 {
          row-gap: 2px;
        }
        &.wrap {
          flex-direction: row;
          flex-wrap: wrap;
          column-gap: 12px;
        }
      }
    }
  }
  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 16px;
    padding: 24px 32px 24px 50px;
  }
}

@import "../utils/sassHelpers.scss";

.nonLiveReportPage {
  padding: $page-padding;
  flex: 1;
  overflow: auto;
  font-size: 14px;
  .notLive {
    background-color: red !important;
    font-weight: 500;
    color: white;
  }
  .notLiveMinimal {
    background-color: #ffff43;
  }
}

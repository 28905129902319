.retail-ops-channel-page {
  .chartContainer {
    height: 200px;
  }
  .retail-ops-form {
    .account-id,
    .channel-name,
    .integration-name {
      height: 38px;
    }
    .retail-ops-type-select {
      width: 560px;
    }

    .cl-dropdown-toggle.dropdown-toggle.btn.btn-primary.lg {
      height: 38px;
    }
  }
}

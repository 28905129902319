@import "../utils/sassHelpers.scss";

.addNewClientBody {
  .form {
    margin-top: 1rem;
  }
  .mediaTypes {
    display: flex;
    gap: 16px;
    .form-check {
      display: flex;
      gap: 10px;
      margin: 0px !important;
      width: 100%;
      padding-left: 20px;
      .form-check-label {
        margin: 0px;
      }
    }
  }

  .row2 {
    display: flex;
    justify-content: space-around;
    .companyAbbreviation {
      flex: 4 2px;
    }
    .agency {
      align-items: flex-start;
      display: flex;
      justify-content: flex-start;
      flex: 1 2px;
      flex-direction: column;
      width: min-content;
      margin-left: 15px;
      max-width: fit-content;
      margin-right: 20px;
      .agencyDropdown {
        width: fit-content;
      }
    }
    .IABCategory {
      flex: 1 2px;
      margin-left: 10px;
      margin-right: 15px;
      max-width: fit-content;
    }
  }

  .form-group {
    margin-bottom: 1.25rem;
  }

  .campaignDates {
    margin-top: 0.75rem;
    font-size: medium;
  }

  .campaignTitle {
    margin-bottom: 0rem;
  }

  .form-label {
    font-size: larger;
    font-weight: 400;
  }

  .dropdown-menu {
    width: min-content;
    word-wrap: none;
    max-width: min-content;
    max-height: 300px;
    overflow-y: scroll;
  }
}

.addNewClientActions {
  width: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .select {
    flex: 1;
  }
  .refresh {
    margin-left: 0.5rem;
  }
}

@import "../utils/colors.scss";

.ccPageActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  & > :not(:last-child) {
    margin-right: 12px;
  }
  .ccKpiPicker.dropdownMenuContainer {
    max-height: 350px;
    .dropdownMenuItemsContainer {
      overflow-y: scroll;
    }
  }
}
.ccPageSubHeader {
  .fetchFilterBar {
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
      * {
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }
}
.ccPage {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  padding: 6px;

  .chartContainerPlaceholder {
    outline: 1.5px solid $Grey60;
    border-radius: 16px;
    margin: 6px;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    background-color: white;
  }

  .ccRow {
    display: flex;
    flex: 1;
    flex-direction: row;
  }

  .ccOverview {
    .ccTopSection {
      display: flex;
      flex-direction: row;
      .ccTopSectionLeftSide {
        display: flex;
        flex: 2;
        flex-direction: column;
      }
      .ccTopSectionRightSide {
        display: flex;
        flex: 1;
        height: 100%;
      }
    }

    .ccDailyKpiVolume {
      display: flex;
      flex: 1;
      min-height: 400px;
    }
    .ccMarketingInputs {
      display: flex;
      flex: 1;
      min-height: 400px;
      .header .leftSide .headerTitle {
        align-items: center;
      }
    }
    .ccKpiFunnel {
      display: flex;
      flex: 1;
      margin-bottom: 10px;
      .chartContainer {
        height: 100%;
      }
    }

    .ccSparkCharts {
      display: flex;
      flex-direction: row;
      .sparkChartContainer {
        height: 350px;
      }
    }
    .ccMetricComparisonChart {
      display: flex;
      flex: 2;
      min-height: 400px;
    }
    .ccAgencyNews {
      height: 500px;
      width: 500px;
      margin: 8px;
      .ccAgencyNewsImage {
        border-radius: 16px;
        border: 1.5px solid $Neutral400;
      }
    }
  }

  .ccDeliveryAndPerformance {
    display: flex;
    flex: 1;
    flex-direction: column;
    .ccDeliverySnapshot {
      display: flex;
      flex: 1;
    }
    .ccDeliveryBreakdown {
      display: flex;
      flex: 2;
    }

    .ccPerformanceSnapshot {
      display: flex;
      flex: 1;
    }
    .ccPerformanceBreakdown {
      display: flex;
      flex: 2;
    }
  }

  .ccMetricsTable {
    display: flex;
    flex: 1;
  }
}

.ccPage:has(.metricsTableWithControlsContainer) {
  padding: 6px 0px 0px 0px;
  .metricsTable {
    background-color: $Neutral200;
  }
  .stickyTableOuter {
    border-bottom: 2px solid $Neutral400;
  }
}
div[id="id:Source for CPX, ROAS, KPI Vol., Rev."],
div[id="id:Source for CPX, ROAS, KPI Vol., Rev. (and all other metrics)"] {
  .arrow::before,
  .tooltip-inner {
    border-bottom-color: $Neutral700;
  }
  .tooltip-inner {
    background-color: $Neutral700;
  }
}

@import "../../utils/colors.scss";

.kpiVolumeContainer {
  display: flex;
  flex: 1;
  .leftSide {
    .headerTitle {
      display: flex;
      flex: 1;
      flex-direction: row;
    }
  }

  .chartContainer .contents {
    padding-left: 10px;
    padding-bottom: 6px;
    padding-right: 16px;
  }

  .kpiVolumeGraphContents {
    display: flex;
    flex: 1;
    flex-direction: row;
    .kpiVolumeGraph {
      display: flex;
      flex: 1;
      flex-direction: row;
    }
    .rightOfChart {
      min-width: 100px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 16px;
      margin-bottom: 10px;
      .kpiVolumeLegend {
        justify-content: start;
        .kpiLegendHeader {
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 8px;
        }
        .legendItem {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 14px;
          margin-bottom: 0.5rem;
          cursor: pointer;
          .square {
            width: 14px;
            height: 14px;
            margin-right: 8px;
          }
          .label {
            &.unselected {
              color: $Neutral500;
            }
          }
        }
      }
      .showInputsToggle {
        cursor: pointer;
        .text {
          font-size: 14px;
          font-weight: 500;
          color: $Neutral1000;
        }
        .dropdownArrow {
          margin-left: 4px;
          &.up {
            transform: rotate(-90deg); // Rotate the arrow up
          }
          &.down {
            transform: rotate(90deg); // Rotate the arrow down
          }
        }
      }
    }
  }
}

@import "../../utils/colors.scss";

.BPMButton {
  transition: all 0.15s ease-in-out;
  &.hasIcon {
    position: relative;
    .icon {
      position: absolute;
      left: 6px;
      top: calc(50% - 2px);
      transform: translateY(-50%);
      font-size: 28px;
      svg {
        width: 24px;
      }
    }
    .label {
      padding-left: 24px;
    }
  }
  &.xs {
    font-size: 0.75rem;
    padding: 0.05rem 0.35rem;
  }
  &.btn-sm > .icon {
    font-size: 22px;
    svg {
      width: 20px;
    }
  }
  &.btn {
    &.btn-primary,
    &.btn-outline-primary,
    &.btn-secondary,
    &.btn-outline-secondary,
    &.btn-outline-danger {
      font-weight: 500;
      border-color: #e6eaee !important;
    }
    &.btn-primary,
    &.btn-primary:hover {
      border-color: $primary !important;
      background-color: $primary !important;
      .icon {
        color: white;
        stroke: white;
      }
    }
    &.btn-outline-primary,
    &.btn-outline-secondary,
    &.btn-outline-danger {
      background-color: white;
      color: #4e4e50;
      &.bordered {
        border: 1px solid rgba(0, 0, 0, 0.4) !important;
      }
      &:hover {
        color: white;
      }
      &.hasIcon {
        .icon {
          color: $primary;
          stroke: $primary;
        }
        &:hover > .icon {
          color: white;
          stroke: white;
        }
      }
    }

    &.btn-outline-primary:hover {
      background-color: $primary;
    }
    &.btn-outline-secondary:hover {
      background-color: $secondary;
    }
    &.btn-outline-danger {
      &.bordered {
        border-color: $danger !important;
      }
      &:hover {
        background-color: $danger;
      }
    }
  }
}

@import "../utils/sassHelpers.scss";

.extremeReachReport {
  flex: 1;
  padding: $page-padding;
  display: flex;
  flex-direction: column;
  min-height: 0;

  .reportHeaderRow {
    display: flex;
    .groupBy {
      margin-left: 20px;
      font-size: 20px;
    }
  }
  .reportBody {
    flex: 1;
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    margin-top: 15px;
    min-height: 0;
    .xrBPMHeaders {
      text-align: center;
      border-bottom: 1px solid black;
      padding-right: 10px;
      & > * {
        margin-bottom: 0;
      }
    }

    .reportContent {
      flex: 1;
      overflow-y: auto;
      padding: 15px;
      min-height: 0;

      .placementName {
        text-decoration: underline;
        .networkLogo {
          margin-right: 15px;
          height: 50px;
        }
        .logoNoImgBox {
          display: inline-block;
          height: 50px;
          width: 50px;
          font-size: 12px;
          border: 1px solid black;
          text-align: center;
          line-height: 50px;
          margin-right: 10px;
          & > span {
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }

  .compareBox {
    display: flex;
    flex-direction: row;
    & > * {
      flex: 1;
      padding: 0 15px;
      &:first-child {
        border-right: 1px solid black;
      }
    }
  }
  .vendorNames {
    font-size: 20px;
  }
  .placementList {
    font-size: 16px;
  }
  .rotationHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    .isciHeader {
      flex: 1;
      display: flex;
      flex-flow: row wrap;
      & > * {
        display: flex;
        flex-direction: row;
        margin: 0 10px;
        align-items: center;
        height: 40px;
        & > *:not(:last-child) {
          margin-right: 10px;
        }
        .swatch {
          display: inline-block;
          height: 20px;
          width: 20px;
          border: 1px solid black;
        }
      }
    }
  }
}

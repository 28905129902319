$delivery: #01b8aa;
$deliverySecured: #3201b8;
$deliveryUpfront: #b8010f;

.linearPacingLegendGroup {
  display: flex;
  align-items: center;
}

.deliverySecured {
  background-color: $deliverySecured;
}
.deliveryUnsecured {
  background-color: $delivery;
}
.deliveryUpfront {
  background-color: $deliveryUpfront;
}

.linearPacingDashboard {
  .controls {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    height: 70px;
    padding: 0 10px;
    border-bottom: 1px solid rgb(242, 244, 245);
    & > *:not(:last-child) {
      margin-right: 15px;
    }
  }

  .leftPane {
    overflow-y: scroll;
  }

  .pane-title {
    font-size: 20px;
    padding: 15px;
  }

  .avail-title {
    font-size: 24px;
    margin-left: 4px;
  }

  .grandTotalCell {
    font-weight: 500;
  }
}

.pacingSpendOverride {
  background-color: #d4bdf2;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.pacingEditControls {
  position: fixed;
  top: 5px;
  right: 1rem;
  padding: 0.25rem 0.5rem;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2);
  & > *:not(:last-child) {
    margin-right: 0.2rem;
  }
  z-index: 1000;
}

@import "../../utils/colors.scss";

.cl-download-toggle {
  &.dropdown-toggle {
    &.btn {
      &.btn-primary {
        &::after {
          display: none;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        border: none;
        background-color: #cbd2e1;
        color: $Brand100;
        padding: 0px 6px;

        &:focus {
          box-shadow: none !important;
        }
        &.filled {
          &.primary {
            &.light {
              background-color: $Brand50;
              color: $White;
              &.hover:not(.disabled),
              &:hover:not(.disabled) {
                background-color: $Brand70;
              }
            }
            &.dark {
              background-color: $White;
              color: $Brand60;
              &.hover:not(.disabled),
              &:hover:not(.disabled) {
                background-color: $Brand20;
                color: $Brand70;
              }
            }
          }
          &.secondary {
            &.light {
              background-color: $Neutral200;
              color: $Brand100;
              &.disabled {
                color: $Brand100;
              }
              &.hover:not(.disabled),
              &:hover:not(.disabled) {
                background-color: $Neutral400;
                color: $Brand100;
              }
            }
            &.dark {
              background-color: $Brand60;
              color: $White;
              &.hover:not(.disabled),
              &:hover:not(.disabled) {
                background-color: $Brand50;
              }
            }
          }
        }
        &.outlined {
          &.primary {
            &.light {
              border: 1px solid $Brand50;
              background-color: $White;
              color: $Brand100;
              &.disabled {
                color: $Brand100;
              }
              &.hover:not(.disabled),
              &:hover:not(.disabled) {
                background-color: $Brand20;
                border-color: $Brand70;
                color: $Brand100;
              }
            }
            &.dark {
              border: 1px solid $White;
              background-color: $Brand80;
              color: $White;
              &.hover:not(.disabled),
              &:hover:not(.disabled) {
                background-color: $Brand70;
              }
            }
          }
          &.secondary {
            &.light {
              border: 1px solid $Neutral400;
              background-color: $White;
              color: $Brand100;
              &.disabled {
                color: $Brand100;
              }
              &.hover:not(.disabled),
              &:hover:not(.disabled) {
                background-color: $Neutral200;
                border-color: $Neutral600;
                color: $Brand100;
              }
            }
            &.dark {
              border: 1px solid $Brand50;
              background-color: $Brand80;
              color: $White;
              &.hover:not(.disabled),
              &:hover:not(.disabled) {
                background-color: $Brand70;
              }
            }
          }
        }
        &.empty {
          &.primary {
            &.light {
              background-color: transparent;
              color: $Brand60;
              &.disabled {
                color: $Brand60;
              }
              &.hover:not(.disabled),
              &:hover:not(.disabled) {
                background-color: $Brand20;
                color: $Brand70;
              }
            }
            &.dark {
              background-color: transparent;
              color: $White;
              &.hover:not(.disabled),
              &:hover:not(.disabled) {
                background-color: $Brand70;
              }
            }
          }
          &.secondary {
            &.light {
              background-color: transparent;
              color: $Brand100;
              &.disabled {
                color: $Brand100;
              }
              &.hover:not(.disabled),
              &:hover:not(.disabled) {
                background-color: $Neutral200;
                color: $Brand100;
              }
            }
            &.dark {
              background-color: transparent;
              color: $Brand30;
              &.disabled {
                color: $Brand30;
              }
              &.hover:not(.disabled),
              &:hover:not(.disabled) {
                background-color: $Brand70;
                color: $White;
              }
            }
          }
        }

        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
        .download-icon {
          display: flex;
          width: 22px;
          height: 22px;
          font-size: 22px;
        }
        &.sm {
          height: 28px;
        }
        &.lg {
          height: 32px;
        }
      }
    }
  }
}

.offlineInputsContainer {
  .stickyTable::-webkit-scrollbar-thumb {
    height: 25% !important;
  }

  .stickyTable::-webkit-scrollbar-track {
    margin-top: 49px;
  }

  .superHeader {
    background: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .headerControls {
      width: 100%;
      min-height: 32px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-bottom: 0.75rem;

      & > button {
        padding: 0;
        width: 32px;
      }

      & > *:not(:first-child) {
        margin-left: 0.5rem;
      }
    }

    .headerLabels {
      width: 100%;
      padding-top: 2px;
      background: var(--neutral-200, #f1f3f9);
      border-radius: 6px;
      text-align: center;
      font-size: 14px;
      line-height: 15.4px;

      & > * {
        padding: 4px;
        height: 23.4px;
      }

      strong {
        font-size: 16px;
        font-weight: 500;
        line-height: 17.6px;
        height: 25.6px;
      }
    }
  }
}

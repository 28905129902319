.streamingNetworkProperties {
  .addNewAttributeContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
    .buttons {
      display: flex;
      flex-direction: row;
      .newAttributeButton {
        display: flex;
        align-items: center;
        margin-right: 0.5rem;
      }
    }
  }
  .attributesList {
    display: flex;
    flex: 1;
    align-items: flex-start;
    flex-flow: row wrap;
    & > * {
      border: 1px solid black !important;
      height: 200px;
      width: 300px;
    }
    .attributeBox {
      margin-right: 1rem;
      margin-bottom: 1rem;
      padding: 1rem;
      overflow: auto;
      background-color: white;
      .attributeName {
        font-weight: 500;
      }
      .attributeOptions {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .option {
          border: black 1px solid;
          margin-right: 1rem;
          margin-top: 0.5rem;
          padding: 0.1rem 0.75rem;
          border-radius: 5px;
        }
        .form-control {
          margin-top: 0.5rem;
        }
        .btn {
          margin-top: 0.5rem;
        }
      }
    }
  }
  .floatingControls {
    position: fixed;
    top: 65px;
    right: 1rem;
    padding: 0.25rem 0.5rem;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2);
    & > *:not(:last-child) {
      margin-right: 0.2rem;
    }
  }
}

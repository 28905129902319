$scrollbar-width: 10px;
$page-padding: 1.2rem;
$redesign-side-padding-addon: 0.6rem;
$redesign-side-padding: $page-padding + $redesign-side-padding-addon;
$redesign-background-color: #f1f2f6;
$scrollbar-width-redesign: 6px;

@mixin fullBox() {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

$font-weight-lighter: 400;
$font-weight-normal: 500;
$font-weight-bold: 600;

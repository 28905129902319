@import "../utils/colors.scss";

$percentage: 0%;

.percentAOIPlaceholder {
  border: 1px solid $Grey40;
  border-radius: 2px;
  height: 12px;
  margin-bottom: 4px;
  padding: 2px;

  .greenPercentBar {
    height: 6px;
    border-radius: 1px;
    background-image: -webkit-linear-gradient(
      30deg,
      $GreenHeat100 var(--percentage),
      $GreenHeat60 var(--percentage)
    );
  }
}

@use "sass:math";
@import "../utils/colors.scss";
@import "../utils/sassHelpers.scss";

.incPlaybookNavBar {
  background: var(--brand-brand-70, #5200ce);
  display: flex;
  flex-direction: column;
  gap: 0px;
  height: 100%;
  min-height: 0px;
  min-width: 260px;
  padding: 16px 0px;
  width: 260px;
  .searchBar {
    padding: 0px 16px;
    min-height: 0px;
    min-width: 0px;
    width: 100%;
    .searchIconContainer {
      svg {
        color: #ffffff !important;
      }
    }
    .filterBarComponent {
      width: 100% !important;
      .filterBarSelect__control {
        background: var(--brand-brand-80, #390094);
        border-radius: 6px !important;
      }
      .filterBarSelect__placeholder {
        color: rgba(255, 255, 255, 0.5) !important;
      }
      .filterBarSelect__input {
        color: var(--White, #fff) !important;
      }
    }
  }
  .dividerLineContainer {
    align-items: center;
    display: flex;
    height: 16px;
    justify-content: center;
    padding-left: 16px;
    padding-right: 16px;
    .dividerLine {
      background: var(--White, #fff);
      height: 1px;
      opacity: 0.5;
      width: 100%;
    }
  }
  .sectionTitle {
    align-items: center;
    color: var(--White, #fff);
    display: flex;
    font-family: Graphik;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    justify-content: flex-start;
    line-height: normal;
    padding: 8px 24px;
  }
  .item,
  .subItem {
    align-items: center;
    display: flex;
    gap: 6px;
    height: 40px;
    justify-content: flex-start;
    padding-left: 28px;
    padding-right: 28px;
    width: 100%;
    .icon,
    .label {
      align-items: center;
      color: var(--White, #fff);
      display: flex;
      font-family: Graphik;
      font-feature-settings: "clig" off, "liga" off;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      height: 100%;
      justify-content: flex-start;
      line-height: normal;
      img,
      svg {
        height: 22px;
        width: 22px;
      }
      img {
        // Will convert any png to white.
        filter: brightness(0) invert(1);
      }
      svg {
        color: $Brand0;
      }
    }
    &:hover,
    &.selected {
      background: $Brand60;
      cursor: pointer;
    }
    &:active,
    &.active {
      background: $Neutral200;
      .icon,
      .label {
        color: $Brand100;
        img {
          // This filter will convert any png first to black then to $Brand100
          filter: brightness(0) invert(5%) sepia(64%) saturate(7321%) hue-rotate(272deg)
            brightness(67%) contrast(108%);
        }
        svg {
          color: $Brand100 !important;
        }
      }
    }
  }
  .subItem {
    padding-left: 62px;
    height: 30px;
    .label {
      font-family: Graphik;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

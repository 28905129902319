@import "../utils/colors.scss";

.mtEditModal {
  .mtModalBody {
    .mtAlertContainer {
      width: 100%;
      display: flex;
      justify-content: center;
      .invalidText {
        width: fit-content;
        block-size: fit-content;
      }
    }
    .mtModalHeaderLabel {
      display: flex;
      align-items: center;
    }
    .defaultGroup {
      display: flex;
      padding: 1rem;
      margin-bottom: 0rem;
      &:is(:first-child) {
        padding-top: 0rem;
      }
      &:is(:last-child) {
        padding-bottom: 0rem;
      }
    }
    .devicesGroup1 {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      margin-bottom: 0px;
      border: 2px solid black;
      padding: 1rem;
      border-radius: 4px;
      margin-bottom: 1rem;
      .devicesHeader {
        font-weight: 500;
        font-size: large;
      }
    }
    .geoGroup {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      margin-bottom: 0px;
      border: 2px solid black;
      padding: 1rem;
      border-radius: 4px;
      margin-bottom: 0rem;
      .geoHeader {
        font-weight: 500;
        font-size: large;
      }
    }
    .behavioralGroup {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      margin-bottom: 0px;
      border: 2px solid black;
      padding: 1rem;
      border-radius: 4px;
      margin-bottom: 0rem;
      .behavioralHeader {
        font-weight: 500;
        font-size: large;
      }
    }
  }
}

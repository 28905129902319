.segmentationIdCreation {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 1rem;

  & > * + * {
    margin-top: 1rem;
  }

  .nav {
    height: 50px;
    background: #fff;
    margin: -1rem -1rem 0;
    padding: 0.5rem 1rem;
    border-bottom: 1.5px solid var(--neutral-400, #cbd2e1);
    align-items: center;

    .nav-item {
      padding: 0 12px;

      .nav-link {
        font-weight: 600;
        padding: 0.5rem 0;
      }

      .nav-link {
        padding: 0.5rem 0.25rem;
        line-height: 17.6px;

        &.active {
          color: var(--brand-60, #6b2def);
          padding-bottom: 5px;
          border-bottom: 3px solid var(--brand-60, #6b2def);
        }

        &:not(.active) {
          color: var(--brand-brand-30, #b5a6ff);
          &.disabled {
            color: var(--neutral-300, #e1e6ef);
          }
        }
      }
    }
  }

  .segmentationIdCreationBody {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1.5px solid var(--neutral-400, #cbd2e1);
    border-radius: 1rem;
    padding: 1rem;
    width: 100%;
    height: 100%;

    & > * + * {
      margin-top: 1rem;
    }

    .bf-label {
      display: flex;
      align-items: center;

      svg {
        margin-right: 0.5rem;
      }
    }

    .controls {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }

    .createNewIdView {
      display: flex;
      flex: 1;
      flex-direction: column;
      & > * + * {
        margin-top: 1rem;
      }

      .newSegmentationIDsTable {
        height: 100%;
      }

      .explainerRow {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .explainer {
          color: var(--brand-brand-30, #b5a6ff);
          font-weight: 600;
        }

        .newIdButtons {
          display: flex;

          & > * + * {
            margin-left: 1rem;
          }
        }
      }
    }

    .selectContainer {
      padding: 3px;
      width: 100%;
    }

    .editableCell {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}

@import "../utils/colors.scss";
.Datepicker_custom_month {
  display: flex;
  justify-content: center;
  margin: 3px 40px;
  select {
    border: none;
    background-color: transparent;
    font-size: 17px;
    font-weight: 500;
    text-align-last: center;
    color: #484848;
  }
}
.DateInput {
  &:not(.DateInput__small) {
    width: 140px !important;
  }
  .DateInput_input {
    text-align: center;
  }
}
.DateInput__small {
  width: 105px !important;
}
.DateInput_fang {
  border-bottom: 1px solid white;
}
.DateRangePicker {
  white-space: nowrap;
}
// Makes their dumb calendar icon aligned properly
.SingleDatePickerInput_calendarIcon {
  position: relative;
  .SingleDatePickerInput_calendarIcon_svg {
    position: absolute;
    top: 2px;
    left: 2px;
  }
}
.SingleDatePickerInput,
.DateRangePickerInput {
  .DateInput {
    width: 102px;
    .DateInput_input {
      padding: 10px 6px 5px;
      text-align: center;
      cursor: pointer;
    }
    .DateInput_fang {
      top: 41px !important;
    }
  }
}
.DateRangePicker_picker,
.SingleDatePicker_picker {
  top: 52px !important;
  z-index: 100 !important;
}

.BPMFileInput {
  align-items: center;
  border: 0;
  flex-direction: row;
  height: max-content;
  margin: 0;
  padding: 0;
  width: max-content;
  .BPMFileInputInnerInputElement {
    display: none;
    height: 0;
    width: 0;
  }
  .BPMFileInputInnerButton {
    .BPMFileInputInnerLabel {
      border: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 0;
    }
  }
}

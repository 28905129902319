.streamingInvoicing {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 1rem;
  margin-left: 1rem;
  & > * {
    margin-bottom: 0;
    &:not(:first-child) {
      margin-left: 1rem;
    }
  }
}

.generateButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  .generatingText {
    margin-right: 5px;
  }
}

@import "../utils/colors.scss";

.youTubePlanToplineNumber {
  background-color: white;
  border: 1.5px solid $Neutral400;
  border-radius: 16px;
  width: 100%;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .youTubePlanToplineNumberLabel {
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
  }

  .youTubePlanToplineNumberValue {
    font-size: 24px;
    font-weight: 600;
    line-height: 26.4px;
    color: $Brand60;
  }
}

@import "../../utils/colors.scss";

.horizontalLegend {
  display: flex;
  pointer-events: none;
  align-items: center;
  height: 28px;
  &.smallWidget {
    width: 100%;
    align-self: flex-start;
  }
  &.inModal::before {
    content: "Legend:";
    color: $Brand100;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    font-size: 14px;
    padding: 0 calc(8px + 0.275em) 0 8px;
  }
  &.inModal {
    border-radius: 6px;
    border: 1px solid #cbd2e1;
    margin-right: 8px;
  }

  .first {
    padding-left: 0 !important;
  }

  .emptySquare {
    height: 16px;
    width: 16px;
    border: solid $Grey80 2px;
    border-radius: 2px;
  }
  .shaded {
    height: 16px;
    width: 16px;
  }
}

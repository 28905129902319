@import "../../utils/colors.scss";
@import "../../styles/Fonts.scss";

.metaBuyingDrafts {
  display: flex;
  flex: 1;
  .draftsTable {
    border-radius: 16px;
    .BPMFilterBarContainer {
      margin: 0px;
    }
    .BPMTableContainer {
      padding: 0px;
      border-radius: 16px;
      .stickyTable {
        .stickyHeader {
          height: 42px !important;
          background-color: $Neutral200;
          .stickyTableCell {
            display: flex;
            background-color: $Neutral200;
            border-bottom: none;
            @include bodyRegular;
            padding: 10px;
            height: 100% !important;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 12px;
            .metaBuyingCheckbox {
              $size: 18px;
              min-height: $size;
              min-width: $size;
              height: $size;
              width: $size;
              border-color: $Brand60;
            }
          }
        }
        .STInnerGrid {
          .stickyTableCell {
            height: 38px !important;
            margin-top: 16px;
            padding-bottom: 3px;
            .adNameCell {
              @include secondaryThin;
              color: $Neutral600;
              padding: 10px 10px 7px 10px;
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
              &.highlighted {
                @include bodyRegular;
              }
            }
            .adSetNameCell,
            .campaignNameCell {
              @include secondaryThin;
              color: $Neutral600;
              padding: 10px 10px 7px 10px;
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
              &.highlighted {
                @include bodyRegular;
              }
            }
            .createdCell,
            .creatorCell {
              @include secondaryThin;
              color: $Brand100;
              padding: 10px 10px 7px 10px;
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
            }
            .checkboxCell {
              $size: 18px;
              min-height: $size;
              min-width: $size;
              height: $size;
              width: $size;
              border-color: $Brand60;
            }
            .actionsCell {
              display: flex;
              flex-direction: row;
              column-gap: 16px;
              height: 100%;
              width: 100%;
              padding: 5px 16px 2px 8px;
              .viewAdPreviewButton {
                color: $Brand60;
              }
              .approvalPlaceholder {
                @include secondaryThin;
                color: $Neutral500;
                display: flex;
                align-items: center;
              }
              .approvalSection {
                display: flex;
                flex-direction: row;
                column-gap: 16px;
                .approvalLabel {
                  @include secondaryRegular;
                  display: flex;
                  align-items: center;
                  padding: 0px 8px;
                }
                .approveButton {
                  border-color: $Success800;
                  .bf-icon {
                    color: $Success800;
                  }
                  &:hover {
                    background-color: $Success800;
                    .bf-icon {
                      color: $White;
                    }
                  }
                }
                .rejectButton {
                  border-color: $Danger700;
                  .bf-icon {
                    color: $Danger700;
                  }
                  &:hover {
                    background-color: $Danger700;
                    .bf-icon {
                      color: $White;
                    }
                  }
                }
                .approvedButton {
                  border-radius: 52px;
                  border-color: $Success900;
                  .bf-label {
                    color: $Success900;
                  }
                  .bf-icon {
                    color: $Neutral500;
                  }
                  &:hover {
                    background-color: $Success900;
                    .bf-label,
                    .bf-icon {
                      color: $White;
                    }
                  }
                }
                .unapprovedButton {
                  border-radius: 52px;
                  border-color: $Danger800;
                  .bf-label {
                    color: $Danger800;
                  }
                  .bf-icon {
                    color: $Neutral500;
                  }
                  &:hover {
                    background-color: $Danger800;
                    .bf-label,
                    .bf-icon {
                      color: $White;
                    }
                  }
                }
              }
            }
            .unapprovedCell {
              @include secondaryRegular;
              color: $White;
              border-radius: 52px;
              background-color: $Danger800;
              height: 28px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0px 16px;
            }
            .pendingCell {
              @include secondaryRegular;
              border-radius: 52px;
              background-color: $Brand25;
              height: 28px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0px 16px;
            }
            .approvedCell {
              @include secondaryRegular;
              border-radius: 52px;
              background-color: $Success700;
              height: 28px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0px 16px;
              .approvedCellLabel {
                margin-right: 6px;
              }
            }
          }
          .stickyTableCell:has(.clientFacingCell) {
            display: flex;
            justify-content: flex-start;
            padding: 5px 10px 5px 10px;
            .clientFacingCell {
              .button-framework {
                padding: 0px 6px;
              }
            }
          }
          .stickyTableCell:has(.pendingCell, .approvedCell, .requestApprovalCell) {
            display: flex;
            justify-content: flex-start;
            padding: 0px 16px;
          }
        }
      }
    }
  }
}

.modal-dialog:has(.mbAlertDialog) .mbAlertDialog .modal-body svg {
  height: 72px !important;
}

.mbAlertDialog {
  width: fit-content;
  .inputFieldBody {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    .inputFieldDescription {
      .inputFieldDescriptionHeader {
        @include bodyRegular;
      }
      .inputFieldDescriptionText {
        @include bodyThin;
      }
    }
    .inputFieldFormLabel {
      padding-top: 16px;
      @include bodyBold;
    }
    .inputFieldFormControl {
      padding: 10px 12px;
      height: 88px;
      width: 378px;
    }
  }
}

@import "../../StreamingCreatives/StreamingCreatives.scss";

.creativeAllocationModal {
  .modalHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    .title {
      margin-right: 1rem;
    }
  }

  .workspace {
    flex: 1;
    .workspaceCard {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 20px;
      border: none;
      .workspaceCardInner {
        flex: 1;
        position: relative;
        min-height: 500px;
        .creativeCardContainer {
          &.notLive {
            background: repeating-linear-gradient(
              -45deg,
              #f1c639,
              #f1c639 8px,
              transparent 10px,
              transparent 23px
            );
          }
          @include creativeCard();
        }
      }
    }
  }

  .tabTitle {
    display: flex;
    .validIndicator {
      margin-left: 4px;
      color: green;
    }
  }

  a.nav-item.nav-link {
    border-top: none;
    border-radius: 0;
  }
}

$sidePadding: 30px;

.pendingChangesContainer {
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  margin-top: 50px;
  transition: width 0.2s ease;
  width: 33%;

  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  overflow: auto;
  box-shadow: 0 2px 10px #888;

  .pendingChangesTop {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    width: 100%;
    padding: 1rem $sidePadding;
    height: 63px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 18px;
    color: #130d2d;
    border-bottom: 1px solid #e6eaee;
    .closeButton {
      position: absolute;
      font-size: 28px;
      right: $sidePadding;
      top: 8px;
      cursor: pointer;
    }
  }

  .pendingChangesBody {
    overflow: auto;
    width: 100%;
    .body {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-left: $sidePadding;
      padding-right: $sidePadding;
      overflow: auto;
      .weekContainer {
        .weekTitle {
          font-weight: 500;
        }
        .editContainer {
          display: flex;
          flex: 1;
          flex-direction: column;
          border: 1px solid gray;
          border-radius: 20px;
          margin-bottom: 1rem;
          .editTitle {
            border-bottom: 1px solid gray;
            padding: 0.5rem;
            display: flex;
            justify-content: center;
          }
          .editsList {
            padding: 0.25rem;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .edit {
              padding: 0.25rem;
              & > * {
                margin-left: 0.5rem;
              }
              .deleteButton {
                border-radius: 50%;
              }
              .editType {
                font-weight: 500;
              }
              .oldValue {
                color: #dc143c;
              }
              .newValue {
                color: green;
              }
            }
          }
        }

        .newRowContainer {
          display: flex;
          flex: 1;
          flex-direction: column;
          border: 1px solid gray;
          border-radius: 20px;
          margin-bottom: 1rem;
          .newRowTitle {
            border-bottom: 1px solid gray;
            padding: 0.5rem;
            .weeksForNewRow {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              .weekContainer {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-right: 0.5rem;
                font-size: 0.9rem;
                .deleteButton {
                  border-radius: 50%;
                  margin-right: 0.25rem;
                }
              }
            }
          }
          .rowDetails {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 0.5rem;
            & > * {
              margin-right: 0.75rem;
            }
            .type {
              font-weight: 500;
            }
            .value {
              color: green;
            }
          }
        }
      }
    }
  }
}

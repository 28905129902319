@import "../utils/sassHelpers.scss";
@import "../utils/colors.scss";

.streamingConstraints {
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  &.saving {
    pointer-events: none;
    opacity: 0.3;
  }
  .constraintTable {
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
    padding-bottom: 0.5rem;
    .tableHeader {
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      .tableName {
        font-size: 24px;
      }
    }
    .tableContainer {
      flex: 1;
      min-height: 0;
      margin-top: 10px;
      .constraintCellInput {
        margin: 0 0.25rem;
      }
      .constraintCellSelect {
        width: 100%;
      }
      .centeredData {
        text-align: center;
      }
    }
  }

  .outerTableContainer {
    .tableContainer {
      max-height: 300px;
      flex: 1;
      .hovered > :not(.rowActions) {
        background-color: $primary;
        color: white;
      }
      .topHeader {
        font-size: 14px;
      }

      .rowActions {
        display: flex;
        flex: 1 1;
        flex-direction: row;
        justify-content: space-around;
        padding: 5px;

        button {
          padding: 0;
        }

        .errorIndicator {
          padding: 0;
          color: red;
          width: 18px;
        }
      }

      .modalEditTableCell {
        font-size: 12px;
        flex: 1;
        height: 100%;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .constraintCellInput {
        margin: 0 0.25rem;
      }
      .constraintCellSelect {
        width: 100%;
      }
      .centeredData {
        text-align: center;
      }
    }
  }
}

.streamingConstraintActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  &.saving {
    pointer-events: none;
    opacity: 0.3;
  }
  & > * {
    margin-right: 1rem;
  }
  .nameLabel {
    font-size: 1.3rem;
  }
  .saveButton {
    min-height: 38px;
    max-width: 42px;
  }
}

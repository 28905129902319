.left {
  display: flex;
  gap: 16px;
  .leftLeft {
    display: flex;
    flex: 3;
    gap: 16px;
    .paidMediaImpactRightActions {
      display: flex;
      gap: 16px;
    }
    .chartContainer {
      margin: 0px;
      .contents {
        min-height: 360px;
      }
    }
  }
  .leftRight {
    width: 30%;
    .chartContainer {
      margin: 0px;
      &.enableHoverDesign {
        height: 100%;
      }
      .contents {
        padding: 0;
        .brandEquityImplication {
          display: flex;
          flex-direction: column;
          gap: 0.25rem;
          line-height: 120%;
          width: 100%;
          font-weight: 500;
          font-style: normal;
          overflow: hidden;
          padding-bottom: 2rem 2rem 2rem 0rem;

          .titleRowStyle {
            margin: 0 1.5rem;
            padding: 0.875rem 0rem 0.75rem 0rem;
            display: flex;
            justify-content: space-between;
            font-weight: 400;
            font-size: 0.875rem;
            .titleStyleLeft {
              flex: 1;
              padding: 0 0 0 0.5rem;
            }
            .titleStyleRight {
              flex: 1;
              margin-left: 17.5%;
            }
          }

          .rowStyle {
            flex: 1;
            display: flex;
            flex-direction: row;
            margin: 0 1.5rem;
            overflow: hidden;
            padding: 0.5rem 0;
            border-radius: 8px;
            align-items: flex-start;

            .spendText {
              flex: 2;
              padding: 0.5rem 0.5rem;
            }
            .cadenceText {
              font-weight: 400;
            }

            .arrowIcon {
              flex: 0.5;
              width: 1.375rem;
              padding: 0.5rem 0 0.5rem 0.75rem;
            }

            .metricText {
              flex: 2;
              display: flex;
              padding: 0.5rem 0 0.5rem 0.75rem;
              gap: 0.5rem;
            }

            .rowStyleColorSpendText {
              background-color: #e8e5ff;
              border-radius: 8px 0px 0px 8px;
            }
            .rowStyleColorArrowIcon {
              background-color: #e8e5ff;
            }
            .rowStyleColorMetricText {
              background-color: #e8e5ff;
              border-radius: 0px 8px 8px 0px;
            }
          }

          .endTextRow {
            margin: 1rem 1.5rem 1.5rem 1rem;
            .endTextRowStyle {
              padding: 0 0.5rem;
            }
          }
        }
      }
    }
  }
}

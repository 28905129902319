@import "../utils/sassHelpers.scss";

.competitiveSpotsActions {
  display: flex;
  flex-direction: row;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 10px;
  }
  .companyLogo {
    height: 20px;
  }
  .competitorSelect {
    width: 350px;
  }
}

.competitiveSpotsBody {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: $page-padding $page-padding 0 $page-padding;

  .spots-table-inner-cell {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 0.8rem;
    padding: 0 10px;
  }

  .spots-table-totals-cell {
    font-size: 0.85rem;
    font-weight: 500;
  }

  .spots-table-header-cell {
    display: flex;
    flex: 1;
    font-weight: 500;
    font-size: 0.9rem;
  }
}

@use "sass:math";

@import "../utils/sassHelpers.scss";
@import "../utils/colors.scss";

$circle-diameter: 14px;
$circle-padding: 10px;

.attributesContainer {
  flex-direction: column;
  .attributesContent {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100px;
    flex: 1 1 auto;
    overflow: hidden;
    .attributeNames {
      width: 30%;
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%;
      border-right: 1px solid #e6eaee;
      .attributeNamesList {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        .list-group-item.active {
          z-index: 2;
          color: #495057;
          background-color: #e6eaee;
          border-color: #e6eaee;
        }
        .attribute {
          display: flex;
          flex-direction: row;
          width: 100%;
          border: none;
          border-radius: 4px;
          .color {
            align-content: center;
            justify-content: center;
            width: 10%;
          }
          .nameAndValue {
            align-content: center;
            justify-content: center;
            width: 90%;
            padding-left: 0.5rem;
            color: #848c99;
            .name {
              color: black;
            }
          }
        }
      }
    }
    .attributesChart {
      width: 70%;
      height: 100%;
      display: flex;
      flex-direction: column;
      flex: 1;
      align-items: center;
      justify-content: center;
      .attributesChartTooltip {
        background-color: lightgray;
        padding: 0.5rem;
        border-radius: 4px;
      }
    }
  }
}

@import "../utils/sassHelpers.scss";

.streamingOrderStatusV2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  .saving {
    @include fullBox();
    position: fixed;
    z-index: 1000;
    opacity: 0.8;
  }

  .fullPageRouter {
    flex: 1;
    display: flex;
    min-height: 0;
  }
}

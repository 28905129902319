@import "../utils/colors.scss";

.streamingMonitoringPageContainer {
  padding: 10px;
  min-height: 100%;

  .tableContainer {
    min-height: 100%;
  }
}

.funnelDynamics {
  display: flex;
  flex: 1;
  flex-direction: row;
  min-height: 425px;
  .left {
    display: flex;
    flex: 1;
    flex-direction: column;
    .kpiResponsesCharts {
      display: flex;
      flex: 1;
      flex-direction: column;
      .responsesChart {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin-bottom: 18px;
        .aboveChart {
          display: flex;
          justify-content: center;
          font-size: 14px;
        }
      }
    }

    .noFunnelKPIs {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      font-weight: 500;
    }
  }
  .middle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 450px;
  }
  .right {
    display: flex;
    flex: 1;
    flex-direction: column;
    .conversionRateCharts {
      display: flex;
      flex: 1;
      flex-direction: column;
      .conversionChart {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin-bottom: 18px;
        .aboveChart {
          display: flex;
          justify-content: center;
          font-size: 14px;
        }
      }
    }
  }
}

.deviceBreakoutActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  & > * {
    margin-right: 1rem;
  }

  .companyPicker {
    width: 450px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .logo {
      width: 70px;
      margin-right: 1rem;
      justify-content: flex-end;
      display: flex;
      img {
        height: 35px;
      }
    }
    .deviceBreakoutSelect {
      flex: 1;
      width: 400px;
    }
  }
}

.deviceBreakout {
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  .deviceBreakoutTable {
    z-index: 1;
    flex: 1;
    .topDataCell {
      display: flex;
      flex-direction: column;
      img {
        width: 70px;
      }
      .platform {
        font-size: 0.8rem;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }
    }
    .selectedDataCell {
      flex: 1;
      padding: 1rem;
      align-items: center;
      &.withData {
        cursor: pointer;
      }
      .cellCount {
        flex: 1;
        text-align: center;
        .cellPercentage {
          font-size: 0.75rem;
          padding-left: 5px;
        }
      }
    }
  }
  .noData {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
  }
}

@use "sass:math";
@import "../utils/sassHelpers.scss";

$stripe-width: 6px;
$border-color: rgba(0, 0, 0, 0.4);
.streamingBuying {
  flex: 1;
  padding: $page-padding;
  display: flex;
  min-height: 0;
  flex-direction: column;

  .stripe {
    position: absolute;
    top: 0;
    bottom: -1px;
    left: 0;
    width: $stripe-width;
    background-color: rgba(0, 0, 0, 0.3);
  }

  & > * {
    flex: 1;
    display: flex;

    flex-direction: column;
    min-height: 0;
    .filterContainer {
      display: flex;
      flex-direction: row;
      & > *:not(:first-child) {
        margin-left: 0.5rem;
      }
    }
    .networkListContainer {
      flex: 1;
      margin-top: 1rem;
      min-height: 0;
      display: flex;
      flex-direction: row;
      .networkList {
        flex: 1;
        min-width: 400px;
        overflow: auto;
        border: 1px solid $border-color;
      }
      .networkView {
        flex: 2;
        min-width: 0;
        padding: 1rem;
        position: relative;
        border: 1px solid $border-color;
        display: flex;
        flex-direction: column;
        .orderList {
          margin-top: 1rem;
          flex: 1;
          overflow: auto;
          .orderRow {
            display: flex;
            flex-direction: row;
            & > *:not(:first-child) {
              position: relative;
              $padding: 16px;
              padding-left: $padding;
              &:before {
                $height-space: 2px;
                $width: 2px;
                content: "";
                position: absolute;
                left: math.div($padding, 2) - math.div($width, 2);
                top: $height-space;
                bottom: $height-space;
                width: $width;
                background-color: rgba(0, 0, 0, 0.2);
              }
            }
            .id {
              min-width: 55px;
            }
            .date {
              min-width: 200px;
              justify-content: center;
            }
            .detailsList {
              flex: 1;
              min-width: 0;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .status {
              min-width: 120px;
              text-align: right;
            }
          }
        }
      }
    }

    .networkRow {
      position: relative;
      height: 70px;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      cursor: pointer;
      border-width: 0;
      border-style: solid;
      border-left-width: $stripe-width;
      &:not(:last-child) {
        border-bottom: 1px solid $border-color;
        border-bottom-width: 1px;
        border-color: $border-color;
      }
      &:hover {
        box-shadow: inset 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
      }
      &.expanded {
        box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.3);
        z-index: 10;

        border-top: math.div($stripe-width, 2) solid black;
        border-bottom: math.div($stripe-width, 2) solid black;
      }
      &:not(.expanded) {
        border-bottom-color: $border-color !important;
      }
      padding: 0.5rem;
      .logo {
        width: 50px;
        margin-left: $stripe-width;
        & > img {
          width: 100%;
        }
      }
      .name {
        margin-left: 1rem;
        font-size: 24px;
        .shortCode {
          margin-left: 2px;
          font-size: 15px;
        }
      }
    }
  }
}
.streamingBuyingProblemPane {
  display: flex;
  flex-direction: row;
  padding-right: $page-padding;
  & > * {
    padding: 0;
    &:not(:first-child) {
      margin-left: 0.5rem;
    }
  }
}

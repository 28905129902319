@import "../utils/colors.scss";

.siteMonitoringActions {
  display: flex;
  align-items: center;
  flex-flow: row wrap-reverse;
  justify-content: flex-end;

  button {
    margin-right: 1.5rem;
  }
}

.siteMonitoring {
  display: flex;
  min-height: 0px;
  flex-direction: column;
  flex: 1 1 0%;
  padding: 1.2rem;
  max-width: 100%;
  width: 100%;

  .helperText {
    background: #fff;
    border-radius: 5px;
    padding: 10px;
    margin: 0 25px 28px 0;

    p {
      padding: 0;
      line-height: 22px;
      margin: 5px 0;

      b,
      a {
        font-weight: 700;
      }
    }
  }

  .tableCell {
    width: calc(100% - 10px);
    overflow-wrap: break-word;
    text-align: center;
    font-size: 1rem;
  }

  .tooltipContainer {
    margin-top: 2.4rem;
  }

  .BPMTable {
    max-height: 840px;

    .BPMDropdownButton {
      display: none;
    }
  }

  .pagination-row {
    justify-content: center;
    margin: 5px;
  }

  .stickyHeader {
    .stickyTableCell {
      span {
        font-size: 1rem;
        line-height: 1rem;
        font-weight: bold;
      }
    }
  }

  .frequencyContainer {
    display: flex;

    .tooltipContainer {
      margin: 0.5rem 5px;
    }
  }

  .stickyTableCell:has(> .actionsDropdown) {
    overflow: visible;
  }

  .form {
    width: 900px;

    .form-row {
      &:not(:has(.tooltipContainer)) {
        margin-right: 19px;
      }

      &:last-child > div:last-child {
        display: flex;
        padding: 2.3rem 29px 0 0;

        button {
          &:first-child {
            margin: 0 1rem 0 auto;
          }
        }
      }
    }

    .formButtons {
      display: flex;
      width: 100%;

      button {
        margin: 0 5px 1rem 1rem;

        &:first-child {
          margin: 0 1rem 1rem auto;
        }
      }
    }
  }
}

@import "../utils/sassHelpers.scss";

$border-color: rgba(0, 0, 0, 0.4);

.creativeViewPage {
  display: flex;
  flex: 1;
  min-height: 0;
  flex-direction: column;
  padding: $page-padding;
  .filterBarContainer {
    margin-bottom: 1rem;
  }

  .creativeViewContent {
    display: flex;
    flex: 1;
    flex-direction: row;
    min-height: 0;
    .leftPane {
      display: flex;
      flex-direction: column;
      width: 450px;
      .toggleButtons {
        display: flex;
        margin-bottom: 0.25rem;
      }
      .creativeList {
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow: auto;
        min-height: 0;
        align-items: stretch;
        .virtualizedCreativeList {
          display: flex;
          flex: 1;
          .creativeRow {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 120px;

            cursor: pointer;

            &:not(:last-child) {
              border-bottom: 1px solid $border-color;
            }

            &:hover {
              box-shadow: inset 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
            }

            .thumbnailContainer {
              display: flex;
              position: relative;
              width: 160px;
              height: 90px;
              align-items: center;
              justify-content: center;
              .audioThumbnail {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 160px;
                height: 90px;
                background-color: #e0dbd9;
                color: black;
              }
              .videoThumbnail {
                width: 160px;
                height: 90px;
                display: flex;
              }
              .videoThumbnailUnloader {
                width: 160px;
                height: 90px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: white;
                color: black;
                border: 1px solid black;
              }
            }

            .descriptions {
              display: flex;
              flex-direction: column;
              padding: 0.5rem;
              overflow: hidden;
              max-height: 100%;
              & > * {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
              .title {
                font-size: 1.2rem;
              }
              .isci {
                font-size: 0.8rem;
              }
              .length {
                font-size: 0.8rem;
              }
            }
          }
        }
      }
    }

    .rightPane {
      display: flex;
      flex: 2;
      min-width: 0;
      padding: 2rem;
      border: 1px solid rgba(0, 0, 0, 0.4);
      margin: 0rem 1rem;
      box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.3);
      .videoContainer {
        display: flex;
        flex: 1;
        min-width: 0;
        min-height: 0;
        justify-content: center;
        align-items: center;
        .videoPlayer {
          width: 100%;
          height: 100%;
          &:focus {
            outline: none;
          }
        }
      }
      .audioContainer {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        .audioPlayer {
          display: flex;
          flex: 1;
          width: 100%;
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}

@import "../../utils/colors.scss";

.decayWrapper,
.satWrapper {
  flex-direction: column;
  padding-bottom: 15px;
  .decayChartTitle,
  .satChartTitle {
    color: $Brand100;
    font-size: 14px;
    font-weight: 600;
  }

  .rightOfChart {
    width: 168px;
  }
}
.satChartXAxisTitle {
  color: $Brand100;
  font-size: 14px;
  font-weight: 600;
  padding-left: 16px;
}

.section4 {
  display: flex;
  .left {
    width: 50%;
    flex-direction: column;
    display: flex;
    .chartContainer:first-of-type {
      padding-bottom: 17px;
      margin-left: 0;
    }
    .contents {
      padding-bottom: 2px;
    }
  }
  .right {
    width: 50%;
    flex-direction: column;
    display: flex;
    .chartContainer {
      margin-right: 0;
    }
  }
  .chartContainer .contents {
    gap: 24px;
    padding-bottom: 0px;

    padding-top: 0px;

    .satWrapper {
      flex: auto;
      .satChartTooltip {
        background-color: white;
        border-radius: 6px;
        border: 2px solid $Neutral600;
        box-shadow: 0px 4px 18px 0px rgba(148, 160, 184, 0.5);
        display: flex;
        flex-direction: column;
        .satHeaderLabel {
          background-color: $Neutral200;
          border-top-right-radius: 6px;
          border-top-left-radius: 6px;
          color: $Brand100;
          font-size: 16px;
          font-weight: 400;
          justify-content: flex-end;
          padding-top: 8px;
          padding-left: 16px;
          width: 100%;

          .satTooltipHeaderItem {
            padding: 0 12px;
          }
        }
        .satChartXAxisTitle {
          left: -2rem;
          position: relative;
        }
        .subHeader {
          color: #9795a1;
          .label {
            font-weight: 500;
          }
          &.other {
            margin-top: 10px;
          }
        }
        .itemList {
          display: flex;
          flex-direction: column;
          margin-top: 8px;
          .itemRow {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            .itemWrapper {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              padding: 0 12px;
              .name,
              .satTooltipHeaderItem {
                font-size: 14px;
                font-weight: 500;
                display: flex;
                justify-content: flex-end;
              }
              .value {
                display: flex;
                justify-content: flex-end;
                padding: 0 12px;
                font-size: 14px;
              }
              .line {
                width: 14px;
                height: 3px;
                margin-right: 12px;
              }
            }
          }
        }
      }
      + .rightOfChart {
        display: flex;
        flex-direction: column;
        &.hide {
          display: none;
        }
        .legend {
          flex: auto;
          height: 320px;
          overflow: auto;
          justify-content: start;
          .businessImpactModelHeader {
            font-size: 1rem;
            color: $Brand100;
            font-weight: 500;
            padding-bottom: 0.5rem;
          }
          .legendItem {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 14px;
            margin-bottom: 0.5rem;
            line-height: 16px;
            .line {
              width: 14px;
              height: 3px;
              margin-right: 12px;
            }
            .legendValue {
              align-items: center;
              display: flex;
              justify-content: space-between;
              width: 100%;
              .inactiveLegend {
                color: $Neutral500;
              }
            }
          }
        }
      }
    }
  }
}

.lineWrapper .lineChart .rightSideWrapper .rightOfChart {
  gap: 0px;
}

.lineWrapper .lineChart .rightSideWrapper .rightOfChart .legendTitle {
  width: 150px;
}

.medianLegendLabel {
  align-items: center;
  display: flex;
  gap: 9px;
  .medianText {
    color: $Brand100;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
  }
}

.spikeChartActions {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  .rightAlignedActions {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    align-items: center;
    .dropdownButton {
      .btn-light {
        background-color: white;
        border: none;
      }
      .dropdown-toggle:after {
        content: none;
      }
    }
    .dropdownIcon {
      color: #bec7d2;
      font-size: 25px;
    }
  }
}

.spikeChartPageContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #f1f2f6;
  min-height: 0;
  overflow-x: hidden;
  .spikeChart {
    display: flex;
    flex: 2;
    margin: 26px 70px 12px 70px;
    border: 1px solid #e6eaee;
    background-color: white;
    border-radius: 2px;
    min-height: 0;
    position: relative;

    &.hoverTooltipMode .recharts-tooltip-wrapper {
      visibility: visible !important;
    }

    circle {
      cursor: pointer;
    }

    .spikeChartLegend {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      position: absolute;
      top: 15px;
      right: 50px;
      .legendExplainer {
        margin-right: 0.5rem;
        align-self: center;
        font-size: 14px;
      }
      .zoomOutButton {
        background-color: white;
        color: black;
        border-color: #bec7d2;
        display: flex;
        flex-direction: row;
        height: 32px;
        .exitIcon {
          align-self: center;
        }
        .zoomOutText {
          align-self: center;
          font-size: 14px;
        }
      }
    }

    tspan {
      pointer-events: none;
      user-select: none;
    }

    .hoverTooltip {
      background-color: white;
      display: flex;
      flex-direction: column;
      padding: 12px 12px 12px 8px;
      box-shadow: 0px 2px 10px 0px rgba(230, 234, 238, 1);
      border-radius: 2px;
      font-size: 14px;
      color: #4e4e50;

      .timeLabel {
        font-weight: 500;
      }
      .kpiLabel {
        font-weight: 500;
      }
    }

    .creativeTooltip {
      width: 270px;
      height: 100px;
      background-color: white;
      display: flex;
      flex-direction: row;
      padding: 8px 12px 12px 8px;
      box-shadow: 0px 2px 10px 0px rgba(230, 234, 238, 1);
      border-radius: 2px;
      font-size: 14px;
      color: #4e4e50;
      &.flipTooltip {
        transform: translateX(-100%);
      }
      .creativeThumbnail {
        display: flex;
        flex: 1;
        height: 100%;
        img {
          height: 90%;
          display: flex;
          align-self: center;
          justify-self: center;
        }
        .tooltipCreativeImageUnloader {
          height: 100%;
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: center;
          background-color: white;
          color: black;
          border: 1px solid black;
        }
      }

      .spotInfo {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-left: 8px;
        .spotNetwork {
          height: 30px;
          width: 30px;
          & > * {
            height: 30px;
            width: 30px;
          }
          .tooltipNetworkImageUnloader {
            font-weight: 500;
          }
        }
        .spotTime {
          font-size: 11px;
        }
        .kpi {
          font-size: 11px;
          .kpiLabel {
            font-weight: 500;
          }
        }
      }
    }
  }

  .spotTable {
    display: flex;
    flex: 1;
    margin: 12px 70px 26px 70px;
    border: 1px solid #e6eaee;
    border-radius: 2px;
    min-height: 0;
    .stickyTableCell {
      justify-content: flex-start;
      & > * {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 1rem;
        &.odd {
          background-color: #f7f8fa;
        }
        &:not(.odd) {
          background-color: white;
        }
      }
    }

    .selectedSpot {
      background-color: #d4bdf2 !important;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
    }

    .logoContainer {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;

      .networkLogo {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        img {
          height: 90%;
        }
        .networkImageUnloader {
          font-weight: 500;
        }
      }
      .avail {
        display: flex;
        position: absolute;
        right: 50%;
      }
    }

    .creativeContainer {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      .creativeImage {
        img {
          width: 75px;
        }
        .creativeImageUnloader {
          width: 75px;
          height: 42px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: white;
          color: black;
          border: 1px solid black;
        }
      }
      .creativeName {
        display: flex;
        flex: 1;
        margin-left: 1rem;
        height: 100%;
        align-items: center;
      }
    }
  }
  .noSpikeData {
    font-weight: 500;
    font-size: 18px;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    margin: 50px 70px 50px 70px;
    border: 1px solid #e6eaee;
    background-color: white;
    border-radius: 2px;
  }
}

@import "../utils/sassHelpers.scss";
@import "../utils/colors.scss";

.creativeMapPage {
  padding: $page-padding;
  display: flex;
  flex: 1;
  min-height: 0;
  .fullPageRouter {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 0;
    min-height: 0;
    .controls {
      display: flex;
      flex-direction: row;
      margin-bottom: 1rem;

      & > *:not(:first-child) {
        margin-left: 1rem;
      }
      .buttonGroup {
        display: flex;
        & > *:not(:first-child) {
          margin-left: 0.5rem;
        }
      }
      .subControls {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        & > *:not(:first-child) {
          margin-left: 1rem;
        }
      }
      .createButton {
        display: flex;
        flex-direction: row;
        align-items: center;
        .savingSpinner {
          margin-left: 0.5rem;
        }
      }
    }
    .list {
      flex: 1;
      .tableRowItem,
      .listItem {
        &:not(.tableRowHeader) {
          cursor: pointer;
          transition: background-color 0.12s ease, color 0.12s ease;

          &:hover,
          &.selected {
            background-color: $primary;
            * {
              color: white !important;
              border-color: white !important;
            }
          }
        }

        .bulkEditCheckbox {
          flex: 0;
          min-width: 40px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;
          font-size: 30px;
          margin-right: 1rem;
        }
      }
      .tableRowItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        &:first-child {
          border-top: 1px solid rgba(0, 0, 0, 0.125);
        }
        .creativeTag {
          display: flex;
          flex: 1;
          flex-direction: row;
          flex-flow: row wrap;
          align-items: flex-start;
          align-content: flex-start;
          justify-content: flex-start;
          .tagName {
            border: black 1px solid;
            padding: 0.1rem 0.25rem;
            border-radius: 5px;
            font-size: 0.75rem;
            margin: 8px 8px 0px 0px;
            max-width: 120px;
            overflow-wrap: break-word;
            hyphens: auto;
          }
        }
        & > * {
          flex: 1;
          padding: 0 8px;
          height: 100%;
          min-width: 0;
          display: flex;
          align-items: center;
          border-right: 1px solid rgba(0, 0, 0, 0.125);
          overflow: hidden;
          flex-wrap: wrap;
          &:first-child {
            border-left: 1px solid rgba(0, 0, 0, 0.125);
          }
          &.isci {
            flex: 1.2;
          }
          &.modelEntry {
            flex: 0;
            min-width: 70px;
          }
          &.name {
            flex: 2;
          }
          &.length {
            flex: 0;
            min-width: 70px;
          }
          &.language {
            flex: 0;
            min-width: 90px;
          }
          &.avail {
            flex: 0;
            min-width: 55px;
          }
          &.live {
            flex: 0;
            min-width: 89px;
          }
          &.centerCell {
            justify-content: center;
          }
        }
      }
      .tableRowHeader {
        // https://codepen.io/imarkrige/pen/vgmij
        .headerCaret {
          position: relative;
          cursor: pointer;
          user-select: none;
          font-weight: 500;
          & > * {
            max-width: 100%;
            overflow: hidden;
          }
          &:before,
          &:after {
            border: 4px solid transparent;
            content: "";
            display: block;
            height: 0;
            right: 4px;
            top: 50%;
            position: absolute;
            width: 0;
          }
          &:before {
            border-bottom-color: #aaaaaa;
            margin-top: -9px;
          }
          &:after {
            border-top-color: #aaaaaa;
            margin-top: 1px;
          }
          &.up:before {
            border-bottom-color: black;
          }
          &.down:after {
            border-top-color: black;
          }
        }
        .nonCaret {
          font-weight: 500;
        }
      }
      .listItem {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        border: 1px solid rgba(0, 0, 0, 0.125);
        margin-bottom: -1px;
        padding: 0.5rem 1rem;
        &:last-child {
          margin-bottom: 0;
        }

        .thumb {
          width: 185.45px;
          height: 102px;
          display: flex;
          img {
            width: 100%;
          }
        }
        .nonImg {
          flex: 1;
          display: flex;
          flex-direction: row;
          & > * {
            flex: 1;
          }
          .title {
            font-size: 24px;
            line-height: 28px;
          }
          .metadata {
            margin-left: 1rem;
            .creativeTags {
              flex-flow: row wrap;
              .optionName {
                border: black 1px solid;
                margin-right: 0.5rem;
                padding: 0.25rem;
                border-radius: 500px;
                font-size: 0.75rem;
                white-space: nowrap;
              }
            }
          }
          .dateBlock {
            margin-left: 2rem;
            .content {
              display: flex;
              flex-direction: row;

              .contentLabel {
                margin-right: 4px;
                font-weight: 500;
              }
              .currentlyLive {
                color: $primary;
              }
              .notLive {
                color: #666666;
              }
            }
          }
        }
      }
    }
    .bulkAddPage {
      flex: 1;
      display: flex;
      flex-direction: column;
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.3);
      .previewName {
        text-align: center;
      }
      .content {
        min-height: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        margin-top: 1rem;

        flex: 1;
        display: flex;
        flex-direction: row;

        .results {
          flex: 1;
          overflow-y: auto;
          max-height: 0;
          min-height: 100%;
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          .generatedList {
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            margin-bottom: -1px;
            .creativeItem {
              .creativeName {
                font-size: 1.5rem;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 0.5rem;
                &:not(.editing) {
                  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                }
                .name {
                  flex: 1;
                }
                .editButton {
                  margin-left: 1rem;
                }
              }
              .subMetaData {
                font-size: 0.8rem;
                display: flex;
                flex-direction: row;
                align-items: center;
                &.editing {
                  margin-bottom: 0.5rem;
                }
                & > * {
                  flex: 1;
                  min-width: 0;
                  word-break: break-all;
                  &:not(:first-child) {
                    margin-left: 1rem;
                  }
                  &.formLabel {
                    flex: 0;
                    white-space: nowrap;
                    min-width: 100px;
                  }
                  .value {
                    font-weight: 500;
                  }
                }
              }
              .isciList {
                margin-top: 0.5rem;
                .isciRow {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  &.editing {
                    margin-bottom: 0.5rem;
                  }
                  &.skipped {
                    text-decoration: line-through;
                    color: #aaaaaa;
                  }
                  .avail {
                    width: 75px;
                  }
                  .length {
                    width: 40px;
                  }
                  .isci {
                    flex: 1;
                    font-weight: 500;
                  }
                }
              }
            }
          }
          .emptyList {
            text-align: center;
            margin-top: 1rem;
            font-size: 1.2rem;
          }
        }
        .leftPane {
          flex: 2;
          display: flex;
          flex-direction: column;
          border-right: 1px solid rgba(0, 0, 0, 0.2);
          padding-bottom: 0.5rem;

          .controls {
            margin: 0 1rem 0 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            & > * {
              display: flex;
              flex-direction: row;
              & > * {
                margin-left: 1rem;
              }
            }
          }
          .addForm {
            overflow-y: auto;

            padding: 1rem 1rem 0 0.5rem;

            flex: 1;
            & > * {
              display: flex;
              flex-direction: row;
              & > * {
                flex: 1;
              }
            }
            .creativeCode {
              flex: 0;
              min-width: 120px;
              margin-left: 1rem;
            }
            .repeat {
              flex: 0;
              min-width: 70px;
              margin-left: 1rem;
            }
            .invalidFeedback {
              color: red;
              font-size: 0.8rem;
            }
            .formNote {
              font-size: 0.8rem;
            }
            .dateRange {
              display: flex;
              flex-direction: row;
              align-items: center;
            }
            .doubleInputRow > *:not(:first-child) {
              margin-left: 1rem;
            }
            .backToBackCheckbox {
              margin-bottom: 1rem;
            }
            .backToBackSelectContainer {
              margin-bottom: 1rem;
              & > :first-child {
                margin-right: 1rem;
              }
            }
            .conceptSelect {
              display: flex;
              flex-direction: column;
              & > * {
                margin-bottom: 0.5rem;
              }
            }
            .creativeAttributes {
              display: flex;
              flex-direction: column;
              .title {
                margin-bottom: 0.5rem;
              }
              .options {
                display: flex;
                flex-direction: row;
                & > * {
                  margin-right: 0.5rem;
                }
              }
              .selectedAttributeBoxes {
                display: flex;
                flex-direction: row;
                flex-flow: row wrap;
                .attributeBox {
                  border: black 1px solid;
                  margin-right: 1rem;
                  padding: 0.5rem;
                  margin-bottom: 1rem;
                  height: 175px;
                  width: 175px;
                  overflow: auto;
                  .name {
                    font-weight: 500;
                  }
                  & > * {
                    flex-direction: column;
                  }
                }
              }
            }
            .dimensionSelect {
              width: 100px;
            }
          }
        }
      }
    }
    .creativeOptionsPage {
      display: flex;
      flex: 1;
      flex-direction: column;
      .floatingControls {
        position: fixed;
        top: 65px;
        right: 1rem;
        padding: 0.25rem 0.5rem;
        background-color: white;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 3px;
        box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2);
        & > *:not(:last-child) {
          margin-right: 0.2rem;
        }
      }
      .creativeAttributesTitle {
        font-weight: 500;
        margin-bottom: 0.5rem;
      }
      .conceptContainer {
        .conceptTitleAndButtons {
          margin-right: 1rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          .title {
            font-weight: 500;
          }
          & > * {
            margin-right: 0.5rem;
            margin-bottom: 0.5rem;
          }
        }
        .conceptRowsContainer {
          border: black 1px solid;
          padding: 1rem;
          margin-bottom: 1rem;
          background-color: white;
          .headers {
            display: flex;
            flex: 1;
            flex-direction: row;
            font-weight: 500;
            margin-bottom: 0.5rem;
            align-items: center;
            .name {
              margin-right: 270px;
            }
            .applicableCategories {
              margin-right: 0.5rem;
            }
          }
          & > * {
            display: flex;
            flex: 1;
            flex-direction: column;
          }
          .row {
            display: flex;
            flex-direction: row;
            margin-bottom: 0.5rem;
            .conceptNameAndEditTools {
              width: 300px;
              margin-left: 1rem;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .conceptName {
                margin-right: 4px;
              }
            }
            & > * {
              align-self: center;
              margin-right: 1rem;
            }
            .checkBox {
              margin-left: 0.5rem;
            }
            .categoryName {
              font-size: small;
            }

            .naCategoriesSelect {
              flex: 1;
              min-width: 0;
            }

            .newConceptForm {
              width: 210px;
              margin-bottom: 0.5rem;
              margin-left: 0.5rem;
            }
          }
        }
      }

      .categoryList {
        display: flex;
        flex: 1;
        align-items: flex-start;
        flex-flow: row wrap;
        & > * {
          border: 1px solid black !important;
          height: 300px;
          width: 300px;
        }
        .categoryBox {
          margin-right: 1rem;
          margin-bottom: 1rem;
          padding: 1rem;
          overflow: auto;
          background-color: white;
          .categoryHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1rem;
          }
          .categoryName {
            font-weight: 500;

            .attributeNameInput {
              width: 100%;
            }
          }
          .categoryControls {
            display: flex;

            button {
              margin: 1px;
            }

            .saveAndCancelButtons {
              display: flex;
            }
          }
          .categoryOptions {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .option {
              border: black 1px solid;
              margin-right: 1rem;
              margin-top: 0.5rem;
              padding: 0.1rem 0.75rem;
              border-radius: 5px;
            }
            .form-control {
              margin-top: 0.5rem;
            }
            .btn {
              margin-top: 0.5rem;
            }
          }
        }
        .newCategoryButton {
          font-size: 3rem;
        }
      }
    }
  }
}
.creativeMapActions {
  .mediaTypePicker {
    margin-right: 2rem;
  }
}
.creativeMapMenuViewModal {
  .creativeMenuModalBody {
    display: flex;
    flex-direction: row;
    .leftPane {
      flex: 2;
    }
    .leftPaneBody {
      max-height: 400px;
      overflow: auto;
    }
    & > * {
      flex: 1;
      display: flex;
      flex-direction: column;
      min-width: 0;

      &:first-child {
        .videoPlayer {
          width: 100%;
        }
        .audioPlayer {
          margin: 1rem;
        }
        .metaData {
          display: flex;
          flex-direction: row;
          word-break: break-all;
          .backToBackCreatives {
            margin-top: 0.5rem;
          }
          .tagsContainer {
            display: flex;
            flex: 1;
            flex-direction: column;
            flex-wrap: wrap;
            .title {
              display: flex;
              flex: 1;
              margin-top: 0.5rem;
            }
            .nameContainer {
              display: flex;
              flex: 1;
              flex-direction: row;
              flex-flow: row wrap;
              .tagName {
                border: black 1px solid;
                margin-right: 0.25rem;
                margin-top: 0.5rem;
                padding: 0.1rem 0.75rem;
                border-radius: 5px;
                font-size: 0.75rem;
              }
            }
          }

          .creativeAttributes {
            display: flex;
            flex-direction: column;
            margin-top: 0.5rem;
            .attributeBoxesContainer {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              .attributeBox {
                border: black 1px solid;
                margin-right: 0.5rem;
                margin-bottom: 0.5rem;
                padding: 0.5rem;
                width: 200px;
                height: 200px;
                overflow: auto;
                .name {
                  font-weight: 500;
                }
                .options {
                  display: flex;
                  flex-direction: column;
                  & > * {
                    margin-right: 0.5rem;
                  }
                }
              }
            }
          }

          .editable {
            cursor: pointer;
            border: 1px solid transparent;
            &:hover {
              border: 1px solid rgba(0, 0, 0, 0.2);
            }
          }
          .editableMetaSelect {
            display: flex;
            flex-direction: row;
          }
          & > * {
            display: flex;
            flex-direction: column;
            min-width: 0;

            &:first-child {
              flex: 1;
            }
            &:nth-child(2) {
              min-width: 70px;
              margin-left: 1rem;
            }
            & > * {
              &:first-child {
                font-size: 14px;
                font-weight: 500;
              }
            }
          }
        }
        .clickthroughInput {
          margin-top: 0.5rem;
          padding-top: 0.5rem;
          border-top: 1px solid rgba(0, 0, 0, 0.2);
        }
      }

      .copyPointsPdfLink {
        margin-right: 8px;
      }

      .fileUploadWrapper {
        .fileInputLabel {
          cursor: pointer;
          border: 1px solid black;
          border-radius: 3px;
          padding: 4px;
          margin-top: 2px;
        }

        #fileInput {
          position: absolute;
          left: 0;
          right: 0;
          opacity: 0;
          z-index: -1;
        }
      }

      &:last-child {
        .newDateBox {
          display: flex;
          flex-direction: column;
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          margin-bottom: 0.5rem;
          z-index: 3;

          .dateState {
            font-size: 1.1rem;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 0.5rem;
          }
          .runThrough {
            margin-top: 0.5rem;
            .label {
              margin-right: 1rem;
            }
          }

          .dateRow {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 0.5rem;
            .SingleDatePicker {
              width: 100px;
            }
            .label {
              font-size: 0.8rem;
            }
          }
          .DateInput_input {
            text-align: center;
          }
          .SingleDatePickerInput__showClearDate {
            padding-right: 20px;
          }
          .SingleDatePickerInput_clearDate {
            margin-right: 0;
          }
        }
        .dateList {
          flex: 1;
          overflow-y: auto;
          .dateListItem {
            display: flex;
            flex-direction: row;
            align-items: center;
            .date {
              flex: 1;
            }
          }
          .noDates {
            width: 100%;
            text-align: center;
            margin-top: 0.75rem;
          }
        }
      }
    }
  }
  .modalControls {
    justify-content: space-between;
    .rightButtons > *:not(:first-child) {
      margin-left: 0.5rem;
    }
  }
  #showRetired.modalControls {
    justify-content: flex-end;
  }
  .savingOverlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    & > * {
      background-color: transparent;
    }
    z-index: 10;
  }
}

.creativeMapBulkEditModal {
  .creativeMapBulkEditBody {
    display: flex;
    flex-direction: column;
    .dateSetters {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 5px;
      & > * {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .dateRange {
        .DateInput_input {
          text-align: center;
        }
      }
    }
    .metaData {
      .creativeAttributes {
        .attributeBoxesContainer {
          .attributeBox {
            border: 1px solid black;
            padding: 8px;
            margin-bottom: 4px;
          }
        }
      }
    }
    .editControlRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 1rem;
      & > *:not(:first-child) {
        margin-left: 1rem;
      }
      .clickthroughLabel {
        white-space: nowrap;
      }
    }
    .creativeList {
      flex: 1;
      min-height: 0;
      overflow-y: auto;
      max-height: 300px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      .creativeRow {
        display: flex;
        flex-direction: column;
        & > * {
          display: flex;
          flex-direction: row;
          align-items: center;
          &.mainRow > *:not(.removeButton) {
            flex: 1;
            align-items: flex-start;
            .strikethrough {
              text-decoration: line-through;
            }
          }
        }
      }
    }
  }
}

@import "../../utils/colors.scss";

.cl-dropdown-toggle {
  &.dropdown-toggle {
    &.btn {
      &.btn-primary {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 6px;
        border: none;
        background-color: #cbd2e1;
        color: $Brand100;

        &:focus {
          box-shadow: none !important;
        }
        &.filled {
          &.primary {
            &.light {
              background-color: $Brand50;
              color: $White;
              &.hover:not(.disabled),
              &:hover:not(.disabled) {
                background-color: $Brand70;
              }
            }
            &.dark {
              background-color: $White;
              color: $Brand60;
              &.hover:not(.disabled),
              &:hover:not(.disabled) {
                background-color: $Brand20;
                color: $Brand70;
              }
            }
          }
          &.secondary {
            &.light {
              background-color: $Neutral200;
              color: $Brand100;
              &.disabled {
                color: $Brand100;
              }
              &.hover:not(.disabled),
              &:hover:not(.disabled) {
                background-color: $Neutral400;
                color: $Brand100;
              }
            }
            &.dark {
              background-color: $Brand60;
              color: $White;
              &.hover:not(.disabled),
              &:hover:not(.disabled) {
                background-color: $Brand50;
              }
            }
          }
        }
        &.outlined {
          &.primary {
            &.light {
              border: 1px solid $Brand50;
              background-color: $White;
              color: $Brand100;
              &.disabled {
                color: $Brand100;
              }
              &.hover:not(.disabled),
              &:hover:not(.disabled) {
                background-color: $Brand20;
                border-color: $Brand70;
                color: $Brand100;
              }
            }
            &.dark {
              border: 1px solid $White;
              background-color: $Brand80;
              color: $White;
              &.hover:not(.disabled),
              &:hover:not(.disabled) {
                background-color: $Brand70;
              }
            }
          }
          &.secondary {
            &.light {
              border: 1px solid $Neutral400;
              background-color: $White;
              color: $Brand100;
              &.disabled {
                color: $Brand100;
              }
              &.hover:not(.disabled),
              &:hover:not(.disabled) {
                background-color: $Neutral200;
                border-color: $Neutral600;
                color: $Brand100;
              }
            }
            &.dark {
              border: 1px solid $Brand50;
              background-color: $Brand80;
              color: $White;
              &.hover:not(.disabled),
              &:hover:not(.disabled) {
                background-color: $Brand70;
              }
            }
          }
        }
        &.empty {
          &.primary {
            &.light {
              background-color: transparent;
              color: $Brand60;
              &.disabled {
                color: $Brand60;
              }
              &.hover:not(.disabled),
              &:hover:not(.disabled) {
                background-color: $Brand20;
                color: $Brand70;
              }
            }
            &.dark {
              background-color: transparent;
              color: $White;
              &.hover:not(.disabled),
              &:hover:not(.disabled) {
                background-color: $Brand70;
              }
            }
          }
          &.secondary {
            &.light {
              background-color: transparent;
              color: $Brand100;
              &.disabled {
                color: $Brand100;
              }
              &.hover:not(.disabled),
              &:hover:not(.disabled) {
                background-color: $Neutral200;
                color: $Brand100;
              }
            }
            &.dark {
              background-color: transparent;
              color: $Brand30;
              &.disabled {
                color: $Brand30;
              }
              &.hover:not(.disabled),
              &:hover:not(.disabled) {
                background-color: $Brand70;
                color: $White;
              }
            }
          }
        }

        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }

        .dt-text {
          font-weight: 500;
          font-style: normal;
          line-height: normal;

          .dt-placeholder {
            opacity: 0.5;
          }
        }
        .dt-icon {
          display: flex;
          margin-right: 6px;
        }
        &::after {
          margin: 0px 4px 0px 8px;
          border-top: 5px solid;
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
          border-bottom: 0px;
        }
        &.sm {
          padding: 0px 6px 0px 12px;
          height: 28px;
          &.has-leading-icon {
            padding-left: 8px;
          }
          .dt-text {
            height: 15px;
            font-size: 14px;
          }
          .dt-icon {
            width: 18px;
            height: 18px;
            font-size: 18px;
          }
        }
        &.lg {
          padding: 0px 6px 0px 16px;
          height: 32px;
          &.has-leading-icon {
            padding-left: 12px;
          }
          .dt-text {
            height: 18px;
            font-size: 16px;
          }
          .dt-icon {
            width: 22px;
            height: 22px;
            font-size: 22px;
          }
          &::after {
            margin-left: 6px;
          }
        }

        &.widget-title {
          &.light,
          &.dark {
            background-color: transparent;
            height: 26px;
            color: $Brand100;
            padding: 0px;
            .dt-text {
              height: 26px;
              font-size: 24px;
            }
            &.disabled {
              color: $Brand100;
              background-color: transparent;
            }
            &.hover:not(.disabled),
            &:hover:not(.disabled) {
              background-color: transparent;
              color: $Brand50;
            }
          }
          &::after {
            color: $Brand50;
          }
        }
      }
    }
  }
}

.creativeDiv {
  display: flex;
  align-items: center;
  margin: 12px;
  padding: 12px;

  .inputTitle {
    font-weight: 500;
    margin-right: 12px;
  }

  .inputBox {
    width: 100%;
  }

  .creativeContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 24px;
    max-width: 190px;

    .creativeImgBox {
      display: flex;
      align-items: stretch;
      margin-bottom: 8px;

      .thumbFetch {
        display: flex;
        align-items: center;
      }
      .creativeImg {
        width: 100%;
      }
      .noImg {
        border: 1px solid black;
        padding: 25px 30px;
      }
    }

    .isci {
      font-size: 16px;
    }
    .creativeName {
      font-size: 12px;
    }
  }

  .inputContainer {
    flex: 2;
    display: flex;
    flex-direction: column;
    margin-right: 24px;

    .inputBoxLabel,
    .dropdownLabel {
      margin-bottom: 4px;
    }

    .inputBox {
      margin-bottom: 24px;
    }

    .companionDropdown {
      flex: 0;
      border: black;
      width: fit-content;
    }
  }
}

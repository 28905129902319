.bpm-kpi-status {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  font-size: 0.85rem;

  .kpi-status-alert-card {
    display: flex;

    width: 75%;
    padding: 1px 10px;
    height: 90%;

    .kpi-status-alert {
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 100%;

      border: 1px solid transparent;
      border-radius: 0.25rem;
      justify-content: center;
      line-height: 0.5;

      h4 {
        font-size: 0.75rem;
      }

      span {
        font-size: 0.65rem;
      }
    }

    .kpi-status-alert-danger {
      color: #721c24;
      background-color: #f8d7da;
      border-color: #f5c6cb;
      cursor: pointer;
    }

    .kpi-status-alert-primary {
      color: #004085;
      background-color: #cce5ff;
      border-color: #b8daff;
      cursor: pointer;
    }

    .kpi-status-alert-success {
      color: #155724;
      background-color: #d4edda;
      border-color: #c3e6cb;
      h4 {
        margin: 0;
      }
    }

    .kpi-status-alert-warning {
      color: #856404;
      background-color: #fff3cd;
      border-color: #ffeeba;
      cursor: pointer;
    }
  }

  .kpi-status-last-checked {
    display: flex;
    flex-direction: column;

    justify-content: stretch;
    align-items: center;
    line-height: 1.5;
    font-size: 0.9em;

    .last-modified-time {
      display: flex;
    }
    .attempts {
      display: flex;
      font-size: 0.65rem;
    }
  }
}

.bpm-kpi-status-popover-body {
  max-height: 150px;
  overflow: hidden;
  overflow-y: scroll;
  font-size: 0.85em;
}

.geo-data-card-container {
  max-width: 250px;

  .geo-state-selection-header {
    color: #485d75;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    justify-content: center;
    line-height: 20px;
    margin-bottom: 15px;
  }
}

.geo-data-card-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
}

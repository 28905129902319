@import "../utils/colors.scss";

.filtersPanel {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid $Grey60;
  background-color: $White;
  flex: 1 1 auto;
  margin: 0.5rem;
  .header {
    display: flex;
    align-items: center;
    padding: 14px 14px 7px 14px;
    border-bottom: 1px solid $Grey60;
    font-size: 1.125rem;
    font-weight: 500;
    min-height: 50px;
  }
  .filters {
    display: flex;
    flex-direction: column;
    padding: 24px 24px 0px 24px;
    height: 100%;
    row-gap: 24px;
    &.reduce-spacing-1 {
      row-gap: 20px;
    }
    &.reduce-spacing-2 {
      row-gap: 16px;
    }
    &.reduce-spacing-3 {
      row-gap: 12px;
    }
    .filterSection {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      &.reduce-spacing-1 {
        row-gap: 8px;
      }
      &.reduce-spacing-2 {
        row-gap: 4px;
      }
      &.reduce-spacing-3 {
        row-gap: 1px;
      }
      .filterName {
        height: 24px;
        font-size: 1rem;
        font-weight: 500;
      }
      .filterOptions {
        display: flex;
        flex-direction: column;
        row-gap: 14px;
        &.reduce-spacing-1 {
          row-gap: 10px;
        }
        &.reduce-spacing-2 {
          row-gap: 6px;
        }
        &.reduce-spacing-3 {
          row-gap: 2px;
        }
        &.wrap {
          flex-direction: row;
          flex-wrap: wrap;
          column-gap: 12px;
        }
      }
    }
  }
  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 16px;
    padding: 24px 32px 24px 50px;
  }
}

@import "../utils/colors.scss";

.creativeSnapshotToggle {
  display: flex;
  flex: 1;
  background-clip: padding-box;

  & > * {
    cursor: pointer;
    &:first-child {
      margin-right: 0.5rem;
    }
    flex-direction: row;
    &:not(.active) {
      color: #c7cfdd;
      background-color: #f7f8fa;
      border: #f7f8fa 1px solid;
      border-radius: 5px;
    }
  }
}

.creativeSnapshotLeftActions {
  cursor: pointer;
  color: $Info1;
  text-decoration-line: underline;
  text-underline-position: under;
  font-size: 14px;
}

.creativeSnapshot {
  display: flex;
  flex: 1;
  .homePageCreativeAttributes {
    display: flex;
    flex: 1;
    .chartContainer {
      margin: 0;
      .header {
        padding: 0;
      }
      .contents {
        padding: 0;
      }
    }

    & > * {
      display: flex;
      flex: 1;
      height: 400px;
      width: 400px;
      border: none;
    }
    .header {
      display: flex;
      height: 50px;
      & > * {
        flex-direction: row;
      }
    }
    .list-group-item {
      max-width: 148px;
      word-wrap: break-word;
    }
    .noCreativeInsightsData {
      justify-content: center;
      align-items: center;
    }
  }
  .homePageLiveCreatives {
    display: flex;
    flex: 1;
    flex-direction: column;
    .liveCreativesContainer {
      display: flex;
      flex-direction: column;
      overflow: auto;
      height: 375px;
      .creativeSelect {
        margin-bottom: 8px;
        display: flex;
        .dropdown-button {
          min-width: 100px;
        }
      }
      .noLiveCreatives {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        font-weight: 500;
      }
      .liveCreativesItem {
        display: flex;
        flex-direction: row;
        margin-bottom: 2rem;
        .thumbnail {
          margin-right: 1rem;
          min-width: 185.45px;
          height: 102px;
          display: flex;
          .creativeThumbnailUnloader {
            min-width: 185.45px;
            height: 102px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            color: black;
            border: 1px solid black;
          }
          img {
            width: 100%;
          }
        }
        .nonImg {
          .creativeTitle {
            font-weight: 500;
          }
          .creativeLength {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}

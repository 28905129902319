.marketingPortfolio {
  display: flex;
  flex: 1;
  flex-direction: column;
  .aboveChart {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    .spendAxisLabel {
      color: #808080;
    }
  }

  .chartContents {
    display: flex;
    flex: 1;
    flex-direction: row;
    .marketingPortfolioChart {
      display: flex;
      flex: 1;
      flex-direction: row;
    }
    .rightOfChart {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 0.5rem;
    }
  }
}

.marketingPortfolioNoData {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
}

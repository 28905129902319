@use "sass:math";
$circle-diameter: 14px;

.chartTooltip {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 25px;
  box-shadow: 0px 2px 10px 0px rgba(230, 234, 238, 1);
  border-radius: 2px;

  .headerLabel {
    font-weight: 500;
  }

  .subHeader {
    color: #9795a1;
    .label {
      font-weight: 500;
    }
    &.other {
      margin-top: 10px;
    }
  }

  .itemList {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    .itemRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      .circle {
        width: $circle-diameter;
        height: $circle-diameter;
        border-radius: math.div($circle-diameter, 2);
        margin-right: 12px;
      }
      .square {
        width: $circle-diameter;
        height: $circle-diameter;
        margin-right: 12px;
      }
      .line {
        width: $circle-diameter;
        height: 3px;
        margin-right: 12px;
      }
      .dottedLine {
        width: $circle-diameter;
        margin: 0px 12px 0px 0px;
      }
      .name {
        font-size: 14px;
        font-weight: 500;
        margin-right: 3rem;
      }
      .value {
        margin-left: auto;
        font-size: 14px;
      }
    }
  }
  .footer {
    font-size: 11px;
    max-width: 250px;
    word-wrap: break-word;
  }
}

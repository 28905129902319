.BPMRelativeDatePicker {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  min-width: 400px;
  background-color: #f1f2f6;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 2.5px 4px 0;
  &.white {
    background-color: white;
    padding: 5px 5px 2.5px;
  }
  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }
  .daysInput {
    display: inline-block;
    width: 65px;
  }
  & > * {
    margin-bottom: 2.5px;
  }
}

@import "../utils/colors.scss";

.cbsaMap {
  scale: 1 1.3;
  overflow: hidden;
  width: 100%;

  .cbsaPath,
  .statePath {
    stroke: black;
    stroke-width: 0.5px;

    &.alaska,
    &.hawaii {
      stroke-width: 1px;
    }
  }

  .cbsaPath {
    &:hover {
      stroke-width: 1px;
    }

    &.alaska,
    &.hawaii {

      &:hover {
        stroke-width: 2px;
      }
    }

    &.alaska {
      translate: 365px 266px;
      scale: 0.37 0.54;
    }

    &.hawaii {
      translate: 503px 117.5px;
      scale: 0.49 0.59;
    }
  }

  .statePath {
    translate: -4.5px -43px;
    scale: 1.015;

    &.alaska {
      translate: 360px 240px;
      scale: 0.38 0.55;
    }

    &.hawaii {
      translate: 500px 92px;
      scale: 0.5 0.6;
    }
  }
}
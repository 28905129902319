@import "../utils/colors.scss";

$dimension-padding: 0.35rem;

$side-margin: 1.7rem;

.performanceTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  // Eyeballing to center the items with the title based on the baseline, not the text itself
  $baseline-centering-adjustment: 2px;
  .viewNameInput {
    width: 200px;
    margin-left: 1rem;
  }
  .presetPicker {
    margin-left: 1rem;
    margin-top: $baseline-centering-adjustment;
  }
  .presetPicker:last-child {
    margin-right: 1rem;
  }

  .groupInput {
    margin-left: 1rem;
    font-size: 15px;
    font-weight: normal;
    width: 200px;
    color: #495057;
  }

  .groupInput__control {
    background: #ffffff;
    border-color: #e6eaee;
    min-height: 30px;
    height: 30px;
    width: 200px;
  }
  .groupInput__value-container {
    height: 30px;
    width: 200px;
    padding: 0 6px;
  }
  .groupInput__input {
    margin: 0px;
    height: 30px;
    color: #495057;
  }
  .groupInput__indicators {
    height: 30px;
  }
  .editControls {
    margin-left: 1rem;
    & > * {
      margin-right: 0.5rem;
    }
  }
  .viewGroupControls {
    display: flex;
    flex-direction: row;
    max-height: 2rem;
    justify-content: center;
    align-items: center;
  }
  .viewControls {
    display: flex;
    flex-direction: row;
    max-height: 2rem;
    justify-content: center;
    align-items: center;
  }
  .optionsButton {
    margin-top: $baseline-centering-adjustment;
  }
  .optionsDropdown {
    margin-right: 1.5rem;
    .dropdown-item {
      padding: 0.25rem 1rem;
      line-height: 1rem;
      svg {
        stroke: #8c8a97;
        color: #8c8a97;
        margin-right: 0.5rem;
        vertical-align: text-bottom;
        vertical-align: -webkit-baseline-middle;
        font-size: 20px;
        width: 20px;
      }
      .label {
        vertical-align: text-bottom;
        vertical-align: -webkit-baseline-middle;
      }
    }
  }
  .copiedToast {
    position: absolute;
    left: 1rem;
    top: 1rem;
    z-index: 1;
    max-width: 100px;
    pointer-events: none;
    & > * {
      padding: 0.25rem 0.75rem;
    }
  }
  .multipleDropdowns {
    display: flex;
  }
}

.performanceActions {
  // I want even margins on this page. The title has a left-margin of 1.7rem and right margin of 5px
  margin-right: calc(#{$side-margin} - 5px);
  display: flex;
  flex-direction: row;
  align-items: center;
  & > * {
    margin-left: 1rem;
  }
  .branchPicker {
    width: 80px;
  }
  .compare {
    font-size: 0.7rem;
    padding: 0;
  }
}
.performance {
  flex: 1;
  display: flex;
  flex-direction: column;
  .notAllowed {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
    text-align: center;
  }

  .editControls {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 0.5rem;
    & > * {
      margin-top: 0.5rem;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }

    .checkboxBox {
      display: flex;
      flex-direction: row;
      align-items: center;
      & > *:last-child {
        margin-left: 0.5rem;
        padding-top: 2px;
      }
    }
    .relativePicker {
      background-color: white;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0.25rem 0.75rem;
      border: 1px solid #e6eaee;
      border-radius: 5px;
      .label {
        margin-right: 10px;
      }
    }
  }

  .globalKpiPicker {
    margin-left: 1rem;
    font-size: 16px;
    color: #4e4e50;
    font-weight: normal;
    transition: color 0.12s ease;
    position: relative;
    &:after {
      content: " ";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: #cccccc;
      transition: background-color 0.12s ease;
    }

    &:hover {
      color: $primary;
      &:after {
        background-color: $primary;
      }
    }
  }
  .kpiPickerConfigContainer {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    & > * {
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
    }
    .label {
      font-weight: 500;
    }
  }
  .miscControlContainer {
    margin: 1rem $side-margin;
    padding: 1rem;
    background-color: white;
    & > *:not(:last-child) {
      border-bottom: 2px solid #e6eaee;
    }
  }
  .overviewConfig {
    margin-top: 1rem;
    padding: 0 0.5rem 0.5rem;
    border: 1px solid #e6eaee;
    border-radius: 5px;

    background-color: #f1f2f6;
    .bigLabel {
      font-weight: 500;
    }
    .overviewToggle {
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 1px solid #e6eaee;
      border-radius: 5px;
      padding: 0.5rem 0.75rem;
      background-color: white;
      .label {
        margin-right: 0.5rem;
        padding-top: 1px;
      }
    }
  }
}
.performanceKpiPickerPopover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px;
  max-width: unset;
  .popover-body {
    white-space: nowrap;
    .kpiPicker {
      margin-right: 0;
    }
  }
}
.performanceHeatmapGlossaryPopover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px;
  pointer-events: none;
  max-width: 350px;
  .glossaryItem {
    font-size: 0.8rem;
    line-height: 0.9rem;
    margin-bottom: 0.25rem;
    *:first-child {
      font-weight: 500;
      text-decoration: underline;
      margin-right: 0.25rem;
    }
  }
}

.performance2GlossaryModal {
  .section {
    .header {
      color: #130d2d;
      font-weight: 500;
      font-size: 14px;
    }
    p {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
.streamingPerformanceMatchedResponsesDownloadModal {
  .row {
    margin: 0 0.5rem 1rem;
    & > *:not(:first-child) {
      margin-left: 0.5rem;
    }
  }
  .blurb {
    margin: 0 0.5rem 1rem;
    .pleaseNote {
      color: #ee0000;
      font-weight: 500;
    }
  }
  .downloadButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    .BPMSpinner {
      margin-left: 0.5rem;
    }
  }
}
.performanceGlobalKpiPickerPopover {
  max-width: unset;
}
.kpiPickerContainer {
  margin-left: 1rem;
  margin-bottom: -0.5rem;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  & > * {
    margin-bottom: 0.5rem;
  }
}

.eBayExportImportButtons {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: space-between;
}

.ebayPageContainer {
  padding-left: 1rem;
  padding-right: 1rem;
  scroll-behavior: auto;
  .tableButton {
    height: 80%;
    width: 70%;
    font-size: smaller;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
  .eBayCampaignsTable {
    min-height: 30rem;
    min-width: 0px;
    height: 100%;
    .tableHeader {
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      .tableName {
        font-size: 24px;
      }
    }
  }
  .eBayAdGroupsTable {
    min-height: 30rem;
    min-width: 0px;
    height: 100%;
    .tableHeader {
      flex-direction: row;
      display: flex;
      justify-content: space-between;
    }
  }
  .eBayKeywordsTable {
    min-height: 30rem;
    min-width: 0px;
    height: 100%;
    .tableHeader {
      flex-direction: row;
      display: flex;
      justify-content: space-between;
    }
  }
}

@import "../../../utils/colors.scss";

.DPPresets {
  .standardPresets {
    display: flex;
    border-bottom: solid 1px gray;
    padding-bottom: 5px;
    .datePresetOptionsContainer {
      display: flex;
      flex-direction: column;
    }
  }
  .customPresets {
    padding-top: 5px;
  }
  .controlButton {
    border-radius: 0;
    .bf-label {
      font-weight: 400;
    }
    &.datePresetButton {
      .bf-label {
        width: 112px;
        text-align: start;
      }
    }
    &.selected {
      background-color: $Brand25;
    }
  }
  &.compare {
    flex-direction: column;
  }
}

.overlayTriggerContainer {
  position: fixed;
  z-index: 10000000000;
  .tooltip {
    opacity: 1;
    position: unset;
    .tooltip-inner {
      max-width: 500px;
      width: max-content;
    }
  }
  .popover {
    position: unset;
    .arrow {
      right: 0;
      margin: 0;
    }
  }
}

.overlayTriggerBackground {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000000000;
  cursor: pointer;
}

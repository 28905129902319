.segmentationMappingPage {
  display: flex;
  flex: 1;

  .fullPageRouter {
    display: flex;
    flex: 1;
    height: 100%;
    width: 100%;
  }
}

.ntb-page {
  margin: 1.2rem;
  margin-top: 15px;

  .basic-card {
    display: flex;
    width: 100%;
  }
  .ntb-page-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    .back-arrow {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }

  .ntb-row {
    display: flex;
    gap: 20px;
    margin-bottom: 30px;
  }

  .row-header {
    display: flex;
    font-size: 20px;
    font-weight: 500;
    gap: 20px;
    margin-bottom: 15px;
    width: 100%;

    .row-header-text {
      width: 100%;
    }
  }

  .table-wrapper {
    display: flex;
    height: 600px;
    flex: 1 1;
    width: 50%;
  }
}

// aligns bar chart legend color block with text
.basic-card.bar-chart .recharts-surface {
  margin-bottom: 3px;
}

.graph-wrapper {
  width: 100%;
}

@media only screen and (max-width: 1679px) {
  .ntb-page .ntb-row {
    flex-direction: column;
  }

  .ntb-page .graph-wrapper,
  .ntb-page .table-wrapper {
    flex: unset;
    width: 100%;
  }
}

.stageOneDiagnostics {
  .chartsContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    & > * {
      display: flex;
      flex: 1;
    }
    .impulseResponseCurve {
      overflow: hidden;
    }
  }
}

.stageOneDiagnosticsSummary {
  .summaryTitle {
    text-align: center;
    margin-top: 1rem;
  }
  .summaryStatistics {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      width: 250px;
      margin: 0.5rem 1rem;
      box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.2);
      border: 1px solid rgba(0, 0, 0, 0.9);
      font-weight: 500;
      .summaryLabel {
        font-weight: normal;
        margin-right: 0.25rem;
      }
    }
  }
}

.noStageOneData {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
}

.modal-footer {
  display: flex;
  flex: 1;
  flex-direction: row;
  .checkbox {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    .label {
      .text {
        margin-right: 2px;
      }
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 0.5rem;
    }
  }
}

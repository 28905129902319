@import "../utils/colors.scss";

.bmcContentContainer {
  flex-direction: column;
  .bmcContent {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    &.Vertical {
      flex-direction: row;
    }
    .bmcInfoSection {
      height: 19px;
      display: flex;
      flex-direction: row;
      column-gap: 12px;
      &.Vertical {
        flex-direction: column;
      }
      .bmcLabel {
        font-weight: 500;
        font-size: 0.875rem;
      }
      .bmcInfoBar {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 6px;
        background-color: $Grey30;
        border-radius: 6px;
        padding: 2px 6px 2px 12px;
        &.Vertical {
          width: 163px;
          position: relative;
          padding: 8px 24px 8px 12px;
        }
        .bmcText {
          font-weight: 400;
          font-size: 0.75rem;
        }
        .bmcTooltipIcon {
          display: flex;
          align-items: center;
          justify-content: center;
          &.Awareness {
            &.Vertical {
              position: absolute;
              left: 55px;
              bottom: 11px;
            }
          }
          &.Consideration {
            &.Vertical {
              position: absolute;
              left: 93px;
              bottom: 11px;
            }
          }
        }
      }
    }
    .bmcData {
      width: 100%;
      display: flex;
      flex-direction: row;
      height: 100%;
      &.showTable {
        padding-top: 6px;
        position: relative;
        &.Vertical {
          padding-top: 0px;
        }
      }
      .bmcGraphSection {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        padding-top: 10px;
        font-size: 0.625rem;
        color: $Grey100;
        font-weight: 400;
        .recharts-responsive-container {
          .recharts-wrapper {
            position: absolute !important;
          }
        }
        .bmcGraphTooltip {
          background-color: $White;
          box-shadow: 0px 4px 4px $Grey60;
          display: flex;
          flex-direction: column;
          font-size: 0.6875rem;
          padding: 8px 10px 12px 10px;
          font-weight: 400;
          .bmcDate {
            padding-bottom: 8px;
          }
          .bmcFocalMa,
          .bmcFocalSampleSize,
          .bmcNonFocalMa,
          .bmcNonFocalSampleSize {
            display: flex;
            flex-direction: row;
            .label {
              font-weight: 500;
            }
          }
          .bmcFocalAdvertiser {
            font-weight: 500;
            color: $Purple80;
          }
          .bmcCompetitor {
            font-weight: 500;
            color: $GreyHeat100;
            margin-top: 8px;
          }
        }
      }
    }
    .bmcLegend {
      display: flex;
      flex-direction: column;
      width: 186px;
      padding-top: 20px;
      padding-left: 27px;
    }
  }
}

.marketingInputsLegend {
  height: 300px;
  overflow: auto;
  .legendItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 6px;
    padding: 4px;
    height: 25px;
    .key {
      border-radius: 50%;
    }
    .title {
      font-size: 12px;
      font-weight: 500;
      margin-left: 4px;
      &.Impressions {
        font-style: italic;
      }
    }
  }
}

.selectionDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0px;

  .selectionTitle {
    font-weight: 500;
    margin-right: 24px;
  }

  .vendorSelect {
    width: fit-content;
  }
}

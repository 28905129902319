.reportsModal {
  .reportConfigGroup {
    display: flex;
  }

  .reportConfigLabel {
    margin-top: 7px;
    width: 150px;
  }
  .reportConfigDropdown {
    width: 150px;
  }
}

.marketingInputsOuterContainer {
  display: flex;
  flex: 1;
  width: 100%;
  .marketingInputsContent {
    display: flex;
    flex: 1;
    flex-direction: row;
    .marketingInputsChart {
      display: flex;
      flex: 1;
      flex-direction: row;
    }
    .rightOfChart {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 0.5rem;
    }
  }
}

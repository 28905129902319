@import "../utils/sassHelpers.scss";
@import "../utils/colors.scss";

.abcdActionsContainer {
  align-items: center;
  display: flex;
  gap: 0.5rem;
  .label {
    font-weight: bold;
  }
}
.abcdPageContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5vh;
  justify-content: center;
  padding: 10vh 10vw;
  .videoContainer {
    width: 60vh;
    video {
      width: 100%;
    }
  }
  .creativeInfoContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 2.5vh;
    width: 100%;
    .featureContainer {
      align-items: flex-start;
      background-color: white;
      border-radius: 6px;
      border: 2px solid $Brand100;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: center;
      padding: 2rem;
      width: 100%;
      .featureLabelContainer {
        align-items: center;
        display: flex;
        gap: 0.5rem;
        justify-content: flex-start;
        .label {
          font-weight: bold;
        }
        .checkIcon {
          color: green;
          height: 24px;
          width: 24px;
        }
      }
      .tagsContainer {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        justify-content: center;
        padding: 0px 2.5vw;
        .tagExplanationContainer {
          align-items: flex-start;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 0.25rem;
          .tag {
            font-weight: bold;
          }
        }
        .timestampsContainer {
          align-items: center;
          display: flex;
          gap: 1.5rem;
          justify-content: center;
          .timestamp {
            align-items: center;
            display: flex;
            gap: 0.1rem;
          }
        }
      }
    }
  }
}

@import "../../utils/colors.scss";

.cl-download {
  .cl-download-menu {
    &.dropdown-menu {
      border: 2px solid $Brand50;
      min-width: fit-content;
      overflow-y: auto;
      .dropdown-item {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: $Brand100;
        &:hover {
          background-color: $Brand20;
        }
      }
    }
    &.lg {
      .dropdown-item {
        height: 32px;
        padding: 0px 16px;
        font-size: 16px;
      }
    }
    &.sm {
      .dropdown-item {
        height: 28px;
        padding: 0px 12px;
        font-size: 14px;
      }
    }
  }
}

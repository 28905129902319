@import "../../StreamingCreatives/StreamingCreatives.scss";

.workspace {
  flex: 1;
  .workspaceCard {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: none;
    .workspaceCardInner {
      flex: 1;
      position: relative;
      min-height: 500px;
      .creativeCardContainer {
        @include creativeCard();
      }
    }
  }
}

.tabTitle {
  display: flex;
  .validIndicator {
    margin-left: 4px;
    color: green;
  }
}

a.nav-item.nav-link {
  border-top: none;
  border-radius: 0;
}

.creativeAllocationCheckboxContainer {
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin: 16px;
  .creativeAllocationCheckbox {
    margin-right: 8px;
  }
}

@import "../utils/colors.scss";

.segmentationLabeling {
  padding: 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 4px;

  & > * + * {
    margin-top: 1rem;
  }

  .nav {
    height: 50px;
    background: #fff;
    margin: -1rem -1rem 0;
    padding: 0.5rem 1rem;
    border-bottom: 1.5px solid var(--neutral-400, #cbd2e1);
    align-items: center;

    .nav-item {
      padding: 0 12px;

      .nav-link {
        font-weight: 600;
        padding: 0.5rem 0;
      }

      .nav-link {
        padding: 8px 4px;
        line-height: 17.6px;

        &.active {
          color: var(--brand-60, #6b2def);
          padding-bottom: 5px;
          border-bottom: 3px solid var(--brand-60, #6b2def);
        }

        &:not(.active) {
          color: var(--brand-brand-30, #b5a6ff);

          &.disabled {
            color: var(--neutral-300, #e1e6ef);
          }
        }
      }
    }
  }

  .segmentationLabelingBody {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1.5px solid var(--neutral-400, #cbd2e1);
    border-radius: 1rem;
    padding: 1rem;
    width: 100%;
    height: 100%;

    & > * + * {
      margin-top: 1rem;
    }

    .segmentationLabelingControls {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;

      .pendingChangesControls {
        display: flex;
        flex-direction: row;
        align-items: center;

        .unsavedChanges {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-right: 1.5rem;
          gap: 0.5rem;

          .unlabeledCounter {
            font-weight: 500;

            .unsavedCount {
              color: $Warning600;
            }
          }

          button.danger:not(:disabled) {
            border-color: $Warning600;
            color: $Warning600;
          }
        }
      }
    }

    .filterBarContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      z-index: 5;
      gap: 2rem;

      .pendingChangesControls {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .BPMFilterBarComponentContainer {
        flex: 1;

        .filterBarComponent {
          flex: 1;
          width: auto !important;

          & > *:not(.filterBarHelp) {
            width: 100% !important;
          }
        }
      }
    }
  }
}
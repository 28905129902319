.bpm {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  .bpmRouter {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    overflow: auto;
  }
  .main {
    flex: 1;
    height: 100%;
    position: relative;
    overflow-y: auto;
  }
}

@use "sass:math";
@import "../utils/colors.scss";
@import "../utils/sassHelpers.scss";

.BPMTable.incrementalityPlaybookTable {
  .stickyHeader {
    .stickyTableCell {
      background: var(--brand-brand-70, #5200ce);
      padding: 8px 16px;
      span {
        color: var(--White, #fff);
        font-feature-settings: "clig" off, "liga" off;
        font-family: Graphik;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        width: 100%;
      }
    }
    span::after {
      display: none !important;
    }
  }
  .STActualGrid {
    .stickyTableCell {
      background-color: #ffffff;
      border-right: 1px solid var(--neutral-600, #5f6c84);
      border-bottom: 1px solid var(--neutral-600, #5f6c84);
      min-width: 0px;
      min-height: 0px;
      > * {
        display: flex;
        min-width: 0px;
        min-height: 0px;
        height: 100%;
        width: 100%;
      }
    }
    .channelNames,
    .platforms,
    .experimentOptions,
    .experimentModes,
    .serviceModels,
    .effortScores,
    .basicTextIcons {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 0px;
      justify-content: center;
      > * {
        border-top: 1px solid var(--neutral-600, #5f6c84);
        min-height: 69px;
        width: 100%;
      }
      .single,
      .first {
        border-top: 1px solid rgba(0, 0, 0, 0);
      }
    }
    .channelNames {
      border-left: 1px solid var(--neutral-600, #5f6c84);
      .channelName {
        align-items: center;
        display: flex;
        gap: 12px;
        justify-content: flex-start;
        padding: 19px 16px;
        & img {
          height: 30px;
          width: 30.8px;
        }
        .label {
          align-items: center;
          color: var(--brand-brand-100, #1f003f);
          display: flex;
          font-family: Graphik;
          font-feature-settings: "clig" off, "liga" off;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          justify-content: flex-start;
          line-height: normal;
        }
      }
    }
    .platforms {
      .platform {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 14px 32px;
        img {
          height: 40px;
          width: 40px;
        }
      }
    }
    .experimentOptions {
      .experimentOption {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 8px 16px;
        .experimentName {
          align-items: center;
          color: var(--brand-brand-50, #8254ff);
          cursor: pointer;
          display: flex;
          font-family: Graphik;
          font-feature-settings: "clig" off, "liga" off;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          justify-content: flex-start !important;
          line-height: normal;
          overflow: hidden;
          text-align: left;
          text-overflow: ellipsis;
          width: 100%;
        }
        .pocContainer {
          align-items: center;
          display: flex;
          justify-content: flex-start;
          gap: 12px;
          width: 100%;
          .pocLabel {
            align-items: center;
            color: var(--brand-brand-100, #1f003f);
            display: flex;
            font-family: Graphik;
            font-feature-settings: "clig" off, "liga" off;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            justify-content: center;
            line-height: 100%; /* 12px */
          }
          .poc {
            color: var(--brand-brand-100, #1f003f);
            cursor: pointer;
            font-family: Graphik;
            font-feature-settings: "clig" off, "liga" off;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 12px */
            text-decoration-line: underline;
          }
        }
      }
    }
    .experimentModes,
    .serviceModels {
      .experimentMode,
      .serviceModel {
        align-items: center;
        display: flex;
        gap: 12px;
        justify-content: flex-start;
        padding: 19px 16px;
        & img {
          height: 30px;
          width: 30.8px;
        }
        .label {
          align-items: center;
          color: var(--brand-brand-100, #1f003f);
          display: flex;
          font-family: Graphik;
          font-feature-settings: "clig" off, "liga" off;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
    .effortScores {
      .effortScore {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 14px 16px;
        img {
          height: 40px;
          width: 70px;
        }
      }
    }
    .bulletedList {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding-top: 8px;
      .basicList {
        padding-left: 26px;
        padding-right: 16px;
      }
    }
    .basicTextIcons {
      .basicTextIcon {
        align-items: center;
        display: flex;
        gap: 12px;
        justify-content: flex-start;
        padding: 19px 16px;
        & img {
          height: 30px;
          width: 30.8px;
        }
        .label {
          align-items: center;
          color: var(--brand-brand-100, #1f003f);
          display: flex;
          font-family: Graphik;
          font-feature-settings: "clig" off, "liga" off;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          justify-content: flex-start;
          line-height: normal;
        }
      }
    }
  }
}

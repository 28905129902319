.streamingGeoTargeting {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  .sidePanel {
    flex: 1;
    height: 100%;
    padding: 8px;

    .groupMenuAndAddNewGroupButton {
      display: flex;
      align-items: center;
      flex-direction: row;
      height: 42px;
      flex: 1;
      .groupMenu {
        flex: 1;
        padding-right: 7px;
        z-index: 6;
      }
    }
    .tableWhileEditing {
      display: flex;
      justify-content: center;
      padding: 8px;
    }
    .tableContainer {
      border: 1px solid #dee2e6;
      padding: 8px;
      margin: 1px;
      margin-top: 12px;
      height: calc(100% - 42px - 12px);
      .BPMTable {
        padding-top: 8px;
        flex: 1;
        height: calc(100% - 42px);
      }
      .tableTitle {
        display: flex;
        flex-direction: row;
        font-weight: 500;
        justify-content: center;
      }
    }
  }
  .rightPanel {
    display: flex;
    max-height: 100%;
    flex: 2;
    flex-direction: column;
    .topRightPanel {
      padding: 8px;
      height: 38;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      .checkBoxContainer {
        display: flex;
        flex-direction: row;
        font-weight: 500;
        .checkBoxTitle {
          padding-left: 4px;
          padding-top: 2px;
        }
      }
      .mapDirections {
        display: flex;
        justify-content: center;
        padding: 2px;
        font-weight: 500;
      }
    }
    .menuContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 10px;
      height: 80px;
    }
    .geoTargetingMap {
      flex: 2;
      padding-left: 24px;

      .dmaPath {
        height: 100%;
      }
    }
    .dmaMenu {
      flex: 1;
      padding: 8px;
    }
  }
}

.inputContainer {
  margin: 12px;
}

.editingButton {
  height: 38px;
  width: 42px;
}

@import "../../utils/colors.scss";

.effectiveModalButton {
  &::after {
    display: none;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: none;
  background-color: #cbd2e1;
  color: $Brand100;
  padding: 0px 6px;

  &:focus {
    box-shadow: none !important;
  }
  &.primary {
    &.light {
      background-color: $Brand50;
      color: $White;
      &.hover:not(.disabled),
      &:hover:not(.disabled) {
        background-color: $Brand70;
      }
    }
    &.dark {
      background-color: $White;
      color: $Brand60;
      &.hover:not(.disabled),
      &:hover:not(.disabled) {
        background-color: $Brand20;
        color: $Brand70;
      }
    }
  }
  &.secondary {
    &.light {
      background-color: $Neutral200;
      color: $Brand100;
      &.disabled {
        color: $Brand100;
      }
      &.hover:not(.disabled),
      &:hover:not(.disabled) {
        background-color: $Neutral400;
        color: $Brand100;
      }
    }
    &.dark {
      background-color: $Brand60;
      color: $White;
      &.hover:not(.disabled),
      &:hover:not(.disabled) {
        background-color: $Brand50;
      }
    }
  }
  .expand-icon {
    display: flex;
    width: 22px;
    height: 22px;
    font-size: 22px;
  }
  &.sm {
    height: 28px;
  }
  &.lg {
    height: 32px;
  }
}

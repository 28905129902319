@import "../utils/sassHelpers.scss";

.graph-bar:hover {
  fill: yellow;
}

.amc-stacked-bar-chart,
.amc-line-chart {
  .custom-tooltip {
    background-color: $redesign-background-color;
    border-radius: 10px;

    .label {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      color: white;
      font-size: 12px;
      font-weight: 500;
      padding: 5px 10px;
    }

    .description {
      align-items: center;
      font-size: 12px;
      font-weight: 400;
      padding: 10px;
    }
  }
}

.chart-wrapper {
  background: linear-gradient(0deg, #ffffff, #ffffff), #fffbfe;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}

.chart-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  margin: 15px 15px 0 0;
  padding-bottom: 5px;

  button {
    padding: 5px;
  }
}

.amc-horizontal-bar-chart {
  .legend-wrapper {
    display: flex;
    align-items: center;
    margin: 16px 0;
  }

  .legend {
    display: flex;
    align-items: center;
  }

  .legend-color {
    width: 32px;
    height: 16px;
    margin-right: 8px;
  }
}

@use "sass:math";

@import "../utils/sassHelpers.scss";

$stripe-width: 6px;
$border-color: rgba(0, 0, 0, 0.4);

.streamingNetworkListContainer {
  display: flex;
  flex: 1;
  padding: $page-padding;
  flex-direction: column;
  min-height: 0;
  .stripe {
    position: absolute;
    top: 0;
    bottom: 0px;
    left: 0;
    width: $stripe-width;
    background-color: rgba(0, 0, 0, 0.3);
  }

  .filterBarContainer {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    .addNetworkButton {
      margin-right: 0.5rem;
    }
  }

  .contentContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    overflow: auto;

    .streamingNetworkList {
      display: flex;
      flex-direction: column;
      border: 1px solid $border-color;
      min-height: 0;
      overflow: auto;
      &:not(.expanded) {
        flex: 1;
      }
      .networkRow {
        position: relative;
        height: 70px;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding: 0.5rem;
        background-color: white;

        cursor: pointer;

        &:not(:last-child) {
          border-bottom: 1px solid $border-color;
        }
        &:hover {
          box-shadow: inset 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
        }
        &.expanded {
          box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.3);
          z-index: 10;

          border-top: math.div($stripe-width, 2) solid black;
          border-bottom: math.div($stripe-width, 2) solid black;
        }
        &:not(.expanded) {
          border-bottom-color: $border-color !important;
        }
        .networkLogo {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50px;
          height: 50px;
          margin-left: $stripe-width;
          & > img {
            width: 100%;
          }
        }
        .networkName {
          margin-left: 1rem;
          font-size: 24px;
          .networkShortCode {
            margin-left: 2px;
            font-size: 15px;
          }
        }
      }
    }
    .networkViewContainer {
      display: flex;
      &.expanded {
        flex: 2;
      }
      .streamingNetworkView {
        background-color: white;
        display: flex;
        min-width: 600px;
        flex: 1;
        padding: 1rem 1.5rem;
        position: relative;
        border: 1px solid $border-color;
        flex-direction: column;
        overflow: auto;
        flex-direction: column;
        &.saving {
          opacity: 0.5;
          pointer-events: none;
        }
        .form-label {
          padding-left: 0rem;
          margin-bottom: 0rem;
          font-weight: 500;
          font-size: 0.9rem;
        }

        .dropdownOptions {
          display: flex;
          .dropdownOptionRow {
            display: flex;
            .dropdownOptionItem {
              display: flex;
              flex: 1;
              flex-direction: row;
              justify-content: space-between;
            }
          }
        }

        .topHeader {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-end;
          margin-bottom: 0.5rem;
          .menuButtons {
            & > *:not(:first-child) {
              margin-left: 0.5rem;
            }
          }
          .editButtons {
            display: flex;
            flex-direction: row;
            & > *:not(:last-child) {
              margin-right: 0.25rem;
            }
          }
          .dropdown-toggle {
            width: 400px;
          }
        }
        .networkContainer {
          display: flex;
          flex-direction: column;
          flex: 1;
          min-height: 0;
          overflow: auto;
          margin-right: -1.5rem;
          padding-right: 1.5rem;
          margin-bottom: -1rem;
          padding-bottom: 1rem;

          .attributesTab {
            display: flex;
            flex: 1;
            .leftSide {
              display: flex;
              flex: 1;
              border-right: 1px solid black;
              padding-right: 0.5rem;
              flex-direction: column;
              .propertyDropdown {
                margin-bottom: 1rem;
              }

              .table {
                flex: 1;
              }

              .stickyTableCell {
                justify-content: flex-start;
                cursor: pointer;
                & > * {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  padding-left: 1rem;
                  & > * {
                    width: 100%;
                  }
                }
              }

              .selectedRow {
                background-color: #d4bdf2 !important;
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
              }
            }
            .rightSide {
              display: flex;
              flex: 1;
              padding-left: 0.5rem;
              flex-direction: column;
              justify-content: flex-start;
              .attributesList {
                display: flex;
                flex: 1;
                flex-direction: row;
                flex-wrap: wrap;
                overflow: auto;
                align-content: flex-start;

                .attribute {
                  display: flex;
                  justify-content: flex-start;
                  margin-right: 0.5rem;
                  margin-bottom: 0.5rem;
                  flex-basis: 150px;
                  height: 100px;

                  &.select {
                    flex-basis: 200px;
                  }
                  & > * {
                    display: flex;
                    flex-direction: column;
                  }
                }
                .defaultText {
                  display: flex;
                  flex: 1;
                  justify-content: center;
                  align-items: center;
                  font-weight: 500;
                }
              }
              .addNewAttribute {
                display: flex;
                flex-direction: row;
                & > * {
                  margin-right: 0.5rem;
                }
                .dropdown {
                  width: 200px;
                }
              }
            }
          }

          .contentDetailsTab {
            display: flex;
            flex: 1;
            .leftSide {
              display: flex;
              flex: 1;
              border-right: 1px solid black;
              padding-right: 0.5rem;
              flex-direction: column;
              .propertyDropdown {
                margin-bottom: 1rem;
              }

              .table {
                flex: 1;
              }

              .stickyTableCell {
                justify-content: flex-start;
                cursor: pointer;
                & > * {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  padding-left: 1rem;
                  & > * {
                    width: 100%;
                  }
                }
              }

              .selectedRow {
                background-color: #d4bdf2 !important;
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
              }
            }
            .rightSide {
              display: flex;
              flex: 2;
              padding-left: 0.5rem;
              flex-direction: column;
              justify-content: flex-start;
              .attributesList {
                display: flex;
                flex: 1;
                flex-direction: row;
                flex-wrap: wrap;
                overflow: auto;
                align-content: flex-start;

                .attribute {
                  display: flex;
                  justify-content: flex-start;
                  margin-right: 0.5rem;
                  margin-bottom: 0.5rem;
                  flex-basis: 150px;
                  height: 100px;

                  &.select {
                    flex-basis: 200px;
                  }
                  & > * {
                    display: flex;
                    flex-direction: column;
                  }
                }
                .defaultText {
                  display: flex;
                  flex: 1;
                  justify-content: center;
                  align-items: center;
                  font-weight: bold;
                }
              }
              .addNewAttribute {
                display: flex;
                flex-direction: row;
                & > * {
                  margin-right: 0.5rem;
                }
                .dropdown {
                  width: 200px;
                }
              }
            }
          }

          .simpleCheckboxLine {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 1rem;
            & > *:not(:first-child) {
              margin-left: 0.5rem;
            }
          }
          .networkNotesContainer {
            display: flex;
            flex: 1;
            .networkNotes {
              display: flex;
              flex-direction: column;
              flex: 1;
              .networkNotesFormLabel {
                margin-bottom: 0.25rem;
              }
              .notesTextArea {
                display: flex;
                flex: 1;
              }
            }
          }
          .networkInfo {
            .editButtons {
              display: flex;
              flex: 1;
              flex-direction: row;
              & > * {
                margin-right: 0.25rem;
              }
            }
            .checkboxGroup {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              .checkboxLabel {
                font-size: 0.9rem;
              }
            }
            .dspList {
              & > *:not(:last-child) {
                margin-right: 0.25rem;
                &::after {
                  content: ",";
                }
              }
            }
            .helperButton {
              font-size: 0.7rem;
            }
            .form-label {
              text-decoration: underline;
            }
            .form-control-plaintext {
              padding-top: 0;
            }
            .platformList {
              display: flex;
              flex-direction: column;
              margin-bottom: 0.25rem;
              .platformListRow {
                display: flex;
                .platformListItem {
                  display: flex;
                  flex: 1;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                }
                .platformListItemNew {
                  display: flex;
                  flex: 1;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                  & > *:not(:last-child) {
                    margin-right: 0.5rem;
                  }
                }
              }
            }
            .tooltipContainer {
              vertical-align: middle;
              margin-left: 0.25rem;
            }
          }
          .contactsInfo {
            display: flex;
            flex-direction: column;
            flex: 1;
            font-size: 0.8rem;
            .platformName {
              margin-bottom: 0.5rem;
              font-weight: 500;
              font-size: 1.1rem;
            }
            .contactsTable {
              table-layout: fixed;
              width: 100%;
              td {
                overflow-wrap: break-word;
              }
              .firstName {
                width: 120px;
              }
              .lastName {
                width: 120px;
              }
              .primary {
                width: 75px;
              }
              .delete {
                width: 75px;
              }
            }
          }
          .globalDescriptionsList {
            display: flex;
            .globalDescriptionRow {
              display: flex;
              flex-direction: row;
              .deleteDescription {
                margin-left: 0.5rem;
              }
            }
          }
          .propertiesTable {
            width: 100%;
            td {
              overflow-wrap: break-word;
            }
            .propertyBuyable {
              width: 85px;
            }
          }
        }
      }
    }
  }
}

.month-picker-row {
  flex-wrap: wrap;
  font-size: 15px;
  font-weight: 400;
  position: relative;
}

.month-picker-row .dd-menu {
  min-width: max-content;
}

.month-picker-info-text {
  display: flex;
  font-size: 11px;
  font-weight: 500;
  position: relative;
  top: 5px;
}

.month-picker-info-text .material-icons-outlined {
  color: #546b86;
  font-size: 16px;
  margin-right: 5px;
}

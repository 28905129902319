@import "../utils/sassHelpers";
@import "../utils/colors";

$titleLine: #94a0b8;
$bodyLine: #3f444d;

.lineAtBottom:not(:last-child)::after {
  content: "";
  display: block;
  height: 0.5px;
  background-color: $bodyLine;
  width: 95%;
  position: relative;
  left: 2.5%;
}

.lineAtBotBase {
  content: "";
  display: block;
  position: relative;
  background-color: $Neutral500;
}

.lineAtBot1 {
  @extend .lineAtBotBase;
  height: 1.5px;
  background-color: $Neutral400;
}

.lineAtBot2 {
  @extend .lineAtBotBase;
  height: 1px;
  margin-left: 16px;
  margin-right: 16px;
}

.lineAtBot3 {
  @extend .lineAtBotBase;
  height: 0.75px;
  background-color: $Neutral400;
  margin-left: 32px;
  margin-right: 32px;
}

.lineAtBot4 {
  @extend .lineAtBotBase;
  height: 0.5px;
  margin-left: 48px;
  margin-right: 48px;
}

.dashboardChartContainer {
  margin-top: 16px;
  background-color: white;
  border-radius: 16px;
  border: 1.5px solid $Neutral400;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;

  .chartsContainerTitle {
    display: flex;
    border-bottom: 2px solid $titleLine;
  }

  .chartsContainerBody {
    display: flex;
    position: relative;
  }

  .chartsContainerBodyHeader {
    margin-left: 2%;
    margin-top: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .containerBodyHeaderTitle {
      font-weight: 600;
      font-size: 1.25rem;
      color: $Brand100;
    }
  }

  .hidden {
    display: none;
  }

  .simpleBarChart {
    width: 85%;
    height: 100%;
    padding: 16px;
    gap: 10px;
    .recharts-surface {
      height: 75px;
    }
  }

  .buttonGraph {
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
    width: 10%;
    .detailsButton {
      font-family: Graphik;
      background-color: #f1f3f9;
      color: black;
      font-weight: 500;
      border: none;
      border-radius: 3px;
      padding: 6px 10px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      margin-right: 40px;

      &:hover {
        background-color: darken(#f1f3f9, 10%);
      }

      svg {
        margin-right: 8px;
      }
    }
  }

  .takeSpace {
    flex-grow: 1;
    width: 100%;
  }
}

.pacingModal .modal-content {
  border: 4px solid $Brand60;
  .close {
    color: $Brand70;
  }
}

.pacingModalHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  color: $Brand100;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.pacingModalHeaderButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

@import "../../styles/Fonts.scss";
@import "../../utils/colors.scss";

.snapshotChartContainer {
  .chartContainer.enableHoverDesign {
    height: 405px;

    .header {
      align-items: flex-start;
    }

    .contents {
      display: flex;
      flex-direction: column;
      height: 405px;
      overflow: auto;
      padding-top: 0px;
    }
  }

  .leftSide {
    .headerTitle {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      max-width: 240px;
      word-wrap: break-word;
    }
  }

  .rightSide {
    gap: 0px;
    align-self: flex-start;
  }

  .snapshotGraphContents {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: scroll;
    overflow-x: hidden;
    position: relative;
    top: 3px;

    .snapshotGraphHeader {
      color: $Neutral600;
      margin-bottom: 16px;
    }

    .snapshotGraphHeaderWithToolTip {
      color: $Brand100;
      font-weight: 500;
      margin-bottom: 16px;
      position: sticky;
      top: 0;
      z-index: 10;
      background-color: #fff;

      .snapShotGraphTooltipBeforeText {
        font-size: 16px;
        margin-left: 125px;
        margin-bottom: 10px;
      }
    }

    .barChartsContainer {
      display: flex;
      flex-direction: row;
      width: 100%;

      .leftOfChart {
        display: flex;
        flex-direction: column;
        position: relative;
        top: 1px;
      }

      .legend {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2px;
        margin-right: 16px;
        overflow: hidden;
        width: 200px;
        height: 55px;

        ~ .legend {
          margin-top: 2px;
        }

        .itemLabel {
          @include bodyRegular;
          display: flex;
          justify-content: space-between;
          margin-right: 16px;
          overflow: hidden;
          flex: 2;
          font-size: 14px;
        }

        .itemValueWrapper {
          display: flex;
          flex-direction: column;

          .itemValue {
            @include bodyThin;
            display: flex;
            flex: 1;
            justify-content: flex-end;
            color: $Brand100;
            font-size: 14px;
          }
        }

        &.hasComputedValue {
          width: 320px;

          .itemLabel,
          .itemComputedValue,
          .itemValueWrapper .itemValue {
            align-items: center;
            margin-bottom: 0.5rem;
          }

          .itemLabel {
            flex: 3 0;
            color: $Brand100;
            font-size: 16px;
            font-weight: 600;
          }

          .itemComputedValue {
            display: flex;
            flex: 1 0;
            justify-content: flex-end;
            margin-right: 1rem;
            font-size: 16px;
            font-weight: 400;
            color: $Brand100;
          }

          .itemValueWrapper {
            flex: 1 0;

            .itemValue {
              color: $Neutral600;
              font-size: 12px;
              font-weight: 400;
            }
          }
        }
      }

      .snapshotBarContainer {
        align-items: center;
        display: flex;
        flex-direction: row;
        height: 100%;
        position: relative;
        top: -5px;
        width: 100%;

        .snapshotBarLabel {
          @include tertiaryRegular;
        }
      }
    }
  }
}
.data-card {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  padding: 15px;
  padding-top: 10px;
  width: 100%;

  .capitalize-first::first-letter {
    text-transform: uppercase;
  }

  .data-card-data {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
  }

  .data-card-title {
    color: #485d75;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 5px;
  }
  .data-card-value {
    font-size: 18px;
    font-weight: 500;
    margin: auto;
  }
}

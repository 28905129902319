@import "../../utils/colors.scss";

.budgetIntakeToolModalEditTable {
  flex: 1;
  display: flex;
  flex-direction: column;
  .tableFooter {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    flex-direction: row;
    gap: 16px;
    padding: 16px 16px 16px 0;
    border-top: 2px solid $Neutral400;
  }

  .deleteButton {
    margin-right: 10px;
  }

  .modalEditTableCell {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .modalEditTableCustomRendererCell {
    width: 100%;
  }

  .outerTableContainer {
    flex: 1;
  }
}

.alertContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  .invalidText {
    width: fit-content;
    block-size: fit-content;
  }
}

.modalHeaderLabel {
  display: flex;
  align-items: center;

  .infoIcon {
    margin-left: 4px;
  }
}

.modal-edit-text-area {
  height: 200px !important;
}

.infoTextParagraph {
  margin: 8px;
}

.inlineFormButton {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.modalRow {
  .SingleDatePicker {
    .SingleDatePickerInput {
      padding-left: 1px;
      border-radius: 4px;
      border: 1px solid hsl(0, 0%, 80%);
      background-color: hsl(0, 0%, 100%);
    }
  }
}

@import "../../utils/colors.scss";

.simpleTooltipText {
  display: flex;
  font-weight: 400;
  font-size: 0.75rem;
  background-color: $Grey80;
  color: $White;
  border-radius: 4px;
  box-shadow: 0px 4px 4px $Grey60;
  width: max-content;
  text-align: center;
  /* Position the tooltip text */
  position: absolute;
  z-index: 100;
  padding: 6px 9px;
  /* Tooltip arrow */
  &::after {
    content: "";
    position: absolute;
    border-width: 6px;
    border-style: solid;
    right: 45%;
    top: 100%;
    border-color: $Grey80 transparent transparent transparent;
  }
  &.bottom {
    &::after {
      top: -11px;
      border-color: transparent transparent $Grey80 transparent;
    }
  }
}

.streamingExperimentsActions {
  display: flex;
  flex-direction: row;
  & > * {
    margin-right: 0.5rem;
  }
}

.experimentsModal {
  .inputTitle {
    font-weight: 500;
  }

  .lagRow {
    .lagFormGroup {
      .lagBtn {
        margin-left: 15px;
      }

      .input-group {
        width: 100%;
        display: flex;
        flex-direction: row;

        .defaultLagArray {
          margin-top: 5px;
          margin-left: 50px;

          .lagItem {
            display: flex;

            .lagNumber {
              margin-left: 5px;
            }
          }
        }

        .customLag {
          display: flex;
          margin-left: 10px;

          .form-label {
            min-width: 120px;
            padding-top: 7px;
          }
        }
      }
    }
  }

  .psaFormGroup {
    display: flex;

    .BPMCheckBox {
      margin-left: 10px;
    }
  }

  .experimentsInputsRowOne {
    display: flex;

    .form-label {
      padding-top: 7px;
    }

    .form-group {
      display: flex;

      .SingleDatePicker {
        margin-bottom: 8px;
      }
    }
  }

  .kpiRow {
    .kpiNameFormGroup {
      display: flex;
      flex-direction: column;

      .kpiGroupList {
        width: 100%;

        .form-label {
          min-width: 150px;
          padding-top: 10px;
        }
        .form-control {
          margin-top: 5px;
        }
      }

      .input-group {
        margin-top: 10px;

        .btn {
          margin-left: 5px;
        }
      }

      .kpiPicker {
        margin-top: 2px;
      }
    }

    .inputTitle {
      display: flex;

      .btn {
        margin-left: 15px;
      }
    }

    .kpiGroup {
      display: flex;
      flex-direction: column;

      .kpiGroupHeader {
        display: flex;
        flex-direction: row;
      }
    }
  }
}

.streamingExperimentsPage {
  display: flex;
  flex: 1;
  flex-direction: column;

  .noExperimentsText {
    font-size: large;
    margin-left: 0.5rem;
    margin-top: 0.5rem;
  }

  .kpiLabel {
    min-width: 120px;
  }

  .experimentTitle {
    font-size: larger;
  }

  .failedExperimentInfo {
    font-size: large;
  }

  .experimentHeader {
    font-weight: 500;
    display: flex;
  }

  .networkLogo {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    img {
      height: 90%;
    }
    .networkImageUnloader {
      font-weight: 500;
    }
  }

  .comboTableChart {
    display: flex;
  }

  .chartData {
    min-height: 100%;
    flex: 1;
    display: block;

    .errorBarsExplainer {
      text-align: center;
      margin-left: 90px;
      font-size: smaller;
      position: absolute;
      bottom: 0px;
    }
  }

  .table-striped {
    flex: 2;
  }

  .experimentInfo {
    font-size: large;
  }

  .experimentInfoWrapper {
    padding-bottom: 20px;
  }

  .infoButton {
    margin-left: 10px;
    margin-right: 10px;
    border: none;
    padding: 0px;
  }

  .experimentInfoOverall {
    font-size: large;
    padding-bottom: 30px;
  }

  .expander {
    float: right;
  }

  .expander {
    float: right;
  }

  .logs {
    float: right;
    margin-right: 0.5rem;
  }

  .downloadCsv {
    float: right;
    margin-right: 0.5rem;
  }

  .refresh {
    float: right;
    margin-right: 0.5rem;
  }

  .delete {
    float: right;
    margin-right: 0.5rem;
  }

  .edit {
    float: right;
    margin-right: 0.5rem;
  }

  .pvalueGreen {
    color: green;
  }

  .custom-tooltip {
    background-color: white;
    border-color: gray;
    border-width: 1px;
    border-style: solid;
    padding: 4px;
    max-width: 400px;
    .label {
      font-weight: 500;
    }
    .elem {
      display: flex;
      .name {
        font-weight: 500;
        margin-right: 6px;
      }
    }
  }
}

@import "../../utils/colors.scss";
@import "../../styles/Fonts.scss";

.revenueImpact {
  display: flex;
  gap: 16px;
  .revenueImpactLeft {
    display: flex;
    flex-direction: column;
    flex: 3;
    gap: 16px;
    min-width: 0px;
    .barChartHoldings {
      display: flex;
      flex-direction: column;
      color: $Brand100;
      .titleContainer {
        display: flex;
        flex-direction: row;
        .titleText {
          font-weight: 600;
          font-size: 1rem;
        }
        .mText {
          font-weight: 400;
          font-size: 0.875rem;
          align-self: flex-end;
        }
      }
      .topSegment {
        width: 0.0625rem;
        height: 0.5rem;
        background: #000;
        margin-left: 3.3rem;
      }
      .topOfBarchartLine {
        height: 0.5rem;
        width: 96.75%;
        align-self: stretch;
        border-top: 0.0625rem solid #000;
        border-right: 0.0625 solid #000;
        border-left: 0.0625rem solid #000;
      }
    }
    .barChartLegend {
      display: flex;
      flex-direction: row;
      gap: 2rem;
      .topRow {
        display: flex;
        flex-direction: row;
        gap: 0.38rem;
        .bar {
          width: 6px;
          height: 20px;
        }
        .barOne {
          @extend .bar;
          background-color: #390094;
        }
        .barTwo {
          @extend .bar;
          background-color: $Brand50;
        }
        .barThree {
          @extend .bar;
          background-color: $Neutral300;
        }
        .titleText {
          font-weight: 600;
          font-size: 1rem;
        }
        .mText {
          margin-left: -0.38rem;
          font-weight: 400;
          font-size: 0.875rem;
          align-self: flex-end;
        }
      }
      .bottomRow {
        margin-left: 0.8rem;
      }
    }

    .chartContainer {
      margin: 0;
      .headerText {
        @include headingThin;
      }
      .contents {
        display: flex;
        padding-bottom: 0;
      }
      .rightOfChart {
        color: $Brand100;
        padding-bottom: 16px;
        padding-right: 2px;
        width: 200px;
      }
    }
  }

  .revenueImpactRight {
    width: 30%;
    .chartContainer {
      margin: 0;
      &.enableHoverDesign {
        height: 100%;
      }
    }
    .takeAwaysContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 1rem;
      flex: 1 0 0;
      align-self: stretch;
      margin-top: 2.2rem;
      margin-right: 1.5rem;
      .sectionOne {
        display: flex;
        padding: var(--24, 1.5rem) 1rem;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        align-self: stretch;
        background: #e8e5ff;
        border-radius: 8px;
        .sectionOneTextContainer {
          display: flex;
          padding: var(--0, 0rem) 0.4375rem;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          align-self: stretch;
          .textLeft {
            display: flex;
            flex-direction: column;
            font-family: Graphik;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          .textMiddle {
            width: 2.32538rem;
            height: 2.32538rem;
            .icon {
              width: 100%;
              height: 100%;
            }
          }
          .textRight {
            display: flex;
            flex-direction: column;
            .dollarImpact {
              display: flex;
              flex-direction: row;
              .mText {
                font-weight: 400;
                font-size: 1.375rem;
                align-self: flex-end;
              }
            }
            .titleTopText {
              font-weight: 600;
              font-size: 1.5rem;
            }
          }
        }
      }
      .sectionTwo {
        display: flex;
        padding: 1rem;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        align-self: stretch;
        background: #e8e5ff;
        border-radius: 8px;
        .sectionTwoTextContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          align-self: stretch;
          .textLeft {
            display: flex;
            flex-direction: column;
            font-family: Graphik;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          .textMiddle {
            width: 2.32538rem;
            height: 2.32538rem;
            .icon {
              width: 100%;
              height: 100%;
            }
          }
          .textRight {
            display: flex;
            flex-direction: column;
            .dollarImpactBottom {
              display: flex;
              flex-direction: row;
              .mText {
                font-weight: 400;
                font-size: 1rem;
                align-self: flex-end;
              }
            }
            .titleText {
              font-weight: 600;
              font-size: 1rem;
            }
          }
        }
      }
      .sectionThree {
        display: flex;
        padding: 0.75rem var(--24, 1.5rem) var(--0, 0rem) 0.5rem;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        align-self: stretch;
        .sectionThreeText {
          font-family: Graphik;
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }
}

@import "../../utils/colors.scss";
@import "../../styles/Fonts.scss";

.left {
  display: flex;
  gap: 16px;
  .leftLeft {
    display: flex;
    flex: 3;
    gap: 16px;
    .incrementalRoasRightActions {
      display: flex;
      gap: 16px;
    }
    .chartContainer {
      margin: 0px;
      .contents {
        min-height: 360px;
      }
      .rightOfChart {
        color: $Brand100;
        padding-bottom: 16px;
        padding-right: 2px;
        width: 200px;
      }
    }
  }
  .leftRight {
    width: 30%;
    .chartContainer {
      margin: 0px;
      &.enableHoverDesign {
        height: 100%;
      }
      .contents {
        padding: 0;
        .parentIncrementalTakeaways {
          display: flex;
          flex-direction: column;
          padding: 1.5rem 1.5rem;
          .topIncremental {
            display: flex;
            flex-direction: row;
            padding: 1rem 0;
            justify-content: center;
            .topTextBox {
              gap: 0px;
            }

            .iconHolder {
              padding: 0rem 1rem;
              .icon {
                width: 100%;
                height: 60%;
              }
            }
            .topText {
              font-weight: 600;
            }
          }
          .middleIncremental {
            display: flex;
            flex-direction: row;
            padding: 1rem 0;
            justify-content: center;
            background-color: #2ae0f61f;
            border-radius: 16px;
            .middleText {
              font-size: 1.5rem;
              font-weight: 600;
              color: #0194ff;
            }
          }
          .bottomIncremental {
            display: flex;
            flex-direction: row;
            padding: 2.5rem 1.5rem 0rem 0.5rem;
            .bottomText {
              font-size: 1rem;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

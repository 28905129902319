@mixin suppressBox {
  .suppressDays {
    .suppressDaysControl {
      width: 50px;
      display: inline;
      vertical-align: middle;
    }
  }
  .becauseInput {
    margin: 0.5rem 0;
  }
  .becauseBox {
    margin-top: 1rem;
    padding: 0.5rem;
    background-color: #eeeeee;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.6);
    font-style: italic;
  }
}
.invalidRotationsReport {
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  min-width: 0;
  min-height: 0;
  & > * {
    flex: 1;
    min-width: 0;
    .dateSet {
      margin-left: 1rem;
    }
    &.rotationListView {
      display: flex;
      flex-direction: column;
      min-height: 0;
      .filterBarSection {
        display: flex;
        flex-direction: row;
        & > *:not(:first-child) {
          margin-left: 0.5rem;
        }
      }
      .rotationList {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        border-top: 1px solid rgba(0, 0, 0, 0.3);
        margin-top: 1rem;
        & > *:not(:first-child) {
          margin-top: 2rem;
          border-top: 1px solid rgba(0, 0, 0, 0.7);
        }
        .sectionHeader {
          font-size: 2rem;
        }
        .subsection {
          .subSectionHeader {
            font-size: 1.75rem;
            font-weight: 500;
            text-decoration: underline;
          }
          .placementSection {
            margin-left: 1rem;
            .placementHeader {
              font-size: 1.5rem;
            }
            .rotationListGroup {
              margin-left: 1.5rem;
              .rotationListLine {
                display: flex;
                flex-direction: column;
                .metaData {
                  display: flex;
                  flex-direction: row;
                  & > * {
                    flex: 1;
                    &:first-child {
                      display: flex;
                      flex-direction: row;
                      .pct {
                        margin-left: 1rem;
                      }
                    }
                  }
                }
                .invalidStarting {
                  flex: 1;
                  .badDate {
                    font-weight: 500;
                    background-color: yellow;
                    color: black;
                    padding: 1px 1rem;
                    &.imminent {
                      color: white;
                      background-color: red;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.problemView {
      margin-left: 1rem;
      border: 1px solid rgba(0, 0, 0, 0.5);
      padding: 1rem;
      overflow: auto;
      .infoSection {
        display: flex;
        flex-direction: column;
        font-size: 1.2rem;
        margin-bottom: 1rem;
        & > * {
          display: flex;
          flex-direction: row;
          :first-child {
            width: 120px;
          }
          :last-child {
            font-weight: 500;
            flex: 1;
          }
        }
      }
      .sectionHeader {
        font-size: 1.5rem;
      }
      .creativeRow {
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        & > * {
          &.imgBox > img {
            width: 320px;
          }
          &.dateBox {
            flex: 1;
            min-width: 0;
            margin-left: 1rem;
          }
        }
      }
      .flightRow {
        display: flex;
        flex-direction: column;
        .flightInfo {
          display: flex;
          flex-direction: row;
          & > * {
            flex: 1;
          }
        }
      }
      @include suppressBox();
    }
  }
}
.invalidRotatationReportBulkSuppressModal {
  .suppressBox {
    @include suppressBox();
  }
}

@import "../../utils/colors.scss";

.BPMToggleSwitch {
  &.filled-button {
    &.primary {
      &.light {
        color: $Brand20;
        .toggleRedesignWrapper {
          &.on {
            background-color: $White;
            .toggleRedesignBall {
              background-color: $Brand50;
            }
          }
          &.off {
            border: solid $Brand25 1.5px;
            .toggleRedesignBall {
              background-color: $Brand25;
            }
          }
        }
        &.checked {
          color: $White;
        }
        &.hover:not(.disabled),
        &:hover:not(.disabled) {
          color: $White;
          &.checked {
            .toggleRedesignWrapper {
              background-color: $Brand20;
              .toggleRedesignBall {
                background-color: $Brand70;
              }
            }
          }
        }
      }
      &.dark {
        .toggleRedesignWrapper {
          &.on {
            background-color: $Brand50;
            .toggleRedesignBall {
              background-color: $White;
            }
          }
          &.off {
            border: solid $Brand40 1.5px;
            .toggleRedesignBall {
              background-color: $Brand40;
            }
          }
        }
        &.hover:not(.disabled),
        &:hover:not(.disabled) {
          &.checked {
            .toggleRedesignWrapper {
              .toggleRedesignBall {
                background-color: $Brand20;
              }
            }
          }
        }
      }
    }
    &.secondary {
      &.light {
        color: $Neutral600;
        &.checked {
          color: $Brand100;
        }
        .toggleRedesignWrapper {
          &.on {
            background-color: $Brand60;
            .toggleRedesignBall {
              background-color: $Neutral200;
            }
          }
          &.off {
            border: solid $Neutral600 1.5px;
            .toggleRedesignBall {
              background-color: $Neutral600;
            }
          }
        }
        &.hover:not(.disabled),
        &:hover:not(.disabled) {
          color: $Brand100;
          background-color: $Neutral400;
          &.checked {
            .toggleRedesignBall {
              background-color: $Neutral400;
            }
          }
        }
      }
      &.dark {
        color: $Brand25;
        &.checked {
          color: $White;
        }
        .toggleRedesignWrapper {
          &.on {
            background-color: $White;
            .toggleRedesignBall {
              background-color: $Brand60;
            }
          }
          &.off {
            border: solid $Brand30 1.5px;
            .toggleRedesignBall {
              background-color: $Brand30;
            }
          }
        }
        &.hover:not(.disabled),
        &:hover:not(.disabled) {
          background-color: $Brand50;
          color: $White;
          &.checked {
            .toggleRedesignWrapper {
              .toggleRedesignBall {
                background-color: $Brand50;
              }
            }
          }
        }
      }
    }
  }

  // Outlined button styling only secondary dark done
  &.outlined-button {
    &.secondary {
      &.dark {
        .toggleRedesignWrapper {
          &.on {
            background-color: $White;
            .toggleRedesignBall {
              background-color: $Brand80;
            }
          }
          &.off {
            border: solid $Brand30 1.5px;
            .toggleRedesignBall {
              background-color: $Brand30;
            }
          }
        }
        &.hover:not(.disabled),
        &:hover:not(.disabled) {
          background-color: $Brand70;
          &.checked {
            .toggleRedesignWrapper {
              .toggleRedesignBall {
                background-color: $Brand70;
              }
            }
          }
        }
      }
    }
  }
  // for all sizes and styles
  .bf-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    .toggleRedesignWrapper {
      background-color: transparent;
      display: flex;
      height: 0.5em;
      width: 100%;
      border-radius: 0.5em;
      position: relative;
      align-items: center;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;

      &.on {
        justify-content: flex-end;
      }
      &.off {
        justify-content: flex-start;
        background-color: transparent;
      }
      .toggleRedesignBall {
        height: 0.25em;
        width: 0.25em;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin: 0 2px 0 1px;
      }
    }
  }
}

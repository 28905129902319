@import "../utils/sassHelpers.scss";

.dag-status {
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: $page-padding;

  .reconcile-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    &.builds .tableContainer {
      font-size: 12px;
    }
  }

  .previousBuild {
    padding-right: 5px;
    font-size: 0.7rem;
  }
}

.activeOttSpendBreakdown {
  position: relative;
}

.spendBreakdownLabel > :first-child {
  margin-right: 1rem;
  font-size: 0.9rem;
}

.spendBreakdownAmount {
  float: right;
}

.spendBreakdownDivider {
  margin: 4px;
}

.cpmSpacer {
  height: 12px;
}

// Position the info icon to the right
// of the media spend amount.
.dspInfoIcon {
  position: absolute;
  right: -16px;
  top: -2px;
}

.dspInfoQuestion {
  font-weight: 500;
}

.overlayTriggerContainer .dspInfoTooltip .tooltip-inner {
  max-width: 240px;
  width: max-content;
  text-align: left;
}

@use "sass:math";
@import "../../utils/colors.scss";

$calendarPadding: 16px;
$pickerWidth: 180px;
$fullWeeksPickerWidth: 290px;

.DPDateContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10002;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }
  .calendarBox {
    position: absolute;
    background-color: white;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    .DPheader,
    .DPfooter {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: math.div($calendarPadding, 2) 24px;
      & > *:not(:first-child) {
        margin-left: 0.5rem;
      }
    }
    .DPheader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .headerTitleText {
        font-size: 24px;
        font-weight: 600;
        color: $Brand60;
        padding: 8px 32px;
        display: flex;
      }
      .rightHeaderItems {
        display: flex;
        align-items: center;
      }
      padding-top: 16px;
      &.comparisonHeader {
        .BPMToggleSwitch {
          margin-left: 10px;
        }
        .DParrow {
          margin-left: 15px;
          color: $Brand60;
          transform: rotate(-90deg);
          &.comparisonArrowClosed {
            transform: rotate(90deg);
          }
        }
        &.compHeadDisabled {
          margin-top: -1px;
          background-color: $Neutral400;
          .headerTitleText {
            color: $Neutral600;
          }
          .compDisabledArrow {
            color: $Grey70;
            transform: rotate(90deg);
          }
          .BPMToggleSwitch {
            background-color: $Neutral400;
          }
        }
      }
    }
    .calendarAndControls {
      border-top: solid 1px gray;
      border-bottom: solid 1px gray;
      display: flex;
      .controls {
        padding: 4px 0;
        border-left: solid 1px gray;
        .singleControlContainer {
          display: flex;
          align-items: center;
          padding: 8px 24px;
          .controlSectionLabel {
            text-align: start;
            align-self: flex-start;
            font-size: 16px;
            font-weight: 500;
            width: 92px;
            .sectionLabelWrapper {
              padding: 8px;
            }
            span {
              font-weight: 400;
              font-size: 14px;
            }
          }
          .presetsContainer {
            display: flex;
            align-items: center;
            padding: 8px 24px;
            .controlSectionLabel {
              text-align: start;
              align-self: flex-start;
              font-size: 16px;
              font-weight: 500;
              width: 92px;
              .sectionLabelWrapper {
                padding: 8px;
              }
              span {
                font-weight: 400;
                font-size: 14px;
              }
            }
          }
          .controlButton {
            border-radius: 0;
            .bf-label {
              font-weight: 400;
            }
            &.datePresetButton {
              .bf-label {
                width: 112px;
                text-align: start;
              }
            }
            &.selected {
              background-color: $Brand25;
            }
          }
          &.compare {
            flex-direction: column;
          }
        }
        & > *:not(:first-child) {
          border-top: solid 1px gray;
        }
        .comparePeriodContainer {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }
    .dateDisplay {
      display: flex;
      justify-content: space-between;
      font-size: 0.9rem;
      color: #7a7a7a;
      .dateInputFieldContainer {
        margin-left: 15px;
      }
    }
    .comparisonControls {
      display: flex;
      width: 100%;
      margin-right: 0;
      margin-left: 1rem;
      justify-content: space-between;
    }
    .comparisonDisabled,
    .comparisonClosed {
      display: none;
    }
    .comHeaderWrapper {
      &.compOpen,
      &.comparisonDisabled {
        display: none;
      }
      &.compClosed {
        height: 8px;
        background-color: #cbd2e1;
        border-top: solid 1px $Neutral500;
        border-bottom: solid 1px $Neutral500;
        &.top {
          margin-top: -1px;
        }
      }
    }
  }
}

@import "../utils/colors.scss";

.mtModalEditTable {
  flex: 1;
  display: flex;
  flex-direction: column;

  .mtTableHeader {
    .mtTableActions {
      display: flex;
      flex-direction: row;
      padding-bottom: 10px;
      column-gap: 1rem;
      .demoTargetingButton {
        margin-left: auto;
      }
    }
  }

  .mtOuterTableContainer {
    .BPMSuperHeader {
      border: 1px #8c8a97 solid;
    }
  }

  .modalEditTableCell {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .modalEditTableCustomRendererCell {
    width: 100%;
  }

  .mtOuterTableContainer {
    flex: 1;
  }
}

.alertContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  .invalidText {
    width: fit-content;
    block-size: fit-content;
  }
}

.modalHeaderLabel {
  display: flex;
  align-items: center;

  .infoIcon {
    margin-left: 4px;
  }
}

.infoTextParagraph {
  margin: 8px;
}

.inlineFormButton {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.defaultGroup {
  display: flex;
  padding: 1rem;
  margin-bottom: 0rem;
  &:is(:first-child) {
    padding-top: 0rem;
  }
  &:is(:last-child) {
    padding-bottom: 0rem;
  }
}
.demoTargetingGroup,
.deviceTargetingGroup,
.geoTargetingGroup,
.mediaTypeGroup,
.dspsGroup {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-bottom: 0px;
  border: 2px solid black;
  padding: 1rem;
  border-radius: 4px;
  &:not(.dspsGroup) {
    margin-bottom: 1rem;
  }
  .demoTargetingHeader,
  .deviceTargetingHeader,
  .geoTargetingHeader,
  .mediaTypeHeader,
  .dspsHeader {
    font-weight: 500;
    font-size: large;
  }
}
.advancedTargetingGroup1 {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-bottom: 0px;
  border: 2px solid black;
  border-bottom: none;
  padding: 1rem;
  border-radius: 4px 4px 0px 0px;
  margin-bottom: 0rem;
  .advancedTargetingHeader {
    font-weight: 500;
    font-size: large;
  }
}
.advancedTargetingGroup2 {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-bottom: 0px;
  border: 2px solid black;
  border-top: none;
  padding: 0rem 1rem 1rem 1rem;
  border-radius: 0px 0px 4px 4px;
  margin-bottom: 1rem;
}

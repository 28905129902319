@import "../../utils/colors.scss";

.sparkChartContainer {
  display: flex;
  flex: 1;
  border-radius: 16px;
  margin: 6px;
  flex-direction: column;
  background-color: white;
  height: 300px;
  border: 1.5px solid $Grey60;
  &.hover {
    outline: 4px solid $Brand60;
    outline-offset: -2.5px;
  }
  .sparkChartHeader {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    min-height: 50px;
    padding: 24px 24px 0px 24px;
    .sparkChartTitle {
      display: flex;
      flex-flow: column;
      align-items: flex-start;
    }
    .sparkChartActions {
      display: flex;
      flex: 1;
      flex-flow: row wrap;
      align-items: center;
      justify-content: flex-end;
      & > :first-child {
        margin-right: 8px;
      }
    }
  }
  .sparkChartSubHeader {
    padding: 0px 24px 0 24px;
    .value {
      font-size: 30px;
      font-weight: 500;
      color: $Brand100;
    }
    .sparkChartToggleSelector {
      display: flex;
      flex-direction: row;
      color: $Brand100;
      cursor: pointer;
      :first-child {
        margin-right: 0.5rem;
      }
      .sparkChartToggleItem {
        &.deselected {
          opacity: 0.3;
          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }
  .sparkChartBody {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;

    .recharts-tooltip-wrapper {
      z-index: 100;
    }

    &.graphView {
      padding: 0px 24px 0px 24px;
    }
    &.tableView {
      padding: 0px 3px 5px 3px;
    }
    > * {
      display: flex;
      flex: 1;
      width: 100%;
      height: 100%;
    }
  }
  .sparkChartFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 16px 16px 24px;
    align-items: center;
    height: 58px;
    .comparison {
      display: flex;
      flex-direction: column;
      font-weight: 400;
      color: $Neutral700;
      font-size: 14px;
      .range {
        text-wrap: nowrap;
        text-overflow: ellipsis;
      }
    }
    .percentChange {
      display: flex;
      align-self: flex-end;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      border-radius: 35px;
      background: #8ef000;
      padding: 6px 12px 6px 8px;
      height: 28px;
      &.inverted {
        background: #cbd2e1;
      }
      .percentArrow {
        margin-right: 4px;
        &.inverted {
          transform: scaleY(-1); // Flip the arrow downwards
        }
      }
    }
  }
}

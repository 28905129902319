@import "../utils/sassHelpers.scss";
@import "../utils/colors.scss";

.bpmLogin {
  @include fullBox();
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 100px;
  overflow-y: auto;

  .bpmLoginInner {
    display: flex;
    flex-direction: row;
    flex: 1;
    .loginForm {
      margin: 30px;
      min-width: 500px;
      max-width: 500px;
      & > * {
        margin-bottom: 1rem;
      }
      .agencyLogo {
        width: 500px;
      }
      .loginPrepend {
        min-width: 140px;
      }
      .loginUpdate {
        margin-bottom: 30px;
        color: $primary;
        font-weight: 500;
        text-align: center;
        min-height: 100px;
      }
      .loginButton {
        min-height: 48px;
        font-size: 16px;
        border-radius: 1px;
      }
      .forgotPassword {
        text-align: center;
        width: 100%;
      }
      .signInWithGoogleButton {
        background-color: white;
        color: #4285f4;
        border-color: #4285f4;
      }
      .signInWithGoogleContainer {
        padding-left: 25%;
        padding-right: 25%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
      }
    }
  }
}

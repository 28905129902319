@import "../utils/colors.scss";
.budgetIntakeToolContainer {
  display: flex;
  margin: 16px;
  border: 1.5px solid $Neutral400;
  background-color: white;
  border-radius: 16px;
  flex-direction: column;
  height: 90vh;
  .budgetIntakeTool {
    .BPMTable {
      background-color: transparent;
      .BPMTableContainer {
        margin-bottom: 8px;
        height: 100%;
        width: 100%;
      }
    }
    .tableHeader {
      padding: 24px 24px 0px 0px;
      flex-direction: row;
    }
    .tableActions {
      gap: 10px;
      display: flex;
    }

    .budgetIntakeFilterBarContainer {
      z-index: 1;
      margin-bottom: 0.5rem;
    }
  }
}

.modal-content {
  margin: 24px;
  border: 2px solid #6b2def;
  border-radius: 16px;
  .modal-header {
    display: flex;
    align-items: center;
    padding: 24px 24px 16px 24px;
    border-bottom: 1px solid $Neutral500;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
  }
  .modalRow {
    padding: 8px 8px;
    font-size: 16px;
    font-weight: 600;
  }
  .css-2b097c-container {
    border-radius: 6px;
    border: 1px solid $Brand50;
    .css-yk16xz-control {
      background-color: transparent;
      border-radius: 0px;
      border-style: none;
      .css-6q0nyr-Svg {
        fill: $Brand50;
      }
    }
  }
  .form-control {
    border-radius: 6px;
    border: 1px solid $Brand50;
  }

  .modalRow .SingleDatePicker .SingleDatePickerInput {
    border-radius: 6px;
    border: 1px solid $Brand50;
    background-color: transparent;
    .DateInput {
      color: transparent;
    }
  }
}

@import "../utils/sassHelpers";
@import "../utils/colors";

$border-color: rgba(0, 0, 0, 0.4);

$delivery: #01b8aa;
$deliverySecured: #3201b8;
$pacing: #374649;
$booked: #ffc000;
$clientTarget: #3da73d;
$bookedTarget: #b8268c;
$pacing-bar-height: 40px;
$pacing-bar-margin: 5px;
$pacing-bar-highlight-width: 5px;
$pacing-bar-alert-size: 30px;
$pacing-pane-padding: 10px;

.pacingDashboard {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  .delivery {
    background-color: $delivery;
  }
  .deliverySecured {
    background-color: $deliverySecured;
  }
  .deliveryUnsecured {
    background-color: $delivery;
  }
  .pacing {
    background-color: $pacing;
  }
  .booked {
    background-color: $booked;
  }
  .bookedTarget {
    background-color: $bookedTarget;
  }
  .clientTarget {
    background-color: $clientTarget;
  }
  .chartPanes {
    flex: 1;
    display: flex;
    flex-direction: row;
    margin: $pacing-pane-padding;
    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    .leftPane {
      flex: 1;
      display: flex;
      flex-direction: column;
      min-height: 0;
      &.condensed {
        flex: 0;
      }
    }
    .rightPane {
      flex: 2;
      display: flex;
      flex-direction: column;
      border-left: 1px solid $border-color;
      .topPane {
        flex: 1;
        display: flex;
        flex-direction: column;
        min-height: 0;
        &.condensed {
          flex: 0;
          min-height: $pacing-bar-height + 2 * $pacing-bar-margin + 3 * $pacing-pane-padding;
        }
      }
      .bottomPane {
        flex: 1;
        padding: $pacing-pane-padding;
        border-top: 1px solid $border-color;
        &.loadingPane {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .pacingPane {
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
    .summaryHeader {
      align-items: center;
      display: flex;
      flex-direction: row;
      font-weight: 500;
      justify-content: space-evenly;
      padding: 20px 20px 0px 20px;
    }
    .summaryLine {
      padding: 20px;
      border-bottom: 1px solid rgb(242, 244, 245);
    }
    .controls {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: $pacing-pane-padding;
      z-index: 1;
      border-bottom: 1px solid rgb(242, 244, 245);
      & > *:not(:last-child) {
        margin-right: 15px;
      }
      .pacingControlGroup {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;
        .pacingControlLabel {
          margin-right: 10px;
        }
      }
      .filterSelect {
        flex: 1;
        margin-top: 15px;
      }
    }
    .pacingLines {
      flex: 1;
      overflow: auto;
      padding: $pacing-pane-padding;
      z-index: 0;
      .pacingRow {
        height: $pacing-bar-height + 2 * $pacing-bar-margin;
        display: flex;
        margin: $pacing-bar-margin;
        cursor: pointer;
        position: relative;

        .rowContents {
          padding: $pacing-bar-margin;
          z-index: 1;
          background-color: white;
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          .pacingRowLabel {
            height: 100%;
            display: flex;
          }
          .lineItemContent {
            flex: 1;
            height: 100%;
            display: flex;
            margin-left: 15px;
            &.withAlert {
              margin-left: $pacing-bar-alert-size;
            }
          }
        }
        .warningIcon {
          color: red;
          font-size: $pacing-bar-alert-size - 6px;
        }
        .selectedBorder {
          position: absolute;
          top: -$pacing-bar-highlight-width;
          right: -$pacing-bar-highlight-width;
          bottom: -$pacing-bar-highlight-width;
          left: -$pacing-bar-highlight-width;
          background: $color-gradient;
        }
      }
    }
  }
}

.pacingItemBar {
  flex: 1;
  height: $pacing-bar-height;
  width: 100%;
  align-items: center;
  position: relative;
  // When there's a label at the end it looks dumb unless we have this.
  margin-right: 40px;
  & > * {
    height: 100%;
    position: absolute;
    left: 0;
    line-height: $pacing-bar-height;
  }
  .horizontal {
    color: white;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .vertical {
    height: $pacing-bar-height + 2 * $pacing-bar-margin;
    top: -$pacing-bar-margin;
  }
  .pacingBarLabel {
    padding-left: 10px;
  }
  .pacingBarAlert {
    left: -$pacing-bar-alert-size;
  }
}
.pacingBarLabel {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  .logo {
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
    }

    &.network {
      width: $pacing-bar-height;
      height: $pacing-bar-height;
    }
    &.company {
      width: $pacing-bar-height * 2;
      height: $pacing-bar-height;
    }
    .noImg {
      display: flex;
      overflow: hidden;
      word-break: break-all;
      border: 1px solid black;
      height: 100%;
      max-height: 100%;
    }
  }
  &.pacingNetworkBarLabel {
    width: 150px;
    .subLabel {
      margin-left: 10px;
      flex: 1;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      font-size: 0.7rem;
    }
  }
  &.pacingCompanyBarLabelLinear {
    width: 150px;
    .subLabel {
      margin-left: 10px;
      flex: 1;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      font-size: 0.7rem;
    }
  }
}

.pacingDashboardActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 1rem;
  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }
  .optionsHelp {
    font-size: 16px;
    cursor: pointer;
  }
  .legend {
    display: flex;
    flex-direction: row;
    align-items: center;
    .circle {
      height: 16px;
      width: 16px;
      border-radius: 8px;
    }
    .delivery {
      background-color: $delivery;
    }
    .pacing {
      background-color: $pacing;
    }
    .booked {
      background-color: $booked;
    }
    .bookedTarget {
      background-color: $bookedTarget;
    }
    .clientTarget {
      background-color: $clientTarget;
    }
    .legendLabel {
      margin: 3px 10px 0 5px;
    }
  }
}

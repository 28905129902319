@import "../utils/sassHelpers";
@import "../utils/colors";

.pacingBarYAxisContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.pacingBarText {
  color: $Brand100;
  font-style: normal;
  line-height: normal;
}

.pacingBarText1,
.pacingBarText2 {
  font-size: 16px;
  font-weight: 600;
}

.pacingBarText3 {
  font-size: 14px;
  font-weight: 500;
}

.pacingBarText4 {
  font-size: 14px;
  font-weight: 400;
}

.pacingBarLogos {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

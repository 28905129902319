.emptyInputsContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > * + * {
    margin-top: 2rem;
  }

  .emptyInputsTitle,
  .emptyInputsSubtitle {
    margin-bottom: 0;
    line-height: 24px;
    font-weight: 600;
  }

  .emptyInputsTitle {
    font-size: 30px;
  }

  .emptyInputsSubtitle {
    font-size: 24px;
  }
}

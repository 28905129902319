.approvals {
  width: 100%;
  padding: 12px;

  .approvalsDataContainer {
    width: 100%;

    .activePlacementsOnlyCheckboxContainer {
      float: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 4px;
      padding-bottom: 8px;

      .activePlacementsOnlyCheckbox {
        margin-right: 8px;
      }

      .activePlacementsOnlyCheckboxLabel {
        font-weight: 500;
      }
    }
  }
}

.commentBoxContainer {
  display: flex;
  flex: 3;
  .confirmRequestChangesButton {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

.commentBox {
  height: 48px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border: 1px solid #b71c1c;
}

.reviewControls {
  display: flex;
  flex-direction: row;
  margin: 8px;

  .reviewerOptionsMenu__value-container {
    height: 48px;
    font-size: 1.25rem;
  }
  .reviewerOptionsMenu {
    flex: 3;
    height: 100%;
    margin-left: 8px;
    margin-right: 8px;
  }
}

.backButton {
  flex: 1;
}
.bottomPageContainer {
  max-height: 68px;
  display: flex;
  flex-direction: row;

  .cancelButton {
    color: #b71c1c;

    margin-left: 4px;
    margin-right: 4px;
  }
  .approveButton {
    flex: 2;
    margin-left: 4px;
    margin-right: 4px;
  }
  .requestChangesButton {
    flex: 1;
  }
}
.displayReviewers {
  flex: 2;
  overflow: auto;
  height: 100%;
}
.displayReviewers:hover {
  cursor: default;
}
.approved {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .nextButton {
    width: 100px;
    margin-left: 4px;
  }
  .rerequestReviewButton {
    margin-left: 4px;
  }
}

@import "../../utils/colors.scss";
.filterPaneDisabled {
  opacity: 0.5;
  pointer-events: none;
}
$sidePadding: 40px;
$verticalPadding: 10px;
.BPMFilterPane {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  box-shadow: -1px 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition: width 0.2s ease;
  .filterPaneInner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    max-width: 100%;
    flex-direction: column;
    justify-content: space-between;
    .filterPaneTop {
      align-items: center;
      border-bottom: 1px solid $Grey60;
      color: #130d2d;
      display: flex;
      flex-direction: row;
      font-size: 18px;
      height: 63px;
      justify-content: flex-start;
      padding: 0 $sidePadding;
      position: relative;
      .closeButton {
        position: absolute;
        font-size: 28px;
        right: $sidePadding;
        top: 8px;
        cursor: pointer;
      }
    }
    .filterPresets {
      border-bottom: 1px solid $Grey60;
      padding: $verticalPadding $sidePadding;
      overflow-y: auto;
      .filterPresetsTitleContainer {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
        vertical-align: middle;
        justify-content: space-between;
        .filterPresetsTitle {
          display: flex;
          flex-direction: row;
          & > * {
            margin-right: 1rem;
            font-weight: 500;
            cursor: pointer;
            transition: color 0.12s ease;
            &.selected {
              color: $primary;
              &::after {
                width: 100%;
                left: 0;
              }
            }
            &:hover {
              color: $primary;
            }
            &::after {
              content: " ";
              position: absolute;
              background-color: $primary;
              width: 0;
              bottom: -2px;
              left: 50%;
              height: 2px;
              transition: all 0.12s ease;
            }
          }
          .filterPresetsTitleText {
            height: fit-content;
          }
          .filterPresetsTitleExpander {
            height: 0;
            position: relative;
            font-size: 30px;
            top: -12px;
            color: #8c8a97;
          }
        }
        .addNewPresetButton {
          color: $Purple100;
          height: 100%;
        }
      }
      .filterPresetRowsContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .filterNameFilterContainer {
          padding-top: 0.25rem;
          margin-bottom: 0.25rem;
          width: 20rem;
          display: flex;
          flex-direction: row;
          height: 1.75rem;
          vertical-align: middle;
          align-items: center;
          .filterNameFilter {
            height: 1.75rem;
            width: 100%;
            z-index: 0;
          }
          .filterBarSearchIcon {
            height: 1.75rem;
            width: 1.75rem;
            color: $Purple40;
            margin-left: -2rem;
            z-index: 1;
          }
        }
        .filterPresetRows {
          max-height: 9rem;
          overflow: auto;
          width: 100%;
          .filterPresetRow {
            align-items: center;
            display: flex;
            flex-direction: row;
            height: 1.75rem;
            justify-content: space-between;
            padding-top: 0.25rem;
            vertical-align: middle;
            width: 20rem;
            .filterPresetSelection {
              display: flex;
              flex-direction: row;
              height: 1.75rem;
              align-items: center;
              vertical-align: middle;
              .filterPresetRowCheckbox {
                color: $Purple100;
                height: 1.5rem;
                width: 1.5rem;
              }
              .filterPresetName {
                margin-left: 0.5rem;
                max-height: 1.75rem;
                max-width: 13.5rem;
                overflow-x: auto;
                overflow-y: hidden;
                white-space: nowrap;
              }
              * {
                max-height: 1.75rem;
              }
            }
            .filterPresetRowIcons {
              align-items: center;
              color: $GreyHeat80;
              height: 1.75rem;
              vertical-align: middle;
              * {
                height: 1.25rem;
                width: 1.25rem;
              }
            }
          }
        }
      }
      .filterPanePresetControls {
        display: flex;
        flex-direction: column;
        flex: 1;
        min-height: 0;
        min-width: 0;
        overflow-y: auto;
      }
    }
    .filterPaneFilterSelection {
      flex: 5;
      overflow-y: auto;
      .filterPaneTypeSelect {
        padding: 0.75rem $sidePadding;
        display: flex;
        flex-direction: row;
        & > * {
          padding: 0 0.5rem;
          margin-right: 1rem;
          font-weight: 500;
          color: #8c8a97;
          cursor: pointer;
          transition: color 0.12s ease;
          &.selected {
            color: $primary;
            &::after {
              width: 100%;
              left: 0;
            }
          }
          &:hover {
            color: $primary;
          }
          position: relative;
          &::after {
            content: " ";
            position: absolute;
            background-color: $primary;
            width: 0;
            bottom: -2px;
            left: 50%;
            height: 2px;
            transition: all 0.12s ease;
          }
        }
      }
      .filterPaneBody {
        padding: 0 $sidePadding;
        flex-direction: column;
        min-height: 0;
        min-width: 0;
        display: flex;
        .none {
          font-size: 12px;
          font-style: italic;
        }
        .compileError {
          padding-top: 0.25rem;
          font-size: 0.8rem;
          color: red;
        }
        .categoryContainer {
          margin-top: 0.5rem;
          .categoryHeader {
            position: relative;
            color: #130d2d;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            font-weight: 500;
            cursor: pointer;
            user-select: none;
            transition: color 0.12s ease;
            &.disabled {
              cursor: default;
            }
            .not {
              font-size: 12px;
              color: #bbbbbb;
              margin-left: 0.5rem;
              padding-top: 4px;
              cursor: pointer;
              transition: color 0.12s ease;
              user-select: none;
              &:hover:not(.disabled),
              &.selected {
                color: $primary;
              }
              &.disabled {
                cursor: default;
              }
            }
            .expander {
              position: absolute;
              font-size: 30px;
              right: 0;
              top: -14px;
              color: #8c8a97;
            }
            &:hover {
              color: black;
            }
          }
          .selectedItems {
            display: flex;
            flex-flow: row wrap;
            min-width: 0;
            padding-top: 0.25rem;
            overflow: auto;
            max-height: 130px;
            .selectedPill {
              display: flex;
              flex-direction: row;
              align-items: center;
              background-color: #f0f1f7;
              border-radius: 100px;
              padding: 0 12px;
              font-size: 12px;
              margin-right: 0.5rem;
              margin-bottom: 0.25rem;
              white-space: nowrap;
              min-height: 28px;
              .remove {
                font-size: 20px;
                margin-left: 0.5rem;
                margin-right: -6px;
                margin-top: -2px;
                color: #9fa2b4;
                cursor: pointer;
                transition: color 0.12s ease;
                &:hover {
                  color: #7f8190;
                }
              }
            }
          }
          .filterBox {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            .tinyFilterBar {
              flex: 1;
              margin-top: 0.5rem;
              font-size: 0.7rem;
            }
            .selectAll {
              white-space: nowrap;
              font-size: 0.7rem;
              margin-left: 0.25rem;
              padding: 0.3rem 0.5rem;
            }
          }
          .categoryItems {
            margin-top: 0.5rem;
            color: #130d2d;
            font-size: 14px;
            .categoryItem {
              display: flex;
              flex-direction: row;
              margin-bottom: 0.25rem;
              cursor: pointer;
              user-select: none;
              &.disabled {
                cursor: not-allowed;
              }
              .itemLabel {
                padding-top: 1px;
                margin-left: 0.5rem;
              }
            }
          }
        }
        .filterPaneAdvancedBox {
          margin-top: 0.5rem;
          font-family: "Courier New", Courier, monospace !important;
          font-weight: 500;
        }
        .suggestionList {
          display: flex;
          flex-flow: row wrap;
          // justify-content: space-between;
          align-content: flex-start;
          $gap: 0.5rem;
          margin-top: 1rem;
          margin-left: -$gap;
          flex: 1;
          min-height: 0;
          overflow: auto;
          .suggestion {
            margin: $gap;
            background-color: #f0f1f7;
            padding: 0.25rem 0.75rem;
            font-size: 14px;
            border-radius: 5px;
            cursor: pointer;
            &:hover {
              background-color: #e6eaee;
            }
          }
        }
      }
    }
    .filterPaneControls {
      display: flex;
      flex-direction: column;
      flex: 0.5;
      justify-content: space-between;
      margin: $verticalPadding 0;
      padding: 0 $sidePadding;
      .filterPaneControlButtons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        & > * {
          * {
            margin-right: 0.5rem;
          }
          height: fit-content;
          width: fit-content;
        }
      }
      .addNewFilterPresetButton {
        align-items: center;
        color: $Purple100;
        display: flex;
        justify-content: center;
        margin-top: $verticalPadding;
        * {
          display: flex;
          height: 100%;
          justify-content: center;
          margin-left: 0.5rem;
          text-align: center;
          vertical-align: middle;
        }
      }
    }
  }
}

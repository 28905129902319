@import "../../utils/colors.scss";
@import "../../styles/Fonts.scss";

.businessImpactOverview {
  display: flex;
  gap: 16px;
  .businessImpactOverviewleft {
    display: flex;
    flex-direction: column;
    flex: 3;
    gap: 16px;

    .chartContainer {
      margin: 0px;
      .contents {
        min-height: 360px;
      }
      .lineWrapper {
        width: 100%;
      }
      .rightOfChart {
        color: $Brand100;
        padding-bottom: 16px;
        padding-right: 2px;
        width: 200px;
      }
    }

    .modelOverviewSpend {
      z-index: 10;
    }
  }

  .brandEquityRight {
    width: 30%;
    .chartContainer {
      margin: 0;
      &.enableHoverDesign {
        height: 100%;
      }
    }
    .brandEquityDataSection {
      border-top: 1px solid $Neutral400;
      padding: 24px 16px 0px 8px;
      color: $Brand100;

      .brandEquityDataSectionTitle {
        color: $Brand100;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .brandEquityDataSectionRow {
        display: flex;
        font-size: 16px;
        font-style: normal;
        justify-content: space-between;
        line-height: 107.143%;
        margin-top: 12px;
        width: 100%;

        .brandEquityLabel {
          align-items: center;
          display: flex;
          font-weight: 500;
          .tooltip-container {
            margin-left: 6px;
            top: -3px;
          }
        }
        .brandEquityValue {
          font-weight: 400;
          &.brandEquityOutcomeVariable {
            text-transform: capitalize;
          }
        }
      }
    }
  }
}

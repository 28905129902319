@use "sass:math";
@import "../../utils/colors.scss";

$dateWidth: 24px;
$dateMargin: 6px;
$calendarPadding: 16px;
$selectedBorderRadius: 12px;

$pickerWidth: 180px;
$fullWeeksPickerWidth: 290px;

.BPMDate {
  border-radius: 4px;
  cursor: pointer;
  background-color: white;
  height: 31px;
  min-width: $pickerWidth;
  max-width: $pickerWidth;
  width: $pickerWidth;
  border: 1px solid #e6eaee;
  display: flex;
  align-items: center;
  transition: all 0.12s ease;
  user-select: none;
  &.bordered {
    border: 1px solid rgba(0, 0, 0, 0.4) !important;
  }
  &.fullWeeksOnly {
    min-width: $fullWeeksPickerWidth;
  }
  &:hover {
    background-color: $primary;
    color: white;
    .icon {
      stroke: white !important;
    }
    .dateRange::after {
      border-bottom-color: white;
      border-top-color: white;
    }
  }
  .icon {
    margin: 0 5px;
    stroke: $primary;
  }
  .dateRange {
    position: relative;
    flex: 1;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
    text-align: center;
    font-size: 14px;
    padding-top: 3px;

    padding-right: 18px;
    &::after {
      content: "";
      position: absolute;
      right: 5px;
      top: 10px;
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
      border-bottom-color: #bec7d2;
      border-top-color: #bec7d2;
    }
    .singleDate {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.BPMDateContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10002;
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }
  .calendarBox {
    position: absolute;
    background-color: white;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    .controls {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 $calendarPadding math.div($calendarPadding, 2);

      .reset {
        justify-self: flex-start;
      }
      .subControls {
        display: flex;
        flex-direction: row;
        align-items: center;
        & > *:not(:first-child) {
          margin-left: 0.5rem;
        }
      }
    }
  }
  .dateDisplay {
    font-size: 0.9rem;
    color: #7a7a7a;
    margin-right: 1rem;
    user-select: none;
    .singleDate {
      cursor: pointer;
      transition: color 0.12s ease;
      font-weight: 500;
      position: relative;
      &:hover {
        color: $primary;
      }
      &.focused:after {
        content: " ";
        position: absolute;
        bottom: -3px;
        left: -1px;
        right: -1px;
        height: 2px;
        border-radius: 1px;
        background-color: $primary;
      }
    }
  }
}
.BPMCalendar {
  position: relative;
  padding: $calendarPadding;
  display: flex;
  flex-direction: row;
  & > .BPMMonth:not(:first-child) {
    margin-left: 24px;
  }
  .navArrow {
    position: absolute;
    top: -4px;
    font-size: 36px;
    color: #9fa2b4;
    cursor: pointer;
    &.left {
      left: $calendarPadding - 3px;
    }
    &.right {
      right: $calendarPadding - 3px;
    }
  }
  .BPMMonth {
    display: flex;
    flex-direction: column;
    user-select: none;

    .monthHeader {
      text-align: center;
      margin-bottom: 16px;
      select {
        border: none;
        background-color: transparent;
        font-size: 14px;
        font-weight: 500;

        color: #484848;
        cursor: pointer;
        appearance: none;
        width: 80px;
        outline: none;
        &:first-child {
          text-align-last: right;
          margin-right: 2px;
        }
        &:last-child {
          margin-left: 2px;
          text-align-last: left;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .daysOfWeek {
      color: #4b506d;
      font-size: 11px;
      font-weight: 500;
      display: flex;
      flex-direction: row;
      & > * {
        width: $dateWidth;
        text-align: center;
        margin: 0 $dateMargin;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .dayGrid {
      color: #4b506d;
      display: flex;
      flex-direction: column;
      & > * {
        display: flex;
        flex-direction: row;
        & > * {
          width: $dateWidth;
          height: $dateWidth;
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: $dateMargin;
          cursor: pointer;
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
          &.notCurrentMonth {
            color: #d2d4d5;
          }
          &.selected {
            background-color: $primary;
            color: white;
            &.selectedLeft {
              position: relative;
              border-radius: $selectedBorderRadius 0 0 $selectedBorderRadius;
              &.nextIsLast:not(:first-child) {
                width: $dateWidth + $dateMargin;
                padding-right: $dateMargin;
              }
            }
            &.selectedRight {
              border-radius: 0 $selectedBorderRadius $selectedBorderRadius 0;
              &.lastWasFirst:not(:first-child) {
                width: $dateWidth + $dateMargin;
                padding-left: $dateMargin;
                margin-left: -$dateMargin;
              }
            }
          }
          &.invalid {
            color: #d2d4d5;
            cursor: not-allowed;
          }
          &.notCurrentMonth {
            cursor: default;
          }
          &:not(.invalid) {
            &:not(.notCurrentMonth):hover {
              position: relative;
              background-color: $primary !important;
              color: white;
            }
            &:not(.selectedRight).focusStart:hover {
              border-radius: $selectedBorderRadius 0 0 $selectedBorderRadius;
            }
            &:not(.selectedLeft).focusEnd:hover {
              border-radius: 0 $selectedBorderRadius $selectedBorderRadius 0;
            }
            &.span {
              background-color: $primary-light;
              width: $dateWidth + 2 * $dateMargin;
              margin: $dateMargin 0;
              &.lastWasFirst,
              &.nextIsLast {
                width: $dateWidth + 3 * $dateMargin;
              }
              &:first-child,
              &:last-child {
                width: $dateWidth + $dateMargin;
              }
              &:first-child,
              &.nextIsLast {
                padding-right: $dateMargin;
              }
              &.nextIsLast:first-child {
                width: $dateWidth + $dateMargin * 2;
                padding-right: $dateMargin * 2;
              }
              &:last-child,
              &.lastWasFirst {
                padding-left: $dateMargin;
              }
              &.lastWasFirst:last-child {
                width: $dateWidth + $dateMargin * 2;
                padding-right: $dateMargin * 2;
              }
              &.lastWasFirst {
                margin-left: -$dateMargin;
              }
              &.nextIsLast {
                margin-right: -$dateMargin;
              }
              &.lastWasFirst.nextIsLast {
                width: $dateWidth + 4 * $dateMargin;
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
}

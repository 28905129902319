.stagedTag {
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  background-color: white;
  padding: 12px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  .propertiesDiv {
    flex: 3;

    .propertyDiv {
      display: flex;

      .propertyTitle {
        font-weight: 500;
        margin-right: 8px;
      }
    }
  }

  .creativesDiv {
    flex: 2;
    display: flex;
    align-items: center;

    .legend {
      margin-left: 12px;

      .overlayMetadata {
        display: flex;
        flex-direction: column;
      }

      .swatchRow {
        display: flex;
        align-items: center;

        .swatch {
          height: 20px;
          width: 20px;
          border: 1px solid black;
          margin-right: 4px;
        }

        .swatchCreativeName {
          margin-right: 4px;
        }

        .swatchIsciName {
          margin-right: 4px;
        }
      }
    }
  }

  .controlsDiv {
    align-self: stretch;
    display: flex;
    align-items: flex-start;

    .editButton {
      margin-right: 4px;
    }
  }
}

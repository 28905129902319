@import "../utils/colors.scss";

.creativeInsightsActions {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  .lastModified {
    padding-right: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 6px;
    font-weight: 500;
    color: $Brand100;
    .refreshIcon {
      width: 22px;
      height: 22px;
      font-size: 22px;
    }
    .lastModifiedDate {
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
    }
  }
  .eye {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8c8a97;
    font-size: 0.75rem;
    margin-right: 10px;
  }
}

.creativeInsightsBody {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  height: 100%;
  min-height: 785px;
  padding: 0.5rem;
  .creativeInsightsCharts {
    width: 50%;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    .header {
      height: 54px;
      .leftSide {
        flex-direction: row;
        column-gap: 0.5rem;
        align-items: center;
        .leftActions {
          display: flex;
          flex-direction: row;
          align-items: center;
          .attributePicker {
            margin-left: 1rem;
          }
          .clearButton {
            margin-left: 0.5rem;
          }
        }
      }
    }
  }
}

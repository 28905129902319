@import "../utils/colors.scss";

.WebTrafficOptimizationActions {
  display: flex;
  align-items: center;
  flex-flow: row wrap-reverse;
  justify-content: flex-end;

  button {
    margin-right: 1.5rem;
  }
}

.WebTrafficOptimization {
  height: 100%;
  display: flex;
  min-height: 0px;
  flex-direction: column;
  flex: 1 1 0%;
  padding: 1.2rem;
  max-width: 100%;
  width: 100%;
}

.infoBox {
  &.warning {
    color: rgb(102, 60, 0);
    background-color: rgb(255, 244, 229);
    display: flex;
    border-radius: 4px;
    padding: 12px 16px;

    .message span {
      color: #2a79ff;
    }
  }

  &.validations {
    border-radius: 5px;
    padding: 6px 16px;
    color: #611a15;
    background: #fdecea;
    margin-bottom: 28px;
    display: flex;
  }

  .icon {
    padding: 7px 0;
    margin-right: 12px;

    svg {
      height: 22px;
      width: 22px;
    }
  }

  .message {
    margin: 0;
    padding: 8px 0;
    font-weight: 500;
  }
}

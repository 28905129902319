@import "../utils/sassHelpers.scss";

$border-color: rgba(0, 0, 0, 0.125);

.reportingPage {
  flex: 1;
  padding: $page-padding $redesign-side-padding;
  overflow-y: auto;
  .noReports {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
  }
  .copiedToast {
    position: absolute;
    pointer-events: none;
    z-index: 10;
    background-color: $border-color;
    margin-left: 100px;
    & > * {
      padding: 0.25rem 0.75rem;
    }
  }
  .filterBar {
    margin-bottom: 5px;
  }
  .listContainer {
    background-color: white;
    border: 1px solid $border-color;
    border-radius: 5px;
    width: 100%;
    .listItem {
      display: flex;
      flex-direction: column;
      &:not(:last-child) {
        border-bottom: 1px solid $border-color;
      }
      .headerRow {
        cursor: pointer;
        height: 75px;
        display: flex;
        flex-direction: row;
        // flex-flow: row wrap;
        align-items: center;
        padding: 0 1rem;
        transition: all 0.12s ease;
        &:hover {
          background-color: rgba(0, 0, 0, 0.03);
        }
        & > * {
          min-width: 0;
          flex: 1;

          &.name {
            min-width: 320px;
          }
          &.lookback {
            min-width: 120px;
          }
          &.status {
            min-width: 110px;
          }
          &.date {
            min-width: 280px;
          }
          &.expand {
            max-width: 50px;
          }
          & > *:first-child {
            font-weight: 500;
            text-decoration: underline;
          }
        }
      }
      .details {
        border-top: 1px solid $border-color;
        padding: 1rem;
        box-shadow: inset 0px 0px 7px 1px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: row;
        .sectionHeader {
          text-decoration: underline;
          font-weight: 500;
          font-size: 1.2rem;
          margin-bottom: 1rem;
        }

        .queries {
          flex: 1;
          max-width: 400px;
          display: flex;
          flex-direction: column;
          .sectionHeader {
            display: flex;
            .queryCopy {
              margin-left: 5px;
            }
          }
          .codeBlock {
            border-radius: 3px;
            border: 1px solid $border-color;
            padding: 1rem;
            font-family: "Courier New", Courier, monospace !important;
            background-color: rgba(0, 0, 0, 0.05);
            overflow-wrap: break-word;
            &:not(:last-child) {
              margin-bottom: 1rem;
            }
          }
        }
        .fileInfo {
          font-size: 0.8rem;
          font-family: "Courier New", Courier, monospace !important;
        }
        .files {
          margin-left: 1rem;
          & > * {
            .success {
              font-size: 0.9rem;
              font-style: italic;
              color: #999999;
            }
          }
        }
      }
    }
  }
}

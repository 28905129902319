.pageNotFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  & > * {
    margin-bottom: 15px;
  }
  .message {
    font-size: 30px;
  }
  .hint {
    font-size: 20px;
  }
  .href {
    font-size: 24px;
  }
}

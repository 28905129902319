.processLogsActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  .actionButton {
    margin-right: 0.5rem;
    &:hover {
      &.clear {
        border-color: red !important;
        background-color: red !important;
      }
      border-color: green !important;
      background-color: green !important;
    }
  }
}

.processLogsContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 2rem;
  overflow: hidden;

  .leftPane {
    display: flex;
    flex: 3;
    flex-direction: column;
    & > * {
      margin-bottom: 1rem;
    }

    .uploadTable {
      display: flex;
      flex: 1;

      &.summaryNotActive {
        flex: 3;
      }

      .actionButton {
        &:hover {
          border-color: red !important;
          background-color: red !important;
        }
      }
    }

    .summaryTable {
      display: flex;
      flex: 1;
      &.active {
        flex: 2;
      }
      .grandTotalCell {
        font-weight: 500;
      }
    }
  }

  .rightPane {
    margin-left: 1rem;
    flex-direction: column;
    display: flex;
    flex: 1;

    .upload-btn-wrapper {
      position: relative;
      overflow: hidden;
      display: inline-block;
      border: 1px solid #e6eaee;
      border-radius: 8px;
    }

    .file-btn {
      border: 2px solid gray;
      color: gray;
      background-color: white;
      padding: 8px 20px;
      border-radius: 8px;
      font-size: 20px;
      font-weight: 500;

      width: 100%;
    }

    .upload-btn-wrapper input[type="file"] {
      font-size: 100px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
    }

    .logDownloadContainer {
      display: flex;
      flex-direction: column;
      flex: 2;
      margin-top: 0.5rem;
      overflow: auto;
      .logDownloadList {
        .title {
          padding-left: 1rem;
          padding-top: 1rem;
          font-size: 16px;
          font-weight: 500;
        }
        .listItem {
          .logDownloadButton {
            width: 100%;
          }
        }
      }
    }
  }
}

@use "sass:math";
@import "../../styles/Fonts.scss";
@import "../../utils/colors.scss";
@import "../../utils/sassHelpers.scss";

.crossChannelSourcePicker {
  height: fit-content;
  width: fit-content;
  .dropdown-menu {
    max-height: 400px;
  }
  .dropdownMenuBodyContainer {
    min-width: 221px;
    width: fit-content;
    .dropdownMenuItemsContainer {
      min-width: 221px;
      width: fit-content;
      .dropdownMenuItem {
        padding-right: 0px !important;
        height: fit-content !important;
        .dropdownMenuItemLabel {
          text-wrap: nowrap;
          width: fit-content;
        }
        .dropdownMenuItemOptions {
          padding-right: 6px;
          .presetOptionExpander {
            align-items: center;
            display: flex;
            * {
              color: $Brand50;
              height: 22px;
              width: 22px;
            }
          }
        }
      }
      .dropdownMenuItem.dropdownOpen {
        background-color: $Brand25;
        .dropdownMenuItemOptions {
          .presetOptionExpander {
            background-color: $Brand40;
            border-radius: 6px;
            * {
              color: #ffffff;
              height: 22px;
              width: 22px;
            }
          }
        }
      }
      .BPMFilterBarComponentContainer {
        width: fit-content;
      }
    }
  }
  .dropdownMenuApplyCancelContainer {
    padding-bottom: 8px;
    padding-left: 24px;
  }
  .dropdownMenuFooterContainer {
    background-color: $Brand25;
    padding: 0px;
    .addNewPresetButton {
      border-radius: 0px;
      color: $Brand100;
      font-family: Graphik !important;
      font-feature-settings: "clig" off, "liga" off;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      height: 100% !important;
      justify-content: flex-start;
      line-height: normal;
      padding: 0px 12px;
      width: 100% !important;
    }
  }
  .cl-dropdown-toggle {
    .dt-icon,
    .dt-text {
      color: $Brand60;
    }
  }
  .cl-dropdown-toggle.dropdown-toggle.btn.btn-primary.lg::after {
    color: $Brand60;
  }
}

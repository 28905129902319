@use "sass:math";
@import "../../styles/Fonts.scss";
@import "../../utils/colors.scss";
@import "../../utils/sassHelpers.scss";
$BPMSearchBarBorderWidth: 1.5px;
$BPMSearchBarContainerHeightLG: 32px;
$BPMSearchBarContainerHeightSM: 28px;
$BPMSearchBarContainerTextHeight: 18px;
$BPMSearchBarIconHeight: 24px;
$BPMSearchBarIconWidth: 24px;
$BPMSearchBarValueContainerHeight: 22px;

// When using the CreatableSelect Component, the classnames are filterBarSelect_<comp_type>
.BPMFilterBarComponentContainer {
  display: flex;
  flex-direction: row;
  position: relative;
  height: $BPMSearchBarContainerHeightLG !important;
  width: fit-content;
  z-index: 10;
  .toggle-button {
    align-items: center;
    border-radius: 6px 0px 0px 6px;
    display: flex;
    z-index: 11;
    .button-framework:nth-child(2) {
      border-radius: 6px 0px 0px 6px;
    }
  }
  .filterBarComponent {
    z-index: 12;
    .filterBarSelect__indicators {
      height: $BPMSearchBarContainerHeightLG !important;
    }
    .filterBarSelect__control {
      align-items: center;
      box-shadow: none !important;
      display: flex;
      height: $BPMSearchBarContainerHeightLG;
      min-height: 0px;
      .filterBarSelect__value-container {
        align-items: center;
        display: flex;
        flex-wrap: unset;
        height: $BPMSearchBarValueContainerHeight;
        padding: $BPMSearchBarBorderWidth 8px;
        position: unset !important;
        row-gap: unset;
        .filterBarSelect__placeholder {
          -webkit-transform: none !important;
          align-items: center;
          display: flex;
          font-family: Graphik !important;
          font-feature-settings: "clig" off, "liga" off !important;
          font-size: 16px !important;
          font-style: normal !important;
          font-weight: 500 !important;
          height: $BPMSearchBarContainerTextHeight;
          line-height: normal;
          margin: 0px;
          min-height: 0px;
          position: unset !important;
          transform: none !important;
          * {
            @include bodyRegular;
          }
        }
        // Keep this to make sure margin on this container is not present (will cause issues with
        // container height).
        div:has(.filterBarSelect__input) {
          height: $BPMSearchBarContainerTextHeight;
          margin: 0px;
          padding: 0px;
        }
        .filterBarSelect__input {
          display: flex !important;
          height: $BPMSearchBarContainerTextHeight;
          margin: 0px;
          padding: 0px;
          * {
            @include bodyRegular;
          }
        }
        .filterBarSelect__multi-value {
          align-items: center;
          background-color: $Neutral200;
          display: flex;
          height: $BPMSearchBarValueContainerHeight;
          margin: 0px 2px 0px 0px !important;
          .filterBarSelect__multi-value__label {
            color: $Brand100;
            font-family: Graphik !important;
            font-feature-settings: "clig" off, "liga" off;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin: 0px 4px;
            padding: 2px 0px;
          }
          .filterBarSelect__multi-value__remove {
            background-color: transparent;
            color: $Neutral500;
            padding: 0px;
            * {
              height: $BPMSearchBarValueContainerHeight;
              width: $BPMSearchBarValueContainerHeight;
            }
          }
        }
        .filterBarSelect__multi-value:hover {
          background-color: $Neutral300;
          .filterBarSelect__multi-value__remove {
            color: $Brand100;
          }
        }
      }
      .filterBarSelect__indicators {
        height: ($BPMSearchBarContainerHeightLG - (2 * $BPMSearchBarBorderWidth)) !important;
      }
    }
    .filterBarSelect__control--is-focused {
      -moz-transition: none !important;
      -o-transition: none !important;
      -webkit-transition: none !important;
      height: unset !important;
      min-height: $BPMSearchBarContainerHeightLG !important;
      transition: none;
      .filterBarSelect__value-container {
        flex-wrap: wrap;
        height: unset !important;
        min-height: $BPMSearchBarValueContainerHeight;
        row-gap: 8px;
        .filterBarSelect__placeholder {
          display: none;
        }
      }
    }
    .filterBarSelect__menu {
      border-radius: 6px;
      border: $BPMSearchBarBorderWidth solid $Brand50;
      box-shadow: 0px 4px 18px 0px rgba(148, 160, 184, 0.5);
      margin: 2px 0px;
      .filterBarSelect__menu-list {
        padding: 2px 0px;
        .filterBarSelect__option {
          color: $Brand100;
          font-family: Graphik !important;
          font-feature-settings: "clig" off, "liga" off;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        .filterBarSelect__option:hover {
          color: $White;
          background-color: $Brand50;
        }
        .filterBarSelect__option--is-focused {
          color: $White;
          background-color: $Brand50;
        }
      }
    }
  }
  .filterBarComponent:has(.filterBarHelp) {
    align-items: flex-start;
    display: flex;
    .filterBarSelect__control {
      padding-right: 22px+16px;
    }
    .filterBarHelp {
      align-items: center;
      display: flex;
      height: $BPMSearchBarContainerHeightLG !important;
      z-index: 13;
      .filterBarHelpIcon {
        color: $Brand50;
        margin: 0px 0px 0px 0px;
        height: 22px !important;
        width: 22px !important;
        margin: -30px;
      }
      .filterBarHelpIcon:hover {
        color: $Brand80;
      }
    }
  }
  .toggle-button ~ .filterBarComponent {
    .filterBarSelect__control {
      border-radius: 0px !important;
      border-left: $BPMSearchBarBorderWidth solid transparent;
      .filterBarSelect__value-container {
        padding: $BPMSearchBarBorderWidth 8px !important;
      }
    }
  }
  .toggle-button.basicOn ~ .filterBarComponent {
    z-index: 10;
  }
  .toggle-button:has(.button-framework:not(.selected):nth-child(2):hover) ~ .filterBarComponent {
    z-index: 10;
  }
}

.BPMFilterBarComponentContainer.sm {
  height: $BPMSearchBarContainerHeightSM;
  .filterBarComponent {
    .filterBarSelect__indicators {
      height: $BPMSearchBarContainerHeightSM !important;
    }
    .filterBarSelect__control {
      height: $BPMSearchBarContainerHeightSM;
      .filterBarSelect__value-container {
        height: ($BPMSearchBarContainerHeightSM - (2 * $BPMSearchBarBorderWidth)) !important;
      }
      .filterBarSelect__indicators {
        height: ($BPMSearchBarContainerHeightSM - (2 * $BPMSearchBarBorderWidth)) !important;
      }
    }
  }
}

.BPMFilterBarComponentContainer.Dropdown {
  .filterBarComponent {
    margin-left: -32px;
    .filterBarSelect__control {
      background-color: $Neutral200;
      border-radius: 0px;
      border: $BPMSearchBarBorderWidth solid $Neutral200;
      color: $Neutral500;
      .filterBarSelect__value-container {
        padding: $BPMSearchBarBorderWidth 8px $BPMSearchBarBorderWidth 32px !important;
        .filterBarSelect__placeholder {
          color: $Neutral500;
          font-family: Graphik !important;
          font-feature-settings: "clig" off, "liga" off;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
    .filterBarSelect__control--is-focused {
      border-radius: 1px;
      border: $BPMSearchBarBorderWidth solid $Brand50;
      color: $Brand100;
      font-family: Graphik !important;
      font-feature-settings: "clig" off, "liga" off;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      .filterBarSelect__value-container {
        .filterBarSelect__placeholder {
          color: $Brand100;
        }
      }
    }
  }
  .searchIconContainer {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 2px 4px;
    pointer-events: none;
    width: 32px !important;
    z-index: 13;
    .searchIcon {
      color: $Neutral500;
      height: $BPMSearchBarIconHeight !important;
      width: $BPMSearchBarIconWidth !important;
    }
  }
}

.BPMFilterBarComponentContainer.Widget {
  .filterBarComponent {
    .filterBarSelect__control {
      border-radius: 6px 0px 0px 6px;
      border: $BPMSearchBarBorderWidth solid $Neutral300;
      border-right: $BPMSearchBarBorderWidth solid transparent;
      .filterBarSelect__placeholder {
        color: $Neutral500;
      }
    }
    .filterBarSelect__control--is-focused {
      border: $BPMSearchBarBorderWidth solid $Brand50;
      color: $Brand100;
      font-family: Graphik !important;
      font-feature-settings: "clig" off, "liga" off;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      .filterBarSelect__placeholder {
        color: $Brand100;
        font-family: Graphik !important;
        font-feature-settings: "clig" off, "liga" off;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
  .filterSubmit {
    align-items: center;
    border-radius: 0px 6px 6px 0px;
    display: flex;
    height: $BPMSearchBarContainerHeightLG !important;
    justify-content: center;
    padding: 2px 4px !important;
    width: 32px;
    z-index: 12;
    .bf-icon {
      height: $BPMSearchBarIconHeight !important;
      width: $BPMSearchBarIconWidth !important;
      * {
        height: $BPMSearchBarIconHeight !important;
        width: $BPMSearchBarIconWidth !important;
      }
    }
  }
}

.filterBarHowto {
  font-size: 0.9rem;
  .carouselContainer {
    display: flex;
    flex-direction: row;
    .carouselControl {
      width: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 40px;
      margin-bottom: 23px;
      cursor: pointer;
      position: relative;
      button {
        position: absolute;
        bottom: 0;
      }
    }
    .carouselInterior {
      flex: 1;
      .carousel-indicators li {
        background-color: black;
      }
      .carousel-item {
        margin: 0 2px 23px;
        height: 400px;
        background-color: white;

        p {
          margin-top: 10px;
        }
        .sampleTable {
          margin: 20px;
          .filterTable {
            margin-top: 10px;
            min-height: 100px;
            max-height: 100px;
            font-size: 12px;
            overflow-y: auto;
          }
        }
      }
    }
  }
}

@use "sass:math";
@import "../../utils/colors.scss";
@import "../../utils/sassHelpers.scss";

.incPlaybookPage {
  .incLineChart {
    max-height: 250px;
    max-width: 650px;
  }
  .incLineChartText {
    color: #000;
    font-family: Graphik;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

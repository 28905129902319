@import "../utils/colors.scss";

.budgetIntakeToolSettings {
  display: flex;
  margin: 16px;
  border: 1.5px solid $Neutral400;
  background-color: white;
  border-radius: 16px;
  flex-direction: column;
  min-height: fit-content;
  height: auto;
  .budgetIntakeToolSettingsHeaderContainer {
    display: flex;
    padding: 24px 24px 16px 24px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    .budgetIntakeToolSettingsHeaderTextContainer {
      display: flex;
      align-items: flex-start;
      align-self: stretch;
      .budgetIntakeToolSettingsHeaderTextColumn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;
        .budgetIntakeToolSettingsHeaderText {
          color: $Brand100;
          font-feature-settings: "liga" off, "clig" off;
          font-family: Graphik;
          font-size: 30px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }
        .bugdetIntakeToolSettingsExplainerText {
          color: $Brand100;
          font-feature-settings: "liga" off, "clig" off;
          font-family: Graphik;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }
  .budgetIntakeToolSettingsLineBelowHeader {
    width: 100%;
    height: 1px;
    opacity: 0.2;
    background: $Neutral400;
  }
  .budgetIntakeToolSettingsBodyContainer {
    display: flex;
    padding: 8px 0px 32px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    .budgetIntakeToolSettingsContainer {
      display: flex;
      padding: 0px 56px;
      align-items: center;
      gap: 32px;
      .budgetIntakeToolSettingsTextAndDropDownContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 32px;

        .budgetIntakeToolSettingsRow {
          display: flex;
          width: 389px;
          align-items: center;
          gap: 24px;
          .budgetIntakeToolSettingsRowHeaderContainer {
            display: flex;
            width: 114px;
            padding: 12px 0px 12px 12px;
            align-items: center;
            gap: 11px;
            flex-shrink: 0;
            .budgetIntakeToolSettingsHeaderText {
              color: $Brand100;
              font-feature-settings: "liga" off, "clig" off;
              font-family: Graphik;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
          }
        }
      }
    }
  }
}

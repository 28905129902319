$primary: #6500cc;
$secondary: #4176cf;
$tertiary: #41da93;

:root {
  --tertiary: #41da93;
  --color-gradient: linear-gradient(180deg, #4176cf 0%, #6500cc 100%);
  --navbar-background: #130d2d;
  --navbar-row-highlight: #261a55;
  --primary-light: #7e57c235;
  --secondary-light: #009fdf35;
}

@import "./index.scss";

.brandEquityHeader {
  margin-bottom: 16px;
  background-color: #fff;
  border-color: black;
  border-radius: 1rem;
  border: 1px solid var(--neutral-400, #cbd2e1);
  display: flex;
  overflow: hidden;
  height: 17rem;
  .brandEquityTextHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
    align-self: stretch;
    .brandEquityTextHeaderTitle {
      font-weight: 600;
      font-size: 1.5rem;
      color: #1f003f;
      width: 24.5rem;
      line-height: 1;
      margin: 1.81rem 0 0 2rem;
    }
    .brandEquityTextHeaderParagraph {
      width: 19.375rem;
      font-weight: 500;
      font-size: 0.875rem;
      color: #1f003f;
      line-height: 1;
      margin: 0.5rem 0 0 2rem;
    }
  }
  .brandEquityHeaderImageContainer {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
  }
}

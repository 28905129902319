@import "../../../utils/colors.scss";

.DPCustomDatePresets {
  width: 465px;
  .customDatePresetContainer {
    padding-top: 5px;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    .DPPresetsControlsContainer {
      display: flex;
      .dropdown-toggle {
        margin-left: 8px;
      }
    }
    .presetTitle {
      font-size: 14px;
      font-weight: 500;
      width: 35px;
      color: $Brand100;
      margin-right: 5px;
      align-self: center;
    }
  }
  .numberInputContainer {
    transition: none;
    animation: none;
    .bf-label {
      display: flex;
      align-items: center;

      .daysInput {
        width: 45px;
        height: 28px;
        display: inline-block;
        background: transparent;
        border: none;
        box-shadow: none;
      }
      .daysInput::-webkit-inner-spin-button,
      .daysInput::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }
    }
  }
  .rangeError {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    height: 14px;
    display: flex;
    padding-right: 100px;
    align-items: flex-start;
    justify-content: flex-end;
    text-wrap: nowrap;
    color: $Error1;
  }
  &.disabled {
    .presetTitle {
      color: $Neutral600;
    }
  }
}

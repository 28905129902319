.dagStartPageContainer {
  background-color: white;
  margin: 20px;
  padding: 20px;

  .selector {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  span {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .dagStartRow {
    display: flex;
    .dagStartFontSize {
      font-size: 1rem;
    }
    .dagStartleft {
      width: 50%;
      padding-right: 5px;
    }
    .dagStartright {
      width: 50%;
    }
  }
}

@import "../utils/colors.scss";
@import "../utils/sassHelpers";

.bpmMasterTargeting {
  height: 100%;
  width: 100%;
  padding: 0.5rem;
  display: flex;

  .masterTargetingTable {
    height: 100%;
    width: 100%;
    .targetingCheckBox {
      color: $White;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #c5d0de;
      font-size: 18px;
      $size: 20px;
      height: $size;
      width: $size;
      min-height: $size;
      min-width: $size;
      border-radius: 3px;
      &.green-check {
        border-color: green;
        background-color: green;
      }
      &.yellow-check {
        border-color: #fcb603;
        background-color: #fcb603;
      }
      &.red-x {
        color: red;
        border-color: red;
        background-color: $White;
      }
    }
  }
}

.masterTargetingActions {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  padding-right: 3px;
  .mtTargetingKey {
    display: flex;
    align-items: center;
    border-radius: 4px;
    height: 2rem;
    background-color: $Grey40;
    padding: 0.5rem 0rem 0.5rem 0.5rem;
    .mtKeySection {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-right: 0.5rem;
      .mtTargetingCheckBox {
        color: white;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid #c5d0de;
        $size: 20px;
        height: $size;
        width: $size;
        border-radius: 3px;
        font-size: 18px;
        margin-right: 0.5rem;
        &.mtGreenCheck {
          border-color: green;
          background-color: green;
        }
        &.mtYellowCheck {
          border-color: #fcb603;
          background-color: #fcb603;
        }
        &.mtRedX {
          color: red;
          border-color: red;
          background-color: $White;
        }
      }
    }
  }
}

@import "../utils/sassHelpers";
@import "../utils/colors";

.modalLegend {
  font-family: Graphik;
}

.modalLegendSection {
  margin-bottom: 24px;
}

.modalLegendtitle {
  font-family: Graphik;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 4px;
  color: $Brand100;
}

.modalItems {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 20px;
  gap: 0.5rem;
}

.modalLabel {
  font-family: Graphik;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: $Brand100;
}

@import "../../utils/colors.scss";

.BPMFilterBar2 {
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 32px;
  position: relative;
  z-index: 10;

  .basicAdvancedToggle {
    border-top: 2px solid $Grey40;
    border-bottom: 2px solid $Grey40;
    border-radius: 4px 0px 0px 4px;

    button {
      position: relative;
      top: -2px;
    }
  }

  .filterBarComponent {
    flex: 1;
    height: 100%;

    .filterBarSelect__control {
      height: 32px;
      border: none;
    }

    .filterBarSelect__control--is-focused {
      border-color: none;
      box-shadow: none;
    }

    .filterBarSelect__value-container {
      height: 32px;
      border-top: 2px solid $Grey40;
      border-bottom: 2px solid $Grey40;
      border-radius: 0px;
      color: $Grey60;
      font-size: 0.875rem;
      font-weight: 500;

      & > * {
        margin: 0px;
        margin-top: -1px;
      }

      .filterBarSelect__placeholder {
        color: $Grey60;
        font-weight: 500;
        margin-left: 8px;
        margin-top: 0px;
      }

      .filterBarSelect__input {
        margin-left: 8px;
        margin-top: 1px;
        display: inherit;

        & > * {
          font-weight: 500;
          color: $Grey100;
        }
      }

      .filterBarSelect__multi-value {
        border-radius: 4px;
        margin-left: 4px;

        &:first-child {
          margin-left: 0px;
        }

        .filterBarSelect__multi-value__label {
          line-height: 1;
        }
      }

      .filterBarSelect__multi-value > * {
        height: 22px;
        display: flex;
        align-items: center;
        background-color: $Grey5;
        font-size: 0.875rem;
      }
    }

    .filterBarSelect__indicators {
      height: 32px;
      border-top: 2px solid $Grey40;
      border-bottom: 2px solid $Grey40;
      border-radius: 0px;
    }

    .filterBarSelect__menu {
      margin: 0px;
    }
  }

  .filterSubmit {
    border-radius: 0px 4px 4px 0px;
    padding: 4px 8px;
  }

  .modeButton > button {
    height: 100%;
    min-width: 100px;
  }
}

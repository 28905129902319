@import "../utils/sassHelpers.scss";
@import "../utils/colors.scss";

.bpmCalendarPageActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  & > * {
    margin-right: 1rem;
  }
  .optionsHelp {
    cursor: pointer;
    margin-right: 0.5rem;
  }
}

.bpmCalendarPage {
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;

  .calendarFilterBar {
    margin-bottom: 1rem;
    z-index: 2;
  }

  .calendarTable {
    z-index: 1;
    flex: 1;
    margin: 0 -1rem -1rem;
    .currentWeek {
      background-color: $non-gradient-purple;
    }
    .currentWeekDateStyle {
      color: white;
    }
    .topDataCell {
      display: flex;
      flex-direction: column;
      img {
        height: 80px;
      }
    }
    .leftWeek {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-right: 0.5rem;
      .calendarHolidayLogo {
        height: 30px;
        margin-right: 0.5rem;
      }
    }
    .contentCell {
      display: flex;
      padding: 0.5rem;
      align-items: stretch;

      .contentBox {
        display: flex;
        flex-direction: column;
        flex: 1;
        border: 3px solid;
        justify-content: center;
        padding-left: 0.5rem;
        background-color: white;
        .contentBoxRow {
          display: flex;
          flex-direction: row;
          & > *:first-child {
            width: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 0.5rem;
          }
          .calendarWarning {
            color: red;
            margin-left: 5px;
          }
        }
      }
      .loadingBox {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-right: 0.5rem;
      }
    }
    .calendarCornerHeader {
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding-bottom: 3px;
      & > * {
        flex: 1;
        font-weight: 500;
        text-align: center;
        font-size: 1.2rem;
      }
    }
  }
}

.stageTwoDiagnostics {
  flex: 1;
  display: flex;

  .creativeSuperHeaders {
    font-style: italic;
  }

  .heatMapCell {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  // TODO: Prevent double borders between touching cells
  .unseenCombo {
    border: 1px solid black;
  }

  .noStageTwoData {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
  }

  .stageTwoNetworkLogos {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    Img {
      height: 90%;
    }
  }

  .leftCell {
    margin-left: 0.35rem;
  }
  .rightCell {
    margin-right: 0.35rem;
  }

  .expandButton {
    width: 40px;
    position: absolute;
    right: 1rem;
  }
}

@use "sass:math";
@import "../utils/sassHelpers.scss";
@import "../utils/colors.scss";

$border-color: rgba(0, 0, 0, 0.4);
$action-box-padding: 2px;
$action-box-border-width: 1px;
$action-box-border-radius: 4px;
$stripe-width: 6px;

$pane-padding: 0.5rem;

.streamingBuyingOrderView {
  border: 1px solid $border-color;
  flex: 1;
  min-height: 0;

  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: $stripe-width;
  .savingOverlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 10000001;
  }
  .panes {
    display: flex;
    flex-direction: row;
    flex: 1;
    min-height: 0;
    .leftPane {
      flex: 2;
      min-width: 0;
      padding: $pane-padding;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
    }
    .rightPane {
      flex: 1;
      min-width: 400px;
      border-left: 1px solid $border-color;
      padding: $pane-padding;
      z-index: 3;
      overflow: auto;
    }
  }

  .orderHeader {
    position: relative;
    border-bottom: 1px solid $border-color;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    .orderInfo {
      position: relative;
      display: flex;
      flex-direction: row;
      height: 50px;
      width: 72%;
      font-size: 20px;
      align-items: center;
      .logo {
        padding: 0.25rem;
        margin-right: -0.5rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        & > img {
          max-height: 100%;
        }
      }
      .text {
        margin-left: 0.75rem;
        &.order {
          font-weight: 500;
        }
        .subText {
          font-size: 16px;
        }
      }
    }
    .geoTargetingLink {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 28%;
      height: 50px;
      font-size: 0.875rem;
      justify-content: flex-end;
      margin-right: 0.5rem;
    }
  }

  .buttonRow {
    display: flex;
    flex-direction: row;
    &.topRow {
      margin-left: -11px; // This is to adjust for the back button link
    }
    & > *:not(:first-child) {
      margin-left: 0.5rem;
    }
    & > *:disabled {
      cursor: not-allowed;
    }
  }
  .flightListFilterBar {
    margin-bottom: 1rem;
  }
  .flightListScrollBox {
    min-height: 0;
    overflow-x: auto;
    border: 1px solid rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
    .flightListBox {
      flex: 1;
      min-height: 0;
      display: flex;
      flex-direction: column;
      font-size: 0.9rem;
      min-width: 750px;

      .flightList {
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        min-height: 0;
      }
      .listHeader {
        font-weight: 500;
        border-bottom: 2px solid rgba(0, 0, 0, 0.4);
        .flightRow {
          cursor: default;
        }
      }
      .listTotals {
        border-top: 2px solid rgba(0, 0, 0, 0.3);
        .flightRow {
          cursor: default;
        }
      }
      $topPadding: 0.5rem;

      .flightRow {
        cursor: pointer;
        display: flex;
        flex-flow: row;
        border-radius: 0 !important;
        $sidePadding: 0.75rem;
        padding: $topPadding $sidePadding;
        transition: all 0.15s ease;
        & > *,
        & > * > * {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &.isNew {
          font-weight: 500;
        }
        &.active {
          background-color: $primary;
          border-color: $primary;
          color: white;
        }
        &.canceled {
          position: relative;
          &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            // Turns out, if the first stop point is 0px, sass is somehow compiling it out. So I
            // need to shift everything
            $stripe-start: 1px;
            $stripe-width: 2px;
            $stripe-space: 5px;
            border: $topPadding solid black;
            border-image: repeating-linear-gradient(
                -45deg,
                transparent $stripe-start $stripe-space,
                rgba(255, 0, 0, 0.4) ($stripe-start + $stripe-space)
                  ($stripe-start + $stripe-space + $stripe-width)
              )
              10;
          }
        }

        .strikethrough {
          text-decoration: line-through;
          color: #999999;
        }
        .change {
          font-weight: 500;
        }
        .id {
          flex: 1;
          display: flex;
          align-items: center;

          .alertIcon {
            margin-left: 8px;
          }
        }
        .description {
          flex: 2;
          position: relative;

          .derivedID {
            position: absolute;
            font-size: 0.6rem;
            right: 0.5rem;
            top: 4px;
            display: flex;
            flex-direction: row;
            align-items: center;
            border-radius: 10px;
            padding: 1px 5px 0px;
            margin-right: 4px;
          }
        }
        .properties {
          flex: 2;
        }
        .dateBox {
          display: flex;
          flex-direction: row;
          min-width: 190px;
          flex: 1;
          .start {
            text-align: right;
          }
          .end {
            justify-content: flex-start;
          }
          .arrow {
            flex: 0 16px;
            margin: 0.15rem 0.15rem;
          }
        }
        .labeledItem {
          display: flex;
          flex-direction: row;
          & > *:first-child {
            margin-right: 0.25rem;
          }
        }
        .spend {
          flex: 1;
          min-width: 110px;
        }
        .impressions {
          flex: 1;
          min-width: 110px;
        }
        .cpm {
          flex: 1;
          width: 80px;
        }
        .actions {
          flex: 0 32px;
          flex-direction: row;
          margin: -0.25rem 0;
          padding: 0;
          position: relative;
          overflow: visible;
          border-radius: 0.2rem;
          align-self: center;
          .noChanges {
            background-color: white;
            &:hover {
              background-color: $primary;
              color: white;
            }
          }
          .actionBox {
            position: absolute;
            z-index: 10;
            background-color: white;
            white-space: nowrap;
            width: max-content;
            right: -$action-box-padding * 2;
            top: math.div(-$action-box-padding, 2) - 2 * $action-box-border-width;
            padding: $action-box-padding $action-box-padding * 2;
            border-radius: $action-box-border-radius;
            border: $action-box-border-width solid $primary;
            & > *:not(:first-child) {
              margin-left: 0.5rem;
            }
          }
        }
      }
    }
  }
  .orderControls {
    margin: auto (-$pane-padding) (-$pane-padding);
    .expander {
      border-radius: 0;
    }
    .orderControlsBody {
      padding: 0.5rem ($pane-padding + 0.5rem);
      border: 1px solid $primary;
      border-left-width: 2px;
      .pdfText {
        margin-bottom: 0.5rem;
        textarea {
          font-size: 0.9rem;
          resize: none;
        }
      }

      .ghostBiddingOptions {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        .ghostBiddingCheckbox {
          margin-right: 4px;
        }

        .ghostBiddingInfo {
          margin-left: 4px;
        }
      }

      .buttonRow {
        display: flex;
        align-items: center;

        button {
          max-height: 50px;
        }

        .beeswaxSyncAlert {
          margin: 0 0 0 24px;
          flex: 1;
          text-align: center;
        }
      }
    }
  }
  .flightForm {
    font-size: 0.9rem;
    .header {
      display: flex;
      align-items: center;
      flex-flow: row nowrap;
      font-size: 1.5rem;
      line-height: 1.5rem;
      border-bottom: 1px solid $border-color;
      padding: 0.5rem 0;
      margin-bottom: 0.5rem;
      .title {
        flex: 1;
        align-self: center;
      }
      .pasteButton {
        flex: 0 32px;
        align-self: center;
        padding-bottom: 0.5rem;
      }
      .tooltipContainer {
        margin-right: 0.5rem;
      }
    }
    .buttonRow {
      .saveContainer {
        margin-left: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        .createAnother {
          font-size: 1rem;
          margin-left: auto;
          margin-right: 0.5rem;
          margin-top: 3px;
        }
      }
    }
    .derivedNetworkInput {
      cursor: pointer;
    }
    .copiedToast {
      position: absolute;
      z-index: 10;
    }
    .form-group {
      margin-bottom: 0.25rem;
    }
    .formDatePicker {
      flex-grow: 0;
      .form-label {
        margin-bottom: 0;
      }
      .DateRangePicker {
        display: block;
      }
      .infoOutline {
        margin-left: 4px;
        margin-bottom: 2px;
      }
    }

    .multiDatePicker {
      width: 200px;
      margin-top: 10px;
    }

    .canceledRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 0.5rem;
      & > *:not(:first-child) {
        margin-left: 0.5rem;
      }
      .label {
        flex: 1;
        font-size: 0.8rem;
        color: red;
      }
    }
    .wrenchSection {
      border: 1px solid $border-color;
      padding: 0.5rem;
      margin: 0.5rem 0;
      .hint {
        font-size: 0.8rem;
      }
    }
    // because this is wrapped in an OverlayTrigger
    // https://github.com/facebook/react/issues/4251#issuecomment-529182276
    .prorate:disabled {
      pointer-events: none;
    }
    .propertyList {
      display: flex;
      flex-flow: row wrap;

      &:not(.past) > * {
        text-decoration: underline;
        transition: color 0.12s ease;
        cursor: pointer;
        &:hover {
          color: red;
        }
      }
      & > *:not(:last-child) {
        margin-right: 0.25rem;
        &::after {
          content: ",";
        }
      }
    }
    .subFlightList {
      border-top: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
      margin: 0.25rem 0;
      padding-top: 0.25rem;
      display: flex;
      flex-direction: column;
      .subFlight {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 0.5rem;
        & > * {
          min-width: 0;
        }
        &.headers {
          font-weight: 500;
        }
        &.notCurrent {
          color: #888888;
        }
        .property {
          flex: 1;
          padding-right: 0.25rem;
          &.changed {
            font-weight: 500;
          }
        }
        .date {
          flex: 2;
        }
      }
    }
  }
  .sendView {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    min-height: 0;

    .mailPane {
      position: relative;
      width: 400px;

      display: flex;
      flex-direction: column;
      min-height: 0;
      .emails {
        flex: 1;
        min-height: 0;
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        .input-group {
          margin-bottom: 0.25rem;
          .inputLabel {
            width: 35px;
            justify-content: center;
          }
        }
        .ccs {
          flex: 1;
          min-height: 0;
          overflow: auto;
          margin-bottom: 0.5rem;
          border-bottom: 1px solid $border-color;
        }
      }
      .content {
        border-top: 1px solid $border-color;

        flex: 1;
        min-height: 0;
        display: flex;
        flex-direction: column;
        padding: 0.5rem;
        .hint {
          font-size: 0.7rem;
        }
        .bodyTextArea {
          flex: 1;
          margin-top: 0.25rem;
          font-size: 0.8rem;
        }
      }
      .buttonRow {
        padding: 0 0.5rem 0.5rem;
        justify-content: space-between;
      }
    }
    .pdfViewer {
      flex: 1;
      position: relative;
      display: flex;
      iframe {
        flex: 1;
      }
    }
  }
}

@use "sass:math";
@import "../styles/Fonts.scss";
@import "../utils/colors.scss";
@import "../utils/sassHelpers.scss";

.presetDropdown {
  height: fit-content;
  width: fit-content;
  .dropdown-menu {
    max-height: 400px;
  }
  .dropdownMenuBodyContainer {
    min-width: 221px;
    width: fit-content;
    .dropdownMenuItemsContainer {
      min-width: 221px;
      width: fit-content;
      .dropdownMenuItem {
        padding-right: 0px !important;
        height: fit-content !important;
        .dropdownMenuItemLabel {
          text-wrap: nowrap;
          width: fit-content;
        }
        .dropdownMenuItemOptions {
          padding-right: 6px;
          .presetOptionExpander {
            align-items: center;
            display: flex;
            * {
              color: $Brand50;
              height: 22px;
              width: 22px;
            }
          }
        }
      }
      .dropdownMenuItem.dropdownOpen {
        background-color: $Brand25;
        .dropdownMenuItemOptions {
          .presetOptionExpander {
            background-color: $Brand40;
            border-radius: 6px;
            * {
              color: #ffffff;
              height: 22px;
              width: 22px;
            }
          }
        }
      }
      .BPMFilterBarComponentContainer {
        width: fit-content;
      }
    }
  }
  .dropdownMenuApplyCancelContainer {
    padding-bottom: 16px;
    padding-left: 24px;
  }
  .dropdownMenuFooterContainer {
    background-color: $Brand25;
    padding: 0px;
    .addNewPresetButton {
      border-radius: 0px;
      color: $Brand100;
      font-family: Graphik !important;
      font-feature-settings: "clig" off, "liga" off;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      height: 100% !important;
      justify-content: flex-start;
      line-height: normal;
      padding: 0px 12px;
      width: 100% !important;
    }
  }
  .cl-dropdown.show {
    .cl-dropdown-toggle {
      background-color: $Brand50 !important;
      .dt-icon,
      .dt-text {
        color: #ffffff;
      }
    }
  }
}

.presetOptionsPresetDropdown {
  align-items: flex-start;
  background: #ffffff;
  border-radius: 6px;
  border: 2px solid $Brand50;
  box-shadow: 0px 4px 18px 0px rgba(148, 160, 184, 0.5);
  display: flex;
  flex-direction: column;
  height: fit-content;
  overflow: hidden;
  padding: 8px 0px;
  width: fit-content;
  .presetOption {
    align-items: center;
    display: flex;
    gap: 6px;
    height: 28px;
    padding: 0px 8px;
    width: 100%;
    .presetOptionIcon {
      align-items: center;
      display: flex;
      * {
        color: $Brand50;
        height: 18px;
        width: 18px;
      }
    }
    .presetOptionText {
      align-items: center;
      color: $Brand100;
      display: flex;
      font-family: Graphik !important;
      font-feature-settings: "clig" off, "liga" off;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .presetOption:hover {
    background-color: $Brand20;
    cursor: pointer;
  }
  .presetOption:active {
    background-color: $Brand25;
  }
  .presetLastModifiedContainer {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 12px 12px 8px 12px;
    width: 100%;
    .presetLastModifiedText {
      color: $Neutral600;
      font-feature-settings: "liga" off, "clig" off;
      font-family: Graphik;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 12px */
    }
  }
  .presetLastModifiedContainer:hover {
    background-color: $Brand20;
    cursor: text;
  }
  .presetLastModifiedContainer:active {
    background-color: $Brand25;
  }
}

.presetDropdown.loading {
  pointer-events: none;
  * {
    opacity: 0.5;
  }
}

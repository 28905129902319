$sidePadding: 30px;

.pendingSegmentationChangesContainer {
  position: fixed;
  z-index: 20;
  top: 0;
  right: 0;
  bottom: 0;
  padding-top: 36px;
  transition: width 0.2s ease;
  width: 33%;

  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  overflow: auto;
  box-shadow: 0 2px 10px #888;

  .pendingSegmentationChangesHeader {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    width: 100%;
    padding: 1rem $sidePadding;
    height: 63px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 18px;
    color: #130d2d;
    border-bottom: 1px solid #e6eaee;

    .closeButton {
      position: absolute;
      font-size: 28px;
      right: $sidePadding;
      top: 8px;
      cursor: pointer;
    }
  }

  .pendingSegmentationChangesBody {

    .unlabeledSection,
    .labeledSection {
      padding-left: $sidePadding;
      padding-right: $sidePadding;
    }
  }
}

.pendingSegmentationChangesBody {
  overflow: auto;
  width: 100%;

  .unlabeledSection,
  .labeledSection {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;

    .editsTitle {
      font-weight: 500;
      margin-bottom: 1rem;

      .warningText {
        font-weight: 400;
        font-size: 14px;
        color: #dc143c;
      }
    }

    .editContainer {
      display: flex;
      flex: 1;
      flex-direction: column;
      border: 1px solid gray;
      border-radius: 20px;
      margin-bottom: 1rem;

      .editTitle {
        border-bottom: 1px solid gray;
        padding: 0.5rem;
        display: flex;
        justify-content: center;
        word-break: break-all;
      }

      .editsList {
        padding: 0.25rem;
        display: flex;
        flex-direction: column;

        .edit {
          padding: 0.25rem;
          display: flex;
          align-items: center;

          & > * + * {
            margin-left: 0.5rem;
          }

          .deleteButton {
            border-radius: 50%;
            border: none;
            padding: 4px;
          }

          .deleteButtonSpacer {
            width: 28px;
          }

          .segmentName {
            font-weight: 500;
          }

          .oldValue {
            color: #dc143c;
          }

          .newValue {
            color: green;
          }
        }
      }
    }
  }
}

.incompleteUpdatesModal {
  max-height: calc(100vh - 3.5rem);
}
@import "../utils/sassHelpers";

.geoControls {
  display: flex;
  align-items: center;

  .kpiSelector {
    margin-right: -0.375rem;
  }

  & > *:not(:first-child) {
    margin-right: 1.5rem;
  }
}

.bpmStreamingGeo {
  padding: $page-padding;
  width: 100%;
  height: 100%;
  display: flex;

  .geoTable {
    min-width: 650px;
    max-width: 650px;
    height: 100%;
    margin-right: 24px;

    .geoEntry {
      text-align: center;
      font-size: 0.8rem;
      padding: 5px;
    }
  }

  .geoMap {
    flex: 1;
    margin-top: 90px;
  }
}

@import "../utils/sassHelpers.scss";
@import "../utils/colors.scss";

.activeottActions {
  display: flex;
  align-items: center;
  flex-flow: row wrap-reverse;
  justify-content: flex-end;
  margin-bottom: -0.5rem;
  & > * {
    margin-bottom: 0.5rem;
  }

  .configButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 1rem;
    & > *:not(:first-child) {
      margin-left: 0.5rem;
    }
  }
}

.activeott {
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  .noData {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
  }
  .filterBarArea {
    height: 38px;
    z-index: 2;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    & > *:not(:first-child) {
      margin-left: 0.5rem;
    }
  }
  .table {
    z-index: 1;
    flex: 1;
    .currentWeek {
      background-color: $non-gradient-purple;

      font-weight: 500;
      color: white;
    }

    .activeOTTRightHeaders {
      height: 100%;
      display: flex;
      align-items: flex-end;
      padding-bottom: 3px;
      & > * {
        flex: 1;
        font-weight: 500;
        text-align: center;
        font-size: 1.2rem;
      }
    }
    .activeOTTRightColumn {
      display: flex;
      & > * {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .activeOTTCell {
      display: flex;
      flex-direction: row;
      justify-content: center;
      &.hasVals {
        cursor: pointer;
      }
      & > * {
        display: flex;
        flex: 1;
        align-items: center;
        min-width: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        &:first-child {
          justify-content: flex-end;
          margin-right: 1rem;
        }
        &:last-child {
          justify-content: flex-start;
          margin-left: 1rem;
        }
        &:only-child {
          justify-content: center;
          margin: 0;
        }
        &.spacer {
          flex: 0;
          background-color: rgba(0, 0, 0, 0.2);
          height: 50%;
        }
      }
    }

    .activeOTTHeader {
      @include fullBox();

      font-size: 11px;
      display: flex;
      flex-direction: column;

      &.companyLogoHeader {
        padding: 2px 5px;
        & > * {
          display: flex;
          align-items: center;
          flex-direction: column;
          &.logo {
            flex: 1;
            min-height: 0;
            padding: 5px 0;
            img {
              height: 100%;
            }
          }
          &.platform {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          &.description {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-align: center;
          }
        }
      }
    }
  }
}

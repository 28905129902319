@import "../../utils/colors.scss";
@import "../../styles/Fonts.scss";

.widgetContainer {
  border: 1.5px solid $Neutral400;
  border-radius: 16px;
  margin-bottom: 16px;
  padding: 32px 24px 22px 24px;
  transition: height 0.5s;
  width: 100%;

  &.enableHoverDesign {
    &:hover {
      .chartContainer > .header {
        .rightSide {
          display: flex;
        }

        .subHeader {
          display: flex;
        }
      }
    }
  }

  &.closed {
    height: 75px;
  }

  &.primary {
    background-color: $Neutral300;

    .widgetBody {
      .widgetContainer {
        &.secondary:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  &.disabled {
    background-color: $Neutral200;

    .headerRow {
      .headerLeft {
        .primaryTitleSize {
          color: $Neutral400;
        }

        .secondaryTitleSize {
          color: $Neutral400;
        }

        .subheader {
          color: $Neutral400;
        }
      }

      .headerRight {
        .rightActions {
          color: $Neutral400;
        }
      }
    }

    .widgetBody {
      .widgetBottom {
        color: $Neutral400;
      }
    }
  }

  &.secondary {
    background-color: $White;
    padding: 16px;
    padding-top: 22px;

    :last-of-type {
      margin-bottom: 0;
    }

    &.closed {
      .headerRow {
        border-bottom: none;
      }
    }

    .headerRow {
      border-bottom: 1px solid $Neutral400;
      padding-bottom: 19px;
    }

    .widgetBody {
      padding: 4px 24px 8px 24px;
    }
  }

  .headerRow {
    display: flex;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 16px;

    &.headerCollapsibleOnclick {
      cursor: pointer;
    }

    .headerLeft {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .primaryTitleSize {
        @include largeTitle;
      }

      .cl-dropdown-toggle.dropdown-toggle.btn.btn-primary.widget-title.light.primaryTitleSize .dt-text {
        @include largeTitle;
        font-weight: 500;
      }

      .secondaryTitleSize {
        @include regularTitle;
      }

      .cl-dropdown-toggle.dropdown-toggle.btn.btn-primary.widget-title.light.secondaryTitleSize .dt-text {
        @include regularTitle;
        font-weight: 500;
      }

      .subheader {
        align-items: center;
        display: flex;
        @include smallTitle;

        &.closed {
          opacity: 0;
          transition: opacity 0.5s;

          .tooltip-container {
            opacity: 0;
            transition: opacity 0.5s;
          }
        }

        .text {
          align-items: center;
          display: flex;

          .tooltip-container {
            margin-left: 16px;
            top: 1px;
          }
        }
      }
    }

    .headerRight {
      display: flex;

      .rightActions {
        display: flex;
      }

      .openCloseArrow {
        color: $Brand60;
        cursor: pointer;
        height: 22px;
        width: 22px;
      }
    }
  }

  .widgetBody {
    margin-top: 31px;

    &.closed {
      display: none;
    }
  }

  .widgetBottom {
    @include smallTitle;
    font-weight: 500;
    margin-top: 16px;
  }
}
@use "sass:math";
@import "../../utils/colors.scss";

$dimension-padding: 0.35rem;
$side-margin: 1.7rem;
$gridBorderColor: #e6eaee;

// NOTE: adjust the constants in the TSX file if these change
$colWidth: 270px !important;
$dividerWidth: 10px;
$halfGutter: 8px;

.editTablePresetModal {
  display: flex;
  min-width: 0px;
  min-height: 0px;
  .modal-dialog {
    margin-left: 10vw;
    margin-right: 10vw;
    max-width: 80vw;
    width: 80vw;
  }
  .modal-content {
    height: 100%;
    .modal-body {
      overflow: auto;
      .dropdownMenuContainer {
        max-height: 350px;
        .dropdownMenuItemsContainer {
          overflow-y: scroll;
        }
      }
      .configBox {
        border-top: unset !important;
        border-bottom: unset !important;
        padding: 16px 0px;
        align-items: flex-start;
        background-color: white;
        display: flex;
        flex-direction: column;
        flex: 1;
        min-height: 0;
        .sortingBox {
          align-items: center;
          align-self: flex-start;
          border-radius: 5px;
          border: 2px solid $gridBorderColor;
          display: flex;
          flex-direction: row;
          margin: 0.5rem $side-margin 0;
          min-height: 60px;
          min-width: 200px;
          padding: 0.5rem;
          transition: background-color 0.12s ease;
          &.isOver {
            background-color: #eeeeee;
          }
          .dragHere {
            font-size: 0.7rem;
            font-style: italic;
            margin-left: 0.75rem;
            margin-top: 1px;
          }
          .sortItemBox {
            display: flex;
            flex-direction: row;
            margin-left: 1rem;
            .sortItem {
              $borderWidth: 8px;
              $iconPadding: 2px;
              $iconSize: 18px;
              background-color: white;
              border-radius: $borderWidth;
              border: $borderWidth solid black;
              cursor: grab;
              font-size: $iconSize;
              height: $iconSize + ($iconPadding + $borderWidth) * 2;
              line-height: $iconSize;
              margin: 0 0.25rem;
              padding: $iconPadding;
              transition: background-color 0.12s ease;
              width: $iconSize + ($iconPadding + $borderWidth) * 2;
              &:hover {
                background-color: #dddddd;
              }
              & > * {
                cursor: pointer;
              }
              &.isOver {
                background-color: #dddddd;
              }
            }
          }
        }
        .configBoxInner {
          $buttonWidth: 28px;
          $superHeaderHeight: 40px;
          $topMargin: 30px;
          display: flex;
          flex-direction: row;
          flex: 1;
          max-width: 100%;
          overflow: auto;
          padding-left: $halfGutter * 3;
          padding: 2 * $halfGutter $halfGutter;
          position: relative;
          &.hasHeaders {
            .columnConfigOuter {
              margin-top: $superHeaderHeight + $halfGutter * 2;
            }
            .addButton {
              top: $superHeaderHeight + $halfGutter;
            }
            .addHeaderButton {
              top: $superHeaderHeight + math.div($halfGutter, 2);
            }
          }
          .addButton {
            position: relative;
            overflow: visible;
            top: -$halfGutter;
            height: 0;
            color: $primary;
            cursor: pointer;
            &::before {
              content: "";
              position: absolute;
              width: $buttonWidth;
              left: math.div(-$buttonWidth, 2);
              height: $buttonWidth;
              border-radius: 50% 50% 50% 0;
              border: 2px solid $primary;
              transform: rotate(-45deg);
            }
            &::after {
              content: "+";
              position: absolute;
              font-weight: 500;
              $fontSize: 30px;
              font-size: $fontSize;
              height: $fontSize;
              transform: translateX(-50%) translateY(-25%);
            }
            &:hover {
              .innerPicker {
                display: block;
              }
              &::after {
                color: white;
              }
              &::before {
                background-color: $primary;
              }
            }
          }

          .addHeaderButton {
            left: math.div($colWidth, 2) + math.div($buttonWidth, 4);
            top: math.div(-$halfGutter, 2);
            &::before {
              transform: rotate(135deg) !important;
            }
          }

          .superHeaderContainer {
            position: relative;
            top: -$halfGutter;
            left: $halfGutter;
            height: $superHeaderHeight;
            width: 0;

            & > * {
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              border: 2px solid $gridBorderColor;
              padding: 0 0.5rem;
              display: flex;
              align-items: center;
              justify-content: center;
              & > *:not(:first-child) {
                margin-left: 0.5rem;
              }
              .sizerBox {
                display: flex;
                flex-direction: row;
              }
            }
          }

          .divider {
            min-width: 10px;
            background-color: #666666;
            min-height: 100%;
            margin: -$halfGutter 0;
            margin-right: $halfGutter * 3;
          }
        }
      }
    }
  }
}

@import "../../utils/colors.scss";

.modal-dialog:has(.alertDialog) {
  &:not(.modal-xl) {
    max-width: 574px;
  }

  .alertDialog {
    .modal-body {
      display: flex;
      flex-direction: row;
      padding: 1.5rem 1.5rem 1rem;

      > * {
        margin-bottom: 0;
      }

      svg {
        margin-right: 1.5rem;
        height: 100%;
      }

      .dialogTitle {
        margin-bottom: 1rem;
        font-weight: 600;
      }

      .dialogBody {
        font-weight: 500;
      }
    }

    .modal-footer {
      padding: 1rem 1.5rem 1rem;

      button {
        margin: 0;

        &:last-child {
          margin-left: 1rem;
        }
      }

      .filled-button:hover {
        opacity: 0.9;
      }
    }
  }

  .success {
    border: 2px solid $Success700;

    .modal-body {
      svg {
        color: $Success700;
      }
    }

    .modal-footer {
      .filled-button,
      .filled-button:hover:not(.disabled) {
        background-color: $Success700;
      }
    }
  }

  .error {
    border: 2px solid $Danger700;

    .modal-body {
      svg {
        color: $Danger700;
      }
    }

    .modal-footer {
      .filled-button,
      .filled-button:hover:not(.disabled) {
        background-color: $Danger700;
      }
    }
  }

  .warning {
    border: 2px solid $Warning1;

    .modal-body {
      svg {
        color: $Warning1;
      }
    }

    .modal-footer {
      .filled-button,
      .filled-button:hover:not(.disabled) {
        background-color: $Warning1;
      }
    }
  }

  .info {
    border: 2px solid $Brand50;

    .modal-body {
      svg {
        color: $Brand50;
      }
    }

    .modal-footer {
      .filled-button,
      .filled-button:hover:not(.disabled) {
        background-color: $Brand50;
      }
    }
  }
}

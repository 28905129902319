@use "sass:math";

@import "../utils/sassHelpers.scss";
@import "../utils/colors.scss";

$circle-diameter: 14px;
$circle-padding: 10px;

.marketingPortfolioStyleImpressions {
  font-style: italic;
}

.marketingPortfolioTooltip {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 25px;
  box-shadow: 0px 2px 10px 0px rgba(230, 234, 238, 1);
  border-radius: 2px;

  .date {
    font-weight: 500;
  }

  .total {
    margin-bottom: 1rem;
    color: #9795a1;
    .totalName {
      font-weight: 500;
    }
  }

  .portfolioList {
    display: flex;
    flex-direction: column;
    .portfolioRow {
      display: flex;
      flex-direction: row;
      .circle {
        width: $circle-diameter;
        height: $circle-diameter;
        border-radius: math.div($circle-diameter, 2);
        margin-right: 12px;
      }
      .name {
        font-size: 14px;
        font-weight: 500;
        margin-right: 3rem;
      }
      .value {
        margin-left: auto;
        font-size: 14px;
      }
    }
  }
}
.homePageActions {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  .rightAlignedActions {
    margin-left: auto;
    margin-right: 27px;
  }
}

.homePageContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: $page-padding;
  overflow: auto;

  .topRowContainer {
    display: flex;
    flex-direction: row;
    height: 420px;
    align-items: center;
    margin-bottom: 0.5rem;

    .overviewContainer {
      display: flex;
      flex-direction: column;
      flex: 1;
      border: 1px solid #e6eaee;
      background-color: white;
      height: 420px;
      margin: 0.5rem;
      .overviewHeader {
        padding-left: 21px;
        padding-top: 1rem;
        font-size: 18px;
        font-weight: 500;
      }
      .overviewChart {
        padding: 21px;
        height: 100%;
        margin-bottom: 1rem;

        .overviewListGroup {
          height: 100%;
          display: flex;
          flex: 1;

          .overviewListItem {
            display: flex;
            margin-top: 0.5rem;
            border: 1px solid rgba(0, 0, 0, 0.1);
            flex: 1;
            align-items: center;
            flex-direction: row;

            .mediaIcon {
              display: flex;
              align-items: center;
              margin-right: 1rem;
            }
            .mediaDescription {
              display: flex;
              flex-direction: column;
              .mediaName {
                color: #8c8a97;
                font-weight: 500;
              }
              .mediaValues {
                display: flex;
                flex-direction: row;
                align-items: center;
                .mediaTotal {
                  font-size: 22px;
                  font-weight: 500;
                  margin-right: 0.5rem;
                }
                .mediaChange {
                  color: white;
                  font-size: 12px;
                  font-weight: 500;
                  border-radius: 4px;
                  height: 22px;
                  width: 46px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  &.positive {
                    background-color: #43a047;
                  }
                  &.negative {
                    background-color: #b71c1c;
                  }
                  &.neutral {
                    background-color: #6e6e6e;
                  }
                }
              }
            }
          }

          &.count2 {
            .overviewListItem {
              .mediaIcon {
                & > * {
                  height: 70px;
                  width: 70px;
                }
              }
            }
          }

          &.count1 {
            .overviewListItem {
              flex-direction: column;
              justify-content: center;
              .mediaIcon {
                margin-bottom: 2rem;
                margin-right: 0rem;
                & > * {
                  height: 88px;
                  width: 88px;
                }
              }
              .mediaDescription {
                .mediaValues {
                  flex-direction: column;
                  .mediaTotal {
                    margin-right: 0rem;
                    margin-bottom: 0.75rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .summaryTableContainer {
    display: flex;
    flex: 2;
    flex-direction: column;
    border: 1px solid #e6eaee;
    margin: 0.5rem;
    height: 420px;
    background-color: white;
    overflow-x: hidden;

    .summaryTitle {
      border-bottom: 1px solid #e6eaee;

      padding: 0.5rem 0.5rem 0.5rem 21px;
      font-size: 18px;
      font-weight: 500;
      display: flex;
      align-items: center;
      flex-direction: row;
      .btn-group-sm > .btn {
        font-size: 0.75rem;
      }
      .title {
        margin-right: 1rem;
      }
      .downloadButton {
        margin-left: auto;
        .btn-light {
          background-color: white;
          border: none;
        }
        .dropdown-toggle:after {
          content: none;
        }
      }
      .dropdownIcon {
        color: #bec7d2;
        font-size: 25px;
      }
    }

    .kpiDebugMenuItem {
      display: flex;
    }

    .summaryTable {
      display: flex;
      flex: 1;
      color: #4e4e50;

      .topHeader {
        & > * {
          border-bottom: 1px solid #e6eaee;
        }
      }

      .bottomHeader {
        & > * {
          border-top: 1px solid #e6eaee;
        }
      }

      .STBottomHeader {
        padding-top: 10px;
      }

      .stickyTable {
        overflow: hidden;
      }

      .stickyTableCell {
        justify-content: flex-start;
        padding-left: 1rem;
      }

      .filterBarContainer {
        margin-bottom: 0;
      }
      .grandTotalCell {
        font-weight: 500;
      }
      .summaryDate {
        font-weight: 500;
      }
    }
  }

  .rowContainer {
    display: flex;
    flex-direction: row;
    .homeChartContainer {
      display: flex;
      flex: 1;
      flex-direction: column;
      border: 1px solid #e6eaee;
      margin: 0.5rem;
      background-color: white;
      height: 400px;
      padding-bottom: 2rem;
      .chartHeader {
        border-bottom: 1px solid #e6eaee;
        padding: 0.5rem 0.5rem 0.5rem 21px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 48px;
        .downloadButton {
          .btn-light {
            background-color: white;
            border: none;
          }
          .dropdown-toggle:after {
            content: none;
          }
          .dropdownIcon {
            color: #bec7d2;
            font-size: 25px;
          }
        }
        .title {
          font-size: 18px;
          font-weight: 500;
          align-self: center;
          .dailyResponseTimezone,
          .microChartTimezone {
            margin-left: 0.25rem;
          }
          .dailyResponseSubTitle,
          .microChartSubTitle {
            margin-left: 0.5rem;
            font-size: 14px;
            font-weight: normal;
          }
        }
        .tooltipContainer {
          margin-left: 0.5rem;
          margin-right: auto;
          align-self: center;
          height: 16px;
        }
      }
      .chartData {
        display: flex;
        flex: 1;

        .liftChartLegend {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          .circle {
            width: $circle-diameter;
            height: $circle-diameter;
            border-radius: math.div($circle-diameter, 2);
            margin-left: 12px;
            margin-right: 6px;
          }
          .withTv {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
          .noTv {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }

        .recharts-tooltip-wrapper {
          z-index: 100;
          .recharts-default-tooltip {
            border-style: hidden !important;
            box-shadow: 0px 2px 10px 0px rgba(230, 234, 238, 1);
            border-radius: 2px;
          }
        }

        .recharts-tooltip-label {
          font-size: 14px;
        }

        &#responses_chart {
          .recharts-tooltip-item-name {
            font-weight: 500;
          }
          .recharts-tooltip-item-separator {
            font-weight: 500;
            color: #4e4e50;
          }
          .recharts-tooltip-item-value {
            color: #4e4e50;
          }
        }

        &#lift_chart,
        &#volume_efficiency_chart {
          .recharts-tooltip-item-name {
            font-weight: 500;
            color: black;
          }
          .recharts-tooltip-item-separator {
            font-weight: 500;
            color: black;
          }
          .recharts-tooltip-item-value {
            color: #4e4e50;
          }
        }

        &#seasonality_chart,
        &#day_of_week_chart {
          .recharts-tooltip-item-name {
            font-weight: 500;
            color: #0082b4;
          }
          .recharts-tooltip-item-separator {
            font-weight: 500;
            color: #0082b4;
          }
          .recharts-tooltip-item-value {
            font-weight: 500;
            color: #0082b4;
          }
        }
      }
      .ingestionInfo {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-evenly;
        .ingestionTable {
          table-layout: auto;
          width: 100%;
          height: 100%;
          .tableHead {
            background-color: #f7f8fa;
            color: #8c8a97;
            font-size: 12px;
            .record {
              padding-left: 21px;
            }
            .record,
            .start,
            .end {
              border-top: none;
              border-bottom: none;
            }
          }
          .tableBody {
            font-size: 13px;
            color: #4e4e50;
            .tableRow {
              .record {
                padding-left: 21px;
              }
              .start,
              .end {
                font-weight: 500;
              }
              & > * {
                vertical-align: middle;
              }
            }
            .btn {
              background-color: $primary;
            }
          }
        }
      }
    }
  }
}

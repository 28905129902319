@import "../../utils/colors.scss";

.offlineInputsContainer {
  position: absolute;
  height: calc(100% - 32px);
  width: calc(100% - 32px);
  min-width: calc(100% - 32px);
  display: flex;
  flex-direction: column;

  .nav {
    height: 50px;
    background: #fff;
    margin: -1rem -1rem 0;
    padding: 0.5rem 1rem;
    border-bottom: 1.5px solid var(--neutral-400, #cbd2e1);
    align-items: center;

    .nav-item {
      padding: 0 12px;

      .nav-link {
        font-weight: 600;
        padding: 0.5rem 0;
      }

      .nav-link {
        padding: 8px 4px;
        line-height: 17.6px;

        &.active {
          color: var(--brand-60, #6b2def);
          padding-bottom: 5px;
          border-bottom: 3px solid var(--brand-60, #6b2def);
        }

        &:not(.active) {
          color: var(--brand-brand-30, #b5a6ff);

          &.disabled {
            color: var(--neutral-300, #e1e6ef);
          }
        }
      }
    }
  }

  .offlineInputsGroup {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: #fff;
    border: 1.5px solid var(--neutral-400, #cbd2e1);
    border-radius: 1rem;
    margin: 1rem 0 0;
    padding: 1.5rem;

    .tablesHeader,
    .tablesFooter {
      display: flex;
      flex-direction: row;

      .tablesActions {
        display: flex;

        & > * + * {
          margin-left: 1rem;
        }

        .bf-label {
          display: flex;
          align-items: center;

          svg {
            margin-right: 0.5rem;
          }
        }
      }
    }

    .tablesHeader {
      margin: -1.5rem -1.5rem 0;
      padding: 1.5rem 1.5rem 1rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid #dee2e6;

      .tablesHeaderUpdatedBy {
        display: flex;
        align-items: center;
        color: $Warning600;
      }
    }

    .tablesFooter {
      justify-content: flex-end;
      margin: 1rem -1.5rem -1.5rem;
      padding: 1.5rem 2rem 2rem;
      border-top: 1px solid #dee2e6;
    }

    .tablesContainer {
      height: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      margin: 1rem 0 0;
      padding: 1rem 0 0;

      .tablesContainerInner {
        height: 100%;
        display: flex;

        & > * + * {
          margin-left: 2rem;
        }
      }
    }

    .tablesContainer.locked > * {
      opacity: 0.75;
    }
  }
}
@import "../../utils/sassHelpers.scss";

.tagView {
  padding: $page-padding;
  flex: 1;
  display: flex;
  flex-direction: column;
  .controlBar {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    & > *:not(:first-child) {
      margin-left: 1rem;
    }
  }
  .content {
    flex: 1;
    min-height: 0;
    overflow: auto;
    padding: 0 $page-padding;
    margin: 0 (-$page-padding);
    .generateBar {
      margin-top: 1rem;
      display: flex;
      flex-direction: row;
      gap: 16px;
      button:first-child {
        margin-left: auto;
      }
    }
  }
}

.ftPlacementRowForNetworkSend {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.actions {
  .oldExpectedBookingsActions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.expectedBookingsPageContainer {
  padding: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0px;
  .tableHeader {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    .tableName {
      font-size: 24px;
    }
  }
  .expectedBookingsTable {
    flex: 1;
    min-height: 0px;
    min-width: 0px;
    height: 100%;
    width: 100%;
  }

  .pendingChangesContainer {
    position: fixed;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    margin-top: 50px;
    transition: width 0.2s ease;
    width: 34%;

    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    overflow: auto;
    box-shadow: 0 2px 10px #888;
    .leftSideContainer {
      display: flex;
      font-size: 1.5rem;
      margin-bottom: 1rem;
      width: 100%;
      height: 100%;
      padding: 1rem 30px;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      font-size: 18px;
      .BPMTable {
        height: 100%;
      }
      .deleted {
        text-decoration-line: line-through;
        color: #ff344c;
        font-size: 14px;
        margin-left: 2px;
        margin-right: 2px;
      }
      .updated {
        color: green;
        font-size: 14px;
        margin-left: 2px;
        margin-right: 2px;
      }
      .updatedBookings {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }
}

.expectedBookingsActions {
  display: flex;
  flex-direction: row;

  .bulkEditButton {
    margin-right: 6px;
  }
  .addNewMediaTypeButton {
    margin-right: 6px;
  }
  .buttonsContainer {
    margin-right: 6px;
  }
  .reloadButton {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.filterTableContainer,
.filterTableContainerEmpty {
  flex: 1;
  display: flex;
  flex-direction: column;

  .filterTableContainerHeader {
    .filterTableContainerName {
      font-size: 24px;
    }
  }
}

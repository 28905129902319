.linearUpfrontActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  & > * {
    margin-left: 0.5rem;
  }
  margin-top: 10px;
}
.newTableView {
  display: flex;
  flex: 1;
  height: 600px;
  width: 100%;

  .noRows {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 28px;
    height: 100%;
  }
  .stickyTableCell {
    & > * {
      width: 90%;
      height: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.button-framework {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: none;
  background-color: #cbd2e1;
  .bf-label {
    font-weight: 500;
    font-style: normal;
    line-height: normal;
  }
  .bf-icon {
    display: flex;
    width: 22px;
    height: 22px;
    font-size: 22px;
  }
  &.bf-icon-only {
    padding: 0px 6px;
  }
  &.bf-leading-icon {
    .bf-icon {
      margin-right: 6px;
    }
  }
  &.bf-trailing-icon {
    .bf-label {
      margin-right: 6px;
    }
  }

  // Size specific styling
  &.lg {
    height: 32px;
    .bf-label {
      height: 18px;
      font-size: 16px;
    }
    &.bf-no-icon {
      padding: 0px 16px;
    }
    &.bf-leading-icon {
      padding: 0px 16px 0px 12px;
    }

    &.bf-trailing-icon {
      padding: 0px 12px 0px 16px;
    }
  }
  &.sm {
    height: 28px;
    .bf-label {
      height: 15px;
      font-size: 14px;
    }
    &.bf-no-icon {
      padding: 0px 12px;
    }
    &.bf-leading-icon {
      padding: 0px 12px 0px 8px;
      .bf-icon {
        width: 18px;
        height: 18px;
        font-size: 18px;
      }
    }
    &.bf-trailing-icon {
      padding: 0px 8px 0px 12px;
      .bf-icon {
        width: 18px;
        height: 18px;
        font-size: 18px;
      }
    }
  }
}

@import "../utils/sassHelpers.scss";
@import "../utils/colors.scss";

$border-color: rgba(0, 0, 0, 0.4);
$wizard-width: 130px;
$hidden-wizard-width: 20px;
$max-speech-bubble-width: 300px;
$speech-bubble-border-size: 2px;
$speech-bubble-arrow-size: 20px;
.streamingWizard {
  position: relative;
  flex: 1;
  padding: $page-padding;
  display: flex;
  min-height: 0;
  flex-direction: column;
  & > div:first-child {
    display: flex;
    min-height: 0;
  }
  .saving {
    position: fixed;
    z-index: 1000;
    opacity: 0.8;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .noWizardRequired {
    text-align: center;
    font-size: 1.25rem;
    margin: auto;
  }
  .wizardTable {
    td,
    th {
      vertical-align: middle;
      text-align: center;
      &.networkLogoCell {
        padding: 0.25rem;
        .networkLogo {
          height: 50px;
        }
      }
    }
    tbody > tr {
      cursor: pointer;
      transition: all 0.15s ease;
      &:hover {
        box-shadow: inset 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
      }
    }
  }
  .details {
    flex: 1;
    display: flex;
    flex-direction: column;
    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 1rem;
      .headerSection {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-left: 2rem;
        & > * {
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          font-size: 1.25rem;

          &.top {
            .dash {
              background-color: black;
              margin: 0 0.75rem;
              height: 0.2rem;
              width: 1.1rem;
            }
            & > * {
              font-size: 1.5rem;
              font-weight: 500;
              small {
                margin-left: 0.3rem;
              }
            }
          }
          &.bottom {
            margin-left: -0.4rem;
            strong {
              margin: 0 0.4rem;
            }
          }
        }
      }
    }
    .wizardBody {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      min-height: 0;
      & > * {
        min-width: 0;
        max-height: 100%;
        overflow-y: auto;
      }
      .impressionTable {
        td,
        th {
          padding: 0.25rem 1.25rem;
        }
      }
      .flightList {
        margin-right: 1rem;
        flex: 1;
        position: relative;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        & > .list-group {
          margin-top: -1px;
        }
        .flightItem {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          transition: all 0.15s ease;
          border-radius: 0;
          border-left: 1px solid rgba(0, 0, 0, 0.2);
          &:last-child {
            border-bottom: none;
          }

          .subBox {
            font-size: 0.8rem;
          }
          .idBox {
            width: 100px;
          }
          .spendInfo {
            width: 125px;
          }
          .zeroLength {
            width: 100px;
          }
          .canceledBox {
            flex: 1;
            text-align: right;
          }
          & > *:not(:first-child) {
            margin-left: 0.5rem;
          }
          &.isBest {
            font-weight: 500;
          }
          &.selected {
            background-color: $primary;
            color: white;
          }
        }
        .subFlight {
          $indent-size: 3rem;
          max-width: calc(100% - #{$indent-size});
          margin-left: $indent-size;
        }
      }
    }
    .wizardAction {
      min-height: 200px;
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      $action-width: 450px;
      .actionButtons {
        width: $action-width;
      }
      .actionBox {
        width: $action-width;
        flex: 1;
        padding: 0.5rem 0;
        display: flex;
        flex-direction: column;
        .because {
          flex: 1;
          margin-bottom: 0.5rem;
        }
        .daysInput {
          display: inline-block;
          width: 4rem;
        }
        .DateInput__small {
          width: 150px !important;
        }
        .DateInput_fang {
          bottom: 26px !important;
        }
        .SingleDatePicker_picker {
          bottom: 35px !important;
        }
        .SingleDatePickerInput_clearDate {
          line-height: 1rem;
          top: 46%;
        }
      }
    }
  }
  .wizardHimself {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: $wizard-width;
    cursor: pointer;
    transition: all 0.3s ease;
    &.danger {
      filter: drop-shadow(0 0 20px red);
    }
    &.warning {
      filter: drop-shadow(0 0 20px yellow);
    }
    &.good {
      filter: drop-shadow(0 0 20px green);
    }
    &.hidden {
      width: $hidden-wizard-width;
      filter: grayscale(100%);
    }
    &:hover {
      filter: grayscale(100%);
    }
  }
  .speechBubble {
    // https://leaverou.github.io/bubbly/
    position: absolute;
    left: $wizard-width + 5px;
    font-size: 0.9rem;
    max-width: $max-speech-bubble-width;
    bottom: 10px;
    padding: 0.5rem;
    background: white;
    border: $speech-bubble-border-size solid black;
    border-radius: 0.5rem;
    cursor: pointer;

    p:last-child {
      margin-bottom: 0;
    }
    .hint {
      font-size: 0.7rem;
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 15%;
      width: 0;
      height: 0;
      border: $speech-bubble-arrow-size + $speech-bubble-border-size * 2 + 1 solid transparent;
      border-right-color: black;
      border-left: 0;
      border-bottom: 0;
      margin-bottom: -$speech-bubble-border-size;
      margin-left: -$speech-bubble-arrow-size - $speech-bubble-border-size * 2 - 1;
    }
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 15%;
      width: 0;
      height: 0;
      border: $speech-bubble-arrow-size solid transparent;
      border-right-color: white;
      border-left: 0;
      border-bottom: 0;
      margin-left: -$speech-bubble-arrow-size;
    }
  }
}

.table-progress-bar {
  align-items: center;
  display: flex;
  gap: 5px;
  padding-right: 10px;
}

.progress-bar-label {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  width: 20%;
}

@import "../utils/colors.scss";
.userAdminPageContainer {
  padding: 10px;
  flex: 1;
  display: flex;
  flex-direction: row;
  min-width: 0px;

  .tableHeader {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    .tableName {
      font-size: 24px;
    }
  }

  .userAdminTable {
    flex: 2;
    min-height: 0px;
    min-width: 0px;
    height: 100%;

    .companiesList {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .agenciesList {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

@import "../../utils/colors.scss";
.deliveryBreakdownContainer {
  display: flex;
  flex: 1;
  flex-direction: row;

  .chartContainer .contents {
    padding-left: 10px;
    padding-bottom: 6px;
    padding-right: 16px;
  }

  .leftSide {
    .headerTitle {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      .titleDivider {
        border-left: 1px solid #94a0b8;
        height: 28px;
        margin-left: 0.25rem;
        margin-right: 0.5rem;
      }

      .primaryDateRange {
        font-size: 24px;
        font-weight: 400;
        color: #94a0b8;
      }

      .addOtherDateRange {
        display: flex;
        align-items: center;
        background-color: #f1f3f9;
        color: #94a0b8;
        border-radius: 20px;
        padding: 0.5rem;
        margin-left: 0.5rem;
        height: 26px;
        cursor: pointer;
      }

      .otherDateRange {
        font-size: 24px;
        font-weight: 400;
        color: #94a0b8;
      }

      .removeButton {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #94a0b8;
        font-size: 14px;
        border-radius: 24px;
        width: 22px;
        height: 22px;
        background-color: #f1f3f9;
        cursor: pointer;
      }
    }
  }

  .deliveryBreakdownGraphContents {
    display: flex;
    flex: 1;
    flex-direction: row;
  }
  .rightOfChart {
    width: 168px;
    .deliveryBreakdownLegend {
      justify-content: start;
      .legendItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        margin-bottom: 0.5rem;
        .square {
          width: 14px;
          height: 14px;
          margin-right: 8px;
        }
        .unselected {
          color: $Neutral500;
        }
      }
    }
  }
}

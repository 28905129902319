@import "../utils/sassHelpers.scss";

.activeOTTNetworkPicker {
  display: flex;
  flex-direction: row;
  align-items: center;
  & > * {
    margin-right: 1rem;
  }
  .logo {
    width: 70px;
    justify-content: flex-end;
    display: flex;
    img {
      height: 35px;
    }
  }
  .networkSelect {
    width: 250px;
  }
}

@import "../utils/sassHelpers.scss";
@import "../utils/colors.scss";

$settings-item-padding: 0.5rem;
$padded-column-padding: 1.5rem;

.slidesPageContainer {
  padding: $page-padding;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  position: relative;
  .loadingOverlay {
    @include fullBox();
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 10;
  }
  .controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .buttons {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      & > *:not(:last-child) {
        margin-right: 1rem;
      }
      .permissionsHelp {
        font-size: 0.75rem;
      }
    }
    .templatePicker {
      display: flex;
      flex-direction: row;
      align-items: center;
      & > * {
        margin-left: 0.5rem;
      }
    }
  }
  .workspace {
    margin-top: 0.5rem;
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: row;
    border: 0px solid rgba(0, 0, 0, 0.3);
    border-top-width: 1px;
    border-bottom-width: 1px;
    .bigAddSlides {
      font-size: 2rem;
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.1);
      margin: 50px;
      transition: all 0.12s ease;
      &:hover {
        background-color: rgba(0, 0, 0, 0.3);
      }
    }
    .tableOfContents {
      width: 200px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding: 0;
      margin-right: 0.1rem;
      overflow-y: auto;

      & > * {
        text-align: left;
        font-size: 0.9rem;
        color: #666666;
        transition: all 0.25s ease;
        cursor: pointer;
        position: relative;
        padding: 0.5rem;
        margin-right: 0.1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        &:hover {
          color: black;
        }
        &.current {
          color: $primary;
          font-weight: 500;
          text-decoration: underline;
        }
        &.isDragging {
          color: rgba(0, 0, 0, 0.1);
        }
        &:not(:last-child)::after {
          content: " ";
          position: absolute;
          background-color: rgba(0, 0, 0, 0.2);
          bottom: 0;
          left: 0;
          right: 24px;
          height: 1px;
        }
        .dragHandle {
          margin-left: 0.25rem;
          font-size: 1.25rem;
          cursor: grab;
        }
      }
      .isOver::before {
        content: " ";
        position: absolute;
        background-color: #999999;
        top: -1px;
        left: 0;
        right: 12px;
        height: 3px;
      }
    }
    .deckSlideList {
      flex: 1;
      display: flex;
      flex-direction: column;
      min-height: 0;
      padding-right: 0.5rem;

      overflow-y: auto;
      .slideSettingsContainer {
        background-color: white;

        border: 1px solid black;
        padding: 0.5rem;
        padding-bottom: 0;
        margin: 0.5rem 0rem;
        position: relative;
        .slideHeader {
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          display: flex;
          flex-direction: row;
          padding-bottom: 0.5rem;
          .slideName {
            font-size: 1.2rem;
            display: flex;
            flex-direction: row;
            align-items: baseline;
            & > *:not(:first-child) {
              margin-left: 0.25rem;
            }
          }
          .toggleSlideButton {
            margin-left: 1rem;
          }
        }
        .disabledOverlay {
          @include fullBox();
          background-color: black;
          z-index: 1;
          pointer-events: none;
          opacity: 0;
          transition: opacity 0.12s ease;
          &.disabled {
            opacity: 0.3;
          }
        }

        // just covers up the border from the slide name
        .emptySettings {
          width: 100%;
          height: 1px;
          background-color: white;
          margin-top: -1px;
        }
        .settingsBox {
          margin: 0.5rem 0;
          display: flex;
          flex-direction: column;
          & > * {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0 (-$settings-item-padding) $settings-item-padding;
            & > * {
              padding: 0 $settings-item-padding;
              &.flex {
                flex: 1;
              }
              &.form-group {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              }
            }

            .checkboxFormGroup {
              align-self: stretch;
            }
            .titleLabelColumn {
              width: 165px;
            }
          }
          .checkboxItem {
            display: flex;
            flex-direction: row;
            align-items: center;
            border: 1px solid rgba(0, 0, 0, 0.4);
            margin: 0 0.5rem;
            padding: 0.25rem 0.5rem;
            border-radius: 5px;
            & > *:first-child {
              margin-right: 0.5rem;
            }
          }

          .datePickerContainer {
            display: flex;
            justify-content: center;
          }
          .wrappingColumn {
            display: flex;
            flex-flow: row wrap;
            &:not(.tight) {
              & > *:not(:last-child) {
                margin-right: $padded-column-padding;
              }
              &.paddedColumn > * {
                margin-bottom: $padded-column-padding;
              }
            }
          }
        }
      }
    }
  }
}
.slideGenerateFloatingSandbox {
  min-height: 350px;
  height: 350px;
  width: 1000px;
  border: 1px solid black;
  text-align: center;
  visibility: hidden;
  position: fixed;
}

.slidesPageActionBar {
  display: flex;
  flex-direction: row;
  & > *:not(:first-child) {
    margin-left: 0.5rem;
  }
}
.slidesAddSlideModal {
  .nameTaken {
    color: red;
    font-style: italic;
  }
}

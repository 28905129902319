@import "../../utils/colors.scss";
@import "../../styles/Fonts.scss";

.metaBuyingPublished {
  display: flex;
  flex: 1;
  .publishedTable {
    border-radius: 16px;
    .BPMFilterBarContainer {
      margin: 0px;
    }
    .BPMTableContainer {
      padding: 0px;
      border-radius: 16px;
      .stickyTable {
        .stickyHeader {
          height: 42px !important;
          background-color: $Neutral200;
          .stickyTableCell {
            display: flex;
            background-color: $Neutral200;
            border-bottom: none;
            @include bodyRegular;
            padding: 10px;
            height: 100% !important;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 12px;
            .metaBuyingCheckbox {
              $size: 18px;
              min-height: $size;
              min-width: $size;
              height: $size;
              width: $size;
              border-color: $Brand60;
            }
          }
        }
        .STInnerGrid {
          .stickyTableCell {
            height: 38px !important;
            margin-top: 16px;
            padding-bottom: 3px;
            .adNameCell {
              @include secondaryThin;
              color: $Neutral600;
              padding: 10px 10px 7px 10px;
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
              &.highlighted {
                @include bodyRegular;
              }
            }
            .adSetNameCell,
            .campaignNameCell {
              @include secondaryThin;
              color: $Neutral600;
              padding: 10px 10px 7px 10px;
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
              &.highlighted {
                @include bodyRegular;
              }
            }
            .createdCell,
            .creatorCell {
              @include secondaryThin;
              color: $Brand100;
              padding: 10px 10px 7px 10px;
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
            }
            .statusCell {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 10px 10px 7px;
              .statusPill {
                display: flex;
                height: 28px;
                justify-content: center;
                align-items: center;
                @include secondaryRegular;
                border-radius: 52px;
                padding: 0px 16px;
                &.live {
                  background-color: $Success700;
                }
                &.paused {
                  background-color: $Brand25;
                }
              }
            }
            .checkboxCell {
              $size: 18px;
              min-height: $size;
              min-width: $size;
              height: $size;
              width: $size;
              border-color: $Brand60;
            }
            .actionsCell {
              display: flex;
              flex-direction: row;
              column-gap: 16px;
              height: 100%;
              width: 100%;
              padding: 5px 10px 2px;
              .viewAdPreviewButton {
                color: $Brand60;
              }
            }
          }
        }
      }
    }
  }
}

@import "../../styles/Fonts.scss";
@import "../../utils/colors.scss";

$secondary: #9896dc;
$percentage-hover: rgba(8, 7, 48, 0.8);

.ccKpiFunnel .chartContainer .header .leftSide .headerTitle {
  font-size: 24px;
}

.kpiFunnelDynamics {
  flex-direction: column;
  min-height: 700px;
  .funnelLegend {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .legendItem {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 14px;
      margin-bottom: 0.5rem;
      margin-right: 24px;
      cursor: pointer;
      .square {
        width: 14px;
        height: 14px;
        margin-right: 8px;
      }
      .label {
        &.unselected {
          color: $Neutral500;
        }
      }
    }
  }
  .svg-funnel-js {
    display: inline-block;
    position: relative;

    svg {
      display: block;
    }

    .svg-funnel-js__labels {
      position: absolute;
      display: flex;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    &.svg-funnel-js--vertical {
      .svg-funnel-js__labels {
        flex-direction: column;
      }
    }
  }

  .svg-funnel-js {
    .svg-funnel-js__container {
      width: 100%;
      height: 100%;
    }

    .svg-funnel-js__labels {
      width: 100%;
      box-sizing: border-box;

      .svg-funnel-js__label {
        flex: 1 1 0;
        position: relative;

        .label__value {
          font-size: 24px;
          color: black;
          line-height: 18px;
          margin-bottom: 6px;
        }

        .label__title {
          @include bodyBold;
        }

        .label__percentage {
          font-size: 16px;
          font-weight: 500;
          color: $secondary;
        }

        .label__segment-percentages {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
          left: 0;
          padding: 8px 24px;
          box-sizing: border-box;
          background-color: $percentage-hover;
          margin-top: 24px;
          opacity: 0;
          transition: opacity 0.1s ease;
          cursor: default;

          ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
              font-size: 13px;
              line-height: 16px;
              color: white;
              margin: 18px 0;

              .percentage__list-label {
                font-weight: 500;
                color: white;
              }
            }
          }
        }

        &:hover {
          .label__segment-percentages {
            opacity: 1;
          }
        }
      }
    }

    &:not(.svg-funnel-js--vertical) {
      padding-top: 64px;
      padding-bottom: 16px;

      .svg-funnel-js__label {
        padding-left: 24px;

        &:not(:first-child) {
          border-left: 1px solid $secondary;
        }
      }
    }

    &.svg-funnel-js--vertical {
      padding-left: 120px;
      padding-right: 16px;
      padding-bottom: 18px;

      .svg-funnel-js__label {
        padding-top: 24px;

        &:not(:first-child) {
          border-top: 1px solid $secondary;
        }

        .label__segment-percentages {
          margin-top: 0;
          margin-left: 106px;
          width: calc(100% - 106px);

          .segment-percentage__list {
            display: flex;
            justify-content: space-around;
          }
        }
      }
    }

    .svg-funnel-js__subLabels {
      display: none;
    }
  }
}

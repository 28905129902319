@import "../utils/colors.scss";
@import "../styles/Fonts.scss";

.percentChange {
  align-items: center;
  background: #8ef000;
  display: flex;
  font-family: Graphik !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-style: normal !important;
  font-weight: 500 !important;
  justify-content: center;
  line-height: normal !important;
  &.inverted {
    background: #cbd2e1;
  }
  .percentChangeLabel,
  .percentChangeIcon {
    align-items: center !important;
    display: flex;
    height: 100%;
    justify-content: center !important;
    text-align: center !important;
    vertical-align: middle !important;
  }
  .percentArrow {
    margin-right: 4px;
    &.inverted {
      transform: scaleY(-1); // Flip the arrow downwards
    }
  }
}

.percentChange.sm {
  border-radius: 25px;
  font-size: 14px !important;
  height: 20px !important;
  padding: 2px 6px;
}

.percentChange.lg {
  border-radius: 35px;
  font-size: 16px !important;
  height: 28px !important;
  padding: 6px 12px 6px 8px;
}

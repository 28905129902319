.roasSection {
  .left {
    .snapshotChartContainer {
      .chartContainer {
        &:hover {
          .header {
            .rightSide {
              visibility: visible;
            }
          }
        }
        .header {
          flex-direction: row;
          flex-wrap: nowrap;
          .rightSide {
            visibility: hidden;
            gap: 8px;
            justify-content: flex-end;
            display: flex;
            & > * {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  .right {
    .chartContainer {
      .contents {
        .labelDimensionIconWrapper {
          align-items: center;
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
        padding-bottom: 0px;
        padding-right: 0px;
        .STCorner {
          .tableHeader {
            justify-content: flex-start !important;
          }
          .tableHeaderLabel {
            justify-content: flex-start !important;
          }
        }
        .STLeftHeader {
          .innerCell.text {
            justify-content: flex-start !important;
          }
        }
        .stickyTableOuter {
          .stickyTable {
            scrollbar-width: auto;
            padding-bottom: 0px;
          }
          @media (min-width: 1730px) {
            .stickyTable {
              overflow-x: hidden;
            }
          }
        }
      }
    }
  }
}

@import "../../../utils/colors.scss";
@import "../../../styles/Fonts.scss";
.lineWrapper {
  display: flex;
  padding-bottom: 15px;
  .lineChart {
    display: flex;
    gap: 24px;
    .rightSideWrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .rightOfChart {
        @include secondaryRegular;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        gap: 20px;
        height: 320px;
        overflow: auto;
        &.hide {
          display: none;
        }
        .legendTitle {
          font-size: 1rem;
          color: $Brand100;
          font-weight: 500;
          padding-bottom: 0.5rem;
          gap: 0;
        }
        .legend {
          justify-content: start;
          .legendItem {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 14px;
            margin-bottom: 15px;
            cursor: pointer;
            line-height: 16px;
            .line {
              width: 14px;
              height: 3px;
              margin-right: 12px;
            }
            .legendValue {
              align-items: center;
              display: flex;
              justify-content: space-between;
              width: 100%;
              .nonGroupLegendWrap {
                align-items: center;
                display: flex;
                .legendHeader {
                  padding-right: 4px;
                }
              }
              .legendSubHeader {
                padding-left: 26px;
                color: $Neutral600;
                font-weight: 400;
                font-size: 12px;
              }
              .toolTipBox {
                width: 22px;
                height: 18px;
                .tooltip-container {
                  position: absolute;
                  margin-bottom: 10px;
                }
              }
              .legendHeaderWrapper {
                display: flex;
                .legendHeader {
                  @include secondaryRegular;
                  margin-bottom: 6px;
                  &.inactiveLegend {
                    color: $Neutral500;
                  }
                }
              }
              .subHeaderWrapper {
                @include secondaryThin;
                align-items: center;
                display: flex;
                margin-bottom: 6px;
                &:last-of-type {
                  margin-bottom: 0;
                }
                .subHeaderTextWrap {
                  display: flex;
                  flex-wrap: wrap;
                }
              }
              .inactiveLegend {
                color: $Neutral500;
              }
            }
          }
        }
      }
      .xAxisLabel {
        @include secondaryBold;
        bottom: 5px;
        position: relative;
      }
    }
  }
}

@import "../utils/colors.scss";

.amc-insights-page {
  height: 100%;

  .fullPageRouter {
    height: 100%;
  }

  .tableauEmbedded {
    height: 100%;
  }

  .empty-message {
    width: 100%;
    text-align: center;
    margin-top: 200px;
  }

  .basic-card {
    background: linear-gradient(0deg, #ffffff, #ffffff), #fffbfe;
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    padding: 20px;
  }

  .list-card {
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    margin-left: 1.2rem;
    margin-top: 15px;
    padding: 10px 25px;
    width: 50%;
  }

  .list-card:hover {
    cursor: pointer;
    background: $primary;
    color: #ffffff;
  }

  .icon-name-wrapper {
    display: flex;
    align-items: center;
  }

  .list-card-name {
    font-family: Roboto;
    font-weight: 400;
    text-transform: capitalize;
  }

  .media-mix-header-title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 22px;
  }

  .media-mix-header {
    align-items: center;
    display: flex;
    margin-bottom: 11px;
    width: fit-content;
  }

  .date-range-wrapper {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: flex-end;
  }
}

@import "../baseColors.scss";

$primary: var(--primary);
$secondary: var(--secondary);
$tertiary: var(--tertiary);
$color-gradient: var(--color-gradient);
$navbar-background: var(--navbar-background);
$navbar-row-highlight: var(--navbar-row-highlight);

$non-gradient-purple: rgb(156, 79, 250);

$mixed-primary: mix(white, #7e57c2);
$transparent-primary: rgba(#7e57c2, 0.25);

$primary-light: var(--primary-light);
$secondary-light: var(--secondary-light);

$background-grey: #f1f2f6;

/******************* New Color Library *******************/

// Primary Interface
$Blue100: #0092cc;
$Blue80: #009fdf;
$Blue60: #3ec8ff;
$Blue40: #6ed5ff;
$Blue20: #9de3ff;
$Blue10: #ccf0ff;
$Blue5: #eefaff;

// Secondary Interface
$Purple100: #613fba;
$Purple80: #7756c9;
$Purple60: #886ccf;
$Purple40: #a894dd;
$Purple20: #c0b1e5;
$Purple10: #dad0f0;
$Purple0: #f3f0fb;

// Greys
$Grey100: #130d2d;
$Grey80: #4e4e50;
$Grey70: #6e6e6e;
$Grey60: #c7cfdd;
$Grey40: #e6eaee;
$Grey30: #f5f5f5;
$Grey20: #f7f8fa;
$Grey5: #f7f8f9;
$White: #ffffff;

// Green Heat Mapping
$GreenHeat100: #15c45b;
$GreenHeat80: #47d27e;
$GreenHeat60: #78e0a1;
$GreenHeat40: #a9eec4;
$GreenHeat20: #c2f5d6;
$GreenHeat10: #dafbe7;

// Grey Heat Mapping
$GreyHeat100: #717171;
$GreyHeat80: #adadad;
$GreyHeat60: #c2c2c2;
$GreyHeat40: #d6d6d6;
$GreyHeat20: #e6e6e6;
$GreyHeat10: #f2f2f2;

$Info1: #1565c0;
$Success1: #43a047;
$Success100: #F3FFE5;
$Success700: #7ED600;
$Success800: #69B200;
$Success900: #386600;
$Error1: #b71c1c;
$Error2: #f1d2d2;
$Warning1: #f9a825;
$Warning100: #FFF8EB;
$Warning500: #FBBB3C;
$Warning600: #DB7712;
$Warning700: #B25E09;
$Danger100: #FEF1F2;
$Danger700: #EB172A;
$Danger800: #C0001C;
$Danger900: #930013;

// Channel Indicators
$Streaming80: #6f30ef;
$Streaming60: #a86ff7;
$Streaming20: #d3b7fb;

$Audio80: #1ead93;
$Audio60: #47e6bb;
$Audio20: #b8f5e4;

$Display80: #d5950d;
$Display60: #e8ba25;
$Display20: #fbe8a9;

$Linear80: #06a750;
$Linear60: #67ed22;
$Linear20: #b0fdca;

// Brand
$Brand100: #1f003f;
$Brand90: #21005e;
$Brand80: #390094;
$Brand70: #5200ce;
$Brand60: #6b2def;
$Brand50: #8254ff;
$Brand40: #977aff;
$Brand30: #b5a6ff;
$Brand25: #d1cdff;
$Brand20: #e8e5ff;
$Brand10: #f8f7ff;
$Brand0: #ffffff;

// Neutral
$Neutral160: #fbfcff;
$Neutral200: #f1f3f9;
$Neutral300: #e1e6ef;
$Neutral400: #cbd2e1;
$Neutral500: #94a0b8;
$Neutral600: #5f6c84;
$Neutral700: #3f444d;
$Neutral1000: #0a0d14;

// Channels
$Channel1: #bd32ff;
$Channel2: #ff7342;
$Channel3: #ffc42e;
$Channel4: #2ae0f6;
@import "../utils/colors.scss";
@import "../styles/Fonts.scss";

.youTubePlan {
  background-color: $Neutral200;
  width: 100%;
  height: 100%;
  border: 1.5px solid $Neutral400;
  border-radius: 16px;
  box-shadow: 0px 4px 18px 40px $Neutral400;
  padding: 24px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;

  .youTubePlanHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    .youTubePlanHeaderTextContainer {
      padding: 0px 16px;

      .youTubePlanHeaderText {
        font-size: 30px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 4px;
      }

      .youTubePlanHeaderSubtext {
        font-size: 24px;
        font-weight: 400;
        line-height: 26.4px;
      }
    }

    .youTubePlanHeaderControls {
      display: flex;
      gap: 10px;

      .youTubePlanEditButton {
        min-width: fit-content;
      }
    }
  }

  .youTubePlanToplineNumbersContainer,
  .youTubePlanSpendChartsContainer {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
  }

  .youTubePlanTableContainer {
    height: 100%;

    .BPMFilterBarContainer {
      margin: 0;
    }

    .youTubePlanTable {
      border-radius: 16px;
      border: 2px solid $Brand60;

      .stickyTableOuter {
        border-radius: 16px;
      }

      .stickyHeader {
        background-color: $Neutral200;
        .stickyTableCell {
          display: flex;
          background-color: $Neutral200;
          @include bodyRegular;
          padding: 10px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 12px;
        }
      }

      .stickyTableCell {
        border-bottom: 1px solid $Neutral500;
      }

      .STlastRow {
        border-bottom: none;
      }

      .formatCell {
        align-self: flex-start;
        width: 100%;
        color: $Brand60;
        font-size: 20px;
        font-weight: 600;
        line-height: 22px;
        padding: 16px;
      }

      .targetingTypeCell {
        display: flex;
        flex-direction: column;
        align-items: space-between;
        height: 100%;
        font-size: 16px;
        font-weight: 600;
        line-height: 17.6px;
        padding: 16px;

        .targetingType {
          height: 100%;
        }
      }

      .segmentsCell {
        display: flex;
        flex-direction: column;
        align-items: space-between;
        height: 100%;
        width: 100%;
        padding: 16px;

        .segmentsCellSection {
          height: 100%;
        }
      }

      .budgetShareInput {
        width: 100%;
      }
    }
  }
}

.twoStageSpendAndEffectContainer {
  .patternDefinitions {
    height: 0;
    width: 0;
    overflow: hidden;
  }
  .SpendAndEffectBarChart {
    .chartLegend {
      font-size: 14px;
    }
  }
}
.audiencePickerModal {
  .demoGroup {
    .demoButtons {
      & > * {
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
  }
}

@import "../utils/colors.scss";

/* toolTip container */
.tooltip-container {
  align-items: center;
  color: $White;
  cursor: default;
  display: inline-flex;
  gap: 6px;
  position: relative;

  .infoIcon {
    vertical-align: top;
  }
  .rightLabel {
    color: $Brand60;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    width: max-content;
  }

  /* toolTip text */
  .toolTiptext {
    font-weight: 500;
    font-size: 14px;
    background-color: $White;
    color: $Brand100;
    border-radius: 5px;
    border: 2px solid $Brand50;
    box-shadow: 0px 4px 4px $Grey60;
    box-shadow: 0px 4px 18px 0px $Neutral500;
    width: max-content;
    max-width: 312px;

    /* Position the toolTip text */
    position: absolute;
    z-index: 999;
    padding: 16px 16px 14px 16px;

    /* toolTip arrow */
    &::after {
      content: "";
      position: absolute;
      border-width: 6px;
      border-style: solid;
    }
    &.top-left::after {
      top: 100%;
      right: 16px;
      border-color: $Brand50 transparent transparent transparent;
    }
    &.top-right::after {
      top: 100%;
      left: 16px;
      border-color: $Brand50 transparent transparent transparent;
    }
    &.bottom-right::after {
      bottom: 100%;
      left: 16px;
      border-color: transparent transparent $Brand50 transparent;
    }
    &.bottom-left::after {
      bottom: 100%;
      right: 16px;
      border-color: transparent transparent $Brand50 transparent;
    }
  }
}

@import "../../utils/colors.scss";

.performanceSnapshotContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  .leftSide {
    .headerTitle {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      .primaryDateRange {
        font-size: 22px;
      }
    }
    .leftActions {
      .otherDateRange {
        color: $Neutral500;
        font-size: 24px;
        font-weight: 600;
      }
    }
  }

  .leftOfChart {
    .performanceSnapshotLegend {
      justify-content: start;
      .legendItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        margin-bottom: 0.5rem;
        .square {
          width: 14px;
          height: 14px;
          margin-right: 8px;
        }
      }
    }
  }

  .performanceSnapshotGraphContents {
    display: flex;
    flex: 1;
    flex-direction: row;
    .barChartsContainer {
      display: flex;
      flex-direction: column;
      .singleBarChartContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 8px;
        .legend {
          height: 100%;
          min-width: 90px;
          display: flex;
          flex-direction: column;
          .channelLabel {
            font-weight: 500;
            font-size: 14px;
          }
          .differenceLabel {
            color: #5f6c84;
            font-size: 12px;
          }
          .differenceValue {
            color: #5f6c84;
            font-size: 12px;
          }
        }
      }
    }

    .snapshotGraphContents {
      max-height: 315px;
    }
  }
}

@import "../../../styles/Fonts.scss";
@import "../../../utils/colors.scss";

.metaTableWidget {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 24px;
  border-radius: 16px;
  border: 2px solid $Brand60;
  background-color: $White;
  .mtwHeader {
    &.internal {
      height: 109px;
    }
    &.external {
      height: 71px;
    }
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding: 24px 24px 16px 24px;
    border-bottom: 1px solid $Neutral500;
    .mtwHeaderTop {
      display: flex;
      height: 28px;
      .mtwHeaderTopLeft {
        display: flex;
        flex: 1;
        align-items: center;
        column-gap: 9px;
        .mtwHeaderTitle {
          @include regularTitle;
        }
        .mtwHeaderDivider {
          width: 1px;
          height: 25px;
          background-color: $Neutral400;
        }
        .mtwHeaderAccount {
          @include headingThin;
        }
      }
    }
    .mtwHeaderBot {
      display: flex;
      justify-content: flex-start;
      .mtwCampaignsButton,
      .mtwAdSetsButton,
      .mtwAdsButton {
        .bf-label {
          @include bodyBold;
        }
        &.selected {
          .bf-label {
            color: $Brand60;
          }
          border-bottom: 3px solid $Brand60;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
        }
        &.unselected {
          .bf-label {
            color: $Brand30;
          }
        }
      }
    }
  }
  .mtwBody {
    display: flex;
    flex: 1;
    &:not(.withFooter) {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }
    overflow: hidden;
  }
  .mtwFooter {
    display: flex;
    align-items: center;
    height: 80px;
    width: 100%;
    padding: 24px;
    border-top: 2px solid $Neutral500;
    .deleteButton {
      display: flex;
      flex: 1;
      align-items: center;
      .bf-icon {
        color: $Brand100;
      }
    }
    .publishButton {
      display: flex;
      align-items: center;
      width: fit-content;
      height: 100%;
    }
  }
}

@use "sass:math";

@import "../utils/colors.scss";

$side-margin: 1.7rem;

.overviewMetrics {
  background-color: white;
  margin: 1rem $side-margin;
  &.skeleton {
    height: 247px;
  }
  .headerBar {
    position: relative;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border: 1px solid #e6eaee;
    .header {
      font-size: 1.1rem;
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      .title {
        font-weight: 500;
      }
    }
    .expandIcon {
      position: absolute;
      right: 1rem;
      top: -2px;
      color: #8c8a97;
      font-size: 30px;
    }
  }
  .overviewPickers {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    .timeTravelLink {
      margin-left: 1.5rem;
      font-size: 12px;
      color: #4e4e50;
      cursor: pointer;
      transition: color 0.12s ease;
      text-decoration: underline;
      &:hover {
        color: $primary;
      }
    }
  }
}
.overviewBody {
  $padding: 1rem;
  $lastUpdatedSize: 0.75rem;
  padding: $padding 0 $padding + $lastUpdatedSize $padding;
  display: flex;
  flex-flow: row wrap;
  position: relative;
  border: 1px solid #e6eaee;
  border-top-width: 0;
  .lastUpdated {
    position: absolute;
    color: #8c8a97;
    font-size: $lastUpdatedSize;
    bottom: math.div($padding - $lastUpdatedSize, 2);
    left: $padding;
  }
  .section {
    display: flex;
    flex-direction: column;

    margin-right: math.div($padding * 5, 2);
    padding-right: math.div($padding * 5, 2);
    margin-bottom: math.div($padding, 2);

    &.boxcolumn:not(:nth-last-child(2)) {
      border-right: 1px solid #e6eaee;
    }

    &:not(.orderedDelivery) {
      margin-top: math.div($padding, 2);
    }
    &.orderedDelivery {
      margin-right: $padding * 2;
      padding: 0.5rem 1rem;
      background-color: #f7f8fa;
      & > *:not(:first-child) {
        margin-top: 0.5rem;
      }
    }
    &.conversions {
      .sectionBody {
        flex: 0;
      }
      .conversionsPer {
        margin-top: 2px;
        font-size: 11px;
        color: #8c8a97;
        text-align: right;
      }
    }
    .overviewSectionItem {
      .label {
        color: #8c8a97;
        font-size: 11px;
        white-space: nowrap;
      }
      .value {
        font-size: 14px;
        font-weight: 500;
      }
    }
    .sectionHeader {
      font-size: 14px;
      font-weight: 500;
    }
    .sectionBody {
      flex: 1;
      display: flex;
      flex-direction: row;
      & > * {
        flex: 1;
        display: flex;
        flex-direction: column;
        &:not(:first-child) {
          margin-left: 1.5rem;
        }
        & > * {
          margin-top: 1rem;
        }
      }
    }
  }
  .overviewChartContainer {
    display: flex;
    flex-flow: row wrap;
    $cppMinWidth: 288px;
    $histMinWidth: 208px;
    $histMaxWidth: 512px;
    $totalPadding: 96px;
    $fullWidth: $cppMinWidth + $histMinWidth + $totalPadding;
    width: $fullWidth;
    max-width: 100% !important;
    flex: 1 0 $fullWidth;

    .streamingPerformanceHistogram {
      max-width: $histMaxWidth;
      min-width: $histMinWidth;
      flex: 2;
    }
    .noData {
      height: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      font-size: 1.5rem;
    }
    .cppChart {
      min-width: $cppMinWidth;
      flex: 3;

      .cppHeader {
        display: flex;
        flex-direction: row;
        &:hover > .saveButton {
          stroke: #8c8a97;
        }
        .saveButton {
          margin-left: 0.25rem;
          width: 18px;
          stroke: transparent;
          transition: stroke 0.12s ease;
          cursor: pointer;
          &:hover {
            stroke: #333333;
          }
        }
      }
    }
  }

  .overviewChart {
    display: flex;
    flex-direction: column;

    margin-bottom: 0;
    height: 200px;
    .basicToggleButton {
      padding: 0;
    }
    .chart {
      margin-top: $padding;
      flex: 1;
      .recharts-cartesian-axis-tick-value {
        font-size: 10px;
      }
    }
  }
}

@import "../utils/sassHelpers.scss";

.activeOTTDetailModal {
  .modalHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    line-height: 1.2;
    img {
      height: 50px;
    }
    .headerName {
      flex: 1;
      margin: 0 0.75rem;
    }
  }
  .dataSection {
    margin-bottom: 0.5rem;
    .dataLabel {
      font-size: 1.2rem;
      padding-bottom: 4px;
    }
    .valRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .labeled,
      .unlabeled {
        font-size: 1rem;
      }
      .labeled > :first-child {
        margin-right: 0.25rem;
        font-size: 0.9rem;
      }
      .pdfText {
        font-family: "Courier New", Courier, monospace;
        background-color: #eeeeee;
        padding: 0.25rem 0.75rem;
        border: 1px solid #aaaaaa;
        border-radius: 5px;
        p {
          margin-bottom: 0;
        }
      }

      & > .flightList {
        flex: 1;
        margin-top: 0.5rem;
        font-size: 1rem;
        & > * {
          .mainFlightRow {
            display: flex;
            flex-direction: column;
            &.expanded {
              background-color: red;
            }
            & > * {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              &:not(:last-child) {
                margin-bottom: 0.5rem;
              }
              .flightIDs > small {
                margin-left: 0.25rem;
              }
            }
          }
          .expandedSection {
            margin-top: 0.5rem;
            padding-top: 0.5rem;
            border-top: 1px solid rgba(0, 0, 0, 0.2);
            .expandedRow {
              display: flex;
              flex-flow: row wrap;
              justify-content: space-between;
            }
          }
        }
      }
    }
  }
}

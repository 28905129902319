.wtoDetailsFormContainer {
  width: 900px;
  margin: 0 auto;

  .wtoDetailsForm {
    .helperText {
      p,
      ul li {
        font-style: italic;
        color: #9e9e9e;
      }

      p {
        padding: 0;
        line-height: 22px;
        margin: 5px 0;

        b,
        a {
          font-weight: 700;
        }
      }
    }
    .sUrlBuilder {
      display: flex;

      > * {
        width: 16%;
      }

      > *:first-child,
      > *:last-child {
        width: 42%;
      }

      > *:not(:last-child) {
        margin-right: 10px;
      }
    }
    .trackingUrlParamWrapper {
      .trackingFields {
        display: flex;
        align-items: baseline;
        gap: 10px;
      }
    }

    .form-group {
      border: 1px solid #e5e7eb;
      background: #fff;
      border-radius: 5px;
      padding: 10px;
      margin: 0 25px 28px 0;
    }

    .ploverUrl {
      color: #2a79ff;
      background: transparent;
      font-style: italic;
    }

    .ploverUrlWrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;

      .copyUrlIcon {
        margin: auto 10px;
        cursor: pointer;
        color: #8254ff;

        &:hover {
          color: #5200ce;
        }
      }
    }

    .ploverUrlBuilder {
      display: flex;

      .ploverDomain {
        width: 42%;

        > div:first-child {
          border-radius: 4px 0 0 4px;
          border-right: none;
        }
      }

      .ploverPath {
        border-radius: 0;
        width: 16%;
      }

      .ploverName {
        width: 42%;
      }
    }

    .trackingParameterBuilder {
      display: flex;

      .testName {
        width: calc(100% - 7rem);
      }

      .version {
        width: 7rem;
      }
    }

    .form-row {
      margin-right: 0;

      .form-group {
        margin-right: 0;
      }
    }

    .addVariantButton {
      margin-top: 10px;
    }

    .fallbackVariantBuilder {
      .fallbackVariantId {
        width: 35%;
      }

      .fallbackVariantUrl {
        width: 65%;
      }
    }

    .variantsWrapper {
      .variantId {
        width: calc(35% - 3.0625rem);
      }

      .variantUrl {
        width: calc(65% - 3.0625rem);
      }

      .input-group {
        margin-bottom: 10px;

        span {
          width: 2.3rem;
          border-radius: 4px 0 0 4px;
          border-right: none;
        }
      }

      button {
        border-radius: 0 4px 4px 0;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
      }

      > div {
        display: flex;
      }
    }

    .notes {
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
    }
  }

  .formButtons {
    display: flex;
    width: 100%;
    margin-bottom: 1.2rem;

    button {
      margin: 0 0 1rem 20px;

      &:first-child {
        margin-left: auto;
      }
    }
  }
}

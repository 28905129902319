@import "../../utils/colors.scss";
@import "../../styles/Fonts.scss";

.singleChannelDeliveryChartContainer {
  display: flex;
  flex: 1;
  .chartContainer {
    margin: 0px;
    .header {
      min-height: 0px;
    }
    .contents {
      min-height: 0px;
      padding-bottom: 16px;
    }
  }
  .leftSide {
    .headerTitle {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      .removeButton {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #94a0b8;
        font-size: 14px;
        border-radius: 24px;
        width: 22px;
        height: 22px;
        background-color: #f1f3f9;
        cursor: pointer;
      }
      .titleDivider {
        border-left: 1px solid #94a0b8;
        height: 28px;
        margin-left: 0.25rem;
        margin-right: 0.5rem;
      }
      .primaryDateRange {
        font-size: 24px;
        font-weight: 400;
        color: #94a0b8;
        margin-right: 0px;
      }
      .addOtherDateRange {
        display: flex;
        align-items: center;
        background-color: #f1f3f9;
        color: #94a0b8;
        border-radius: 20px;
        padding: 0.5rem;
        margin-left: 4px;
        height: 26px;
        cursor: pointer;
      }
      .otherDateRange {
        font-size: 24px;
        font-weight: 400;
        color: #94a0b8;
      }
      .cl-dropdown:has(.singleChannelDimensionDropdown) {
        .dropdownMenuContainer {
          max-height: unset !important;
        }
      }
    }
  }

  .dc-Graph {
    display: flex;
    flex-direction: column;
    .recharts-layer.recharts-bar-rectangle {
      cursor: pointer;
    }
    .dc-aboveGraphContainer {
      align-items: center;
      display: flex;
      width: 100%;
      .dc-aboveGraph {
        @include bodyBold;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        min-height: 0px;
        padding-right: 35px;
      }
      .border {
        border-right: unset !important;
        border-top: unset !important;
        border-bottom: unset !important;
        border-left: 1px solid #94a0b8 !important;
        height: 100%;
      }
    }
    .dc-GraphContents {
      display: flex;
      flex: 1;
      flex-direction: row;
      min-height: 0px;
      .dc-Graph {
        display: flex;
        flex: 1;
        flex-direction: row;
        min-height: 0px;
      }
      .rightOfChart {
        align-items: center;
        border-left: 1px solid #94a0b8;
        display: flex;
        max-width: 450px;
        min-height: 0px;
        .dc-Legend {
          align-items: center;
          display: flex;
          flex-direction: column;
          gap: 16px;
          height: 100%;
          justify-content: flex-start;
          min-width: 100px;
          padding-left: 28px;
          padding-right: 28px;
          width: fit-content;
          .percentChangeContainer {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
          }
          .legendDateRangeContainer {
            align-items: center;
            background: var(--neutral-200, #f1f3f9);
            border-radius: 6px;
            display: flex;
            height: 32px;
            justify-content: center;
            width: 100%;
            .legendDateRange {
              align-items: center;
              display: flex;
              gap: 4px;
              height: 32px;
              justify-content: space-evenly;
              width: fit-content;
              .primary {
                color: var(--brand-brand-100, #1f003f);
                font-family: Graphik !important;
                font-feature-settings: "clig" off, "liga" off;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
              .primary,
              .comparison {
                padding-left: 8px;
                padding-right: 8px;
              }
              .comparison,
              .vs {
                color: var(--brand-brand-100, #1f003f);
                font-family: Graphik;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
              svg {
                color: $Brand60;
                cursor: pointer;
              }
            }
          }
          .legendCPMContainer {
            align-items: flex-end;
            display: flex;
            flex-direction: column;
            padding-left: 6px;
            padding-right: 12px;
            width: 100%;
            .spendImp,
            .CPM {
              display: flex;
              flex-wrap: nowrap;
              gap: 12px;
              height: 30px;
              justify-content: space-between;
              padding-bottom: 6px;
              padding-top: 6px;
              width: 100%;
              .iconContainer {
                align-items: center;
                display: flex;
                height: 100%;
                width: 18px;
                .icon {
                  background-color: $Brand100;
                  height: 4px;
                  width: 18px;
                }
              }
              .label,
              .valueContainer {
                align-items: center;
                color: var(--brand-brand-100, #1f003f);
                display: flex;
                font-family: Graphik;
                font-feature-settings: "clig" off, "liga" off;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-wrap: nowrap;
              }
              .valueContainer {
                align-items: center;
                display: flex;
                flex: 0 50%;
                gap: 10px;
                justify-content: flex-end;
                text-align: right;
                .value {
                  width: 100%;
                }
                .percentChangeContainer {
                  width: fit-content;
                }
              }
            }
            .spendImp {
              .icon {
                background-color: transparent !important;
              }
            }
          }
          .legendDivider {
            border-top: 1px solid #94a0b8;
            height: 1px;
            width: 100%;
          }
          .selectedAllLabel {
            align-items: center;
            color: var(--brand-brand-100, #1f003f);
            cursor: pointer;
            display: flex;
            font-family: Graphik;
            font-feature-settings: "clig" off, "liga" off;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            padding-left: 16px;
            text-wrap: nowrap;
            width: 100%;
          }
          .legendItems {
            align-items: center;
            display: flex;
            flex-direction: column;
            gap: 8px;
            min-height: 36px;
            overflow-y: auto;
            width: 100%;
            .legendItemContainer {
              align-items: center;
              cursor: pointer;
              display: flex;
              gap: 12px;
              justify-content: flex-end;
              padding: 6px 0px 6px 0px;
              width: 100%;
              .labelContainer {
                align-items: center;
                display: flex;
                gap: 12px;
                justify-content: flex-start;
                overflow-wrap: anywhere;
                padding-left: 16px;
                width: 100%;
                .colorContainer {
                  align-items: center;
                  display: flex;
                  height: 100%;
                  width: 18px;
                  .color {
                    border-radius: 2px;
                    height: 12px;
                    width: 16px;
                  }
                }
                .iconContainer {
                  align-items: center;
                  display: flex;
                  height: 100%;
                  width: 18px;
                  .icon {
                    height: 20px;
                    width: 20px;
                  }
                }
                .label {
                  align-items: center;
                  color: var(--brand-brand-100, #1f003f);
                  display: flex;
                  font-family: Graphik;
                  font-feature-settings: "clig" off, "liga" off;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                }
              }
              .valueContainer {
                align-items: center;
                display: flex;
                flex: 0 50%;
                gap: 10px;
                justify-content: flex-end;
                padding-right: 12px;
                text-align: right;
                .value {
                  color: var(--brand-brand-100, #1f003f);
                  font-family: Graphik;
                  font-feature-settings: "clig" off, "liga" off;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  width: 100%;
                }
                .percentChangeContainer {
                  width: fit-content;
                }
              }
              .comparisonContainer {
                align-items: flex-start;
                align-self: stretch;
                border-radius: var(--Medium-Corner-Radius, 6px);
                display: flex;
                flex-direction: column;
                gap: 10px;
                justify-content: center;
                padding: var(--8, 8px) var(--12, 12px) var(--12, 12px) var(--16, 16px);
                width: 100%;
                .itemDescription {
                  align-items: center;
                  display: flex;
                  justify-content: flex-start;
                  width: 100%;
                  .labelContainer {
                    align-items: center;
                    display: flex;
                    flex: 0 75%;
                    justify-content: flex-start;
                    padding-left: 0px;
                    .iconContainer {
                      align-items: center;
                      display: flex;
                      height: 100%;
                      width: 18px;
                      .icon {
                        height: 20px;
                        width: 20px;
                      }
                    }
                  }
                  .valueContainer {
                    padding-right: 0px;
                  }
                }
                .dateContainer {
                  align-items: center;
                  display: flex;
                  gap: 12px;
                  justify-content: flex-start;
                  width: 100%;
                  .colorContainer {
                    align-items: center;
                    display: flex;
                    height: 100%;
                    width: 18px;
                    .color {
                      border-radius: 2px;
                      height: 12px;
                      width: 16px;
                    }
                  }
                  .dateRange {
                    color: var(--brand-brand-100, #1f003f);
                    font-family: Graphik;
                    font-feature-settings: "clig" off, "liga" off;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-wrap: nowrap;
                  }
                  .value {
                    color: var(--brand-brand-100, #1f003f);
                    display: flex;
                    flex: 0 60%;
                    font-family: Graphik;
                    font-feature-settings: "clig" off, "liga" off;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    justify-content: flex-end;
                    line-height: normal;
                    min-width: 0px;
                    text-align: right;
                    text-wrap: nowrap;
                  }
                }
              }
              .comparisonContainer {
                background: var(--neutral-200, #f1f3f9);
                border-radius: 6px;
              }
            }
            .legendItemContainer:not(.legendItemContainer:has(.comparisonContainer)):hover {
              background: var(--neutral-200, #f1f3f9);
              border-radius: 6px;
            }
            .greyedOut {
              color: $Neutral600 !important;
              opacity: 0.3;
              img {
                filter: grayscale(100%);
              }
              .colorContainer {
                .color {
                  background-color: $Neutral600 !important;
                }
              }
              .percentChange {
                background: #cbd2e1;
                color: $Brand100;
              }
            }
          }
        }
      }
    }
  }
}

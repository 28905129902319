.bpm-incrementality-status {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  font-size: 0.85rem;

  .incrementality-status-alert-card {
    display: flex;

    width: 75%;
    padding: 1px 10px;
    height: 90%;

    .incrementality-status-alert {
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 100%;

      border: 1px solid transparent;
      border-radius: 0.25rem;
      justify-content: center;
      line-height: 0.5;

      h4 {
        font-size: 0.75rem;
      }

      span {
        font-size: 0.65rem;
      }
    }

    .incrementality-status-alert-fail {
      color: #721c24;
      background-color: #f8d7da;
      border-color: #f5c6cb;
      h4 {
        margin: 0;
      }
    }

    .incrementality-status-alert-running {
      color: #004085;
      background-color: #cce5ff;
      border-color: #b8daff;
      h4 {
        margin: 0;
      }
    }

    .incrementality-status-alert-success {
      color: #155724;
      background-color: #d4edda;
      border-color: #c3e6cb;
      h4 {
        margin: 0;
      }
    }
  }
}

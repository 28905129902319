@use "sass:math";

@import "../utils/sassHelpers.scss";
@import "../utils/colors.scss";

$circle-diameter: 14px;
$circle-padding: 10px;

.tagsContainer {
  flex-direction: column;
  .tagsContent {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;
    .tagsChart {
      height: 100%;
      padding-left: 2rem;
      display: flex;
      flex-direction: column;
      .recharts-surface {
        overflow: visible;
        .referenceLineLabel,
        .quantityChartLabel {
          font-weight: 500;
          font-size: 10px;
        }
      }
      .quantityChart {
        .recharts-cartesian-axis-ticks {
          text {
            fill: transparent;
          }
        }
      }
      .tagsChartTooltip {
        background-color: lightgray;
        padding: 0.5rem;
        border-radius: 4px;
      }
    }
  }
  .tagsContentPlaceholder {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50%;
    height: auto;
    margin: auto;
    padding: 20px;
    color: lightgray;
    font-weight: 500;
  }
}

.kpiMappingPage {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: white;
  margin: 16px;
  padding: 12px;
  border-radius: 16px;
  overflow: hidden;

  .footerControls {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    :not(:last-child) {
      margin-right: 24px;
    }
    .rightControls {
      display: flex;
      flex-direction: row;
      :not(:last-child) {
        margin-right: 24px;
      }
    }
  }
}

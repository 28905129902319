@import "../../utils/colors.scss";

.questionCard {
  width: 30%;
  padding: 1rem;
  border: 4px solid $Brand0;
  border-radius: 16px;

  &.focused {
    border: 4px solid $Brand25;
  }

  &:hover {
    border: 4px solid $Brand25;
  }

  .additionalInfo {
    margin-top: 2rem;

    .label {
      font-weight: 600;
    }
  }

  .questionCardLinkCard {
    padding: 1rem 1.5rem;
    margin: 0.5rem 0;
  }

  .questionCardLinkInput {
    border-radius: var(--4, 4px);
    border: 1.5px solid $Brand60;
  }

  .questionCardDropdown {
    width: 100%;
  }

  .dropdownMenuApplyCancelContainer {
    display: none;
  }

  input[type="text"],
  textarea {
    border-radius: var(--4, 4px);
    border: 1.5px solid $Brand60;
  }

  .fields {
    margin-top: 0.75rem;
    margin-left: 1rem;

    .nestedFields {
      margin-top: 1rem;
      margin-left: 3rem;
    }
  }

  .cardContent {
    margin-left: 0.75rem;
  }

  .cardSubtitle {
    font-weight: 400;
  }
}

.questionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  background-color: $Brand10;
  color: $Brand50;
  font-weight: 500;

  p {
    margin: 0.5rem;
  }
}

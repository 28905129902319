@import "../../../utils/colors.scss";
@import "../../../styles/Fonts.scss";

.mamContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 2px solid $Brand50;
  border-radius: 6px;
  box-shadow: 0px 4px 18px 0px rgba(148, 160, 184, 0.5);
  padding: 8px 0px;
  row-gap: 4px;
  .mamButton {
    justify-content: flex-start;
    width: 100%;
    .bf-label {
      @include secondaryRegular;
    }
  }
  .divider {
    height: 1px;
    width: 100%;
    background-color: $Neutral500;
  }
}

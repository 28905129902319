.adminActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  & > * {
    margin-left: 0.5rem;
  }
  margin-top: 10px;
}

.adminPageContainer {
  .adminPageButtons {
    button {
      margin-top: 30px;
      margin-left: 10px;
      width: 300px;
    }
  }

  .paddedButton {
    margin-left: 5px;
  }

  .adminPageTableRow {
    height: 40%;
    min-height: 400px;
    flex-direction: row;
    display: flex;

    .filterTableContainerEmpty,
    .filterTableContainer {
      width: 50%;
      padding: 20px;
    }
  }
}

@import "../utils/sassHelpers.scss";

.activeOTTCompanyPicker {
  width: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .logo {
    width: 70px;
    margin-right: 1rem;
    justify-content: flex-end;
    display: flex;
    img {
      height: 35px;
    }
  }
  .select {
    flex: 1;
  }
}

.streamingPlansBuilderActions {
  display: flex;
  flex-direction: row;
  .savedPlansDropdown {
    width: 300px;
  }
  .feeInput {
    max-width: 150px;
  }
  & > * {
    margin-right: 0.5rem;
  }
}
.companyInput {
  min-width: 200px;
  max-width: fit-content;
}

.generateSlidesButton {
  min-width: fit-content;
  max-height: 50px;
  font-size: small;
  align-content: center;
  text-align: center;
  align-items: center;
}

.streamingPlansBuilderPage {
  display: flex;
  flex: 1;
  flex-direction: column;

  .planBuilderInputs {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 1rem;

    & > *:not(:last-child) {
      flex: 1;
      margin-right: 0.5rem;
    }
    .networkFormGroup {
      max-width: 250px;
    }
    .feeFormGroup {
      max-width: 190px;
    }
    .spendFormGroup {
      max-width: 190px;
    }
    .cpmFormGroup {
      max-width: 120px;
    }
    .clearanceFormGroup {
      max-width: 120px;
    }
    .feeTypeFormGroup {
      max-width: 120px;
    }
    .impFormGroup {
      max-width: 140px;
    }
    .editRowButtons {
      & > :first-child {
        margin-right: 0.5rem;
      }
    }
    .formHeaderWithInfo {
      display: flex;
      flex-direction: row;
      .infoIcon {
        margin-left: 0.5rem;
        margin-right: auto;
        align-self: center;
        height: 16px;
        align-items: center;
      }
    }
  }

  .filterBar {
    padding: 1rem;
    z-index: 0;
  }

  .BPMTable {
    display: flex;
    flex: 1;
    text-align: center;
    .dataCell {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      height: 100%;
      cursor: pointer;
    }
    .selectedRow {
      background-color: #d4bdf2 !important;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .newRow {
      font-weight: 500;
    }
    .warning {
      color: red;
    }
  }
}

@use "sass:math";
@import "../utils/colors.scss";
@import "../utils/sassHelpers.scss";

.body:has(.incPlaybookNavBar, .incPlaybookPageContainer) {
  display: flex;
  flex-direction: row;
}
.incPlaybookPageContainer {
  display: flex;
  height: 100%;
  width: 100%;
  .incPlaybookPage {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    overflow: auto;
    padding: 16px;
    width: 100%;
    .incPlaybookSectionBox {
      align-items: center;
      background: var(--white, #fff);
      border-radius: var(--16, 16px);
      border: 1.5px solid var(--neutral-400, #cbd2e1);
      display: flex;
      flex-direction: column;
      max-width: 1437px;
      width: 100%;
      .titleContainer {
        align-items: center;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        padding: 24px 24px 24px 24px;
        width: 100%;
        .labelContainer {
          display: flex;
          align-items: center;
          gap: 10px;
          justify-content: space-between;
          .sectionTitle {
            color: var(--brand-brand-100, #1f003f);
            font-family: Graphik;
            font-feature-settings: "clig" off, "liga" off;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          .pageTitle {
            color: var(--brand-brand-100, #1f003f);
            font-family: Graphik;
            font-feature-settings: "clig" off, "liga" off;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        .iconContainer {
          height: 24px;
          padding-right: 16px;
          width: 40px;
          svg {
            margin-top: -10px;
            color: $Brand60;
            height: 24px;
            transform: rotate(270deg);
            width: 24px;
          }
        }
      }
    }
    .incPlaybookSectionBox.expanded {
      .titleContainer {
        padding-bottom: 16px;
      }
      .dividerLineContainer {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        height: 25px;
        padding-bottom: 16px;
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 8px;
        width: 100%;
        .dividerLine {
          background-color: $Neutral400;
          height: 1px;
          width: 100%;
        }
      }
      .contentContainer {
        padding: 0px 16px 16px 16px;
        display: flex;
        min-height: 0px;
        width: 100%;
        height: 100%;
        .mainTable {
          display: flex;
          min-height: 0px;
          height: 100%;
          width: 100%;
        }
        .basicSection {
          align-items: flex-start;
          display: flex;
          flex-direction: column;
          height: 100%;
          min-height: 0px;
          padding: 8px 16px 16px 16px;
          width: 100%;
          &.center {
            align-items: center;
          }
          &.right {
            align-items: flex-end;
          }
        }
        .basicText,
        .basicList,
        .basicHeader,
        .minimalList {
          width: 100%;
        }
        .basicText,
        .basicList {
          color: #000;
          font-family: Graphik;
          font-feature-settings: "clig" off, "liga" off;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
        }
        .basicList,
        .minimalList {
          margin: 0px;
        }
        .minimalList {
          list-style: none;
        }
        .basicText {
          line-height: normal;
        }
        .basicHeader {
          color: #000;
          font-feature-settings: "clig" off, "liga" off;
          font-family: Graphik;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        .basicText,
        .basicHeader {
          padding: 0px 8px;
        }
        iframe {
          width: 100%;
          min-height: 500px;
        }
      }
    }
    .incPlaybookSectionBox.expanded:has(.mainTable) {
      height: fit-content;
    }
  }
}

@import "../../styles/Fonts.scss";
@import "../../utils/colors.scss";
@import "../../utils/sassHelpers";

$pacing-bar-height: 60px;

.snapshotGraphContents {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: scroll;
  position: relative;
  top: 3px;

  & > * {
    margin-bottom: 8px;
  }

  .snapshotRow {
    display: flex;
    flex-direction: row;

    &.hovered {
      background-color: $Brand10;
      border-radius: 8px;
    }

    .snapshotLabelsLeft {
      display: flex;
      flex-direction: column;
      width: 80px;
      margin-right: 8px;
      .itemLabel {
        font-size: 14px;
        font-weight: 600;
        color: $Brand100;
      }
      .itemSubLabel {
        font-size: 12px;
        font-weight: 400;
        color: $Brand100;
      }
    }
    .itemBar {
      display: flex;
      flex: 1;
      height: $pacing-bar-height;
      width: 100%;
      align-items: center;
      position: relative;
      & > * {
        height: 100%;
        position: absolute;
        left: 0;
        line-height: $pacing-bar-height;
      }
      .horizontal {
        color: white;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .vertical {
        height: $pacing-bar-height;
      }

      .remainder {
        background-color: $Neutral200;
      }
      .budget {
        background-color: $Brand100;
      }
    }

    .doubleItemBar {
      display: flex;
      flex: 1;
      flex-direction: column;
      height: $pacing-bar-height;
      width: 100%;
      align-items: center;
      position: relative;
      align-items: flex-start;
      & > * {
        height: 50%;
        left: 0;
        line-height: $pacing-bar-height;
      }
      .horizontal {
        color: white;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.grey {
          background-color: $Neutral400;
        }
      }

      .remainder {
        background-color: $Neutral200;
      }
      .budget {
        background-color: $Neutral400;
      }
    }

    .snapshotValuesRight {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 80px;
      margin-left: 8px;
      .topValue {
        font-weight: 400;
        font-style: italic;
        font-size: 14px;
        color: $Neutral600;
      }
      .bottomValue {
        font-weight: 500;
        font-size: 14px;
        color: $Brand100;
      }

      &.doubleBarChart {
        .topValue {
          font-weight: 500;
          font-size: 14px;
          color: $Brand100;
          font-style: normal;
        }
        .bottomValue {
          font-weight: 400;
          font-style: italic;
          font-size: 14px;
          color: $Neutral600;
        }
      }
    }
  }
}

@import "../../utils/sassHelpers.scss";

.orderView {
  padding: $page-padding;
  flex: 1;
  display: flex;
  flex-direction: column;
  .filterButtons {
    margin-bottom: 1rem;
  }
  .orderTable {
    flex: 1;
    font-size: 0.8rem;
    .listCell {
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding: 0 0.25rem;
    }
    .pdfButtons {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      & > * {
        margin-left: 0.2rem;
        font-size: 0.7rem;
        padding: 0.2rem 0.4rem;
      }
    }
  }
}

.wtoBulkPloverModal {
  font-weight: 500;

  .downloadTemplateWrapper {
    margin-bottom: 20px;
    display: flex;
    font-size: 0.75rem;

    button:not(:last-child) {
      margin-right: 10px;
    }
  }

  .helperText {
    font-style: italic;
    font-size: 0.8rem;
    margin-top: 5px;
    color: #9e9e9e;
  }
}

.piePicker {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  .pieChart {
    flex: 1;
    padding: 30px;
    .pieContainer {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .pickerOptions {
    flex: 1;
    padding: 30px;
    position: relative;
    .pickerOptionContainer {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      .pickerItem {
        display: inline-block;
      }
    }
    .noCreativesMessage {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.BPMPieChart {
  position: relative;
}
.pieChartHoverTooltipPortal {
  position: fixed;
  background-color: white;
  border: 1px solid #999999;
  padding: 1rem;
  z-index: 2000;
  transition: top 0.25s ease, left 0.25s ease;
  pointer-events: none;
  box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.3);
}

@import "../utils/sassHelpers.scss";

.mlDiagnosticsActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: right;
  & > * {
    margin-right: 1rem;
  }

  .configuration {
    margin-bottom: 0px;
  }

  .currentKpi {
    font-weight: 500;
  }
}

.mlDiagnostics {
  flex: 1;
  display: flex;
  .fullPageRouter {
    flex: 1;
    display: flex;
    padding: $page-padding;
  }

  .stickyHeader {
    text-align: center;
  }
}

.SPCSFPageContainer {
  padding: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0px;

  .tableHeader {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    .tableName {
      font-size: 24px;
    }
  }

  .companySpecificFiltersTable {
    flex: 1;
    min-height: 0px;
    min-width: 0px;
    height: 100%;
  }
}

.actionButtons {
  display: flex;
  gap: 15px;
}

.modalRow {
  height: 130px;
}

.modalHeaderLabel {
  font-size: 0.95rem !important;
}

.secondariesCell {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pendingChangesContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 1000px 50px rgba(0, 0, 0, 0.3);
  clip-path: inset(0px -10px 0px -240px);
  width: 45%;
  .changesList {
    width: 100%;
    padding: 20px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .updatedCompanySpecificFilterCard {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 15px;
      gap: 5px;
      background: #ffffff;
      border: 1.5px solid #cdcdcd;
      border-radius: 10px;
      box-shadow: 0 5px 12px -7px rgba(0, 0, 0, 0.15);
      .fieldTitle {
        font-weight: 500;
      }
      .cardTitle {
        font-weight: 700;
        font-size: larger;
      }
      .cardRow {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 5px;
        .oldVal {
          text-decoration-line: line-through;
          color: #ff344c;
        }
        .newVal {
          font-weight: 500;
          color: #5300bd;
        }
        .arrayRow {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-auto-rows: auto;
        }
        .changedBigField {
          flex-basis: auto;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          align-items: left;
          justify-content: space-between;
          padding: 5px;
          background: #faf6ff;
          border: 1px solid #5300bd;
          border-radius: 5px;
        }
        .unchangedBigField {
          flex-basis: auto;
          background: #fdfdfd;
          border: 1px solid #e4e4e4;
          border-radius: 5px;
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          align-items: left;
          justify-content: space-between;
          padding: 5px;
        }
      }
    }
  }
}

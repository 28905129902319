@import "../utils/colors.scss";

$color: #130d2d;

.BPMDropdownButton {
  &.sm > .dropdown-menu {
    font-size: 14px;
  }
  & > button {
    border-color: $primary;
    color: $color;
    white-space: nowrap;
    padding-left: 16px;
    padding-right: 16px;
    background-color: white;
    &:hover {
      color: white;
      &::after {
        border-bottom-color: white;
        border-top-color: white;
      }
    }
    .dropdownLabel {
      font-weight: 500;
    }
    .dropdownLabelNormal {
      font-weight: normal;
    }
    &::after {
      border-bottom-color: #bec7d2;
      border-top-color: #bec7d2;
      vertical-align: 0.15em !important;
      margin-left: 5px;
      transition: all 0.15s ease;
    }
  }
  &.frozen > *:disabled {
    pointer-events: none;
    color: $color;
    background-color: #e9ecef;
  }
  &.noDrop > button {
    pointer-events: none;
  }
  &.bold > .btn {
    font-weight: 500;
  }
  &.wrapping {
    .value {
      white-space: normal;
    }
  }

  &.placeholder {
    .value {
      color: #888888;
    }
    &.show .value,
    &:hover .value {
      color: white;
    }
  }
  .dropdown-menu {
    & > * {
      &:hover:not(.searchBar) {
        background-color: #e6eaee;
      }
    }
    overflow: auto;
  }
  .searchBar {
    height: 1.75rem;
    width: calc(100% - 1.5rem);
    margin: 0 auto 0.5rem auto;
    padding: 0 10px;
  }
}

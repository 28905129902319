@import "../../utils/colors.scss";
@import "../../styles/Fonts.scss";
$circle-diameter: 14px;

.actualVsPredictedWrapper {
  display: flex;
  .actualVsPredictedGraph {
    .actVPredTitle {
      @include secondaryRegular;
      display: flex;
      position: relative;
      top: 5px;
      margin-bottom: -5px;

      .trainPeriod {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        margin-left: 5.8rem;
      }
      .testPeriod {
        display: flex;
        justify-content: flex-end;
        margin-right: 2.8rem;
        width: 100%;
      }
    }

    + .rightOfChart {
      @include secondaryRegular;
      left: -0.75rem;
      flex-shrink: 0;
      padding-top: 2.4rem;
      position: relative;
      width: 168px;
      .legend {
        cursor: default;
        justify-content: start;
        .legendItem {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 14px;
          margin-bottom: 0.5rem;
          line-height: 16px;
          .square {
            border-radius: 1px;
            height: 12px;
            margin-right: 8px;
            width: 16px;
            margin-right: 8px;
          }
          .line {
            width: $circle-diameter;
            background: #6b2def;
            height: 3px;
            margin-right: 8px;
          }
        }
      }
    }
  }
}

@import "../utils/sassHelpers.scss";
@import "../utils/colors.scss";

.creativesContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  border: 1px solid $Grey60;
  background-color: white;
  margin: 0.5rem;
  border-radius: 6px;
  .creativesHeader {
    border-bottom: 1px solid #e6eaee;
    padding: 14px 14px 7px 14px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    min-height: 54px;
    .title {
      font-size: 18px;
      font-weight: 500;
    }
    .selectedAttribute,
    .selectTactic {
      display: flex;
      margin-left: auto;
      background-color: $Neutral200;
      height: 28px;
      width: auto;
      padding: 0px 16px;
      align-items: center;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
    }
    .tagPicker,
    .selectTactic {
      margin-left: 8px;
    }
    .clearButton {
      margin-left: 4px;
    }
  }
  .creativesContent {
    display: flex;
    flex: 1 1 auto;
    height: 100px;
    width: 100%;
    .content {
      width: 100%;
      height: 100%;
      .creativesView {
        display: flex;
        flex: 1;
        flex-direction: row;
        width: 100%;
        height: 40%;
        align-items: center;
        justify-content: center;
        .creativeMedia {
          width: 70%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .videoContainer {
            display: flex;
            flex: 1;
            width: 95%;
            height: 90%;
            justify-content: center;
            align-items: center;
            margin: 1rem;
            .videoPlayer {
              width: 100%;
              height: 100%;
              &:focus {
                outline: none;
              }
            }
          }
        }
        .creativeDescription {
          width: 30%;
          height: 90%;
          margin: 1rem 1rem 1rem 0rem;
          overflow-y: auto;
          .title,
          .length,
          .isci,
          .dateRanges,
          .spend,
          .spots {
            padding-bottom: 0.5rem;
          }
        }
        .creativesViewPlaceholder {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 50%;
          height: auto;
          margin: auto;
          padding: 20px;
          color: lightgray;
          font-weight: 500;
        }
      }
      #setHeight.creativesList {
        display: flex;
        flex: 1 1 auto;
        flex-flow: row wrap;
        height: 60%;
        width: 100%;
        border-top: 1px solid #e6eaee;
        overflow-y: auto;
      }
      #noSetHeight.creativesList {
        display: flex;
        flex: 1 1 auto;
        flex-flow: row wrap;
        width: 100%;
        border-top: 1px solid #e6eaee;
      }
      .list-group-item.active {
        z-index: 2;
        color: #495057;
        background-color: #e6eaee;
        border-color: #e6eaee;
      }
      .list-group-item {
        border-radius: 0px;
      }
      .creativeRowItem {
        width: 25%;
        border-color: #e6eaee;
        border-left: none;
        padding: 10px;
        cursor: pointer;
        overflow: auto;
        min-height: 200px;
        &.lastRowItem {
          border-bottom: none;
        }
        &:is(:first-child) {
          border-top: none;
        }
        &:is(:nth-child(4n)) {
          border-right: none;
        }
        .thumbnailContainer {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: center;
          .videoThumbnail {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .descriptions {
          display: flex;
          text-overflow: ellipsis;
          overflow: hidden;
          height: 90px;
          width: 100%;
          justify-content: center;
          .title {
            font-size: 1rem;
            align-self: center;
          }
        }
      }
    }

    .creativeListPlaceholder {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 50%;
      height: auto;
      margin-bottom: 30%;
      margin-left: auto;
      margin-right: auto;
      padding: 20px;
      color: lightgray;
      font-weight: 500;
    }
  }
}

.dashboardPageActions {
  display: flex;
  flex-direction: row;
  padding-right: 10px;
  .branchBuildBox {
    display: flex;
    flex: 1;
    min-width: 100px;
  }
  .kpiPickerContainer {
    margin-left: 10px;
  }
}
.dashboardPage {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  .dashboardPageBody {
    display: flex;
    flex: 1;
    min-height: 0;
    overflow-y: auto;
    margin-top: 10px;

    .viewButtons {
      size: 5em;
      padding: 50px;
    }

    .row {
      margin-top: 10px;
      display: flex;
      height: 100%;
      .dashboardCol {
        padding: 5px;
        padding-bottom: 50px;
      }
      .dashboardSectionTitle {
        padding-left: 10px;
        font-size: 1.5rem;
        font-weight: 500;
      }
      .dashboardWidget {
        display: flex;
        flex-direction: column;
        min-height: 450px;
        height: 100%;
        width: 100%;
        padding: 15px 15px 15px 15px;

        border: 1px solid #e6eaee;

        .audiencePicker {
          padding-bottom: 10px;
        }

        .BPMTable {
          max-height: 400px;
        }

        .streamingPerformancePicker {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding-bottom: 10px;
        }

        .dashboardWidgetHeader {
          display: flex;
          justify-content: center;
          font-size: 1.3rem;
          width: 100%;
          text-align: center;
        }

        .loadingHeader {
          display: flex;
          justify-content: left;
          font-size: 1.3rem;
        }
        .sparkChartHeadersContainer {
          margin-bottom: 30px;

          .sparkChartValueHeader {
            display: flex;
            justify-content: center;
            font-size: 1rem;
            padding-bottom: 5px;
          }

          .trendsContainer {
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;

            .trendLabelContainer {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;

              .trendValue {
                display: flex;
                flex-direction: row;
                align-items: center;
                font-weight: 500;
                padding-left: 10px;
              }
            }
          }
        }
        .sparkChartInnerBox {
          display: flex;
          flex: 1;
        }

        .dashboardTableColumnHeader {
          position: absolute;
          left: 2px; // just a little padding
          right: 8px; // For the sort carets
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .tableHeaderDateContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-bottom: 4px;
          position: relative;

          .tableHeaderActionsContainer {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-right: 40px; // for the save button
            & > *:not(:first-child) {
              margin-left: 0.5rem;
            }
          }
          .tableSaveButton {
            position: absolute;
            bottom: 0;
            right: 0;
          }

          .dropdown-menu {
            max-height: 200px;
            overflow-y: scroll;
            width: 100%;
            text-align: center;
          }

          .DateRangePicker {
            align-self: flex-end;
          }
        }

        .filterBarContainer {
          justify-content: flex-end;
        }
      }
    }
  }
}

.kpiChartsContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;

  .dashboardchartContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    border: 1px solid #e6eaee;
    margin: 5px 5px 20px 5px;
    background-color: white;
    height: 400px;
    padding-bottom: 2rem;
    .chartHeader {
      border-bottom: 1px solid #e6eaee;
      padding: 0.5rem 0.5rem 0.5rem 21px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 48px;
      .downloadButton {
        .btn-light {
          background-color: white;
          border: none;
        }
        .dropdown-toggle:after {
          content: none;
        }
        .dropdownIcon {
          color: #bec7d2;
          font-size: 25px;
        }
      }
      .title {
        font-size: 18px;
        font-weight: 500;
        align-self: center;
        .dailyResponseTimezone,
        .microChartTimezone {
          margin-left: 0.25rem;
        }
        .dailyResponseSubTitle,
        .microChartSubTitle {
          margin-left: 0.5rem;
          font-size: 14px;
          font-weight: normal;
        }
      }
      .infoIcon {
        margin-left: 0.5rem;
        margin-right: auto;
        align-self: center;
        height: 16px;
      }
    }
    .chartData {
      display: flex;
      flex: 1;
      .recharts-tooltip-wrapper {
        z-index: 100;
        .recharts-default-tooltip {
          border-style: hidden !important;
          box-shadow: 0px 2px 10px 0px rgba(230, 234, 238, 1);
          border-radius: 2px;
        }
      }

      .recharts-tooltip-label {
        font-size: 14px;
      }

      &#responses_chart {
        .recharts-tooltip-item-name {
          font-weight: 500;
        }
        .recharts-tooltip-item-separator {
          font-weight: 500;
          color: #4e4e50;
        }
        .recharts-tooltip-item-value {
          color: #4e4e50;
        }
      }

      &#lift_chart,
      &#volume_efficiency_chart {
        .recharts-tooltip-item-name {
          font-weight: 500;
        }
        .recharts-tooltip-item-separator {
          font-weight: 500;
        }
        .recharts-tooltip-item-value {
          color: #4e4e50;
        }
      }
    }
  }
}

@import "../utils/colors.scss";
@import "../utils/sassHelpers.scss";

#mmmHeaderSubActions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding-right: 32px;

  .mmmKpiDropdown::before {
    content: "Outcome Variable:";
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    font-size: 16px;
    padding-right: 0.275em;
  }
  .mmmGroupByDropdown::before {
    content: "Group by:";
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    font-size: 16px;
    padding-right: 0.275em;
  }
  #mmmArchiveKpis {
    display: flex;

    .mmmArchiveDropdown::before {
      content: "Model Run:";
      font-weight: 500;
      font-style: normal;
      line-height: normal;
      font-size: 16px;
      padding-right: 0.275em;
    }
    .internal {
      border-top-right-radius: 0%;
      border-bottom-right-radius: 0%;
      margin-right: 0px;
    }
    .archiveToggleButton {
      white-space: nowrap;
      border-top-left-radius: 0%;
      border-bottom-left-radius: 0%;
      margin-right: 0px;
      .BPMCheckBox {
        border: 1px solid $Brand50;
        background-color: transparent;
        &.checked {
          border: none;
          background-color: $Brand50;
          color: white;
        }
      }
    }
  }
}

.primaryActionsContainerMMM {
  display: flex;
  .mmmEffectiveRawToggle {
    margin-right: 5px !important;
  }
  #mmmArchiveActions {
    .mmmArchiveDropdown::before {
      content: "Model Run:";
      font-weight: 500;
      font-style: normal;
      line-height: normal;
      font-size: 16px;
      padding-right: 0.275em;
    }
    .dropdownMenuContainer  {
      max-height: 350px;
      .dropdownMenuItemsContainer {
        overflow-y: scroll;
      }
    }
  }
  .customDropdownMenuContent {
    display: flex;
    justify-content: flex-end;
    .statusPillContainer {
      display: flex;
      width: 75px;
      margin-left: 10px;

      .dropdownPill {
        display: flex;
        align-items: center;
        height: 20px;
        padding: 2px 12px;
        border-radius: 35px;
        font-size: 12px;
        font-weight: 400;
        line-height: 100%;
        background: $Neutral300;
      }
      .statusNewest {
        background: rgba(142, 240, 0, 0.8);
      }
    }
  }
  .archiveOptionsInternal {
    .archiveOptions {
      justify-content: flex-start !important;
    }
    .dropdownMenuItemLabel {
      width: 200px;
    }
    .customDropdownMenuContent {
      width: fit-content;
      justify-content: space-between;
    }
    .branch {
      margin-left: 20px;
      width: fit-content;
      .statusMain {
        background-color: $Brand25;
      }
    }
  }
}

.mmmPage {
  padding: 16px;
  ::-webkit-scrollbar-thumb {
    background-color: $Neutral400; /* Color of the track */
  }
  ::-webkit-scrollbar {
    width: $scrollbar-width-redesign;
    height: $scrollbar-width-redesign;
  }
  .widgetContainer {
    .left {
      display: flex;
      flex-direction: column;
      flex: 2;
    }
    .right {
      flex: 1;
    }
    .widgetBody {
      margin-top: 16px;
    }
  }
  .mmmWidgetBottomWrapper {
    align-items: center;
    display: flex;
    padding-left: 6px;
  }
  .chart-tooltip {
    .itemList {
      padding-bottom: 0px;
    }
  }
}

.pagination-row {
  align-items: center;
  display: flex;
  gap: 5px;
  justify-content: flex-end;
  margin: 15px 15px 0 0;

  .bpmIconButton {
    padding: 5px;
  }
}

.pagination-data-shown {
  display: flex;
  gap: 3px;
}

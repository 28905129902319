@use "sass:math";
@import "../../utils/colors.scss";
@import "../../utils/sassHelpers.scss";

$itemContainerHeightSM: 28px;
$itemContainerHeightLG: 32px;
$tickHeight: 22px;
$tickWidth: 22px;
.cl-dropdown:has(.dropdownMenuContainer.show) {
  z-index: 1000;
}
.dropdownMenuContainer {
  align-items: flex-start;
  background: #ffffff;
  border-radius: 6px;
  border: 2px solid $Brand50 !important;
  box-shadow: 0px 4px 18px 0px rgba(148, 160, 184, 0.5);
  display: flex !important;
  flex-direction: column;
  height: fit-content;
  overflow: hidden;
  padding: 8px 0px;
  &.align-right {
    right: 0 !important;
    left: auto !important;
  }
  .dropdownMenuHeaderContainer {
    align-items: center;
    color: $Brand100;
    display: flex;
    font-family: Graphik !important;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    height: 32px;
    line-height: normal;
    padding-left: 8px;
    padding-right: 8px;
    width: fit-content;
  }
  .dropdownMenuSpinner {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .dropdownMenuBodyContainer {
    display: flex;
    overflow: hidden;
    width: 100%;
    .dropdownMenuItemsContainer {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      width: 100%;
      .dropdownMenuFilterBarContainer {
        min-width: 200px;
        padding: 4px 12px 8px 12px;
        .BPMFilterBarComponentContainer {
          width: 100%;
        }
      }
      .dropdownMenuItemsTitleContainer {
        align-items: center;
        color: $Brand100;
        display: flex;
        font-family: Graphik !important;
        font-feature-settings: "clig" off, "liga" off;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        height: 32px;
        line-height: normal;
        padding: 7px 24px;
        width: fit-content;
      }
      .dropdownMenuItems {
        width: 100%;
        .dropdownMenuSection {
          border-top: 1px solid $Neutral400;
          padding-top: 8px;
          margin-top: 5px;
        }
        .dropdownMenuItem {
          align-items: center;
          cursor: pointer;
          display: flex;
          height: $itemContainerHeightLG;
          padding: 7px 24px;
          width: 100%;
          .dropdownMenuItemTick {
            display: flex;
            color: $Brand50;
            height: fit-content;
            margin-right: 6px;
            * {
              height: $tickHeight;
              width: $tickWidth;
            }
          }
          .dropdownMenuItemLabel {
            align-items: center;
            color: $Brand100;
            display: flex;
            font-family: Graphik !important;
            font-feature-settings: "clig" off, "liga" off;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            height: fit-content;
            line-height: normal;
            padding-bottom: 0px;
            padding-top: 0px;
          }
          .dropdownMenuItemOptions {
            align-items: center;
            display: flex;
            margin-left: auto;
          }
        }
        .dropdownMenuItem.disabled {
          cursor: not-allowed;
          opacity: 0.5;
          pointer-events: none;
        }
        .archiveOptions {
          display: flex;
          justify-content: space-between;
        }
        .dropdownMenuItem:hover {
          background-color: $Brand20;
        }
        .dropdownMenuItem:active {
          background-color: $Brand25;
        }
        .dropdownMenuItem:has(.dropdownMenuItemTick):active {
          background-color: #ffffff;
        }

        .dropdownMenuItemOverride {
          padding: 8px 8px 8px 24px;
          color: $Brand100;
        }
      }
      .dropdownMenuItems::-webkit-scrollbar {
        width: 8px !important; /* width of the entire scrollbar */
      }

      .dropdownMenuItems::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0) !important; /* color of the tracking area */
      }

      .dropdownMenuItems::-webkit-scrollbar-thumb {
        background-color: $Neutral400 !important; /* color of the scroll thumb */
        border-radius: 8px !important; /* roundness of the scroll thumb */
        border-right: 2px solid rgba(0, 0, 0, 0) !important; /* creates padding around scroll thumb */
      }
    }
  }
  .dropdownMenuBodyContainer.multiMenu {
    .dropdownMenuItemsContainer {
      padding-top: 8px;
      border-left: 1px solid $Neutral500;
    }
    .dropdownMenuItemsContainer:first-child {
      border-left: unset;
    }
  }
  .dropdownMenuApplyCancelContainer {
    align-items: center;
    display: flex;
    gap: 16px;
    justify-content: right;
    padding: 16px 16px 8px 0px;
    width: 100%;
    // Remove Bootstrap formatting
    .dropdown-item {
      background-color: transparent !important;
      border: unset;
      clear: unset;
      color: unset;
      display: flex;
      font-weight: unset;
      height: fit-content !important;
      padding: 0px !important;
      text-align: unset;
      white-space: unset;
      width: fit-content !important;
    }
    ////
  }
  .dropdownMenuFooterContainer {
    align-items: center;
    background: $Neutral200;
    color: $Brand100;
    display: flex;
    font-family: Graphik !important;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    height: 48px;
    justify-content: left;
    line-height: normal;
    padding: 8px 12px;
    width: 100%;
  }
}
.dropdownMenuContainer.small {
  .dropdownMenuItemsContainer {
    .dropdownMenuItems {
      .dropdownMenuItem {
        height: $itemContainerHeightSM;
      }
    }
  }
}
.dropdownMenuContainer:has(.dropdownMenuFooterContainer) {
  padding-bottom: 0px;
}
.dropdownMenuContainer:has(.dropdownMenuFooterContainer):not(.dropdownMenuContainer:has(.dropdownMenuApplyCancelContainer)) {
  .dropdownMenuItemsContainer {
    padding-bottom: 8px;
  }
}
.dropdownMenuContainer:has(.multiMenu) {
  padding-top: 0px !important;
  .dropdownMenuItemsContainer {
    padding-bottom: 8px;
  }
  .dropdownMenuApplyCancelContainer {
    border-top: 1px solid $Neutral500;
  }
}
.dropdownMenuContainer.title {
  padding-top: 8px !important;
  .dropdownMenuBodyContainer.multiMenu {
    border-top: 1px solid $Neutral500;
  }
}

@import "../utils/colors.scss";

.expandCollapseButton {
  background-color: $Neutral200;
  border-radius: 6px;
  height: 28px;
  width: 34px;
  margin-right: 24px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &.expanded .icon {
    transform: rotate(90deg);
  }

  &.collapsed .icon {
    transform: rotate(270deg);
  }
}

.icon {
  color: #1f003f;
}

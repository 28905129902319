@use "sass:math";
@import "../../utils/colors.scss";
@import "../../utils/sassHelpers.scss";

.metricsTable {
  height: 100%;
  width: 100%;
  // Remove default table styling
  .STCornerInner:not(.STnw) {
    border: unset !important;
  }
  .stickyTableOuter .stickyHeader.STTopHeader > * {
    border-bottom: unset !important;
  }
  .stickyTableOuter .stickyHeader.STBottomHeader > * {
    border-top: unset !important;
  }

  // Header,Footer border-style
  .STTopHeader {
    padding-bottom: 4px;
    z-index: 2;
  }
  .STBottomHeader {
    background-color: #ffffff !important;
    min-height: 39px !important;
    z-index: 1;
  }

  // Dimension Cell Styling
  .leftCell {
    align-items: center;
    border-right: 4px solid $Neutral400 !important;
    display: flex;
    height: fit-content;
    min-width: 16px;
    overflow-x: visible;
    .innerCell {
      color: $Neutral1000 !important;
      font-family: Graphik !important;
      font-feature-settings: "clig" off, "liga" off !important;
      font-style: normal !important;
      font-weight: 600 !important;
      line-height: normal !important;
      .dimensionCellWithIcon {
        display: flex;
        align-items: center;
        gap: 14px;
        width: fit-content;
        .label {
          display: flex;
        }
        .label.noWrap {
          text-wrap: nowrap;
        }
      }
    }
  }

  .lastRow {
    align-items: flex-start !important;
    max-height: unset !important;
  }

  // Dimension Header Styling
  .STCorner:has(.STnw) {
    z-index: 3;
  }
  .STCornerInner.STnw {
    align-items: flex-end;
    border-bottom: unset !important;
    border-right: 4px solid $Neutral400 !important;
    display: flex;
    .dimensionCornerContainer {
      align-items: center;
      display: flex;
      width: fit-content;
      .tableHeader {
        color: $Brand100;
        font-family: Graphik !important;
        font-feature-settings: "clig" off, "liga" off;
        font-size: 14px;
        font-style: normal;
        font-weight: 501;
        line-height: normal;
        min-width: 16px;
        text-align: right;
      }
    }
  }
  .STCornerInner.STsw {
    border-right: 4px solid $Neutral400 !important;
  }

  // Generic Cell Styling
  .STInnerGrid {
    display: flex;
    overflow: visible !important;
  }
  .bodyCell {
    align-items: center;
    display: flex;
    max-height: 68px;
    min-width: 16px;
  }
  .bodyCell:not(.subtotalCell):not(.start):not(.leftDivider):not(.rightDivider) {
    border-left: 0.25px solid $Neutral200;
    border-right: 0.25px solid $Neutral200;
  }
  .bodyCell:not(.subtotalCell):not(.leftDivider) {
    border-left: 0.25px solid $Neutral200;
  }
  .bodyCell:not(.subtotalCell):not(.rightDivider):not(.start) {
    border-right: 0.25px solid $Neutral200;
  }
  .stickyTableCell {
    padding: 0px;
  }
  .stickyTableCell:not(.STOdd, .subtotalCell, .tableHeaderContainer, .stickyTableCell:has(.superHeaderContainer)) {
    background-color: $Neutral160 !important;
  }
  .stickyTableCell.STOdd {
    background-color: #ffffff !important;
  }

  // Cell Styling
  .cellWrapper {
    display: flex;
    padding-left: 4px;
    .innerCell {
      color: $Neutral1000;
      font-family: Graphik !important;
      font-feature-settings: "clig" off, "liga" off;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      overflow-wrap: anywhere;
    }
    .innerCell.text {
      color: $Neutral1000;
      font-family: Graphik !important;
      font-feature-settings: "clig" off, "liga" off;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
    }
    .innerCell.text.regular {
      align-items: center;
      display: flex;
      font-size: 14px;
      gap: 6px;
      min-height: 26px;
    }
    .innerCell.text.regular.heatMapped {
      padding-left: 12px;
      padding-right: 12px;
    }
    .innerCell.text.small {
      align-items: center;
      display: flex;
      display: inline-flex;
      font-size: 12px;
      gap: 6px;
      height: 26px;
      min-height: 20px;
    }
    .innerCell.text.small.heatMapped {
      padding-left: 4px;
      padding-right: 4px;
    }
    .innerCell.text.extraSmall {
      align-items: center;
      display: flex;
      font-size: 12px;
      gap: 6px;
      height: 22px;
      min-height: 20px;
    }
    .innerCell.text.extraSmall.heatMapped {
      padding-left: 2px;
      padding-right: 2px;
    }
    .innerCell.logo {
      align-items: center;
      display: flex;
      padding: 0px;
      img {
        height: 50px;
        width: 50px;
      }
    }
    .innerCell.thumbnail {
      align-items: center;
      display: flex;
      padding: 0px;
      img {
        height: 50px;
        width: 90px;
      }
    }
    .innerCell.footer {
      padding: 4px 0px;
    }
  }
  .cellWrapper:has(.heatMapped) {
    border-radius: 6px;
  }

  // Dividers
  .leftDivider {
    border-left: 1px solid $Neutral400 !important;
  }
  .rightDivider {
    border-right: 1px solid $Neutral400 !important;
  }
  .rightEndDivider {
    border-right: 2px solid $Neutral400 !important;
  }
  .end {
    border-right: unset !important;
  }

  // Header Styling
  .tableHeaderContainer {
    box-shadow: 0px 4px 2px 0px rgba(203, 210, 225, 0.25);
    display: flex;
    flex-direction: column;
    gap: 4px;
    .subHeader {
      align-items: center;
      color: $Brand100;
      display: flex;
      font-family: Graphik !important;
      font-feature-settings: "clig" off, "liga" off;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 12px */
      text-align: right;
      padding: 4px 0px;
    }
  }
  .tableHeader {
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    gap: 4px;
    justify-content: center;
    max-height: 68px;
    min-width: 16px;
    .sortIconContainer {
      align-items: center;
      display: flex;
      flex-shrink: 0;
      height: 100%;
      justify-content: center;
      width: 16px;
      * {
        flex-shrink: 0;
        height: 16px;
        width: 16px;
      }
    }
    .sortIconContainer:not(.sorted) {
      display: none;
    }
    .text {
      color: $Brand100;
      font-family: Graphik !important;
      font-feature-settings: "clig" off, "liga" off;
      font-size: 14px;
      font-style: normal;
      font-weight: 501;
      line-height: normal;
      padding: 4px 0px;
      text-align: right;
    }
  }
  .tableHeader:hover {
    .sortIconContainer:not(.sorted) {
      display: flex;
      * {
        color: $Neutral500;
      }
    }
    .sortIconContainer.sorted {
      * {
        color: $Brand50;
      }
    }
  }
  .tableHeader:active {
    .sortIconContainer {
      display: flex !important;
      * {
        color: $Brand100 !important;
      }
    }
  }

  // Footer Styling
  .subtotalCell {
    box-shadow: 0px -4px 2px 0px rgba(203, 210, 225, 0.25);
  }
  .footer {
    display: flex;
    flex-direction: column;
    .footerTypeContainer {
      color: $Neutral500;
      display: flex;
      font-family: Graphik !important;
      font-feature-settings: "clig" off, "liga" off;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      height: 12px;
      line-height: 100%; /* 12px */
      text-align: right;
    }
    .footerContainer {
      color: $Brand100;
      display: flex;
      font-family: Graphik !important;
      font-feature-settings: "clig" off, "liga" off;
      font-size: 14px;
      font-style: normal;
      font-weight: 501;
      height: 15px;
      line-height: normal;
      text-align: right;
    }
  }
  // Super Header Styling
  .superHeaderContainer {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
    .innerCell {
      align-items: center;
      color: $Brand100;
      display: flex;
      font-family: Graphik !important;
      font-feature-settings: "clig" off, "liga" off;
      font-size: 16px;
      font-style: normal;
      font-weight: 501;
      height: 100%;
      justify-content: center;
      line-height: normal;
      padding-bottom: 4px;
      padding-top: 4px;
      width: 100%;
    }
  }
  .superHeaderContainer.hasText {
    background-clip: content-box;
    background-color: $Neutral200 !important;
  }
}

@import "../utils/colors.scss";

$dimension-padding: 0.35rem;
$side-margin: 1.7rem;
$gridBorderColor: #e6eaee;

.gridBox {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
  min-height: 300px;
  overflow: hidden;
  .gridControls {
    padding: 1rem $side-margin;
    border-bottom: 1px solid $gridBorderColor;
    display: flex;
    flex-direction: row;
    align-items: center;
    & > *:not(:first-child) {
      margin-left: 0.5rem;
    }
    .glossaryButton {
      font-weight: normal;
    }
    .searchGroup {
      width: 350px;
      border: 1px solid #e6eaee;
      border-radius: 2px;
      .searchIcon {
        font-size: 20px;
        margin-top: 1px;
        margin-left: 12px;
        color: #c5d0de;
      }
      .search {
        border: none;
        outline: none;
        box-shadow: none;
        font-size: 14px;
        &::placeholder {
          color: #c5d0de;
        }
      }
    }
    .compareTo {
      margin-left: 1rem;
    }
  }
  .gridContainer {
    flex: 1;

    .dividerCell {
      border-left: 2px solid $gridBorderColor;
    }

    .tableHeader {
      cursor: pointer;
      color: #8c8a97;
      font-size: 0.75rem;
      $arrowSize: 9px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: center;
      user-select: none;
      font-weight: 500;
      transition: color 0.12s ease;
      border-bottom: 1px solid $gridBorderColor;
      text-align: center;
      &:hover {
        color: #333333;
      }
      &.unsorted {
        cursor: default;
      }
      &.centered > .content {
        text-align: center;
      }
      .content {
        min-width: 0;
        margin: 0 $arrowSize;
        position: relative;
        .arrow {
          position: absolute;
          bottom: 3px;
          right: -$arrowSize;
          font-size: $arrowSize * 1.8;
          line-height: 0.75rem;
        }
      }
      &.twoDateMode {
        .content {
          flex: 1;
        }
        &.dividerCell {
          border-left: 3px solid #d4bcfd;
        }
        & > *:first-child {
          margin-left: $arrowSize + 3px;
        }
        & > *:not(:first-child) {
          color: #c6c3d3;
        }
      }
    }
    .superHeader {
      border-bottom: 1px solid $gridBorderColor;

      color: #4e4e50;
      font-weight: 500;
      size: 14px;

      &.leftDivider {
        border-left: 2px solid $gridBorderColor;
        &.twoDateMode {
          border-left: 3px solid #d4bcfd;
        }
      }
      &.rightDivider {
        border-right: 2px solid $gridBorderColor;
        &.twoDateMode {
          border-right: 3px solid #d4bcfd;
        }
      }
    }
    .STCornerInner {
      display: flex;
      align-items: flex-end;
      &.STnw {
        border-color: $gridBorderColor !important;
        border-bottom-width: 0 !important;
        border-right-width: 2px !important;
        .dimensionCorner {
          height: 100%;
          display: flex;
          flex-direction: row;
          &.withSuperHeaders {
            height: 41px;
            border-top: 1px solid $gridBorderColor;
          }
          .tableHeader {
            position: relative;
            justify-content: flex-start;
            text-align: left;
            .content {
              margin: 0 $dimension-padding;
            }
          }
        }
      }
      &.STsw {
        border-width: 0;
        border-right: 2px solid $gridBorderColor;
        border-bottom: 1px solid $gridBorderColor;

        text-transform: uppercase;
        color: $primary;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .bodyCell {
      display: flex;
      padding: 0;
      &:not(.lastRow) {
        border-bottom: 1px solid $gridBorderColor;
      }
      &.start {
        padding-left: 4px;
      }
      &.end {
        padding-right: 4px;
      }

      .innerCell {
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $gridBorderColor;
        border-radius: 3px;
        color: #4e4e50;
        .content {
          font-size: 12px;
          font-weight: 500;
        }
        .pct {
          font-size: 10px;
          margin-left: 2px;
          margin-top: 3px;
          color: #6a6a6a;
        }
      }
      .cellWrapper {
        flex: 1;
        display: flex;
        height: 100%;
        padding: 8px 4px;
      }
      &.twoDateMode {
        &:not(.start) {
          border-left: 3px solid #d4bcfd;
        }
        .cellWrapper {
          padding: 8px 0;
          border-left: 2px solid transparent;
        }
        .innerCell {
          margin: 0 8px;
        }

        .otherDateWrapper {
          flex: 1;
          background-color: #f7f8fa;
          border-left: 2px solid $gridBorderColor;
          padding: 8px 0;
          height: 100%;
          &.notReady {
            flex: 2;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.8rem;
          }

          .innerCell {
            height: 100%;
            margin: 0 8px;
            background-color: white;
          }
        }
      }
    }
    .STBottomHeader {
      box-shadow: 0px -2px 10px rgba(213, 210, 210, 0.5), inset 0px 1px 0px #e4e4e4;
      .subtotalCell {
        border-top: none;
      }
    }
    .STLeftHeader > .stickyTableCell {
      border-right: 2px solid $gridBorderColor;
    }
    .leftCell {
      display: flex;
      flex-direction: row;
      align-items: center;
      &:not(.lastRow) {
        border-bottom: 1px solid $gridBorderColor;
      }
      .networkLogo {
        position: relative;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          max-height: 80%;
        }
      }

      .description {
        font-size: 11px;
      }
      .content {
        padding: 0 $dimension-padding;
        color: #666666;
        max-height: 100%;
      }
    }
    .overall {
      color: $primary;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      justify-content: flex-start;
      padding-left: 0.25rem;
    }
  }
}

.allPanes {
  height: 100%;
  display: flex;
  flex-direction: column;

  .additionalControls {
    & > *:not(:last-child) {
      margin-right: 10px;
    }
  }

  .filterBarContainer {
    display: flex;
    z-index: 2;
    & > *:not(:first-child) {
      margin-left: 10px;
    }
    margin-bottom: 5px;
  }

  .topPane {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 15px 15px 15px 15px;

    .superHeader::before {
      content: "";
      position: absolute;
      left: 1rem;
      right: 1rem;
      bottom: 2px;
      height: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    }

    .highlightCell {
      width: 100%;
      text-align: center;

      &.aboveGoal {
        background-color: #ffc7ce;
      }
      &.atGoal {
        background-color: #c6efce;
      }
      &.belowGoal {
        background-color: #c5d9f1;
      }
    }
  }

  .bottomPane {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 15px 15px 15px 15px;

    .selectableCell {
      width: 100%;
      height: 100%;
      text-align: center;
      cursor: pointer;
    }
  }

  .grandTotalCell {
    font-weight: 500;
  }

  .acceptedButton {
    display: block;
  }
}

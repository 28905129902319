@import "../utils/sassHelpers.scss";

.mlDiagnosticsActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: right;
  & > * {
    margin-right: 1rem;
  }

  .branchNameInput {
    width: 200px;
  }
}

.mlDiagnostics {
  flex: 1;
  display: flex;
  .fullPageRouter {
    flex: 1;
    display: flex;
    padding: $page-padding;
    .stageOneDiagnostics {
      flex: 1;
      flex-direction: column;
      display: flex;
      .bigChart {
        flex: 1;
        min-height: 0;
      }
      .summary {
        height: 200px;
      }
    }
  }
}

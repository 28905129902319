@import "../../utils/colors.scss";

.toggle-button {
  display: inline-flex;
  flex-direction: row;
  width: fit-content;
  border-radius: 6px;
  box-sizing: border-box;
  overflow: hidden;

  &.primary-light {
    border: 1px solid $Brand50;
    background-color: $Neutral200;
  }
  &.primary-dark {
    border: 1px solid $White;
    background-color: $Brand70;
  }
  &.secondary-light {
    background-color: $Neutral200;
  }

  &.lg {
    height: 32px;
  }
  &.sm {
    height: 28px;
  }

  .button-framework {
    &.toggle-item {
      height: 100%;
      border-radius: 0px;

      &.primary-light {
        &.left-toggle {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
        &.right-toggle {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }
        background-color: $Neutral200;

        &.selected {
          .bf-label {
            font-size: 16px;
            font-weight: 500;
            color: $White;
          }
          background-color: $Brand50;
        }
        &.unselected {
          .bf-label {
            font-size: 16px;
            font-weight: 400;
            color: $Neutral600;
          }
          &:hover {
            .bf-label {
              color: $Brand70;
            }
            background-color: $Brand25;
          }
        }
      }
      &.primary-dark {
        &.left-toggle {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
        &.right-toggle {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }
        background-color: $Brand70;

        &.selected {
          .bf-label {
            font-size: 16px;
            font-weight: 500;
            color: $Brand60;
          }
          background-color: $White;
        }
        &.unselected {
          .bf-label {
            font-size: 16px;
            font-weight: 400;
            color: $Brand25;
          }
          &:hover {
            .bf-label {
              color: $Brand70;
            }
            background-color: $Brand25;
          }
        }
      }
      &.secondary-light {
        border-radius: 6px;
        background-color: $Neutral200;
        &.selected {
          .bf-label {
            font-size: 14px;
            font-weight: 500;
            color: $Brand100;
          }
          background-color: $White;
          border: 1px solid $Brand50;
        }
        &.unselected {
          .bf-label {
            font-size: 14px;
            font-weight: 400;
            color: $Neutral600;
          }
          &:hover {
            .bf-label {
              color: $Brand70;
            }
            background-color: $Neutral300;
          }
        }
      }
    }
  }
}

.geo-page {
  margin: 1.2rem;
  margin-top: 15px;

  .basic-card {
    display: flex;
    margin-top: 15px;
    width: 100%;
  }
  .geo-page-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    .back-arrow {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }

  .basic-card.geo-map-and-data {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 20px;

    .map-section-content {
      display: flex;
      justify-content: space-between;

      .dropdown-state-map-wrapper {
        display: flex;
        flex-basis: 80%;
        flex-direction: column;
      }

      .dd-secondary.dd-menu.list-group {
        left: 0px;
        width: max-content;
      }
    }
  }

  .table-wrapper {
    height: 650px;
    width: 100%;
  }
}

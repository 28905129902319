@use "sass:math";
@import "../../utils/colors.scss";
@import "../../utils/sassHelpers.scss";

.homeOverview {
  min-height: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px 16px 16px 16px;
  .overviewText,
  .moreInfo,
  .list {
    color: #000;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Graphik;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
  .list {
    margin: 0px;
  }
  .overviewText,
  .moreInfo {
    line-height: normal;
  }
  .header {
    color: #000;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Graphik;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

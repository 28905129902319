.data-card-row {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;

  .data-card-row-section {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
  }
}

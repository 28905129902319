@import "../utils/sassHelpers.scss";

$summary-icon-width: 50px;
$summary-icon-margin: 1rem;

.linearDelivery {
  flex: 1;
  padding: $page-padding;
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow: hidden;
  .subActions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 0 5px 1rem 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    margin: 0 -15px 1rem;
    .filterBar {
      z-index: 1;
      flex: 1;
    }
    .labeledControl {
      display: flex;
      align-items: center;
      .label {
        font-size: 16px;
        margin-right: 10px;
      }
      button:not(:first-child) {
        margin-left: 0.5rem;
      }
    }

    & > *:not(:first-child) {
      margin-left: 1rem;
    }
  }
  .box {
    background-color: white;
    border: 1px solid #e6eaee;
  }
  .summaries {
    height: 100px;
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    .infoCard {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      .skeletonBox {
        width: 50%;
        height: $summary-icon-width;
      }
      &:not(:last-child) {
        margin-right: 32px;
      }
      & > * {
        display: flex;
        flex-direction: row;
        // margin-left: -$summary-icon-width - $summary-icon-margin;
        .iconContainer {
          width: $summary-icon-width;
          margin-right: $summary-icon-margin;
        }
        .info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .title {
            color: #8c8a97;
            font-weight: 500;
            .infoIcon {
              position: relative;
              top: -1px;
              left: 5px;
              font-size: 0.9rem;
            }
          }
          .value {
            font-weight: 700;
            color: #4e4e50;
            font-size: 1.25rem;
          }
        }
      }
    }
  }
  .chartBox {
    flex: 1;
    display: flex;
    flex-direction: column;
    .chart {
      width: 100%;
      flex: 1;
      min-height: 0;
      overflow: visible;
      padding: 2rem 1rem;
    }
    .legend {
      overflow-x: hidden;
      .loadingLegend {
        height: 180px;
      }
    }
  }
}
.linearDeliveryActions {
  display: flex;
  flex-direction: column;
  align-items: center;
  & > * {
    display: flex;
    flex-direction: row;
    align-items: center;
    & > *:not(:first-child) {
      margin-left: 15px;
    }
    .labeledControl {
      display: flex;
      align-items: center;
    }
    .editControls > :first-child {
      margin-right: 10px;
    }
  }
}

@import "../utils/colors.scss";

.kpiFunnelConfig {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: white;
  margin: 16px;
  padding: 12px;
  border-radius: 16px;
  overflow-x: auto;
  .kpiFunnelTopControls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;
    .title {
      font-size: 20px;
      font-weight: 600;
    }
  }
  .kpiFunnelTable {
    border-collapse: separate;
    border-spacing: 0 12px;
    .tableHeader {
      min-width: 200px;
      font-size: 16px;
      font-weight: 600;
    }
    .tableHeaderDefaultColumn {
      min-width: 120px;
    }
    .kpiDropdownCell {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-right: 8px;
      .kpiFunnelSelectDropdown {
        min-width: 150px;
        margin-right: 16px;
      }
      .rightArrow {
        color: $Brand50;
      }
    }
  }

  .kpiFunnelBottomControls {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 16px;
    & > :not(:last-child) {
      margin-right: 12px;
    }
  }
}

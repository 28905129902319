.linearClearanceAssumptionsPageContainer {
  padding: 10px;
  flex: 1;
  display: flex;

  .tableHeader {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    .tableName {
      font-size: 24px;
    }
  }
}

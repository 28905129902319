@use "sass:math";

@import "../utils/colors.scss";

$logo-size: 35px;
$logo-horizontal-margin: 10px;
$logo-horizontal-padding: 5px;
$logo-vertical-margin: 10px;
$total-item-height: 80px;
$circle-size: 12px;
$tall-height: 97px;
$residential-width: 92px;
$creative-width: 110px;
$daypart-width: 80px;
$avail-width: 60px;

.streamingDeliveryLegend {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0 3rem;

  min-height: $total-item-height;
  max-height: 280px;
  margin: -$logo-vertical-margin (-$logo-horizontal-margin) 0;
  &.streamingDeliveyrCreativeLegend {
    max-height: 300px;
  }
  .creative {
    width: $creative-width + 2 * $logo-horizontal-margin !important;
  }
  .residential {
    width: $residential-width + 2 * $logo-horizontal-margin !important ;
  }
  .daypart {
    width: $daypart-width + 2 * $logo-horizontal-margin !important ;
  }
  .avail {
    width: $avail-width + 2 * $logo-horizontal-margin !important ;
  }
  .legendToggleAll {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: $logo-vertical-margin;
    color: #130d2d;
    cursor: pointer;
    margin-right: 0.5rem;
    transition: all 0.15s ease;
    height: fit-content;
    min-width: 76px;
    &:hover {
      color: $primary;
    }
    .toggleAllLabel {
      font-size: 10px;
      margin-top: 6px;
      margin-left: 2px;
    }
  }
  .items {
    display: flex;
    align-items: flex-start;
    text-align: center;
    flex-flow: row wrap;
    .legendItem {
      padding: $logo-vertical-margin $logo-horizontal-margin 0;
      width: $logo-size + 2 * ($logo-horizontal-margin + $logo-horizontal-padding);
      padding: 1rem 5px;
      cursor: pointer;

      &.disabled {
        opacity: 0.2;
      }
      .legendItemInner {
        min-height: $total-item-height;
        &.tall {
          min-height: $tall-height;
        }
        border: 1px solid #e6eaee;
        border-radius: 2px;
        padding-top: 10px;

        .legendLabel {
          width: $logo-size;
          height: $logo-size;
          margin: auto;
          overflow: hidden;
          font-size: 12px;
          &.creativeLabel {
            width: $creative-width !important;
            height: $creative-width * math.div(9, 16);
          }
          &.residentialLabel {
            width: $residential-width !important;
          }
          &.daypartLabel {
            width: $daypart-width !important;
          }
          &.availLabel {
            width: $avail-width !important;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .legendBox {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 5px;

        .circle {
          height: $circle-size;
          width: $circle-size;
          border-radius: math.div(circle-size, 2);
        }
      }
      .creativeName {
        font-size: 11px;
        margin: 5px 2px;
        line-height: 11px;
        overflow-wrap: break-word;
        overflow: hidden;
        max-height: 23px;
      }
    }
  }
}
.streamingDeliveryLegendItemTooltip {
  & > .arrow {
    display: none;
  }
  & > .tooltip-inner {
    background-color: white;
    color: #130d2d;
    font-size: 10px;
    box-shadow: 0px 0px 15px 0px rgba(78, 78, 80, 0.3);
    margin-bottom: -6px;
  }
}

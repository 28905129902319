@import "../../utils/colors.scss";

.cptbWidgetDropdownWrapper {
  .dropdown {
    .cl-dropdown-toggle.widget-title.primary.light.lg.largeTitle.dropdown-toggle.btn.btn-primary {
      color: $Brand50;
    }
  }
}
.cptb {
  .left {
    .chartContainer {
      .header {
        .leftSide {
          .headerTitle {
            max-width: 280px;
          }
        }
        .rightSide {
          gap: 8px;
          & > * {
            margin-right: 0;
          }
        }
      }
    }
  }
  .right {
    .chartContainer {
      .contents {
        padding-top: 0;
        padding-bottom: 0;
        position: relative;
        top: -2px;
        .areaChart {
          .rightOfChart {
            position: relative;
            top: 20px;
          }
        }
      }
    }
  }
  .rightOfChart {
    width: 168px;
  }
}

.widgetContainer {
  .headerRight {
    .rightActions {
      gap: 9px;
      .cptbCovariatesToggle {
        height: 33px;
        .BPMToggleSwitch {
          align-items: center;
          display: flex;
          height: 33px;
        }
      }
      .DatePickerButton {
        margin-right: 16px;
        background-color: $Brand10;
      }
    }
  }
}

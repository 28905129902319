@use "sass:math";
@import "../../utils/colors.scss";
@import "../../utils/sassHelpers.scss";

.incPlaybookPageContainer {
  .userLevelCalculatorContainer {
    display: flex;
    height: 100%;
    width: 100%;
    padding: 8px 16px 16px 16px !important;
    .userLevelCalculator {
      height: 100%;
      min-height: 600px;
      width: 100%;
    }
  }
}

.state-tooltip {
  background: linear-gradient(0deg, #ffffff, #ffffff), #fffbfe;
  border-radius: 4px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  padding: 5px;
  position: absolute;
  z-index: 100000000;
}

.state-tooltip-data {
  line-height: 16px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
}
.state-tooltip-data::first-letter {
  text-transform: uppercase;
}

.state-tooltip-title {
  color: #485d75;
  line-height: 16px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
}

.mediaUpdateContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;
  margin: 8px 20px;

  .mediaUpdateContainerTitle {
    font-size: larger;
    font-weight: 500;
  }

  .mediaUpdateContainerHeader {
    font-size: medium;
    font-weight: 500;
    margin: 5px 0px 5px 0px;
  }

  .mediaUpdateContainerInput {
    margin: 2px 0px 2px 0px;
    width: 90vw;
  }

  .mediaUpdateRow {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 0;
    width: 90vw;
    border: solid black 0.5px;
    overflow: scroll;
  }
}

@import "../utils/colors.scss";

.checkBoxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 14px;
  column-gap: 7px;
  .checkBox {
    cursor: pointer;
    background-color: white;
    transition: all 0.12s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $Grey60;
    height: 14px;
    width: 14px;
    border-radius: 2px;
    &.checked {
      background-color: $Brand50;
    }
  }
  .label {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    &.checked {
      font-weight: 500;
    }
  }
}

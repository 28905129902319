@import "../utils/sassHelpers";

.bpmLinearRatesComparisons {
  height: 100%;
  width: 100%;
  padding: $page-padding;
  display: flex;

  .linearRatesComparisonsTable {
    flex: 1;

    .companyList {
      display: flex;
      flex-direction: row;

      .companyEntry {
        margin-left: 10px;
      }
    }
  }
}

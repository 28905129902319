@import "../../utils/colors.scss";
@import "../../styles/Fonts.scss";

.toggleWrapper {
  align-items: center;
  background-color: $Brand50;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  gap: 6px;
  padding-right: 8px;
  padding-left: 12px;
  width: fit-content;
  height: 100%;

  &:hover {
    background-color: $Brand70;
    .leftLabel {
      color: $White;
    }
    .toggleOnWrap {
      background-color: $Brand70;
    }
    .toggleIcon {
      &.on {
        color: $Brand70;
      }
    }
  }

  .leftLabel {
    @include secondaryRegular;
    color: $Brand20;
  }
  .rightLabel {
    @include secondaryRegular;
    color: $White;
    display: flex;
    &.icon {
      bottom: 1px;
      position: relative;
    }
  }

  .toggleOnWrap {
    align-items: center;
    background-color: $Brand50;
    border-radius: 10px;
    display: flex;
    height: 10px;
    position: relative;

    .toggleBorderHide {
      background-color: hsla(0, 0%, 100%, 1);
      border-radius: 20px;
      height: 8px;
      left: 1px;
      position: absolute;
      width: 18px;
    }
  }
  .toggleIcon {
    &.off {
      color: $Brand20;
    }
    &.on {
      color: $Brand50;
      z-index: 10;
    }
  }
}

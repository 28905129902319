.BPMTable {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: stretch;
  .BPMFilterBarContainer {
    display: flex;
    z-index: 2;
    & > *:not(:first-child) {
      margin-left: 10px;
    }
    margin-bottom: 5px;
  }
  .BPMTableContainer {
    flex: 1;
    padding: 0 1px;
    z-index: 0;
  }
  .BPMSuperHeader {
    background-color: #f7f8fa !important;
    box-shadow: inset 0 -1px 0 0 #f1f2f6;
    color: #8c8a97;

    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 13px;
  }
  .STTopHeader > .stickyTableCell {
    color: #8c8a97;

    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 13px;
  }
}

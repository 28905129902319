@import "../utils/colors.scss";

.budgetSnapshotLegend {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 2rem;
  .legendItem {
    border: 1px solid $Grey60;
    border-radius: 6px;
    padding: 4px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 100px;
    height: 60px;
    .title {
      font-size: 12px;
      font-weight: 500;
    }
    .info {
      font-size: 11px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .label {
        font-weight: 500;
      }
    }
  }
}

.tableViewContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  .filterBarContainer {
    z-index: 1;
    margin-bottom: 0.5rem;
  }
  .newTableView {
    display: flex;
    flex: 1;
    .noRows {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 28px;
      height: 100%;
    }
    .stickyTableCell {
      & > * {
        width: 90%;
        height: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .checkbox {
        width: auto;
        height: auto;
      }

      .selfFocusingSelect {
        & > * {
          width: 100%;
        }
      }
      .newRow {
        color: green;
      }
      .oldValue {
        text-decoration: line-through;
        margin-right: 0.5rem;
      }

      .stackedCell {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 100%;
        .topContent {
          font-size: 0.875rem;
          font-weight: 500;
        }
        .middleContent {
          font-size: 0.75rem;
          color: #6b7280;
        }
        .bottomContent {
          font-size: 0.75rem;
          color: #9ca3af;
        }
      }
    }
  }
}

@import "../utils/colors.scss";
.wrappingColumn {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  .options {
    flex: 1;
    * {
      min-width: fit-content;
      margin-bottom: 5px;
    }
    .note {
      font-size: small;
    }
    .footnoteDiv {
      .footnoteTextEntry {
        min-height: fit-content;
      }
    }
  }
  .datePickers {
    flex: 1;
    .dp + .dp {
      margin-top: 10px;
    }
    .dp {
      .dateRangeText {
        margin-bottom: 5px;
      }
      .dateRangeLabelAndSortBy {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .dateRangeSortBy {
          display: flex;
          flex-direction: row;
          margin-right: 10px;
        }
      }
    }
    max-width: fit-content;
  }
}

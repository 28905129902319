@use "sass:math";
@import "../../utils/colors.scss";

.editPagePresetModal,
.editTablePresetModal {
  display: flex;
  min-width: 0px;
  min-height: 0px;
  .modal-dialog {
    align-items: flex-start;
    border-radius: 8px;
    display: flex;
    height: 100vh;
    justify-content: center;
    margin-bottom: 0px;
    margin-top: 0px;
    max-height: 100vh;
    min-height: 0px;
    min-width: 0px;
    padding-bottom: 2.5vh;
    padding-top: 2.5vh;
  }
  .modal-header {
    align-items: flex-end;
    background-color: $Brand80;
    border-bottom: unset !important;
    display: flex;
    gap: 8px;
    min-width: 0px;
    padding: 16px;
    .titleContainer {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      height: 64px;
      justify-content: center;
      min-width: 0px;
      width: 100%;
      .modal-title {
        align-items: center;
        color: var(--white, #fff);
        display: flex;
        font-family: Graphik;
        font-feature-settings: "clig" off, "liga" off;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        height: 32px;
        line-height: normal;
        padding-left: 16px;
        padding-right: 16px;
      }
      .pagePresetNameContainer,
      .tablePresetNameContainer {
        display: flex;
        height: 32px;
        padding-left: 16px;
        .newNameInput {
          align-items: flex-start;
          align-self: stretch;
          border-radius: 4px;
          border: 2px solid $Brand60;
          box-shadow: unset !important;
          color: $Brand100;
          display: flex !important;
          flex-direction: column;
          flex: 0 0 400px;
          font-family: Graphik !important;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          gap: 10px;
          height: 32px;
          justify-content: center;
          line-height: normal;
          padding: 8px 0px 8px 16px;
          transition: unset !important;
        }
        .pagePresetName,
        .tablePresetName {
          align-items: center;
          color: var(--white, #fff);
          display: flex;
          font-family: Graphik !important;
          font-feature-settings: "clig" off, "liga" off;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          height: 100%;
          line-height: normal;
          padding-right: 16px;
        }
        .editPagePresetNameIcon,
        .editTablePresetNameIcon {
          align-items: center;
          cursor: pointer;
          display: flex;
          height: 100%;
          height: 100%;
          padding-right: 6px;
          width: fit-content;
          * {
            color: #fff;
            height: 18px !important;
            width: 18px !important;
          }
        }
      }
    }
    .BPMToggleSwitch {
      background-color: $Brand80;
      .bf-label {
        text-wrap: nowrap;
      }
    }
    .closeContainer {
      align-items: center;
      cursor: pointer;
      display: flex;
      flex-shrink: 0;
      height: 28px;
      padding-left: 6px;
      padding-right: 6px;
      * {
        color: #ffffff;
        height: 22px;
        width: 22px;
      }
    }
  }
  .modal-content {
    border-radius: 8px;
    border: 1px solid $Brand60;
  }
  .modal-footer {
    align-items: center;
    border-top: 1px solid $Neutral400;
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    padding: 16px;
    height: 60px;
    max-height: 60px;
    > * {
      margin: 0px;
    }
  }
}

.editPagePresetModal {
  .modal-dialog {
    max-width: 850px;
    width: 850px;
  }
  .modal-content {
    .modal-body {
      border-top: unset !important;
      border-bottom: unset !important;
      padding: 16px 0px;
      .tabSelector {
        display: flex;
        gap: 16px;
        margin: 0px !important;
        padding-left: 32px;
        padding-right: 24px;
        border-bottom: 1px solid $Neutral400;
        .tab {
          align-items: center;
          color: #94a0b8;
          cursor: pointer;
          display: flex;
          font-family: Graphik !important;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding: 8px 4px;
          text-wrap: nowrap;
        }
        .active.tab {
          border-bottom: 3px solid $Brand60;
          color: $Brand60;
          font-family: Graphik;
          font-feature-settings: "clig" off, "liga" off;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          padding-bottom: 5px;
        }
      }
      .datesContainer,
      .globalOptionsContainer,
      .filtersContainer,
      .groupsContainer,
      .overviewContainer,
      .metricsTableContainer,
      .deliveryContainer,
      .dpContainer {
        margin: 0px;
        padding-top: 16px;
      }
      .datesContainer {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        gap: 24px;
        .dateIncrementsContainer {
          align-items: flex-start;
          gap: 8px;
          display: flex;
          flex-direction: column;
          .dateIncrementsLabel {
            color: $Brand100;
            font-family: Graphik !important;
            font-feature-settings: "clig" off, "liga" off;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding-left: 32px;
            padding-right: 32px;
          }
          .dateIncrementsDropdownContainer {
            padding-left: 32px;
            padding-right: 32px;
          }
        }
        .datePickerContainer {
          align-items: flex-start;
          gap: 8px;
          display: flex;
          flex-direction: column;
          .datePickerLabel {
            color: $Brand100;
            font-family: Graphik !important;
            font-feature-settings: "clig" off, "liga" off;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding-left: 32px;
            padding-right: 32px;
          }
          .datePickerContainer {
            padding-left: 32px;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            .dateTypeToggleContainer {
              align-self: flex-start;
              padding-top: 16px;
            }
          }
          .dateFieldContainer {
            display: flex;
            padding-left: 48px;
            .dateInputFieldContainer:not(:first-child) {
              margin-left: 8px;
            }
          }
          .datePresetsContainer {
            width: 100%;
            display: flex;
            justify-content: center;
            .standardPresets {
              border-bottom: solid 1px $Neutral400;
            }
          }
        }
      }
      .filtersContainer {
        .filterPresetContainer {
          align-items: flex-start;
          gap: 8px;
          display: flex;
          flex-direction: column;
          .filterPresetLabel {
            color: $Brand100;
            font-family: Graphik !important;
            font-feature-settings: "clig" off, "liga" off;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding-left: 32px;
            padding-right: 32px;
          }
          .tempLabel {
            color: var(--brand-brand-60, #6b2def);
            font-family: Graphik;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: -4px;
            padding-left: 32px;
            padding-right: 32px;
          }
          .filterPresetDropdownContainer {
            padding-left: 32px;
            padding-right: 32px;
          }
        }
      }
      .groupsContainer {
        .groupContainer {
          align-items: flex-start;
          gap: 8px;
          display: flex;
          flex-direction: column;
          .groupLabel {
            color: $Brand100;
            font-family: Graphik !important;
            font-feature-settings: "clig" off, "liga" off;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding-left: 32px;
            padding-right: 32px;
          }
          .groupSelectionContainer {
            align-items: center;
            display: flex;
            gap: 16px;
            padding-left: 32px;
            padding-right: 32px;
            .dropdownMenuFooterContainer {
              align-items: center;
              background-color: $Brand25;
              display: flex;
              gap: 6px;
              justify-content: flex-start;
              svg {
                color: var(--brand-brand-100, #1f003f);
                height: 22px;
                width: 22px;
              }
              .label {
                color: var(--brand-brand-100, #1f003f);
                font-family: Graphik;
                font-feature-settings: "clig" off, "liga" off;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-wrap: nowrap;
              }
            }
            .groupFormContainer {
              .newNameInput {
                align-items: flex-start;
                align-self: stretch;
                border-radius: 4px;
                border: 2px solid $Brand60;
                box-shadow: unset !important;
                color: $Brand100;
                display: flex !important;
                flex-direction: column;
                flex-shrink: 0;
                font-family: Graphik !important;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                gap: 10px;
                height: 28px;
                justify-content: center;
                line-height: normal;
                padding: 5px 0px 5kpx 16px;
                transition: unset !important;
                width: 100%;
              }
            }
          }
        }
      }
      .overviewContainer,
      .globalOptionsContainer,
      .dpContainer {
        .globalOptionsLabel {
          color: $Brand100;
          font-family: Graphik !important;
          font-feature-settings: "clig" off, "liga" off;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        .globalOptionsDropdownContainer {
          padding-left: 32px;
          padding-right: 32px;
        }
        .kpiVolumeContainer,
        .funnelDynamicsContainer,
        .sparkChartsContainer,
        .cmContainer,
        .dpItemContainer,
        .globalOptionsDropdownContainer {
          align-items: flex-start;
          gap: 8px;
          display: flex;
          flex-direction: column;
          .kpiVolumeLabel,
          .funnelDynamicsLabel,
          .sparkChartsLabel,
          .cmLabel,
          .dpLabel,
          .dpContainer {
            color: $Brand100;
            font-family: Graphik !important;
            font-feature-settings: "clig" off, "liga" off;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding-left: 32px;
            padding-right: 32px;
          }
          .kpiVolumeDropdownsContainer,
          .funnelDynamicsDropdownsContainer,
          .sparkChartSelectorsContainer,
          .cmDropdownsContainer,
          .dpItemDropdownsContainer {
            align-items: flex-start;
            gap: 16px;
            display: flex;
            flex-direction: column;
            .kpiVolumeDropdownContainer,
            .funnelDynamicsDropdownContainer,
            .sparkChartSelector,
            .cmDropdownContainer,
            .dpItemDropdownContainer {
              padding-left: 32px;
              padding-right: 32px;
            }
          }
        }
      }
      .datesContainer,
      .overviewContainer,
      .globalOptionsContainer,
      .dpContainer {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        gap: 0px;
        .kpiVolumeDropdownsContainer,
        .funnelDynamicsDropdownsContainer,
        .sparkChartSelectorsContainer,
        .cmDropdownsContainer,
        .dpItemDropdownsContainer,
        .globalOptionsDropdownContainer {
          flex-direction: column !important;
          .toggle {
            padding-left: 32px;
            padding-right: 32px;
          }
          .kpiVolumeDropdownContainer,
          .funnelDynamicsDropdownContainer,
          .sparkChartSelector,
          .cmDropdownContainer,
          .dpItemDropdownContainer {
            align-items: center;
            display: flex;
            gap: 16px;
            .label {
              color: var(--brand-brand-100, #1f003f);
              font-family: Graphik;
              font-feature-settings: "clig" off, "liga" off;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              padding-left: 16px;
              padding-right: 16px;
              width: 108px;
            }
          }
        }
        > *:not(:first-child) {
          border-top: 1px solid $Neutral400;
          padding-top: 24px;
        }
        > * {
          width: 100%;
        }
        > *:not(:last-child) {
          padding-bottom: 24px;
        }
      }

      .metricsTableContainer {
        .tablePresetContainer {
          align-items: flex-start;
          gap: 8px;
          display: flex;
          flex-direction: column;
          .tablePresetLabel {
            color: $Brand100;
            font-family: Graphik !important;
            font-feature-settings: "clig" off, "liga" off;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding-left: 32px;
            padding-right: 32px;
          }
          .tablePresetDropdownContainer {
            padding-left: 32px;
            padding-right: 32px;
          }
        }
      }
      .deliveryContainer {
        .deliveryChartContainer {
          align-items: flex-start;
          display: flex;
          flex-direction: column;
          gap: 8px;
          .deliveryLabel {
            color: $Brand100;
            font-family: Graphik !important;
            font-feature-settings: "clig" off, "liga" off;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding-left: 32px;
            padding-right: 32px;
          }
          .deliveryOptionsContainer {
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding-left: 32px;
            padding-right: 32px;
            .deliveryOption {
              align-items: center;
              display: flex;
              gap: 10px;
              .label {
                color: var(--brand-brand-100, #1f003f);
                font-family: Graphik;
                font-feature-settings: "clig" off, "liga" off;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                padding-left: 16px;
                padding-right: 16px;
              }
            }
          }
        }
      }
    }
  }
  .DatePickerButton {
    border: solid 1px $Brand50;
  }
  .DatePickerButton:hover {
    border-color: $Brand70;
  }
}

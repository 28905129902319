@import "../utils/sassHelpers.scss";

$border-color: rgba(0, 0, 0, 0.4);
$action-box-padding: 2px;
$action-box-border-width: 1px;
$action-box-border-radius: 4px;
$stripe-width: 6px;

$pane-padding: 0.5rem;

.linearPlansActions {
  display: flex;
  flex-direction: row;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 10px;
  }

  .linearPlansLegend {
    display: flex;
    flex-direction: row;
    margin-right: 20px;

    .aboveGoal {
      color: #ffc7ce;
      margin-left: 20px;
    }

    .atGoal {
      color: #c6efce;
      margin-left: 20px;
    }

    .belowGoal {
      color: #c5d9f1;
      margin-left: 20px;
    }
  }
}

.allPanes {
  height: 100%;
  display: flex;
  flex-direction: column;

  .additionalControls {
    & > *:not(:last-child) {
      margin-right: 10px;
    }
  }

  .filterBarContainer {
    display: flex;
    z-index: 2;
    & > *:not(:first-child) {
      margin-left: 10px;
    }
    margin-bottom: 5px;
  }

  .topPane {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 15px 15px 15px 15px;

    .superHeader::before {
      content: "";
      position: absolute;
      left: 1rem;
      right: 1rem;
      bottom: 2px;
      height: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    }

    .highlightCell {
      width: 100%;
      text-align: center;

      &.aboveGoal {
        background-color: #ffc7ce;
      }
      &.atGoal {
        background-color: #c6efce;
      }
      &.belowGoal {
        background-color: #c5d9f1;
      }
    }
  }

  .bottomPane {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 15px 15px 15px 15px;

    .selectableCell {
      width: 100%;
      height: 100%;
      text-align: center;
      cursor: pointer;
    }
  }

  .grandTotalCell {
    font-weight: 500;
  }

  .acceptedButton {
    display: block;
  }
}

.modal-90wh {
  width: 90%;
  height: 90%;
  max-width: none !important;
  max-height: none !important;

  .sendView {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    min-height: 500px;

    .mailPane {
      position: relative;
      min-width: 400px;

      display: flex;
      flex-direction: column;
      min-height: 0;
      .emails {
        flex: 1;
        min-height: 0;
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        .input-group {
          margin-bottom: 0.25rem;
          .inputLabel {
            width: 35px;
            justify-content: center;
          }
        }
        .ccs {
          flex: 1;
          min-height: 0;
          overflow: auto;
          margin-bottom: 0.5rem;
          border-bottom: 1px solid $border-color;
        }
      }
      .content {
        border-top: 1px solid $border-color;

        flex: 1;
        min-height: 0;
        display: flex;
        flex-direction: column;
        padding: 0.5rem;
        .hint {
          font-size: 0.7rem;
        }
        .bodyTextArea {
          flex: 1;
          margin-top: 0.25rem;
          font-size: 0.8rem;
        }
      }
      .buttonRow {
        padding: 0 0.5rem 0.5rem;
        justify-content: space-between;
      }
    }
    .pdfViewer {
      flex: 1;
      position: relative;
      display: flex;
      min-height: 400px;
      iframe {
        flex: 1;
      }
    }
  }
}

.toast {
  position: fixed;
  z-index: 10;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}

@import "../utils/colors.scss";

.budgetIntakeTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  & > :first-child {
    margin-right: 2rem;
  }
  & > :not(first-child) {
    margin-right: 1rem;
  }
  .budgetIntakeButtonSelectedControls {
    display: flex;
    align-items: center;
    & > * {
      margin-right: 0.5rem;
      height: 100%;
    }
    .budgetIntakeButtonLabel {
      font-size: 1rem;
      font-weight: normal;
    }
  }

  .budgetIntakeButtonMeasurement {
    margin-bottom: 0px;

    .budgetIntakeButtonBtn {
      padding: 4px 8px 4px 8px;
      background-color: white;
      color: #4e4e50;
      font-weight: 500;
      border-color: #e6eaee !important;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.2rem;
    }
  }
}

.constraintViewEditModalForBudgets {
  .modal-content {
    margin: 24px;
    border: 2px solid #6b2def;
    border-radius: 16px;

    .modal-header {
      display: flex;
      align-items: center;
      padding: 24px 24px 16px 24px;
      border-bottom: 1px solid $Neutral500;
      font-size: 24px;
      font-weight: 600;
      line-height: normal;
    }

    .modalBody {
      display: flex;
      flex-direction: column;

      .modalRow {
        display: flex;
        flex-direction: row;
        min-width: 0;
        padding: 8px 8px;
        font-size: 16px;
        font-weight: 600;
      }

      .modalRow .SingleDatePicker .SingleDatePickerInput {
        border-radius: 6px;
        border: 1px solid $Brand50;
        background-color: transparent;
        .DateInput {
          color: transparent;
        }
      }

      .form-control {
        border-radius: 6px;
        border: 1px solid $Brand50;
      }

      .form-group {
        margin-right: 10px;
      }
    }

    .css-2b097c-container {
      border-radius: 6px;
      border: 1px solid $Brand50;
      .css-yk16xz-control {
        background-color: transparent;
        border-radius: 0px;
        border-style: none;
        .css-6q0nyr-Svg {
          fill: $Brand50;
        }
      }
    }
  }
}

@import "../utils/sassHelpers.scss";

$border-color: rgba(0, 0, 0, 0.4);

.referenceList {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  min-height: 0;
  align-items: stretch;
  .listItem {
    display: flex;
    flex: 1;
    max-height: 40px;
    padding-top: 0px;
    padding-bottom: 0px;

    .section {
      font-weight: 500;
      font-size: 20px;
      padding-top: 3px;
    }
    .pdf {
      padding-top: 7px;
      width: 50px;
      margin-left: 30px;
    }
    .title {
      font-weight: 500;
      padding-top: 7px;
      width: 300px;
    }
    .description {
      padding-top: 7px;
    }
  }
}

$calendarPadding: 16px;

.DPCalendar {
  position: relative;
  padding: $calendarPadding;
  display: flex;
  flex-direction: row;
  & > .DPMonth:not(:first-child) {
    margin-left: 24px;
  }
  .navArrow {
    position: absolute;
    top: -4px;
    height: 54px;
    font-size: 36px;
    color: #9fa2b4;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &.left {
      left: $calendarPadding - 3px;
    }
    &.right {
      right: $calendarPadding - 3px;
    }
  }
}

@import "../../../utils/colors.scss";
@import "../../../styles/Fonts.scss";

.areaChart {
  display: flex;
  gap: 24px;
  padding-bottom: 15px;
  .rightOfChart {
    @include secondaryRegular;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 320px;
    overflow: auto;
    padding-bottom: 16px;
    &.hide {
      display: none;
    }
  }
  .legendTitle {
    font-size: 1rem;
    margin-bottom: 10px;
    font-weight: 600;
  }
}

@import "../../../utils/colors.scss";

$pickerWidth: 200px;
$fullWeeksPickerWidth: 290px;

.DatePickerButton {
  cursor: pointer;
  padding-right: 6px !important;
  .dpbRange {
    position: relative;
    flex: 1;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
    text-align: center;
    font-size: 14px;
    padding-top: 3px;
    .singleDate {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .dpRangeAndCaret {
    display: flex;
  }
  .comparisonPeriod {
    span {
      font-weight: 200;
    }
  }
}

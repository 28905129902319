@import "../../utils/colors.scss";

.deliverySnapshotContainer {
  display: flex;
  flex: 1;

  .chartContainer .contents {
    padding: 12px;
  }

  .header {
    .leftSide {
      .headerTitle {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
        .primaryDateRange {
          font-size: 22px;
        }

        .topDeliverySnapshotDropdown {
          &.flipColor {
            .dt-text {
              :not(:hover) {
                color: $Neutral500;
              }
            }
          }
        }
      }
      .leftActions {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;

        .bottomDeliverySnapshotDropdown {
          .dt-text {
            font-size: 24px !important;
            font-weight: 600 !important;
            :not(:hover) {
              color: $Neutral500;
            }
          }

          &.flipColor {
            .dt-text {
              :not(:hover) {
                color: $Brand100;
              }
            }
          }
        }
      }
    }
  }

  .deliverySnapshotContents {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-left: 12px;

    .totalsContainer {
      display: flex;
      flex-direction: row;
      margin-bottom: 16px;
      font-weight: 600;
      font-size: 14px;
      .totalsLabel {
        margin-right: 4px;
      }
      .totalsDiff {
        margin-right: 4px;
      }
      .totalsPercentDiff {
        font-weight: 500;
      }
    }

    .snapshotGraphContents {
      max-height: 280px;
    }
  }
}

.performanceBreakdown {
  display: flex;
  flex: 1;
  flex-direction: column;
  .aboveChart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 110px;
    font-size: 14px;
    .aboveChartLeft {
      display: flex;
      flex-direction: row;
      align-items: center;
      & > :first-child {
        margin-right: 2px;
      }
    }
    .spendAxisLabel {
      color: #808080;
    }
  }
  .chartContents {
    display: flex;
    flex: 1;
    flex-direction: row;
    .performanceBreakdownChart {
      display: flex;
      flex: 1;
      flex-direction: row;
    }
    .rightOfChart {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 0.5rem;
    }
  }
}

.performanceBreakdownTable {
  .farRightColumn {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #8c8a97;
  }
}

.invoicingPageActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  .paginationButtons {
    display: flex;
    flex: 1;
    margin-right: 1rem;
    & > * {
      margin-bottom: 0;
      display: flex;
      flex: 1;
      align-items: center;
    }
  }
  .fetchSummaryButton {
    margin-left: 0.5rem;
  }
  .speed {
    width: 90px;
    margin-left: 0.5rem;
  }
}

.invoicingPageContainer {
  display: flex;
  flex: 1;
  flex-direction: column;

  .invoicingTable {
    display: flex;
    flex: 1;

    .pass {
      color: green;
    }
    .fail {
      color: red;
    }
    .warning {
      color: orange;
    }
    .error {
      color: #990000;
      font-weight: 500;
    }
    .topHeader {
      & > * {
        display: flex;
        flex: 1;
        align-items: flex-end;
        padding-bottom: 0.75rem;
      }
    }
    .companyColumn {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      padding-left: 0.5rem;
      .companyLogo {
        img {
          width: 75px;
        }
      }
      .companyName {
        display: flex;
        flex: 1;
        margin-left: 1rem;
      }
    }
    .checkerResultsColumn {
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      & > * {
        margin-right: 1rem;
        &.result {
          width: 50px;
          font-size: 50px;
        }
        &.logs {
          display: flex;
          flex-direction: column;
        }
        &.streaming {
          display: flex;
          flex: 2;
          flex-direction: column;
          .streamingSpend {
            display: flex;
            flex: 1;
            flex-direction: row;
            margin-right: 1rem;
            .streamingSpendItems {
              display: flex;
              flex: 1;
              flex-direction: row;
            }
          }
          .deliverySpend {
            display: flex;
            flex: 1;
            flex-direction: row;
            .deliverySpendItems {
              display: flex;
              flex: 1;
              flex-direction: row;
            }
          }
          .modalButton {
            margin-left: 0.5rem;
            cursor: pointer;
          }
        }
      }
      .recheck {
        margin-right: 1rem;
      }
    }
    .commissionColumn {
      margin-right: 1rem;
    }
    .downloadColumn {
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: flex-start;
      .downloadButton {
        width: 55px;
        & > * {
          width: 100%;
          display: flex;
          justify-content: center;
          min-height: 36px;
        }
      }
      & > *:not(:first-child) {
        margin-left: 0.5rem;
      }
    }
  }
}
.invoiceModal {
  .networkBreakdown {
    .networkName {
      font-size: 20px;
      font-weight: 500;
    }
    .detail {
      font-size: 16px;
      display: flex;
      flex-direction: row;
      & > * {
        text-align: right;
      }
      .invoiceModalLabel {
        width: 110px;
      }
      .cost {
        font-weight: 500;
        width: 125px;
      }
      .count {
        padding-left: 10px;
      }
    }
    .impsHeader {
      margin-top: 10px;
      font-weight: 500;
      font-size: 16px;
    }
  }
}

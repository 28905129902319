@import "../utils/sassHelpers.scss";
@import "../utils/colors.scss";

.optimizationPage {
  padding: $page-padding;
  flex: 1;
  display: flex;
  & > * {
    flex: 1;
    display: flex;
    flex-direction: row;
    min-width: 0;
    .listOptimizations {
      flex: 1;
      display: flex;
      flex-direction: row;
      margin-top: -1rem;

      .constraints {
        width: 630px;
        margin-left: 1rem;
      }
      .optimizations {
        flex: 1;
      }

      .optimizationTableHeader {
        display: flex;
        font-size: 24px;
        height: 40px;
        margin-top: 5px;
        margin-bottom: 5px;

        .BPMSpinner {
          max-width: 50px;
          margin-left: 20px;
        }
      }

      .constraintTableHeader {
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 24px;
        height: 40px;
      }

      .listOptimizationsBody {
        min-width: 0;
        display: flex;
        flex-direction: column;

        .filterTableContainer .constraintActions button {
          margin-left: 10px;
          padding: 2px 10px;
        }

        .logsLink {
          padding: 0;
        }

        .stickyTableCell {
          font-size: 12px;
          button {
            font-size: 12px;
          }
        }
      }
    }
    .constraintViewBody {
      width: calc(100% + 2.4rem);
      margin: -1.2rem;
      display: flex;
      flex-direction: column;

      .overallBudgetContainer {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #dee2e6;
        padding: 10px 0;

        .tableHeader {
          margin: auto 10px auto 1.2rem;
          display: flex;
          flex-direction: row;
          align-content: space-between;
          width: 150px;
          .tableName {
            font-size: 18px;
          }
        }

        .overallBudget {
          max-width: 220px;
        }
      }
      .constraintView {
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 1.2rem;
        background-color: #f1f2f6;

        .avoidGlobalGroup {
          flex: 1;
          display: flex;

          .avoidTable {
            flex: 1 1 50%;
            margin-right: 0px !important;
          }
          .globalTable {
            flex: 1 1 50%;
            margin-left: 12.5px;
          }
        }

        .constraintTable {
          flex: 1;
          display: flex;
          flex-direction: column;
          border: 1px solid #e6eaee;
          margin-bottom: 25px;
          min-height: 200px;

          .tableHeader {
            flex-direction: row;
            display: flex;
            justify-content: space-between;
            background-color: #fff;
            padding-top: 10px;
            border-bottom: 1px solid #e6eaee;

            .tableName {
              font-size: 18px;
              font-weight: 500;
              margin-left: 14px;
              margin-top: 4px;
            }

            .tableActions {
              margin-right: 10px;
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              & > * {
                margin-left: 10px;
              }

              button {
                max-height: 32px;
              }
            }
          }
          .outerTableContainer {
            .tableContainer {
              max-height: 300px;
              flex: 1;
              .hovered > :not(.rowActions) {
                background-color: $primary;
                color: white;
              }
              .topHeader {
                font-size: 14px;
              }

              .rowActions {
                display: flex;
                flex: 1 1;
                flex-direction: row;
                justify-content: space-around;
                padding: 5px;

                button {
                  padding: 0;
                }

                .errorIndicator {
                  padding: 0;
                  color: red;
                  width: 18px;
                }
              }

              .modalEditTableCell {
                font-size: 12px;
                flex: 1;
                height: 100%;
                padding: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
              }
              .constraintCellInput {
                margin: 0 0.25rem;
              }
              .constraintCellSelect {
                width: 100%;
              }
              .centeredData {
                text-align: center;
              }
            }

            * > .noRowsRenderer {
              text-align: center;
              margin-top: 15px;
              color: #8c8a97;
            }
          }
        }
      }
    }

    .constraintViewHeader {
      z-index: 100;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      border-bottom: 1px solid #dee2e6;
      margin-bottom: 15px;
      min-height: 50px;
    }

    .resultBuyable {
      width: 100%;
      height: 100%;

      .topHeader {
        font-weight: 500;
      }

      .bottomHeader {
        font-weight: 500;
      }
    }

    .resultConstraints {
      width: 100%;
      display: flex;
      flex-direction: column;

      .topHeader {
        font-weight: 500;
      }

      .bottomHeader {
        font-weight: 500;
      }

      .beforeFetch {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 35px;
        margin-right: auto;
        margin-left: auto;
        width: 30%;
        font-weight: 400;
        font-size: 24px !important;

        .downloadButton {
          font-size: 20px;
          font-weight: 500;
        }
      }

      .checkboxRow {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 15px;
      }
    }

    .resultPivot {
      width: 100%;
      display: flex;
      flex-direction: row;

      .resultPivotColumn {
        display: flex;
        flex-direction: column;
        margin-right: 20px;

        .BPMTable {
          height: 100%;
          margin-bottom: 30px;

          .topHeader {
            font-weight: 500;
          }
        }

        .grandTotalCell {
          font-weight: 500;
        }
      }

      .left {
        min-width: 450px;
      }

      .right {
        min-width: 320px;

        .BPMTable {
          max-height: 538px;
        }
      }

      .middle {
        width: 100%;

        .BPMTable {
          min-height: 207px;
          flex: 1;
        }

        .BPMTable:first-child {
          min-height: 495px;
        }

        .BPMTable:last-child {
          min-height: 495px;
        }
      }
    }
  }
}

.modalControls {
  align-items: flex-end;

  .delete {
    margin-right: auto;
  }

  .total {
    margin-right: auto;
    font-size: 24px;
  }
}

.constraintViewEditModal {
  .modalBody {
    display: flex;
    flex-direction: column;

    .modalRow {
      display: flex;
      flex-direction: row;
      min-width: 0;
    }
    .form-group {
      margin-right: 10px;
    }
  }
}

.lastModified {
  font-size: 1rem;
}

.navItem {
  font-weight: 500;
  font-size: 15px;
}

.presetFilterBar {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  .presetFilterBarTitle {
    font-weight: 500;
    margin-right: 20px;
    display: flex;
    align-items: center;
  }

  .filterButton + .filterButton {
    margin-left: 10px;
  }
}

@import "../../utils/colors.scss";

.chartContainerFooterContainer {
  overflow: hidden;
  .chartContainerFooter {
    padding: 0 24px;
    background-color: $Brand10;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    color: $Brand60;
    font-size: 12px;
    max-width: 100%;
    overflow: hidden;
    flex-wrap: wrap;
    box-sizing: border-box;
    span {
      padding-left: 15px;
      white-space: nowrap;
    }
  }
}

@import "../utils/colors.scss";

.BHMActions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 14px;
  padding-right: 11px;
  .lastUpdated {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
    font-size: 14px;
    color: $Brand100;
    font-weight: 500;
    padding-right: 16px;
    .lastUpdatedIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22px;
      height: 22px;
      font-size: 22px;
      color: white;
    }
    .lastUpdatedText {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 4px;
      color: white;
    }
  }
}
.brandHealthMetrics {
  display: flex;
  flex-direction: row;
  min-height: 625px;
  min-width: 1500px;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  .leftSection {
    display: flex;
    flex-direction: column;
    width: 75%;
    min-width: 1020px;
    height: 100%;
  }
  .rightSection {
    display: flex;
    min-width: 340px;
    width: 25%;
    flex-direction: column;
    .signature {
      height: 86px;
      border-radius: 6px;
      display: flex;
      padding: 8px 8px 8px 40px;
      column-gap: 12px;
      .signatureLabel {
        display: flex;
        align-self: center;
        align-items: flex-start;
        font-weight: 500;
        font-size: 1.1875rem;
        color: $Grey100;
      }
      .signatureImage {
        display: flex;
        align-items: center;
        align-self: center;
      }
    }
  }
}

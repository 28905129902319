.demoGenActions {
  display: flex;
  gap: 8px;
  justify-content: space-between;
}

.body:has(.demoGenInputs) {
  display: flex;
  gap: 2.5vh;
  padding: 2.5vw 10vh;
  height: 400vh;
  .demoGenInputs {
    .title {
      font-size: larger;
      font-weight: bolder;
      margin-left: -1rem;
    }
  }
  .demoGenTableTitle {
    font-size: larger;
    font-weight: bolder;
    margin-left: -1rem;
    margin-bottom: -2.5vh;
  }
  .BPMTable {
    height: 100vh !important;
  }
}

@import "../../utils/colors.scss";
@import "../../styles/Fonts.scss";

.compareMetricsContainer {
  display: flex;
  flex: 1;

  .leftSide {
    .headerTitle {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      .removeButton {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #94a0b8;
        font-size: 14px;
        border-radius: 24px;
        width: 22px;
        height: 22px;
        background-color: #f1f3f9;
        cursor: pointer;
      }

      .addComparisonMetricDropdown {
        width: 48px;
        height: 22px !important;
        padding: 0px 8px 0px 12px !important;
        border-radius: 52px;
        &.hover:not(.disabled),
        &:hover:not(.disabled) {
          background-color: $Brand25 !important;
          .dt-text {
            color: $Brand100 !important;
          }
        }

        .dt-text {
          color: $Neutral600;
        }
      }

      .titleDivider {
        border-left: 1px solid #94a0b8;
        height: 28px;
        margin-left: 0.25rem;
        margin-right: 0.5rem;
      }

      .primaryDateRange {
        font-size: 24px;
        font-weight: 400;
        color: #94a0b8;
        margin-right: 0px;
      }

      .addOtherDateRange {
        display: flex;
        align-items: center;
        background-color: #f1f3f9;
        color: #94a0b8;
        border-radius: 20px;
        padding: 0.5rem;
        margin-left: 4px;
        height: 26px;
        cursor: pointer;
      }

      .otherDateRange {
        font-size: 24px;
        font-weight: 400;
        color: #94a0b8;
      }
    }
  }

  .cm-Graph {
    display: flex;
    flex-direction: column;
    .cm-aboveGraph {
      @include bodyBold;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-left: 10px;
      margin-right: 148px;
    }
    .cm-GraphContents {
      display: flex;
      flex: 1;
      flex-direction: row;
      .cm-Graph {
        display: flex;
        flex: 1;
        flex-direction: row;
      }
      .rightOfChart {
        .cm-Legend {
          justify-content: start;
          min-width: 100px;
          padding-right: 28px;
          .legendDateRange {
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 0.5rem;
          }
          .legendItem {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 14px;
            margin-bottom: 24px;
            line-height: 16px;
            .colorKey {
              margin: 0 0.25rem 0 0;
            }
            .unselected {
              color: $Neutral500;
            }
          }
        }
      }
    }
  }
  &.showPercentChange {
    .BPMSuperHeaderWithData:last-child {
      min-width: fit-content;
      margin-left: -15px;
    }
  }
}

.metaBuyingPage {
  display: flex;
  flex: 1;

  .fullPageRouter {
    display: flex;
    flex: 1;
    height: 100%;
    width: 100%;
  }
}

.metaBuyingTabs {
  border-bottom: 1px solid #cbd2e1;
  background: #fff;
  display: flex;
  flex: 1;

  button {
    border-radius: 0px;
    opacity: 0.5;
    background: transparent;
    font-weight: 600;

    &.selected {
      opacity: 1;
      border-bottom: 3px solid #6b2def;
    }
  }
}

.metaBuyingWidgetWrapper {
  height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.metaBuyingWidget {
  width: 100%;
  margin: 24px;
  border: 2px solid #6b2def;
  border-radius: 16px;
  max-width: 812px;
  background: #fff;

  h2 {
    padding: 24px 24px 16px;
  }

  h3 {
    margin-bottom: 10px;
  }
}

.metaBuyingWidgetBody {
  border-top: 1px solid #94a0b8;
  border-bottom: 1px solid #94a0b8;
  padding: 24px 42px;
}

.submitSelections {
  margin: 24px 24px 24px auto;
}

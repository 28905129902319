@import "../../utils/colors.scss";
.bpmInputContainer {
  position: relative;

  .cl-input {
    border: 1px solid $Brand50;
    border-radius: 6px;
    min-width: fit-content;
    height: 32px;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    padding: 0px 6px 0px 16px;
  }

  .cl-input:disabled {
    border: 1px solid $Neutral400;
    color: $Neutral200;
  }

  .searchableInputDropdown {
    position: absolute;
    width: 100%;
    z-index: 100;
    overflow: scroll;

    .dropdownMenuBodyContainer {
      overflow: scroll;

      .dropdownMenuItemsContainer {
        overflow: scroll;
      }
    }
  }

  ::placeholder {
    color: $Brand100;
    opacity: 0.5;
  }
}

.adOpsContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;
  margin: 8px 20px;

  .adOpsRow {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 0;

    .adOpsCard {
      margin: 8px !important;
      width: calc(50% - 16px);
      padding: 12px;
      overflow: auto;
      min-height: 320px;

      .adOpsCardTitle {
        font-size: larger;
        font-weight: 500;
      }
      .adOpsElem {
        margin-bottom: 3px;
        margin-top: 3px;
        .adOpsFormCheck {
          margin-left: 20px;
        }
      }
    }
  }
}

@import "../../../utils/colors.scss";
@import "../../../styles/Fonts.scss";

.rightOfChart {
  .legendWithGrouping {
    justify-content: start;
    .legendItem {
      align-items: center;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      font-size: 14px;
      margin-bottom: 15px;
      line-height: 16px;
      .square {
        border-radius: 1px;
        flex-shrink: 0;
        height: 12px;
        margin-right: 8px;
        width: 16px;
      }
      .legendValue {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;
        .legendHeaderWrapper {
          display: flex;
          .legendHeader {
            @include secondaryRegular;
            margin-bottom: 6px;
            &.inactiveLegend {
              color: $Neutral500;
            }
          }
        }
        .subHeaderWrapper {
          @include secondaryThin;
          display: flex;
          margin-bottom: 6px;
          &:last-of-type {
            margin-bottom: 0;
          }
          .subHeaderTextWrap {
            display: flex;
            flex-wrap: wrap;
          }
        }
        .inactiveLegend {
          color: $Neutral500;
        }
      }
    }
  }
}

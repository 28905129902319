@import "../utils/colors.scss";

.youTubePlanningActions {
  display: flex;
}

.youTubePlanningBody {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: $Brand20;
  padding: 16px;

  .planningForm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 33%;
    margin: 24px;
    border: 2px solid #6b2def;
    border-radius: 16px;
    max-width: 812px;
    background: #fff;

    h2 {
      padding: 24px 24px 16px;
    }

    h3 {
      margin-bottom: 8px;
    }

    .planningFormHeader {
      display: flex;
      align-items: center;
      padding: 24px 24px 16px 24px;
      border-bottom: 1px solid $Neutral500;
      font-size: 24px;
      font-weight: 600;
      line-height: normal;
    }

    .planningFormBody {
      .planningFormSection {
        padding: 16px 24px;
        font-size: 16px;
        font-weight: 600;

        .planningFormSectionTitle {
          margin-bottom: 10px;
        }

        .goalRadioOption {
          padding: 0px 8px 10px 32px;
          font-size: 14px;
        }

        .googleAdsAccountDropdown {
          width: 100%;
        }

        .dropdown-menu {
          width: 100%;
          max-height: 300px;
        }

        .searchableInputDropdown {
          width: 100%;
          max-height: 250px;
          overflow-y: scroll;
        }

        .queryInput,
        .budgetInput {
          width: 100%;
        }
      }
    }

    .planningFormFooter {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 24px;
      height: 80px;
      border-top: 1px solid $Neutral500;
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

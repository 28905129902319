@use "sass:math";
@import "../utils/colors.scss";
@import "../styles/Fonts.scss";
$circle-diameter: 14px;

.googleBrandHealth {
  display: flex;

  .googleBrandHealthleft {
    flex: 3;
    padding: 8px 0px 8px 8px;
    height: calc(95vh - 18px);
    .googleBrandHealthRightActions {
      display: flex;
      gap: 16px;
    }
    .chartContainer {
      height: 100%;
      .titleForGqvChart {
        color: $Brand100;
      }
    }
    .lineWrapper {
      padding-top: 32px;
    }

    .lineWrapper .lineChart .rightSideWrapper {
      width: 20%;
      padding-left: 16px;
      margin-right: 10px;
    }
    .lineWrapper .lineChart .rightSideWrapper .rightOfChart {
      height: 450px;
    }
    .gqvTableContainer {
      .labelFilter .labelItem .item {
        color: $Brand100;
        font-weight: 500;
        font-size: 14px;
      }

      .gqvTable {
        flex: 4;
        .BPMTable .STTopHeader > .stickyTableCell {
          color: $Brand100;
          font-weight: 500;
          font-size: 14px;
        }
        .BPMTable .BPMSuperHeader {
          color: $Brand100;
          font-weight: 500;
          font-size: 16px;
        }

        .cellContents {
          flex-direction: row;
          .cellContentsHeader {
            color: $Neutral500;
          }
          .cellContentsValue {
            text-align: center;
          }
        }
      }
      .labelFilter {
        flex: 1;
        width: 232px;
        padding: 32px 0px 0px 16px;
        margin-right: 10px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        .labelItem {
          display: flex;
          flex-direction: column;
          font-size: 14px;
          margin-bottom: 15px;
          line-height: 16px;
          .item {
            display: flex;
            align-items: flex-start;
            flex: 1 0 0;
            .itemButton {
              margin-left: 10px;
            }
            .itemButtonLabel {
              color: $Brand100;
              font-weight: 500;
            }
            .form-check-input {
              margin-left: 0px;
              appearance: none;
              width: 16px;
              height: 16px;
              border: 2px solid $Neutral500;
              border-radius: 50%;
              background-color: white;
              cursor: pointer;
              &:checked {
                background-color: $Brand50;
                border-color: $Brand50;
              }

              &:hover {
                border-color: $Brand50;
              }
            }
            .itemButtonLabel {
              margin-left: 26px;
            }
          }
          .itemSubHeader {
            padding-left: 37px;
            color: $Neutral600;
            font-weight: 400;
            font-size: 12px;
          }
        }
      }
    }
    .geoMapContainer {
      .geoMapLegendAndMap {
        flex: 4;
        flex-direction: row;
        margin-top: 25px;
        .legendMap {
          display: flex;
          padding: 4px 4px 4px 16px;
          align-items: center;
          margin-top: 14px;
          margin-bottom: 32px;
          gap: 8px;
          border-radius: 4px;
          border: 1px solid #cbd2e1;
          width: fit-content;
          .legendTitle {
            font-family: Graphik;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            color: $Brand100;
          }
          .legendItem {
            display: flex;
            padding: 6px 12px 6px 6px;
            flex-direction: row;
            align-items: flex-start;
            gap: 8px;
            .legendShape {
              width: 16px;
              height: 12px;
              color: $Brand20;
              border-radius: 1px;
              flex-shrink: 0;
              margin-right: 6px;
            }
            .legendText {
              color: $Brand100;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }
        }
        .bpmTooltip {
          background-color: transparent;
          border: 0px;
          border-radius: 0px;
          box-shadow: none;
        }
        .chartTooltipMap {
          background-color: white;
          border-radius: 6px;
          border: 2px solid $Neutral600;
          box-shadow: 0px 4px 18px 0px rgba(148, 160, 184, 0.5);
          display: flex;
          flex-direction: column;
          position: absolute;
          left: 100%;
          .headerLabelMap {
            background-color: $Neutral200;
            border-top-right-radius: 6px;
            border-top-left-radius: 6px;
            color: $Brand100;
            font-size: 16px;
            font-weight: 400;
            padding: 8px 16px 0 16px;
            width: 100%;
          }

          .itemListMap {
            padding: 10px 14px 14px 14px;
            display: flex;
            flex-direction: column;
            .itemRowMap {
              align-items: center;
              display: flex;
              margin-bottom: 8px;
              .nameMap {
                @include secondaryRegular;
                font-size: 14px;
                margin-right: 3rem;
              }
              .valueMap {
                @include secondaryThin;
                margin-left: auto;
                font-size: 14px;
              }
            }
          }
          .footerMap {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding-top: 8px;
            border-top: 1px solid $Neutral600;
            .footerLabelMap {
              color: $Brand100;
              font-size: 16px;
              font-weight: 500;
            }
            .footerValueMap {
              color: $Brand100;
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
      }

      .labelFilter {
        flex: 1;
        width: 232px;
        padding: 32px 0px 0px 16px;
        margin-right: 10px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        .labelItem {
          display: flex;
          flex-direction: column;
          font-size: 14px;
          margin-bottom: 15px;
          line-height: 16px;
          .item {
            display: flex;
            align-items: flex-start;
            flex: 1 0 0;
            .itemButton {
              margin-left: 10px;
            }
            .itemButtonLabel {
              color: $Brand100;
              font-weight: 500;
            }
            .form-check-input {
              margin-left: 0px;
              appearance: none;
              width: 16px;
              height: 16px;
              border: 2px solid $Neutral500;
              border-radius: 50%;
              background-color: white;
              cursor: pointer;
              &:checked {
                background-color: $Brand50;
                border-color: $Brand50;
              }

              &:hover {
                border-color: $Brand50;
              }
            }
            .itemButtonLabel {
              margin-left: 26px;
            }
          }
          .itemSubHeader {
            padding-left: 37px;
            color: $Neutral600;
            font-weight: 400;
            font-size: 12px;
          }
        }
      }
      svg {
        object-fit: contain;
      }
    }
  }
  .googleBrandHealthRight {
    flex: 1;
    flex-direction: column;
    padding: 8px 8px 0px 0px;
    .chartContainer {
      height: 80%;
    }
    .signature {
      height: 20%;
      margin: 0px 8px 0px 8px;
      display: flex;
      .signatureLabelAndImageContainer {
        display: flex;
        margin: 8px 8px 24px 8px;
        .signatureLabel {
          margin: 21px 0px 21px 8px;
          display: flex;
          width: 50%;
          align-self: center;
          align-items: flex-start;
          font-weight: 600;
          font-size: 24px;
          color: #1f003f;
        }
        .signatureImage {
          width: 50%;
          display: flex;
          align-items: center;
          align-self: center;
          margin-right: 8px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
}

.extendEditModal {
  .modalTitle {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    & > *:first-child {
      margin-right: 0.5rem;
    }
  }
  .header {
    display: flex;
    flex: 1;
    flex-direction: column;
    & > * {
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;
    }
    .week {
      font-weight: 500;
      font-size: 1.2rem;
    }
    .selectedRowDetails {
      display: flex;
      flex-wrap: wrap;
      & > * {
        margin-right: 0.5rem;
      }
      .detail {
        border: 1px solid gray;
        border-radius: 10px;
        padding: 0.5rem;
        margin-top: 0.25rem;
        .label {
          font-weight: 500;
        }
      }
    }
    .editDetails {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      font-size: 1.2rem;
      & > * {
        margin-right: 0.5rem;
      }
      .editType {
        font-weight: 500;
      }
      .oldValue {
        color: #dc143c;
      }
      .newValue {
        color: green;
      }
    }
  }
  .futureRows {
    .checkAllButton {
      margin-bottom: 0.5rem;
    }
    .select {
      width: 100px;
    }
    .week {
      width: 150px;
    }
    .edit {
      & > * {
        margin-right: 0.5rem;
      }
      .editType {
        font-weight: 500;
      }
      .oldValue {
        color: #dc143c;
      }
      .newValue {
        color: green;
      }
    }
  }
}

.viewPlacementMetadata {
  display: flex;
  flex-direction: row;

  .metadataHeader {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 12px;
  }

  .placementMetadata {
    flex: 1;
    max-width: 50%;

    .placementMetadataContainer {
      flex-direction: column;
      padding: 12px;
      margin-bottom: 8px;
      margin-right: 24px;
      border-radius: 3px 3px 0 0;
      box-shadow: 0px 2px 10px #888888;
      border: 1px solid #cccccc;

      .placementMetadataLabel {
        font-weight: 500;
        padding-bottom: 4px;
      }

      .urlText {
        width: fit-content;
        max-width: 100%;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 2px;
      }
    }
  }

  .rotationMetadata {
    flex: 1;
    max-width: 50%;

    .rotationMetadataOuterContainer {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
      padding: 12px;
      border-radius: 3px 3px 0 0;
      box-shadow: 0px 2px 10px #888888;
      border: 1px solid #cccccc;

      .isciLabel {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 500;
        padding-bottom: 2px;

        .isci {
          font-size: 1.5rem;
          margin-right: 8px;
        }

        .creativeName {
          font-size: 0.8rem;
        }
      }

      .rotationMetadataContent {
        display: flex;
        flex-direction: column;

        .rotationMetadataInnerContainer {
          display: flex;
          flex-direction: column;

          .rotationMetadataLabel {
            font-weight: 500;
            margin-top: 4px;
          }

          .urlText {
            width: fit-content;
            max-width: 100%;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 2px;
          }

          .audioCompanionContainer {
            display: flex;
            flex-direction: row;
          }
        }
      }
    }
  }
}

.overlayTriggerContainer .urlTooltip .tooltip-inner {
  max-width: 1000px;
  word-break: break-all;
}

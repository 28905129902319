.media-mix-page {
  margin: 1.2rem;
  margin-top: 15px;
}

.media-mix-page .basic-card {
  display: flex;
  gap: 20px;
  margin-top: 15px;
}

.media-mix-page .basic-card:last-of-type {
  margin-bottom: 15px;
}

.media-mix-page .table-wrapper {
  height: 460px;
}

.media-mix-page .graph-wrapper,
.media-mix-page .table-wrapper {
  display: flex;
  flex: 1 1;
  width: 50%;
}

@media only screen and (max-width: 1679px) {
  .media-mix-page .basic-card {
    flex-direction: column;
  }

  .media-mix-page .graph-wrapper,
  .media-mix-page .table-wrapper {
    flex: unset;
    width: 100%;
  }
}

.ad-path-filters-menu {
  margin-bottom: 16px;
}

.ad-path-filters-row {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.ad-path-filters-row-label {
  font-size: 20px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  width: 240px;
  padding: 0 12px;
}

.ad-path-filters-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
}

.ad-path-dropdown-wrapper {
  display: flex;
  align-items: center;
  padding: 8px 12px;

  .cl-dropdown {
    width: 100%;

    button {
      width: 100%;
    }
  }
}

.spendBreakdown {
  display: flex;
  flex: 1;
  flex-direction: column;
  .aboveChart {
    display: flex;
    align-items: center;
    & > :first-child {
      margin-right: 2px;
    }
  }
  .chartContents {
    display: flex;
    flex: 1;
    flex-direction: row;
    .spendBreakdownChart {
      display: flex;
      flex: 1;
      flex-direction: row;
    }
    .rightOfChart {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 0.5rem;
    }
  }
}

.spendBreakdownTable {
  .farRightColumn {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #8c8a97;
  }
}

@import "../utils/sassHelpers.scss";

.addNetworkView {
  display: flex;
  flex-direction: column;
  padding: $page-padding;

  &.saving {
    opacity: 0.5;
    pointer-events: none;
  }
  .editButtons {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
    & > *:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
  .form-label {
    padding-left: 0rem;
    margin-bottom: 0rem;
    font-weight: 500;
    font-size: 0.9rem;
  }

  .newDspList {
    & > *:not(:last-child) {
      margin-right: 0.25rem;
      &::after {
        content: ",";
      }
    }
  }

  .newPlatformList {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.25rem;
    .newPlatformListRow {
      display: flex;
      .newPlatformListItem {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        & > *:not(:last-child) {
          margin-right: 0.5rem;
        }
      }
    }
  }
  .tooltipContainer {
    vertical-align: middle;
    margin-left: 0.25rem;
  }
}

@import "../utils/sassHelpers";
@import "../utils/colors";

$delivered: #2ae0f6;
$projected: #aef3fc;
$remainder: #d9f9fd;
$budget: #007f8e;

.pacingDashboardActions {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  .optionsHelp {
    color: white;
  }
}

.crossChannelPacingDashboard {
  background-color: $Neutral300;
  border-radius: 16px;
  border: 1.5px solid $Neutral400;
  min-height: 100vh;
  padding: 20px;
  margin: 20px;
  overflow-y: auto;
  gap: 1rem;
  padding: 2rem;

  .dashboardTitleContainer {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: stretch;

    .dashboardTitleContainerTopHalf {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      gap: 0.5rem;
      .dashboardTitleLeftHalf {
        display: flex;
        align-items: center;
        flex-direction: flex-start;
        width: 100%;
        gap: 0.5rem;
        .dashboardTitleElements {
          display: flex;
          align-items: center;
          align-self: stretch;
          font-size: 1.875rem;
          color: $Brand100;
          font-weight: 500;
          gap: 0.5rem;
        }
        .cl-dropdown-toggle.dropdown-toggle.btn.btn-primary.widget-title.light .dt-text {
          font-family: Graphik;
          font-size: 1.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: 1.5rem;
          color: $Brand100;
        }
      }

      .dashboardTitleButtons {
        align-items: center;
        gap: 0.5rem;
        flex-direction: row;
      }

      .removeDimension {
        cursor: pointer;
        background: none;
        border: none;
        color: rgb(29, 0, 55);
      }
    }

    .dashboardTitleContainerBottomHalf {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
    }

    .pacingLegend {
      margin-left: auto;
      display: flex;
      height: 1.75rem;
      padding: 0rem 1rem;
      justify-content: flex-end;
      align-items: center;
      gap: 0.5rem;
      border-radius: 0.375rem;
      background: #fff;
      .pacingLegendContainer {
        display: flex;
        align-items: center;
        gap: 1.25rem;
      }

      .pacingLegendHead {
        color: $Brand100;
        font-size: 0.875rem;
        font-weight: 600;
        line-height: normal;
      }

      .pacingBoxLineText {
        display: flex;
        align-items: center;
        gap: 0.4375rem;
      }
      .pacingBox {
        width: 1.125rem;
        height: 1.125rem;
        align-items: center;
        display: flex;
        padding: 0.125rem 0.625rem;
        gap: 0.625rem;
      }
      .pacingLine {
        width: 0.1875rem;
        height: 1.125rem;
        display: inline-block;
      }
      .delivered {
        background-color: $delivered;
      }
      .projected {
        background-color: $projected;
      }
      .remainder {
        background-color: $remainder;
      }
      .budget {
        background-color: $budget;
      }
      .legendLabel {
        font-size: 0.875rem;
        font-weight: 500;
        line-height: normal;
      }
      .legendBudgetText {
        color: #007f8e;
      }
    }
  }
}

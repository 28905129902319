@import "../utils/sassHelpers.scss";

.BPMSpinner {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}
.bpmFullPageSpinner {
  @include fullBox();
  background-color: white;
  display: flex;
  z-index: 10000000;
  &.transparent {
    background-color: rgba(255, 255, 255, 0.7);
  }
  &.fixed {
    position: fixed;
  }
}

@import "../../utils/colors.scss";

/* Tooltip container */
.tooltipContainer {
  cursor: default;
  display: inline-block;
  position: relative;
  color: $Brand50;

  .infoIcon {
    vertical-align: top;
  }

  /* Tooltip text */
  .tooltipText {
    font-weight: 400;
    font-size: 14px;
    background-color: $White;
    color: $Brand100;
    border-radius: 4px;
    border: 2px solid $Brand50;
    box-shadow: 0px 4px 4px $Grey60;
    width: max-content;
    max-width: 312px;

    /* Position the tooltip text */
    position: absolute;
    z-index: 999;
    padding: 16px 16px 14px 16px;

    /* Tooltip arrow */
    &::after {
      content: "";
      position: absolute;
      border-width: 6px;
      border-style: solid;
    }
    &.top-left::after {
      top: 100%;
      right: 16px;
      border-color: $Brand50 transparent transparent transparent;
    }
    &.top-right::after {
      top: 100%;
      left: 16px;
      border-color: $Brand50 transparent transparent transparent;
    }
    &.bottom-right::after {
      bottom: 100%;
      left: 16px;
      border-color: transparent transparent $Brand50 transparent;
    }
    &.bottom-left::after {
      bottom: 100%;
      right: 16px;
      border-color: transparent transparent $Brand50 transparent;
    }
  }
}

@import "../../utils/colors.scss";

.BPMFilterBar {
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 38px;
  position: relative;
  z-index: 10;
  .filterBarComponent {
    flex: 1;
    height: 100%;
    position: relative;

    .filterBarSelect__control--is-focused {
      border-color: $primary;
      box-shadow: 0 0 0 1px $primary;
    }
  }
  .filterSubmit {
    margin-left: 0.5rem;
  }
  .modeButton > button {
    height: 100%;
    min-width: 100px;
  }
  & > *:not(:first-child) {
    margin-left: 0.5rem;
  }
}

.filterBarHowto {
  font-size: 0.9rem;
  .carouselContainer {
    display: flex;
    flex-direction: row;
    .carouselControl {
      width: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 40px;
      margin-bottom: 23px;
      cursor: pointer;
      position: relative;
      button {
        position: absolute;
        bottom: 0;
      }
    }
    .carouselInterior {
      flex: 1;
      .carousel-indicators li {
        background-color: black;
      }
      .carousel-item {
        margin: 0 2px 23px;
        height: 400px;
        background-color: white;

        p {
          margin-top: 10px;
        }
        .sampleTable {
          margin: 20px;
          .filterTable {
            margin-top: 10px;
            min-height: 100px;
            max-height: 100px;
            font-size: 12px;
            overflow-y: auto;
          }
        }
      }
    }
  }
}

@import "../utils/colors.scss";

.breakdownLegend {
  .legendItem {
    border: 1px solid $Grey60;
    border-radius: 6px;
    padding: 8px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 80px;
    height: 60px;
    .title {
      font-size: 12px;
      font-weight: 500;
    }
    .key {
      font-size: 11px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

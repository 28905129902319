.wtoRulesFormModal {
  .iconWrapper {
    margin: 0 10px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .ruleNameWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    * {
      margin: 0;
    }
  }

  .modal-dialog {
    min-width: 1000px !important;
  }

  .variantsInputWrapper {
    .variantSplitWrapper {
      display: flex;
      margin-bottom: 20px;

      .deleteButton {
        margin: auto 20px;
      }
    }
  }

  .dropdown button {
    padding: 4px 16px;
  }

  .variantDropdown {
    width: 21.25rem;

    button,
    div {
      width: calc(100% - 25px);
    }
  }

  .percentageWrapper {
    display: flex;
    align-items: center;

    input {
      margin-right: 10px;
    }
  }

  .variantPercentage {
    width: calc(3.125rem + 30px);
  }

  .retentionCheckbox {
    margin: 20px 0;
    padding: 0;

    * {
      position: static;
      margin-left: 0;
    }

    label {
      padding-left: 10px;
    }

    input {
      padding: 0;
      cursor: pointer;
    }
  }

  .retentionWrapper {
    display: flex;
    align-items: center;

    .SingleDatePicker {
      margin: 1rem;
    }
  }

  .trafficSplits {
    .trafficSplit {
      align-items: center;
      display: flex;
      margin-bottom: 10px;
    }
  }

  .timeSelection {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    background: #fff;
    color: #212529;
    margin-right: 10px;
    padding-right: 10px;

    .timeDropdown {
      button {
        transition: none;
        border: none;
        color: #212529;
        background: transparent;
        padding: 2px 8px;
        width: 2.5rem;

        &:active {
          background: #fff;
          color: #212529;
        }
      }

      &.hour button {
        padding-right: 0;
      }

      &.minute button {
        padding-left: 0;
      }

      &.ampm button {
        width: 3rem;
      }

      &:not(.ampm) {
        button:after {
          content: none;
        }
      }

      .dropdown-menu {
        max-height: calc(15rem + 18px);
        overflow: auto;
      }
    }
  }

  .dowOperations {
    .dowHeader {
      margin: 20px 0;
    }

    .dowControls {
      .dowSelections {
        display: flex;
        width: calc(12.25rem + 60px);
        margin: 0 auto 10px 0;

        .dowSelection {
          border: 1px solid;
          border-color: #3b7fe2;
          border-radius: 50%;
          height: 1.75rem;
          width: 1.75rem;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #93b8ed;
          cursor: pointer;
          margin: 3px auto;
          font-weight: 500;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }

          &.selected {
            color: white;
            background: #93b8ed;
          }

          &.disabled {
            color: #0000001f;
            cursor: auto;
            pointer-events: none;
          }

          &:hover {
            background: #538cdd;
            border-color: #538cdd;
          }
        }
      }
    }
  }

  .addSplitWrapper {
    margin: 20px 0 40px;
  }

  .conditionsInputWrapper {
    .variantSplitWrapper {
      display: flex;
    }
  }

  .conditionFieldsWrapper {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 10px;
    margin: 16px 0;

    .operatorWrapper {
      width: calc(180px + 4.5rem);
      display: flex;
      gap: 10px;

      .conditionalOperator {
        min-width: 50px;
        font-weight: 500;
        color: #4c4c4c;
        background-color: #e4e4e4;
        padding: 0 16px;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        width: 4.5rem;
      }
    }

    .conditionDropdown {
      min-width: 170px;

      button {
        min-width: 170px;
      }
    }

    .delete-icon {
      margin: auto 0 auto auto;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .form-group {
      max-width: 160px;
      margin: 0;
      padding: 0;
    }

    .ruleDropdown {
      width: 160px;

      button {
        width: 160px;
      }
    }
  }

  .operationSection {
    .variantWrapper {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      margin: 10px 0;
    }

    .operationWrapper {
      display: flex;
      flex-direction: column;
      width: 100%;

      .eachOperation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0;

        .col {
          margin-bottom: 0;
        }
      }
    }
  }

  .ruleEditor {
    margin: 20px 0;
    background-color: #f2f7fd;
    padding: 35px;
    border-left: 2px solid #1876d2;

    .dropdown-toggle {
      background-color: #8254ff;
      color: #fff;
      font-weight: 500;
      border-radius: 6px;
      border: none;

      &:disabled {
        cursor: default;
      }
    }

    .ruleHeader {
      font-weight: 500;
      color: #555;
      margin-bottom: 35px;
      display: block;
    }

    .addSplitRow {
      position: relative;

      .splitTotal {
        position: absolute;
        left: 21.25rem;
        top: 0;
        height: 100%;
        min-height: 2rem;
        width: calc(3.125rem + 30px);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .addRule {
      margin-top: 30px;
    }
  }

  .utmCookieWrapper {
    .conditionDropdown {
      margin: auto auto auto 0;
    }

    .operationSection {
      .operationWrapper {
        .dropdown-toggle {
          width: 166px;
        }
      }

      .add-optrn {
        width: 200px;
        margin: 10px 0 10px;
      }
    }
  }

  .logicSelection {
    width: 100%;
    display: flex;

    &.conditions {
      gap: 10px;
      width: calc(100% - 4.5rem - 216px);

      > * {
        width: calc(33.33% - 6.67px);
        max-width: none;

        button {
          width: 100%;
        }
      }
    }
  }

  .timeRangeWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .timeSelection {
      margin: 0 40px 0 10px;
    }
  }

  .timeframeWrapper {
    .dateRangeWrapper {
      display: flex;
      align-items: center;
      gap: 12px;
      margin: 0 0 20px;
    }

    .timeframeUtmWrapper {
      .custom-control {
        margin-bottom: 10px;
      }

      .utmFieldContainer {
        display: flex;
        align-items: baseline;
        gap: 10px;
        margin: 16px 0;
      }
    }
  }

  .deleteButton {
    margin-left: auto;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  input {
    padding: 3px 12px;
    height: inherit;
  }

  strong {
    font-weight: 600;
  }
}

@import "./PerformanceGridConfig.scss";

.columnConfigOuter {
  margin: 0 $halfGutter;
  .columnConfig {
    position: relative;
    height: 100%;
    min-width: $colWidth;
    max-width: $colWidth;
    font-size: 0.75rem;
    background-color: white;
    $borderWidth: 2px;
    border: $borderWidth solid $gridBorderColor;
    $stripeHeight: 5px;
    padding: $stripeHeight 8px 8px;
    word-wrap: break-word;
    position: relative;
    transition: all 0.12s ease;
    opacity: 1;
    .typeSelect {
      width: 100%;
    }
    .stripe {
      position: absolute;
      top: -$borderWidth;
      right: -$borderWidth;
      left: -$borderWidth;
      height: $stripeHeight + $borderWidth;
    }
    .topActions {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 2px solid #e6eaee;
      margin: 0 #{-$halfGutter} 0.5rem;
      padding: 0 $halfGutter;
      .addActions {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem $halfGutter 0.5rem;
        border-left: 2px solid #e6eaee;

        .addLabel {
          font-size: 1rem;
          margin-right: 0.5rem;
        }
        .addHeaderIcon {
          transform: rotate(90deg);
        }
        .addHeaderButton:disabled {
          pointer-events: none;
          opacity: 0.5;
        }
      }
      .dragHandle {
        $dragPadding: 6px;
        font-size: 28px;
        padding-right: $dragPadding;
        margin-left: $dragPadding - $halfGutter;
        color: #cccccc;
        cursor: grab;
      }
    }

    &.dragging {
      opacity: 0.2 !important;
    }
    &.isOver {
      $margin: 20px;
      &.right {
        margin-right: $margin;
        &::before {
          right: -$margin - 2px;
        }
      }
      &.left {
        margin-left: $margin;
        &::before {
          left: -$margin - 2px;
        }
      }
      &::before {
        content: " ";
        position: absolute;
        background-color: #666666;
        top: 5px;
        bottom: 5px;
        width: 5px;
      }
    }
    &.hasDivider {
      min-width: $colWidth + $dividerWidth;
      max-width: $colWidth + $dividerWidth;
      border-left: $dividerWidth solid $primary;
      & > .stripe {
        left: -$dividerWidth;
      }
    }
    &.isHoverItem {
      box-shadow: 0px 0px 0px $halfGutter * 2 rgba(0, 0, 0, 0.3);
    }
    .deleteButton {
      margin: 0.5rem 0;
    }
    .basicSection {
      display: flex;
      flex-direction: row;
      margin-bottom: 0.5rem;
      .label {
        margin-right: 0.25rem;
        font-weight: 500;
      }
      .value {
        text-decoration: underline;
      }
    }
    .kpiSection {
      flex-direction: column;
      .resetToGlobal {
        cursor: pointer;
        transition: color 0.12s ease;
        &:hover {
          color: $primary;
        }
      }
      .value {
        cursor: pointer;
        transition: color 0.12s ease;
        &:hover {
          color: $primary;
        }
      }
    }
    .formSection {
      flex-direction: column;
      margin-bottom: 0.5rem;

      .label {
        font-weight: 500;
      }
      input {
        color: #666666 !important;
      }
    }
    .heatmappingSection {
      border: 1px solid rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      padding: 0 0.5rem;
    }
    .checkboxSection {
      display: flex;
      flex-direction: row;
      align-items: center;
      label {
        padding-top: 1px;
      }
    }
    .headerSection {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      margin: 0.5rem 0;
      .label {
        font-weight: 500;
        font-size: 0.9rem;
      }
      .glossary {
        text-decoration: underline;
        transition: color 0.12s ease;
        cursor: pointer;
        &:hover {
          color: $primary;
        }
      }
    }
  }
}

.performanceKpiPickerPopover {
  .dropdown-menu {
    max-height: inherit;
  }
}

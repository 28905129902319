.actionsSelector {
  width: 32px;
  height: 32px;
  position: relative;
  cursor: pointer;

  * {
    height: 100%;
    width: 100%;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    color: #bec7d2;
  }

  select {
    border: none;
    color: transparent;
    background: transparent;
    cursor: pointer;
    outline: none;
  }
}

.wtoTable {
  height: calc(100% - 64px);

  .copyPlover {
    cursor: pointer;

    &:hover {
      color: #5200ce;
    }
  }

  .statusFilterButtons {
    margin-bottom: 25px;
    border-bottom: 1px solid #d9d9d9;
    display: flex;

    .statusFilterButton {
      padding: 6px 12px;
      background-color: transparent;
      border-radius: 0;
      border: none;
      font-weight: 500;
      color: #8254ff;
      width: 10rem;
      border-radius: 5px 5px 0 0;
      transition: all 0.12s ease;
      height: 2rem;

      &:hover:not(.disabled) {
        background-color: #8254ff;
        color: #fff;
      }

      &.selected {
        color: #5200ce;
        border-top: 2px solid transparent;
        border-bottom: 2px solid #5200ce;
      }
    }
  }

  .tableCell {
    width: calc(100% - 10px);
    overflow-wrap: break-word;
    text-align: center;
    font-size: 1rem;
  }

  .stickyTableCell:has(> .actionsDropdown) {
    overflow: visible;
  }

  .wtoStatus {
    cursor: pointer;

    .statusWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .statusWrapperText {
      font-size: 12px;
      padding: 4px 15px;
      border-radius: 25px;
      margin: 2px;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .tooltipContainer {
    margin-top: 2.4rem;
  }

  .BPMTable {
    max-height: 840px;

    .BPMDropdownButton {
      display: none;
    }
  }

  .pagination-row {
    justify-content: center;
    margin: 5px;
  }

  .stickyHeader {
    .stickyTableCell {
      span {
        font-size: 1rem;
        line-height: 1rem;
        font-weight: bold;
      }
    }
  }

  .frequencyContainer {
    display: flex;

    .tooltipContainer {
      margin: 0.5rem 5px;
    }
  }

  .stickyTableCell:has(> .actionsDropdown) {
    overflow: visible;
  }
}

.overlayMenu {
  position: fixed;
  z-index: 100;
  border-radius: 5px;
  background: #fff;
  overflow: hidden;
  padding: 0.5rem 0;
  border: 1px solid #00000026;
  min-width: 10rem;
  float: left;

  button.overlayMenuOption {
    width: 100%;
    border-radius: 0;

    &:not(:disabled):not(.disabled) {
      padding: 0.25rem 1.5rem;
      background-color: transparent;
      color: #212529;
      display: flex;
      justify-content: left;

      svg {
        width: 1rem;
      }

      div {
        pointer-events: none;
        font-weight: 400;
        height: 100%;
        line-height: 1.5rem;
        margin: 0;

        &:first-child {
          margin-right: 10px;
        }
      }

      &:active {
        color: #fff;
        background-color: #7e57c2;
      }

      &:hover {
        color: #16181b;
        background-color: #e9ecef;
      }
    }
  }

  &.hide {
    display: none;
  }
}

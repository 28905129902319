@import "../utils/colors.scss";

$chartContainerHeight: 400px;
$chartContainerPadding: 48px;

.customerInsightsPieChart,
.customerInsightsBarChart {
  height: 100%;

  .recharts-legend-wrapper,
  .recharts-legend-wrapper * {
    overflow-x: hidden;
  }

  .legend {
    width: 326px;
    margin-right: 1rem;

    // Distribute padding evenly on either side so that the hover interactive area feels smooth
    & > *:not(:first-child) {
      padding-top: 2px;
    }

    & > *:not(:last-child) {
      padding-bottom: 2px;
    }

    .legendItem {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.75rem;
      cursor: pointer;

      .itemLabel {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1 0 154px;
        color: $Brand100;
        font-size: 16px;
        font-weight: 600;
      }

      .itemComputedValue {
        display: flex;
        align-items: center;
        flex: 1 0 44px;
        justify-content: flex-end;
        font-size: 16px;
        font-weight: 400;
        color: $Brand100;
      }

      & > .itemValue,
      .stackedItemValues {
        flex: 1 0 63px;
      }

      .itemValue {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: $Neutral600;
        font-size: 12px;
        font-weight: 400;
      }

      .stackedItemValues {
        height: 36px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
    }
  }

  .recharts-bar,
  .recharts-pie-sector {
    cursor: pointer;
  }

  .unhoveredValue,
  .unselectedValue {
    opacity: 0.25;
  }

  .chartContainer > .contents {
    padding: 0;
  }
}

.customerInsightsBarChart {
  .recharts-legend-wrapper {
    left: 0 !important;
    top: 5px !important;

    .legend > * {
      padding: 0;
    }
  }

  .chartContainer .contents {
    padding: 0;
    // Shift scrollbar to be 4px from edge of parent container
    padding-right: 8px;
    margin-right: 8px;
    width: calc(100% + 16px);
    margin-top: 1rem;
    display: block;
    overflow-y: scroll;

    &::-webkit-scrollbar-thumb {
      background-color: $Neutral400;
    }
  }
}

.customerInsightsPieChart {
  .contents {
    margin-top: calc(1rem - 28px);
    padding: 0;
  }

  .recharts-legend-wrapper {
    height: calc(100% - #{28px}) !important;
    overflow-y: auto;
    margin-top: 31px;
    top: 0 !important;
    left: 0 !important;

    &::-webkit-scrollbar-thumb {
      background-color: $Neutral400;
    }

    .legend {
      margin-top: 2px;

      & > *:not(:first-child) {
        padding-top: 6px;
      }

      & > *:not(:last-child) {
        padding-bottom: 6px;
      }

      .legendItem {
        height: fit-content;

        .itemColor {
          border-radius: 50%;
          flex: 0 0 22px;
          height: 22px;
          margin-right: 0.5rem;
        }
      }
    }
  }
}

.customerBaseBarChart {
  .legendItem {
    height: 48px;
  }

  .chartContainer .contents {
    margin-top: 10px;
  }
}

.indexSelectedPieChart {
  .indexOuterPie {
    opacity: 0.25;
  }
}

.penetrationPieChart,
.selectedPenetrationPieChart {
  .penetrationInnerPie {
    opacity: 0.3;
  }

  .penetrationOuterPie {
    opacity: 0.75;
  }
}

.customerBaseBarChart,
.customerBasePieChart {

  .recharts-bar,
  .recharts-pie-sector {
    cursor: default;
  }

  .legend {
    .legendItem {
      cursor: default;
    }
  }
}
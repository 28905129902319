@import "./baseColors.scss";

@font-face {
  font-family: "Graphik";
  // ?c=1 is to break a weird caching thing happening
  src: url("https://cdn.blisspointmedia.com/assets/fonts/Graphik-Regular.ttf?c=1");
}
@font-face {
  font-family: "Graphik";
  // ?c=1 is to break a weird caching thing happening
  src: url("https://cdn.blisspointmedia.com/assets/fonts/Graphik-RegularItalic.ttf?c=1");
  font-style: italic;
}
@font-face {
  font-family: "Graphik";
  // ?c=1 is to break a weird caching thing happening
  src: url("https://cdn.blisspointmedia.com/assets/fonts/Graphik-Medium.ttf?c=1");
  font-weight: 500;
}
@font-face {
  font-family: "Graphik";
  // ?c=1 is to break a weird caching thing happening
  src: url("https://cdn.blisspointmedia.com/assets/fonts/Graphik-MediumItalic.ttf?c=1");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Graphik";
  // ?c=1 is to break a weird caching thing happening
  src: url("https://cdn.blisspointmedia.com/assets/fonts/Graphik-Semibold.ttf?c=1");
  font-weight: 600;
}
@font-face {
  font-family: "Graphik";
  // ?c=1 is to break a weird caching thing happening
  src: url("https://cdn.blisspointmedia.com/assets/fonts/Graphik-SemiboldItalic.ttf?c=1");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Graphik";
  // ?c=1 is to break a weird caching thing happening
  src: url("https://cdn.blisspointmedia.com/assets/fonts/Graphik-Bold.ttf?c=1");
  font-weight: 700;
}
@font-face {
  font-family: "Graphik";
  // ?c=1 is to break a weird caching thing happening
  src: url("https://cdn.blisspointmedia.com/assets/fonts/Graphik-BoldItalic.ttf?c=1");
  font-weight: 700;
  font-style: italic;
}
// These fonts exist in the CDN but seem excessive for the app, and don't need to be downloaded by
// the client

// @font-face {
//   font-family: 'Graphik';
//   src: url('https://cdn.blisspointmedia.com/assets/fonts/Graphik-Thin.ttf');
//   font-weight: 100;
// }
// @font-face {
//   font-family: 'Graphik';
//   src: url('https://cdn.blisspointmedia.com/assets/fonts/Graphik-ThinItalic.ttf');
//   font-weight: 100;
//   font-style: italic;
// }
// @font-face {
//   font-family: 'Graphik';
//   src: url('https://cdn.blisspointmedia.com/assets/fonts/Graphik-Extralight.ttf');
//   font-weight: 200;
// }
// @font-face {
//   font-family: 'Graphik';
//   src: url('https://cdn.blisspointmedia.com/assets/fonts/Graphik-ExtralightItalic.ttf');
//   font-weight: 200;
//   font-style: italic;
// }
// @font-face {
//   font-family: 'Graphik';
//   src: url('https://cdn.blisspointmedia.com/assets/fonts/Graphik-Light.ttf');
//   font-weight: 300;
// }
// @font-face {
//   font-family: 'Graphik';
//   src: url('https://cdn.blisspointmedia.com/assets/fonts/Graphik-LightItalic.ttf');
//   font-weight: 300;
//   font-style: italic;
// }
// @font-face {
//   font-family: "Graphik";
//   src: url("https://cdn.blisspointmedia.com/assets/fonts/Graphik-Super.ttf");
//   font-weight: 900;
// }
// @font-face {
//   font-family: "Graphik";
//   src: url("https://cdn.blisspointmedia.com/assets/fonts/Graphik-SuperItalic.ttf");
//   font-weight: 900;
//   font-style: italic;
// }

body,
html,
#root {
  font-family: "Graphik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  min-height: 200px;
  overflow: hidden;
}
* {
  font-family: inherit !important;
}

@import "../node_modules/bootstrap/scss/bootstrap";

.btn,
.btn:focus,
.btn:active,
button,
button:focus,
button:active {
  /* Bootstrap adds this stupid blue border on focus */
  outline: none;
  box-shadow: none;
  cursor: pointer;
}

.dropdown-toggle::after {
  vertical-align: 0.17em !important;
}
.DateInput_fang {
  transform: translate(0px, 1px);
}

.bs-popover-bottom {
  margin-top: -1px;
}
.bs-popover-top {
  margin-bottom: -1px;
}

.metadataForm {
  min-height: 240px;
  max-height: 600px;
  overflow-y: auto;

  .placementLevelInputContainer {
    display: flex;
    flex-direction: column;

    .placementLevelInputBox {
      margin-top: 8px;
    }
  }
}

.clickthroughTypeCheckboxContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 4px;

  .clickthroughTypeCheckbox {
    margin-right: 8px;
  }
}

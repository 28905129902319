.offlineInputsContainer {
  .tableInputFieldGroup {
    margin: 0 0.5rem;
  }

  .BPMTable {
    .STInnerGrid {
      // Prevent horizontal scrolling from oversized inner grid
      width: 100% !important;
    }
  }
}

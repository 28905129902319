.progressCircle {
  position: relative;
}

.progressCircleBg {
  fill: none;
}

.progressCircleBar {
  fill: none;
  stroke-linecap: round;
  transition: stroke-dasharray 0.3s;
}

.progressCircleText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  text-align: center;
}
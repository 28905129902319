@use "sass:math";
@import "../../styles/Fonts.scss";
@import "../../utils/colors.scss";
@import "../../utils/sassHelpers.scss";

$item-gap: 16px;

// Remove parent padding
.pageSubHeader:has(.fetchFilterBar) {
  padding: 0px !important;
}

.fetchFilterBar {
  align-items: flex-start;
  display: flex;
  height: 46px;
  justify-content: space-between;
  max-width: 100%;
  padding: 8px 32px;
  width: 100%;
  .cl-dropdown {
    height: 28px;
    .cl-dropdown-toggle {
      align-items: center;
      border-radius: 52px !important;
      display: flex;
      height: 28px !important;
      .dt-text {
        align-items: center;
        display: flex;
      }
      .tokenContent {
        align-items: center;
        display: flex;
        gap: 4px;
      }
    }
    *::after {
      display: none;
    }
  }
  .cl-dropdown:has(.allowWrap) {
    height: fit-content !important;
    .cl-dropdown-toggle {
      height: fit-content !important;
      * {
        height: fit-content !important;
      }
      .tokenContent {
        display: flex;
        align-items: center;
        padding: 4px 0px;
        .tokenItemsContent {
          text-wrap: wrap !important;
          text-align: left;
        }
      }
    }
  }
  .cl-dropdown:has(.chipDisabled) {
    opacity: 0.5;
    cursor: pointer;
    :active {
      pointer-events: none;
    }
  }
  .presetDropdown {
    margin-right: $item-gap;
    width: fit-content;
    .cl-dropdown-toggle {
      background-color: $Neutral300 !important;
      color: $Brand100 !important;
      padding-right: 16px !important;
    }
    .cl-dropdown-toggle:hover {
      background-color: $Brand25 !important;
    }
    .dropdownMenuBodyContainer {
      min-width: 221px;
      width: fit-content;
      .dropdownMenuItemsContainer {
        overflow: auto !important;
        .dropdownMenuItem {
          padding-right: 0px !important;
          height: fit-content !important;
          .dropdownMenuItemLabel {
            text-wrap: nowrap;
            width: fit-content;
          }
          .filterPresetOptionExpander {
            align-items: center;
            display: flex;
            padding: 0px 6px;
            * {
              color: $Brand50;
              height: 22px;
              width: 22px;
            }
          }
        }
        .dropdownMenuItem.dropdownOpen {
          background-color: $Brand25;
          .dropdownMenuItemOptions {
            .filterPresetOptionExpander {
              * {
                border-radius: 6px;
                background-color: $Brand40;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
    .dropdownMenuApplyCancelContainer {
      padding-bottom: 16px;
      padding-left: 24px;
    }
    .dropdownMenuFooterContainer {
      background-color: $Brand25;
      padding: 0px;
      .addNewFilterButton {
        border-radius: 0px;
        color: $Brand100;
        font-family: Graphik !important;
        font-feature-settings: "clig" off, "liga" off;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        height: 100% !important;
        justify-content: flex-start;
        line-height: normal;
        padding: 0px 12px;
        width: 100% !important;
      }
    }
    .cl-dropdown.show {
      .cl-dropdown-toggle {
        background-color: $Brand50 !important;
        .dt-icon,
        .dt-text {
          color: #ffffff;
        }
      }
    }
  }
  .presetDropdown.presetSelected {
    .cl-dropdown-toggle {
      background-color: $Brand50 !important;
      color: #ffffff !important;
    }
    .cl-dropdown-toggle:hover {
      background-color: $Brand60 !important;
    }
  }
  .tokenDropdowns {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    gap: $item-gap;
    min-width: 0px;
    width: 100%;
    .cl-dropdown {
      display: flex;
      max-width: 100%;
      .cl-dropdown-toggle {
        max-width: 100%;
        .dt-text {
          max-width: 100%;
          * {
            max-width: 100%;
            .tokenContent {
              max-width: 100%;
              .tokenItemsContent {
                max-width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
          }
        }
      }
    }
    .cl-dropdown-toggle {
      background-color: #ffffff !important;
      border: 1px solid $Neutral300 !important;
      color: $Brand100 !important;
      padding-right: 8px !important;
      .tokenContent {
        .tokenCategory.tokenItemsContent {
          color: $Brand100;
          font-family: Graphik;
          font-feature-settings: "clig" off, "liga" off;
          font-size: 14px;
          font-style: normal;
          line-height: normal;
        }
        .tokenCategory {
          font-weight: 500;
        }
        .tokenItemsContent {
          font-weight: 400;
        }
        .tokenExpandContainer {
          align-items: center;
          display: flex;
          height: 28px;
          justify-content: center;
          padding-left: 2px;
          width: 20px;
          svg {
            height: 18px;
            width: 18px;
          }
        }
      }
    }
    .cl-dropdown-toggle:not(.hasItems):hover {
      background-color: $Brand25 !important;
    }
    .cl-dropdown-toggle.hasItems {
      background-color: $Brand25 !important;
    }
    .cl-dropdown-toggle.hasItems:hover {
      background-color: $Brand30 !important;
    }
    .dropdownMenuBodyContainer {
      min-width: 228px;
      max-width: 400px;
      .dropdownMenuItem {
        height: fit-content !important;
        .dropdownMenuItemLabel {
          color: $Brand100;
          font-family: Graphik;
          font-feature-settings: "clig" off, "liga" off;
          font-size: 12.92px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-wrap: nowrap;
        }
      }
    }
  }
  .controls {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: $item-gap;
    justify-content: flex-end;
    width: fit-content;
    .editFilterContainer {
      align-items: center;
      color: $Brand50;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      gap: 6px;
      height: 100%;
      justify-content: flex-end;
      padding-left: 8px;
      padding-right: 12px;
      .editFilterText {
        align-items: center;
        color: $Brand60 !important;
        display: flex;
        height: 100%;
        text-wrap: nowrap;
        width: fit-content;
        @include secondaryRegular;
      }
      .editFilterIcon {
        align-items: center;
        display: flex;
        height: 100%;
        svg {
          height: 18px;
          width: 18px;
        }
      }
    }
    .expandContainer {
      align-items: center;
      cursor: pointer;
      display: flex;
      height: 100%;
      svg {
        color: $Brand60;
        height: 18px;
        transform: rotate(90deg);
        width: 18px;
      }
    }
    .saveContainer {
      color: $Brand60;
      cursor: pointer;
      height: 28px;
      padding: 3px 6px;
      width: 34px;
      svg {
        height: 22px;
        width: 22px;
        vertical-align: unset;
      }
    }
    .saveContainer.disabled {
      cursor: not-allowed;
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
.fetchFilterBar.expanded {
  height: fit-content;
}

.filterPresetOptions {
  align-items: flex-start;
  background: #ffffff;
  border-radius: 6px;
  border: 2px solid $Brand50;
  box-shadow: 0px 4px 18px 0px rgba(148, 160, 184, 0.5);
  display: flex;
  flex-direction: column;
  height: fit-content;
  overflow: hidden;
  padding: 8px 0px;
  width: fit-content;
  .filterPresetOption {
    align-items: center;
    display: flex;
    gap: 6px;
    height: 28px;
    padding: 0px 8px;
    width: 100%;
    .filterPresetOptionIcon {
      align-items: center;
      display: flex;
      * {
        color: $Brand50;
        height: 18px;
        width: 18px;
      }
    }
    .filterPresetOptionText {
      align-items: center;
      color: $Brand100;
      display: flex;
      font-family: Graphik !important;
      font-feature-settings: "clig" off, "liga" off;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .filterPresetOption:hover {
    background-color: $Brand20;
  }
  .filterPresetOption:active {
    background-color: $Brand25;
  }
}

// Shared styles for all modals
.editModal,
.renameSaveAsNewModal,
.saveModal {
  display: flex !important;
  .modal-dialog {
    align-items: flex-start;
    display: flex;
    justify-content: center;
    margin-top: 120px;
    width: 800px;
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
  .modal-content {
    border-radius: 6px !important;
    border: 2px solid $Brand50 !important;
    padding-top: 8px !important;
    .modal-body {
      overflow: auto;
      > * {
        margin: 0px;
      }
    }
    .modal-footer {
      align-items: center;
      border-top: 1px solid $Neutral400;
      display: flex;
      gap: 16px;
      justify-content: flex-end;
      padding: 24px;
      > * {
        margin: 0px;
      }
    }
  }
}

.editModal {
  .modal-header {
    align-items: center;
    padding-right: 32px;
    .filterTitleContainer {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      min-height: 32px;
      .modalType {
        align-items: center;
        color: $Brand100;
        display: flex;
        font-family: Graphik !important;
        font-feature-settings: "clig" off, "liga" off;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        height: 32px;
        line-height: normal;
        padding-left: 16px;
        padding-right: 16px;
      }
      .filterTitle {
        align-items: center;
        display: flex;
        height: 32px;
        .modal-title {
          align-items: center;
          color: $Brand100;
          display: flex;
          font-family: Graphik !important;
          font-feature-settings: "clig" off, "liga" off;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          height: 32px;
          line-height: normal;
          padding-left: 16px;
          padding-right: 16px;
        }
        .editIconContainer {
          align-items: center;
          display: flex;
          height: 32px;
          padding-left: 6px;
          padding-right: 6px;
          width: 34px;
          svg {
            color: $Brand50;
            height: 22px;
            width: 22px;
          }
        }
      }
    }
    .buttonsContainer {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      gap: 4px;
      height: 100%;
      justify-content: flex-end;
      .toggle-button {
        align-self: flex-end;
      }
    }
  }
  .modal-body {
    padding: 0px;
    .filterPaneBody {
      display: flex;
      flex-direction: column;
      min-height: 0;
      min-width: 0;
      overflow: hidden;
      padding: 0px;
      .none {
        font-size: 12px;
        font-style: italic;
      }
      .compileError {
        color: red;
        font-size: 0.8rem;
        padding-top: 0.25rem;
      }
      .categoryContainer {
        align-items: center;
        border-bottom: 1px solid $Neutral400;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-height: 314px;
        min-height: 32px;
        overflow: hidden;
        padding: 8px 24px;
        .categoryHeader {
          align-items: center;
          cursor: pointer;
          display: flex;
          gap: 10px;
          height: 32px;
          justify-content: space-between;
          padding-left: 8px;
          padding-right: 8px;
          width: 100%;
          &.disabled {
            cursor: default;
          }
          .headerLabel {
            color: $Brand100;
            font-family: Graphik;
            font-feature-settings: "clig" off, "liga" off;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            width: 100%;
          }
          .includeExcludeToggleButtonContainer {
            align-items: flex-end;
            display: flex;
            height: 32px;
          }
          .expander {
            align-items: center;
            display: flex;
            svg {
              color: $Brand100;
              height: 18px;
              transform: rotate(90deg);
              width: 18px;
            }
          }
        }
        .categoryOptions {
          display: flex;
          flex-direction: column;
          max-height: 282px;
          overflow-y: hidden;
          width: 100%;
          .selectAllButtons {
            align-items: center;
            display: flex;
            gap: 0px;
          }
          .dropdownMenuContainer {
            border: unset !important;
            box-shadow: unset !important;
            padding: 0px;
            width: 100%;
            .dropdownMenuBodyContainer {
              width: 100% !important;
              .dropdownMenuItems {
                height: fit-content;
                max-height: 254px;
                overflow: auto;
                .dropdownMenuItem {
                  height: 32px;
                  padding-left: 8px !important;
                  padding-right: 8px !important;
                  width: 100%;
                }
              }
            }
          }
        }
      }
      .categoryContainer:last-child {
        border-bottom: unset;
      }
      .suggestionList {
        display: flex;
        flex-flow: row wrap;
        flex: 1;
        gap: 10px;
        min-height: 0;
        overflow: auto;
        padding-left: 16px;
        row-gap: 10px;
      }
    }
    .filterPaneBody:has(.filterPaneAdvancedBox) {
      padding: 24px 0px 24px 16px;
      .filterPaneAdvancedHeader {
        align-items: center;
        color: $Brand100;
        display: flex;
        font-family: Graphik !important;
        font-feature-settings: "clig" off, "liga" off;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        height: 32px;
        line-height: normal;
        padding-left: 16px;
        padding-right: 40px;
      }
      .filterPaneAdvancedBoxContainer {
        padding: 0px 32px 16px 16px;
        .filterPaneAdvancedBox {
          border-radius: 6px;
          border: 1px solid $Brand60;
          box-shadow: none;
          color: $Brand100 !important;
          font-family: Graphik;
          font-feature-settings: "clig" off, "liga" off;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          max-height: 500px;
          min-height: 134px !important;
        }
      }
    }
  }
}

// Shared styles for renameSaveAsNewModal and saveModal
.renameSaveAsNewModal,
.saveModal {
  .modal-content {
    max-width: 500px;
  }
  .modal-header {
    align-items: flex-end;
    display: flex;
    padding: 16px;
    .renameSaveAsHeader,
    .saveHeader {
      align-items: center;
      color: var(--brand-brand-100, #1f003f);
      display: flex;
      font-family: Graphik;
      font-feature-settings: "clig" off, "liga" off;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      height: 32px;
      line-height: normal;
      padding: 0px 16px;
      width: 100%;
    }
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    padding: 24px 24px 24px 32px;
    .renameSaveAsText {
      @include bodyBold;
      align-items: center;
      cursor: default;
      display: flex;
      height: 32px;
      padding-left: 8px;
    }
    .renameSaveAsInputContainer,
    .saveInputContainer {
      display: flex;
      padding: 0px;
      width: 100%;
      .renameSaveAsInput,
      .saveInput {
        align-items: flex-start;
        align-self: stretch;
        border-radius: 4px;
        border: 2px solid $Brand60;
        box-shadow: unset !important;
        color: $Neutral500;
        display: flex !important;
        flex-direction: column;
        flex-shrink: 0;
        font-family: Graphik !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        gap: 10px;
        height: 40px;
        justify-content: center;
        line-height: normal;
        padding: 8px 0px 8px 16px;
        transition: unset !important;
        width: 100%;
      }
      .renameSaveAsInput:focus,
      .saveInput:focus {
        color: $Brand100;
      }
    }
  }
}

.saveModal {
  .modal-body {
    display: flex;
    flex-direction: column;
    padding: 0px;
    > * {
      margin: 0px;
      padding: 16px;
    }
    .saveOptionContainer {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      gap: 4px;
      justify-content: space-between;
      .saveInputContainer {
        padding: 0px 8px 8px 40px;
      }
      .saveOption {
        align-items: center;
        cursor: pointer;
        display: flex;
        gap: 6px;
        height: 32px;
        padding: 0px 20px;
        .saveOptionTick {
          align-items: center;
          color: $Brand50;
          display: flex;
          * {
            height: 22px;
            width: 22px;
          }
        }
        .transparent {
          opacity: 0;
          pointer-events: none;
          cursor: default;
        }
        .saveOptionText {
          color: $Neutral1000;
          font-family: Graphik !important;
          font-feature-settings: "clig" off, "liga" off;
          font-size: 16px !important;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      .saveOption.onlyOption {
        cursor: default;
      }
    }
  }
}

@import "../utils/colors.scss";

.linkSelector {
  .dropdownToggle {
    font-size: 14px;
    background-color: white;
    color: $Info1;
    border: none;
    text-decoration-line: underline !important;
    background-color: white !important;
    color: $Info1 !important;
    padding: 0px 0px 6px 0px !important;
    text-underline-position: under;
    &:hover {
      background-color: white;
      color: $Info1;
      border-color: white;
    }
    &:focus {
      background-color: white;
      color: $Info1;
      border-color: white;
      box-shadow: none !important;
    }
  }
}

@import "../../utils/colors.scss";
@import "../../styles/Fonts.scss";

.decayWrapper {
  .lineWrapper {
    display: flex;
    height: 100%;
    padding-bottom: 0;
  }
}
.decayWrapper,
.satWrapper {
  flex-direction: column;
  padding-bottom: 15px;
  z-index: 1;
  .decayChartTitle,
  .satChartTitle {
    color: $Brand100;
    font-size: 14px;
    font-weight: 600;
  }

  .rightOfChart {
    width: 168px;
  }
}
.satChartXAxisTitle {
  color: $Brand100;
  font-size: 14px;
  font-weight: 600;
  left: -1rem;
  position: relative;
  top: -0.5rem;
}
.section4 {
  .left {
    .chartContainer:first-of-type {
      padding-bottom: 17px;
    }
    .contents {
      padding-bottom: 2px;
      .rightOfChart {
        position: relative;
        top: 1rem;
      }
    }
  }
  .right {
    .rightOfChart {
      position: relative;
      top: -5px;
    }
  }
  .chartContainer .contents {
    gap: 24px;
    padding-bottom: 0px;
    padding-top: 0px;

    .satWrapper {
      flex: auto;
      .satChartTooltip {
        background-color: white;
        border-radius: 6px;
        border: 2px solid $Neutral600;
        box-shadow: 0px 4px 18px 0px rgba(148, 160, 184, 0.5);
        display: flex;
        flex-direction: column;
        width: max-content;
        .satHeaderLabel {
          background-color: $Neutral200;
          border-top-right-radius: 6px;
          border-top-left-radius: 6px;
          color: $Brand100;
          font-size: 16px;
          font-weight: 400;
          justify-content: flex-end;
          padding-top: 8px;
          padding-left: 16px;
          width: 100%;

          .satTooltipHeaderItem {
            padding: 0 12px;
          }
        }
        .satTooltipHeaderItem {
          color: $Brand100;
        }
        .satChartXAxisTitle {
          left: -2rem;
          position: relative;
        }
        .subHeader {
          color: #9795a1;
          .label {
            font-weight: 500;
          }
          &.other {
            margin-top: 10px;
          }
        }
        .itemList {
          display: flex;
          flex-direction: column;
          margin-top: 8px;
          .itemRow {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            .itemWrapper {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              padding: 0 12px;
              .name,
              .satTooltipHeaderItem {
                font-size: 14px;
                font-weight: 500;
                display: flex;
                justify-content: flex-end;
              }
              .value {
                display: flex;
                justify-content: flex-end;
                font-size: 14px;
                padding: 0;
              }
              .line {
                width: 14px;
                height: 3px;
                margin-right: 12px;
              }
              &.dataLabel {
                max-width: 245px;
              }
            }
          }
        }
      }
      + .rightOfChart {
        @include secondaryRegular;
        display: flex;
        flex-direction: column;
        gap: 30px;
        &.hide {
          display: none;
        }
        .legend {
          width: 168px;
          flex: auto;
          height: 320px;
          overflow: auto;
          padding-right: 17px;
          justify-content: start;
          .legendItem {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 14px;
            margin-bottom: 15px;
            line-height: 16px;
            .line {
              width: 14px;
              height: 3px;
              margin-right: 12px;
            }
            .legendValue {
              align-items: center;
              display: flex;
              justify-content: space-between;
              width: 100%;
              .legendHeaderWrapper {
                display: flex;
                .legendHeader {
                  @include secondaryRegular;
                  margin-bottom: 6px;
                  &.inactiveLegend {
                    color: $Neutral500;
                  }
                }
              }
              .subHeaderWrapper {
                @include secondaryThin;
                align-items: center;
                display: flex;
                margin-bottom: 6px;
                &:last-of-type {
                  margin-bottom: 0;
                }
                .subHeaderTextWrap {
                  display: flex;
                  flex-wrap: wrap;
                }
              }
              .inactiveLegend {
                color: $Neutral500;
              }
            }
          }
        }
      }
    }
    .decayWrapper {
      .lineWrapper {
        .lineChart {
          .rightSideWrapper {
            .rightOfChart {
              height: 410px;
              .legend {
                padding-right: 17px;
                height: 410px;
              }
            }
          }
        }
      }
    }
  }
}

.medianLegendLabel {
  align-items: center;
  display: flex;
  gap: 9px;
  .medianText {
    color: $Brand100;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
  }
}

.performanceSnapshot {
  display: flex;
  flex: 1;
  flex-direction: column;
  .aboveChart {
    display: flex;
    align-items: center;
    .performanceSnapshotControls {
      display: flex;
      flex: 1;
      flex-direction: row;
      font-size: 12px;
      align-items: center;
      .performanceSnapshotControlsDropdown {
        margin-right: 2px;
      }
    }
  }
  .chartContents {
    display: flex;
    flex: 1;
    flex-direction: row;
    .performanceSnapshotChart {
      display: flex;
      flex: 1;
    }
    .leftOfChart {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 1rem;
    }
  }
}

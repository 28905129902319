@import "../utils/sassHelpers.scss";

$stripe-width: 6px;
$border-color: rgba(0, 0, 0, 0.4);

.linearBuyingTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  & > :first-child {
    margin-right: 2rem;
  }
  & > :not(first-child) {
    margin-right: 1rem;
  }
  .selectedControls {
    display: flex;
    align-items: center;
    & > * {
      margin-right: 0.5rem;
      height: 100%;
    }
    .label {
      font-size: 1rem;
      font-weight: normal;
    }
  }

  .measurement {
    margin-bottom: 0px;

    .btn {
      padding: 4px 8px 4px 8px;
      background-color: white;
      color: #4e4e50;
      font-weight: 500;
      border-color: #e6eaee !important;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.2rem;
    }
  }
}

.linearBuyingActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  & > * {
    margin-left: 0.5rem;
  }
}

.linearBuying {
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow: auto;
  padding: $page-padding;
  .leftSideContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    .tableView {
      display: flex;
      flex: 1;
      .grandTotalCell {
        font-weight: 500;
      }
    }

    .networkListContainer {
      display: flex;
      flex: 1;
      flex-direction: column;
      min-height: 0;

      .noOrders {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 2rem;
      }

      .filterBarContainer {
        display: flex;
        flex-direction: row;
        z-index: 0;
        margin-bottom: 0.5rem;
      }
      .networkList {
        overflow: visible;
      }
    }
    .summaryView {
      display: flex;
      flex: 1;
      flex-direction: row;
      overflow: hidden;
      .grandTotalCell {
        font-weight: 500;
      }
      .leftSide {
        margin-right: 0.5rem;
        display: flex;
        flex-direction: column;
        & > :first-child {
          flex: 2;
        }
      }
      .rightSide {
        display: flex;
        flex-direction: column;
      }
      & > * {
        width: 100%;
      }
    }
  }
}

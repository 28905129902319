@import "../../utils/colors.scss";

.icon-toggle-button {
  display: flex;
  flex-direction: row;
  width: fit-content;
  border-radius: 6px;
  &.lg {
    height: 32px;
  }
  &.sm {
    height: 28px;
  }
  .unselected {
    .bf-label {
      font-weight: 400;
    }
  }
  &.light {
    background-color: $Neutral200;
    .icon-toggle-item {
      background-color: $Neutral200;
      color: $Brand60;
      animation-duration: 0.5s;
      animation-direction: forwards;
      &.icon_0 {
        &:hover:not(.selected) {
          background-color: $Neutral400;
        }
        &.selected {
          background-color: $Brand50;
          color: $White;
          &.animated {
            animation-name: selectedUpdateLeft;
          }
        }
        &.unselected {
          background-color: $Neutral200;
          color: $Brand60;
          &.animated {
            animation-name: unselectedUpdateLeft;
          }
        }
      }
      &.icon_1 {
        &:hover:not(.selected) {
          background-color: $Neutral400;
        }
        &.selected {
          background-color: $Brand50;
          color: $White;
          &.animated {
            animation-name: selectedUpdateRight;
          }
        }
        &.unselected {
          background-color: $Neutral200;
          color: $Brand60;
          &.animated {
            animation-name: unselectedUpdateRight;
          }
        }
      }
      &.icon_2 {
        &:hover:not(.selected) {
          background-color: $Neutral400;
        }
        &.selected {
          background-color: $Brand50;
          color: $White;
          &.animated {
            animation-name: selectedUpdateRight;
          }
        }
        &.unselected {
          background-color: $Neutral200;
          color: $Brand60;
          &.animated {
            animation-name: unselectedUpdateRight;
          }
        }
      }
    }
  }
  &.dark {
    background-color: $Brand60;
    .icon-toggle-item {
      color: $Brand30;
      background-color: $Brand60;
      &:hover:not(.selected) {
        background-color: $Brand50;
        color: $White;
      }
      &.selected {
        background-color: $White;
        color: $Brand60;
      }
    }
  }
}

@keyframes selectedUpdateLeft {
  0% {
    color: $Brand60;
    background-image: linear-gradient(to right, $Neutral400 50%, $Brand50 50%);
    background-size: 400% 400%;
    background-position: left;
  }

  100% {
    background-position: right;
    color: $White;
  }
}

@keyframes selectedUpdateRight {
  0% {
    color: $Brand60;
    background-image: linear-gradient(to right, $Brand50 50%, $Neutral400 50%);
    background-size: 400% 400%;
    background-position: right;
  }

  100% {
    background-position: left;
    color: $White;
  }
}

@keyframes unselectedUpdateLeft {
  0% {
    color: $White;
    background-image: linear-gradient(to right, $Neutral400 50%, $Brand50 50%);
    background-size: 400% 400%;
    background-position: right;
  }

  100% {
    background-position: left;
    color: $Brand60;
  }
}

@keyframes unselectedUpdateRight {
  0% {
    color: $White;
    background-image: linear-gradient(to right, $Brand50 50%, $Neutral400 50%);
    background-size: 400% 400%;
    background-position: left;
  }

  100% {
    background-position: right;
    color: $Brand60;
  }
}

$vertical-padding: 0.5rem;
.kpiPicker {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-right: 20px;
  margin-top: -$vertical-padding;
  position: relative;

  .kpiPill {
    margin-top: $vertical-padding;
    &:not(:last-child) > button {
      margin-right: 10px;
    }
  }

  .kpiIdIcon {
    margin-top: $vertical-padding - 0.2rem;
    cursor: pointer;
  }
  .copiedToast {
    position: absolute;
    right: 1rem;
    top: 0.5rem;
    z-index: 1;
    pointer-events: none;
    & > * {
      padding: 0.25rem 0.75rem;
    }
  }
  &.noBreak {
    flex-flow: row nowrap;
  }
}
.kpiPickerKpiIDTooltip {
  z-index: 10000000;
  & > * {
    max-width: unset;
  }
}

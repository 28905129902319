@import "../utils/colors.scss";

.editKpiMappingContainer {
  display: flex;
  overflow: auto;
  height: 100%;
  flex-direction: column;
  background-color: white;
  margin: 16px;
  padding: 12px;
  border-radius: 16px;

  .editModeButton {
    width: 200px;
  }

  .kpiMappingEditControls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .editSection {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin-bottom: 12px;

    .crossChannelKpiColumn {
      width: 300px;
      border-right: 1px solid $Neutral400;
      margin-right: 12px;
      padding-right: 12px;
      .columnHeader {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 42px;
        color: $Brand100;
        height: 40px;
      }
      .crossChannelKpiItem {
        margin-bottom: 26px;
        height: 50px;
        display: flex;
        flex-direction: row;
        .deleteNewKpiButton {
          margin-right: 8px;
          height: 38px;
        }
      }
    }

    .scrollableSection {
      display: flex;
      flex: 1;
      flex-direction: row;
      overflow-x: scroll;

      .sourceColumn {
        padding-left: 12px;
        padding-right: 12px;
        border-right: 1px solid $Neutral400;
        .columnHeader {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 6px;
          padding-left: 12px;
          color: white;
          background-color: $Neutral200;
          text-align: start;
          color: $Brand100;
          border-radius: 8px;
          height: 40px;
          & > * {
            margin-right: 8px;
          }
        }
        .accountIdColumns {
          display: flex;
          flex-direction: row;
          .accountIdColumn {
            display: flex;
            flex-direction: column;
            min-width: max-content;
            margin-right: 12px;
            .accountIdLabel {
              color: $Brand100;
              margin-bottom: 12px;
              display: flex;
              flex-direction: row;
              .labelPrefix {
                margin-right: 4px;
              }
              .labelValue {
                font-weight: 500;
              }
            }
            .sourceItem {
              .sourceKpiPicker {
                height: 40px;
                display: flex;
                flex-direction: row;
                .sourceKpiDropdown {
                  width: 100%;
                  min-width: 200px;
                }
              }
              .rawSourceKpiCount {
                :first-child {
                  margin-right: 4px;
                }
                display: flex;
                flex-direction: row;
                color: $Neutral600;
                height: 24px;
                font-size: 14px;
                margin-bottom: 12px;
                padding-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}

@import "../utils/sassHelpers.scss";
@import "../utils/colors.scss";

$header-padding: 5px;
$app2-design-background-color: $Brand80;

.bpmPage {
  @include fullBox();
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100%;
  width: 100%;
  min-width: 700px;
  background-color: white;
  z-index: 1;
  .errorBoundary {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;
  }
  & > .header {
    z-index: 100;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    border-bottom: 1px solid #dee2e6;
    padding-top: $header-padding;
    min-height: 50px;
    &.app2Redesign {
      background-color: $app2-design-background-color;
    }
    .titleContainer {
      margin-bottom: $header-padding;
      margin-left: $page-padding;
      display: flex;
      flex-flow: row;
      align-items: center;
      .blissPointLogo {
        height: 30px;
        width: 100px;
        margin-right: 16px;
      }
      .divider {
        border-left: 2px solid white;
        height: 36px;
      }
      .title {
        margin-left: $redesign-side-padding-addon;
        margin-right: 1rem;
        font-size: 1.3rem;
        font-weight: 500;
        &.app2Redesign {
          color: white;
          margin-right: 32px;
        }
      }
      .navs {
        font-size: 0.8rem;
        flex-wrap: nowrap;
        white-space: nowrap;
        // app2Redesign for nav items
        .pageNavItem {
          font-size: 16px;
          font-weight: 500;
          color: $Brand30;
          margin-right: 20px;
          cursor: pointer;
          &.active {
            color: white;
            text-decoration: underline;
            text-underline-offset: 8px;
          }
        }
      }
    }
    .actions {
      flex: 1;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: $header-padding;
      margin-right: $header-padding;
      &.app2Redesign {
        margin-right: 32px;
      }
    }
  }
  // app2Redesign for subheader
  .pageSubHeader {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: center;
    min-height: 40px;
    padding: $header-padding;
    background-color: white;
    border-bottom: 1.5px solid #cbd2e1;
  }
  & > .body {
    flex: 1;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    justify-content: stretch;
    flex-direction: column;
    background-color: $redesign-background-color;
  }
}

.BPMModalAlert {
  margin-bottom: 0 !important;
  .modalFooter {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    & > *:not(:first-child) {
      margin-left: 1rem;
    }
  }
}

.newVersionAlertBar {
  z-index: 101;
  background-color: #ffff93;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #666666;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

::-webkit-scrollbar {
  width: $scrollbar-width;
  height: $scrollbar-width;
}
/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* Handle */
::-webkit-scrollbar-thumb {
  // -webkit-border-radius: 10px;
  // border-radius: 10px;
  background: rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}
.sleekScroll {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  // /* Handle */
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0);
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  &:hover {
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }
  }
}

@import "../../utils/colors.scss";

.stacked-toggle-button {
  display: flex;
  flex-direction: column;
  width: fit-content;
  border-radius: 6px;
  .unselected {
    .bf-label {
      font-weight: 400;
    }
  }

  background-color: $Neutral200;
  .stacked-toggle-item {
    background-color: $Neutral200;
    color: $Brand100;
    &:hover:not(.selected) {
      background-color: $Neutral300;
    }
    &.selected {
      background-color: $White;
      border: 1px solid $Brand50;
      color: $Brand100;
      &.animated {
        animation-name: selectedUpdateTop;
      }
    }
  }
}

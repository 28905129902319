.streamingBuyingMoveFlightModal {
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    .headerIcon {
      margin-right: 0.75rem;
    }
    & > * {
      margin-right: 0.25rem;
    }
  }
  .helpButton {
    padding: 0;
    cursor: help !important;
  }
  .help {
    padding: 1.25rem;
    background-color: #dddddd;
    font-size: 0.85rem;
    margin: 0.5rem 0 1rem;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
  .flightInfo {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    & > * {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      min-width: 0;
      .labeledItem {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex-shrink: 1;
        .label {
          font-weight: 500;
          margin-right: 0.5rem;
        }
      }
    }
  }
  .orderListContainer {
    margin-top: 1rem;
    max-height: 300px;
    overflow-y: auto;
    &.loading {
      min-height: 170px;
    }
    .orderRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .applyToSubsequent {
    display: flex;
    flex-direction: row;
    align-items: center;
    .label {
      margin-left: 0.5rem;
      cursor: pointer;
    }
    .helpButton {
      margin-top: 3px;
      margin-left: 0.5rem;
    }
  }
  .savingBox {
    background-color: rgba(255, 255, 255, 0.7);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
}

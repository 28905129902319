@use "sass:math";
@import "../../../utils/colors.scss";
@import "../../../styles/Fonts.scss";
$circle-diameter: 14px;

.chart-tooltip {
  background-color: white;
  border-radius: 6px;
  border: 2px solid $Neutral600;
  box-shadow: 0px 4px 18px 0px rgba(148, 160, 184, 0.5);
  display: flex;
  flex-direction: column;

  .headerLabel {
    background-color: $Neutral200;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    color: $Brand100;
    font-size: 16px;
    font-weight: 400;
    padding: 8px 16px 0 16px;
    width: 100%;
  }

  .subHeader {
    color: #9795a1;
    .label {
      font-weight: 500;
    }
    &.other {
      margin-top: 10px;
    }
  }

  .itemList {
    padding: 10px 14px 14px 14px;
    display: flex;
    flex-direction: column;
    .itemRow {
      align-items: center;
      display: flex;
      margin-bottom: 8px;
      .circle {
        width: $circle-diameter;
        height: $circle-diameter;
        border-radius: math.div($circle-diameter, 2);
        margin-right: 12px;
      }
      .square {
        border-radius: 1px;
        flex-shrink: 0;
        width: 16px;
        height: 12px;
        margin-right: 6px;
      }
      .line {
        width: $circle-diameter;
        height: 3px;
        margin-right: 12px;
      }
      .name {
        @include secondaryRegular;
        font-size: 14px;
        margin-right: 3rem;
      }
      .value {
        @include secondaryThin;
        margin-left: auto;
        font-size: 14px;
      }
    }
  }
  .footer {
    font-size: 11px;
    max-width: 250px;
    word-wrap: break-word;
  }
}

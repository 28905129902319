@import "../utils/colors.scss";

.brandEquityRightActions {
  display: flex;
  gap: 16px;
  padding-right: 32px;
  .brandEquityGroupByDropdown::before {
    content: "Brand Health Metric:\00a0 ";
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    font-size: 16px;
  }
  .brandEquityArchiveActions {
    display: flex;

    .brandEquityArchiveDropdown::before {
      content: "Model Run:";
      font-weight: 500;
      font-style: normal;
      line-height: normal;
      font-size: 16px;
      padding-right: 0.275em;
    }
    .internal {
      border-top-right-radius: 0%;
      border-bottom-right-radius: 0%;
      margin-right: 0px;
    }
    .archiveToggleButton {
      white-space: nowrap;
      border-top-left-radius: 0%;
      border-bottom-left-radius: 0%;
      margin-right: 0px;
    }
  }
}

.brandEquityPage {
  padding: 16px;
  ::-webkit-scrollbar-thumb {
    background-color: $Neutral400;
  }
}

.pageSubHeader {
  .subHeaderItemAtEnd {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 60px;
    .brandEquityLevelDropdown::before {
      content: "Group by:";
      font-weight: 500;
      font-style: normal;
      line-height: normal;
      font-size: 16px;
      padding-right: 0.275em;
    }
  }
}
